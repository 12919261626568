import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '../../base/InputLabel'
import { withStyles } from '@material-ui/core/styles'

import ItemFormInputAspects from './ItemFormInputAspects'
import fetchItemAspectsForCategory from '../../../actions/fetchItemAspectsForCategory'
import CategoryHelpers from '../merchants/helpers/categoryHelpers'
import { EE, Events } from '../../../message'

import {
    Grid,
    Paper,
    //Popover, TODO Sean
    TextField,
    Typography,
} from '@material-ui/core';

const styles = theme => ({
    root: {
        border: 'none',
    },
    input: {
        border: 'none',
    },
    inputFocused: {
        border: 'none',
    },
    popper: {

    },
})

class ItemFormInputCategories extends React.Component {

    constructor(props) {
        super(props);

        this.categoriesLookup = CategoryHelpers.lookupTableForMerchant(props.merchantName)
        this.categories = CategoryHelpers.categoriesForMerchant(props.merchantName)

        console.log('Categories..', props)

        this.state = {
            categoryPath: props.defaultCategoryPath || [],
            leafCategoryID: null,
        };

        this.onChangeFns = [0, 1, 2, 3, 4, 5, 6].map(function (index) {
            return function (event, value) {
                console.log('Change at level', index, ' with value', value)

                const categoryPath = this.state.categoryPath
                let updatedCategoryPath = []
                if (categoryPath.length < index) {
                    return console.error(`Invalid category change with category path length: ${categoryPath.length}, index: ${index}`)
                } else if (index === 0) {
                    updatedCategoryPath = [ value ].filter(Boolean)

                } else {
                    updatedCategoryPath = categoryPath.slice(0, index)
                            .concat([ value ])
                            .filter(Boolean)
                }
                this.setCategoryPath(updatedCategoryPath)
            }
        })
        this.setCategoryPath = this.setCategoryPath.bind(this)
        this.onReceivedPoshmarkPath = this.onReceivedPoshmarkPath.bind(this)
        this.onChangeFns = this.onChangeFns.map(fn => fn.bind(this))
        this._summaryFragments = this._summaryFragments.bind(this)
        this._isCompleted = this._isCompleted.bind(this)
        this._fetchAspects = this._fetchAspects.bind(this)
        this._fetchAspectsForCategoryPath = this._fetchAspectsForCategoryPath.bind(this)
        this._isMounted = false

        // 7 levels should be the maximum number of possible levels
        this.autocompleteRefs = [
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
            React.createRef(),
        ]
    }

    componentDidMount() {
        this._isMounted = true

        const categoryPath = this.props.defaultCategoryPath || []
        this._fetchAspectsForCategoryPath(categoryPath)

        EE.addListener(Events.ItemForm.DID_PROVIDE_POSHMARK_CATEGORY_PATH, this.onReceivedPoshmarkPath)
    }

    componentWillUnmount() {
        this._isMounted = false

        EE.removeListener(Events.ItemForm.DID_PROVIDE_POSHMARK_CATEGORY_PATH, this.onReceivedPoshmarkPath)
    }


    componentDidUpdate(prevProps, prevState) {

        if (prevProps.hidden && !this.props.hidden) {
            // Just became visible
            //
            this._fetchAspects()
        }
    }

    setCategoryPath(updatedCategoryPath) {
        this.setState({
            categoryPath: updatedCategoryPath
        })
        this._fetchAspectsForCategoryPath(updatedCategoryPath)

        // sean TODO this is the hackiest shit but currently the Autocomplete onChange callback happens BEFORE category values
        // are available in the DOM (which is how we validate the form). So add this timeout here
        // so that the values are available when we trigger our onChange callback
        setTimeout(() => {
            if (this.props.onChange) {
                this.props.onChange()
            }
        }, 0)
    }

    onReceivedPoshmarkPath(poshmarkCategoryPath = []) {
        const { categoryPath = [] } = this.state
        const { merchantName } = this.props

        // Update the path only if we do not have one currently
        if (categoryPath.length === 0) {
            const merchantCategoryPath = CategoryHelpers.mappedCategoryPath(poshmarkCategoryPath, merchantName)
            if (merchantCategoryPath && merchantCategoryPath.length) {
                this.setCategoryPath(merchantCategoryPath)
            }
        }
    }

    onClickCategoriesSummary() {
        let reversedRefs = this.autocompleteRefs.slice()
        reversedRefs.reverse()

        const autocompleteRef = reversedRefs.find(ref => ref.current)
        autocompleteRef.current.click()
    }

    _fetchAspectsForCategoryPath(categoryPath = []) {
        const options = this._optionsForCategoryPath(categoryPath)
        if (this._isCompleted(options, categoryPath) && categoryPath.length > 0) {
            console.log('About to fetch category aspects')
            return this._fetchAspects(categoryPath[categoryPath.length - 1])
        }
    }

    _fetchAspects(key = null) {
        const { merchantName, item } = this.props
        if (!key) {
            if (!this.state || !this.state.categoryPath || !this.state.categoryPath.length > 0) {
                return
            }
            key = this.state.categoryPath[this.state.categoryPath.length - 1]
        }
        if (!merchantName) {
            return
        }

        return fetchItemAspectsForCategory(merchantName, key).then(aspects => {
            if (!this._isMounted || !aspects) {
                return
            }

            const aspectsDefaultValues = CategoryHelpers.autoFilledAspectsForMerchant(
                merchantName,
                item,
                aspects,
            )

            console.log(`Setting category aspects for ${merchantName}.. have default values`, aspectsDefaultValues)
            this.setState({
                leafCategoryID: key,
                aspectsData: aspects,
                aspectsDefaultValues,
            })
        })
    }

    // The category form is completed
    //
    _isCompleted(options, categoryPath) {
        if (!options) {
            console.error('Options are required in _isCompleted')
        }
       return categoryPath && categoryPath.length > 0 && categoryPath.length === options.length
    }

    // Return the option keys available at each depth level
    //
    // Returns array of array of strings (category keys) along our provided category path
    _optionsForCategoryPath(categoryPath = []) {

        let results = []
        let remainingCategoryPath = [...categoryPath]
        let currentCategories = this.categories

        while (currentCategories) {

            results.push(Object.keys(currentCategories))

            if (remainingCategoryPath.length === 0) {
                return results
            }

            let currentCategoryKey = remainingCategoryPath.shift()
            let currentCategory = currentCategories[currentCategoryKey]

            if (!currentCategory || !currentCategory.children) {
                return results
            }

            currentCategories = currentCategory.children
        }
    }

    _summaryFragments() {

        let displayNames = []
        let remainingCategoryPath = [...this.state.categoryPath]
        let currentCategories = this.categories

        const formatIntoFragments = (names) => {
            let fragments = []
            for (let i = 0; i < names.length; i++) {
                if (i < names.length - 1) {
                    fragments.push(<span key={`${i}`}>{names[i]}</span>)
                    fragments.push(<span key={`${i}a`}> > </span>)
                } else {
                    fragments.push(<span key={`${i}`}><b>{names[i]}</b></span>)
                }
            }
            return fragments
        }

        while (remainingCategoryPath.length > 0) {

            let currentCategoryKey = remainingCategoryPath.shift()
            let currentCategory = currentCategories[currentCategoryKey]

            if (!currentCategory) {
                return formatIntoFragments(displayNames)
            }

            displayNames.push(currentCategory.display)

            if (!currentCategory.children) {
                return formatIntoFragments(displayNames)
            }

            currentCategories = currentCategory.children
        }
        return formatIntoFragments(displayNames)
    }

    render() {
        const { merchantName, item, loading, onChange = () => {} } = this.props
        const { aspectsData, aspectsDefaultValues = {} } = this.state
        const options = this._optionsForCategoryPath(this.state.categoryPath)

        // the "false" category will represent the newly unfilled option, if that level of category selection exists
        const categoryPath = this.state.categoryPath.concat([ false ])
        // e.g. False if is an Etsy path and it tries to use the Poshmark category IDs
        const shouldShowSummaryForPath = categoryPath.length > 2 && this.categoriesLookup[categoryPath[0]]
        const showAspects = (
            this.state.categoryPath.length > 0 &&
            this.state.categoryPath[this.state.categoryPath.length - 1] === this.state.leafCategoryID
            && aspectsData &&
            Object.keys(aspectsData).length > 0
        )

        return (
            <div>
                { shouldShowSummaryForPath &&
                    <Typography
                        variant="body1"
                        onClick={this.onClickCategoriesSummary.bind(this)}
                        style={{
                          background: 'rgb(245, 245, 245)',
                          padding: '12px 5px',
                          fontSize: 16,
                          marginBottom: 20,
                          cursor: 'pointer',
                        }
                    }>
                        {this._summaryFragments()}
                    </Typography>
                }
                <InputLabel>
                    Category
                </InputLabel>
                <div>
                    <Grid container spacing={2}>
                        {
                            categoryPath.map((categoryKey, idx) => {

                                let categoryKeyAtIndex = this.state.categoryPath[idx] || ''
                                if (
                                    merchantName === 'mercari' ||
                                    merchantName === 'etsy' ||
                                    merchantName === 'facebook'
                                ) {
                                    // Mercari/Etsy/Facebook specifically have a weird Number/String mixup issue for categoryKeys
                                    categoryKeyAtIndex = String(categoryKeyAtIndex)
                                }

                                let defaultValue = ''
                                if (
                                    options[idx] &&
                                    options[idx].length &&
                                    categoryKeyAtIndex &&
                                    options[idx].indexOf(categoryKeyAtIndex) > -1
                                ) {
                                    defaultValue = categoryKeyAtIndex
                                }

                                return (
                                    (options[idx] && options[idx].length) ?
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        key={`${merchantName}categoryKey${idx + 1}`}
                                    >
                                        <Autocomplete
                                          id={`${merchantName}categoryKey${idx + 1}`}
                                          ref={this.autocompleteRefs[idx]}
                                          clearOnEscape
                                          options={options[idx]}
                                          getOptionLabel={optionKey => (this.categoriesLookup[optionKey] || {}).display || '' }
                                          filterOptions={(options, state) => {
                                            // Options is the list of keys (strings). Get the rest of the metadata
                                            let fullOptions = options.map(optionKey => this.categoriesLookup[optionKey])
                                            const inputDisplay = state.inputValue.toLowerCase()
                                            return fullOptions
                                                .filter(fullOption => {
                                                    if (!fullOption || !fullOption.display) {
                                                        return false
                                                    }
                                                    const display = fullOption.display.toLowerCase()
                                                    return (
                                                        display === 'none' ||
                                                        display.indexOf(inputDisplay) > -1 ||
                                                        display.indexOf('other') > -1
                                                    )
                                                })
                                                .map(fullOption => fullOption.id)
                                          }}
                                          renderInput={params => (
                                            <TextField {...params} variant="outlined" fullWidth />
                                          )}
                                          style={{ /* See this to improve style https://material-ui.com/api/autocomplete/*/
                                              borderRadius: 0,
                                              background: 'rgb(245, 245, 245)',
                                              border: 'none',
                                              fontSize: `1rem`,
                                              lineHeight: `1.1875em`,
                                          }}
                                          size="small"
                                          onChange={this.onChangeFns[idx]}
                                          placeholder="Select Category ..."
                                          defaultValue={defaultValue}
                                          className={!loading && categoryKeyAtIndex === '' ? 'Mui-error' : null}
                                          data-key={categoryKeyAtIndex}
                                        />
                                    </Grid> :
                                    null
                                )
                            })
                        }
                    </Grid>
                    { showAspects &&
                        <ItemFormInputAspects
                            aspectsData={aspectsData || []}
                            defaultValues={aspectsDefaultValues || {}}
                            item={item}
                            merchantName={merchantName}
                            onChange={onChange}
                        />
                    }
                </div>
            </div>
        );

    }
}

export default withStyles(styles)(ItemFormInputCategories)
