import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { withStyles } from '@material-ui/core/styles'

import { 
  Fab,
  Grid,
} from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete'

const type = 'Image' // Need to pass which type element can be draggable

const styles = {
    removeBtn: {
      transition: '250ms ease',
      position: 'absolute',
      opacity: 0,
      top: -15,
      right: -15,
      width: 36,
      height: 36,
      zIndex: 100,
    },
    imageItem: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      WebkitBoxPack: 'center',
      WebkitBoxAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      lineHeight: 1.1,
      transition: 'background 250ms ease 0s',
      background: 'rgba(116,151,240,0)', // LJ color
      padding: 20,
    },
    image: {
      paddingTop: "100%",
      backgroundSize: "contain",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
    },
    imageContainer: {
      position: 'relative',
      display: 'block',
      width: '100%',
      cursor: 'move',
      color: 'transparent',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'rgb(203, 203, 203)',
      borderImage: 'initial',
      padding: 0,
      '&:hover $removeBtn': {
        opacity: 1
      },
      '&:hover $count': {
        color: 'white',
      },
    },
    count: {
      position: "absolute",
      left: 10,
      bottom: 10,
      lineHeight: '20px',
      height: 20,
      textAlign: "center",
      fontSize: "80%",
      width: 20,
      borderRadius: "100%"
    },
}


const Image = ({ image, index, moveImage, onDelete, classes }) => {
  const ref = useRef(null)

  const [, drop] = useDrop({
    accept: type,
    hover(item) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Move the content
      moveImage(dragIndex, hoverIndex)
      // Update the index for dragged item directly to avoid flickering when half dragged
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type, id: image.id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  // initialize drag and drop into the element
  drag(drop(ref))
  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 0 : 1 }}
      className={classes.imageContainer}
    >
      <Fab onClick={onDelete(index)}
          aria-label="Delete"
          className={classes.removeBtn}
      >
          <DeleteIcon />
      </Fab>
      <div
       className={`image-list-item ${classes.imageItem}`}
      >
        <div className='text'>Drag to re-order</div>
      </div>
      <div
        className={classes.image}
        style={{
          backgroundImage: `url(${image.url || image.data})`,
        }}
      >
      </div>
      <div
        className={classes.count}
      >
        <div>{index + 1}</div>
      </div>
    </div>
  )
}

const ItemFormImageList = ({ images, moveImage, onDelete, classes }) => {
  const renderImage = (image, index) => {
    return (
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3} key={`${image.id}-image`}>
        <Image
          image={image}
          index={index}
          moveImage={moveImage}
          onDelete={onDelete}
          classes={classes}
        />
      </Grid>
    )
  }

  return (
    <Grid container alignItems='center' spacing={2} style={{ marginTop: 20 }}>
      {images.map(renderImage)}
    </Grid>
  )
}

export default withStyles(styles)(ItemFormImageList)
