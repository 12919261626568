import React from 'react'
import moment from 'moment'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'

import {
  Link,
  withRouter,
} from 'react-router-dom'

import MoreVertIcon from '@material-ui/icons/MoreVert'

import { withStyles } from '@material-ui/core/styles'

import MerchantIcon from '../icons/MerchantIcon'

import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import deleteItem from '../../actions/deleteItem'
import copyItem from '../../actions/copyItem'

import BadgeLabel from '../base/BadgeLabel'
import ItemHelpers from '../../item'
import Store from '../../store'

const cardPadding = 12

const styles = theme => ({
  card: {
    borderRadius: 6,
  },
  row: {
    cursor: 'pointer',
  },
  image: {
    maxWidth: 100,
    maxHeight: 100,
    padding: 0,
    margin: 0,
  },
  rowSubtitleItem: {
    marginRight: 20,
  },
  cardContent: {
    minHeight: 124, // Hack this is delicate based on height of a card w a title w two lines
  },
  moreIcon: {
    root: {
      padding: 4,
    },
  }
})

class ItemCard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      moreMenuTarget: null,
    }
  }

  onClickMoreMenu(e) {
    this.setState({
      moreMenuTarget: e.currentTarget,
    })
  }

  onCloseMoreMenu() {
    this.setState({
      moreMenuTarget: null,
    })
  }

  onClickCreateCopyGenerator(item) {
    return (() => {
      copyItem(item)
      this.setState({
        moreMenuTarget: null,
      })
    }).bind(this)
  }

  onClickDeleteItemGenerator(item) {
    return (() => {
      if (window.confirm(`Are you sure you want to delete "${item.title || ''}"?`)) {
        deleteItem(item)
      }
      this.setState({
        moreMenuTarget: null,
      })
    }).bind(this)
  }

  onClickDetailLinkGenerator(item, merchantName = '') {
    return (() => {

      console.log('Saving scroll position: ')
      const { item, index, history } = this.props

      const gridRef = Store.getGridRef()
      if (gridRef && gridRef.current) {
        Store.setItemIndex(index)
      } else {
        Store.setVerticalScrollPosition(window.scrollY)
      }
      history.push(`/item/${item.id}/${merchantName}`)

    }).bind(this)
  }

  render() {
    const { classes, item, style = {} } = this.props
    const buttonForMerchant = (merchantName) => {
      if (
        !item.merchants ||
        !item.merchants[merchantName] ||
        !item.merchants[merchantName].listed
      ) {
        return null
      }
      return (
        <Link
          to={`/item/${item.id}/${merchantName}`}
          key={merchantName}
          onClick={this.onClickDetailLinkGenerator(item, merchantName).bind(this)}
        >
          <div
            className='cardMerchantIcon'
            style={{
              display: 'inline-block',
              marginRight: 12,
            }}
          >
            <MerchantIcon
              merchantName={merchantName}
              style={{
                borderRadius: 16,
                height: 32,
                width: 32,
                overflow: 'hidden',
                backgroundColor: 'white',
                border: '0.5px solid #aaa',
              }}
            />
          </div>
        </Link>
      )
    }
    return (
      <Card
        className={classes.card}
        style={{
          ...style,
          width: style.width - (2 * cardPadding),
          height: style.height - (2 * cardPadding),
          left: style.left + cardPadding,
          padding: cardPadding,
        }}
      >
        <div style={{
          position: 'relative',
          marginTop: -1 * cardPadding,
          marginLeft: -1 * cardPadding,
          marginRight: -1 * cardPadding,
        }}>
          <Link
            to={`/item/${item.id}`}
            onClick={this.onClickDetailLinkGenerator(item).bind(this)}
          >
            <CardMedia
              component="img"
              alt="product"
              height={250}
              image={item.imageURLs && item.imageURLs.length > 0 ? item.imageURLs[0] : 'https://via.placeholder.com/250'}
            />
          </Link>
          <div style={{
            position: 'absolute',
            bottom: 12,
            left: 12,
          }}>
            {
              [
                buttonForMerchant('ebay'),
                buttonForMerchant('etsy'),
                buttonForMerchant('facebook'),
                buttonForMerchant('mercari'),
                buttonForMerchant('poshmark'),
              ]
            }
          </div>
          <div style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}>
            <IconButton
              aria-controls="more-menu"
              aria-label="more-menu"
              onClick={this.onClickMoreMenu.bind(this)}
              className={classes.moreIcon}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="more-menu"
              keepMounted
              anchorEl={this.state.moreMenuTarget}
              open={Boolean(this.state.moreMenuTarget)}
              onClose={this.onCloseMoreMenu.bind(this)}
            >
              <MenuItem onClick={this.onClickCreateCopyGenerator(item).bind(this)}><FileCopyIcon /> Create a copy</MenuItem>
              <MenuItem onClick={this.onClickDeleteItemGenerator(item).bind(this)}><DeleteIcon /> Delete</MenuItem>
            </Menu>
          </div>
        </div>
        <CardContent
          className={classes.cardContent}
          style={{
            position: 'relative',
            cursor: 'pointer',
            padding: '12px 4px 12px 4px',
          }}
          onClick={this.onClickDetailLinkGenerator(item).bind(this)}
        >
          <Typography gutterBottom variant="h5" component="h2">
            {item.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
           {item.createdOn && item.createdOn.seconds ? moment(item.createdOn.seconds * 1000).format('MM/DD/YY') : ''}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            ${item.price} {item.sku && ` | ${item.sku}`}
          </Typography>
          { ItemHelpers.isSold(item) &&
            <div style={{
              position: 'absolute',
              bottom: 32,
              right: 20,
            }}>
              <BadgeLabel
                sold
                text="Sold"
              />
            </div>
          }
        </CardContent>
      </Card>
    )
  }
}

export default withRouter(withStyles(styles)(ItemCard))
