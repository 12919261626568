
import React from 'react'
import iconStyles from './iconStyles'

const EtsyIcon = ({ style }) => (
  <div style={{
    ...iconStyles,
    ...style,
  }}>
    <svg viewBox="0 0 150 150"><path d="M28 66.7V50.5c0-.6 0-1 1-1h13.6c2.4 0 3.7 2 4.6 6l.8 3h2.3c.4-8.7.8-12.5.8-12.5s-5.7.7-9.2.7H24.4l-9.4-.3V49l3.2.5c2.2.4 2.7 1 3 3V84c0 2-.8 2.6-3 3l-3.2.6V90l9.4-.2H40l11.6.3 1.6-13H51l-2.3 5.5c-2 4.2-4.6 4.5-7.5 4.5h-9c-3 0-4.3-1.2-4.3-3.8V69.5s6.4 0 8.6.2c1.7 0 2.7.6 3.2 3l.8 3H43V68l.3-8h-2.6l-.8 3.4c-.6 2.3-1 2.7-3.3 3-2.6.3-8.8.2-8.8.2zm35-14.5c-.8 3.7-1.7 6.6-4.6 8.5-1.8 1.2-3.6 1.6-4.3 1.7v2.2h5.3V83c0 5.2 3.4 7.7 8 7.7 3.5 0 7-1.5 8.3-4.6l-1.3-1.5c-.6 1-2.5 2.5-5 2.5s-4-1.8-4-6.3V64.4l9 .7.4-4-9.2.3v-9H63zm19 28.3h-2.2V89s4.5 1.6 9 1.6c6.3 0 11.2-3 11.2-9 0-10-15-8.4-15-15.2 0-2.8 2.5-4 5.2-4 2.2 0 4 1 4.3 2L96 69l2.2-.2c.2-2.4.3-5.2.7-7.4-2-.8-6-1.3-8.5-1.3-6 0-10.6 2.7-10.6 8.4 0 10 14.6 8 14.6 15.4 0 2.6-1.6 4.5-5.2 4.5-3.4 0-5-1.8-5.7-3.5L82 80.5zm36 7c-3.5 9.6-7.7 12-11.5 12-1.7 0-2.4-.6-2.7-1.7l-1-4.7-2.4.2c-.4 2.8-1 5.8-1.6 8.3 1.5 1.2 4 1.8 6 1.8 4 0 10.3-.6 16-14.2l9.6-22.7c.8-1.8 1-2 3.4-3l1.2-.4v-2.2l-5.6.3-6-.2v2l1.4.6c1.6.5 2.3 1 2.3 2.2 0 .5 0 1-.4 2-1 2.2-6.4 15.7-8 18.6l1.6-.6c-2.6-6.5-6.6-17.4-7.2-19.3l-.2-1c0-1 .7-1.6 2-2l2-.4v-2.2l-8 .3-6.6-.2v2l1 .5c1.6.5 2 1 2.8 3 5 11.4 7.3 17.6 10.4 25.7l1.5-4.6z" fill="#F27224"></path></svg>
  </div>
)

export default EtsyIcon
