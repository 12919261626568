import colors from '../../styles/colors'

const primaryColor = "#9c27b0";
const warningColor = "#ff9800";
const dangerColor = "#f44336";
const successColor = "#4caf50";
const infoColor = "#00acc1";
const roseColor = "#e91e63";
const grayColor = "#999999";

const navPillsStyle = theme => ({
  root: {
    marginTop: 20,
    paddingLeft: 0,
    marginBottom: 0,
    lineHeight: 24,
    textTransform: "uppercase",
    fontSize: 12,
    fontWeight: 500,
    position: "relative",
    display: "block",
    color: "inherit",
  },
  displayNone: {
    display: "none !important"
  },
  horizontalDisplay: {
    display: "block"
  },
  pills: {
    float: "left",
    position: "relative",
    display: "block",
    borderRadius: 30,
    minWidth: 60,
    textAlign: "center",
    transition: "all .3s",
    padding: 0,
    color: "#555555",
    opacity: 1,
    maxWidth: "100%",
    margin: "0 5px"
  },
  pillsSmall: {
    float: 'right !important',
    minWidth: '36px !important',
    minHeight: '36px !important',
  },
  pillsWithIcons: {
    borderRadius: 4
  },
  tabIconSmall: {
    width: 20,
    height: 20,
    display: "block",
    margin: "8px 0 !important",
    "&, & *": {
      letterSpacing: "normal !important"
    },
  },
  tabIcon: {
    width: 30,
    height: 30,
    display: "block",
    margin: "15px 0 !important",
    "&, & *": {
      letterSpacing: "normal !important"
    }
  },
  horizontalPills: {
    width: "100%",
    float: "none !important",
    "& + button": {
      margin: "10px 0"
    }
  },
  contentWrapper: {
    marginTop: 20
  },
  primary: {
    "&,&:hover": {
      color: "#FFFFFF",
      backgroundColor: colors.listingjoyhex,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4)"
    }
  },
  info: {
    "&,&:hover": {
      color: "#FFFFFF",
      backgroundColor: infoColor,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4)"
    }
  },
  success: {
    "&,&:hover": {
      color: "#FFFFFF",
      backgroundColor: successColor,
      boxShadow:
        "0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)"
    }
  },
  warning: {
    "&,&:hover": {
      color: "#FFFFFF",
      backgroundColor: warningColor,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)"
    }
  },
  danger: {
    "&,&:hover": {
      color: "#FFFFFF",
      backgroundColor: dangerColor,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)"
    }
  },
  rose: {
    "&,&:hover": {
      color: "#FFFFFF",
      backgroundColor: roseColor,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)"
    }
  },
  alignCenter: {
    alignItems: "center",
    justifyContent: "center"
  },
  tabWrapper: {
    color: "inherit",
    position: "relative",
    fontSize: 12,
    lineHeight: 24,
    fontWeight: 500,
    textTransform: "uppercase",
    "&,& *": {
      letterSpacing: "normal"
    }
  }
});

export default navPillsStyle;
