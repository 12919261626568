import React, { Fragment } from 'react'
import MerchantConnections from '../account/MerchantConnections'

import {
  Page,
} from '../../styles/layout'

import {
  Button,
  Grid,
  LinearProgress,
  Typography,
} from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'
import Modal from 'react-modal'
import ChromeExtensionVerifier from '../base/ChromeExtensionVerifier'
import QuickImporter from '../posts/import/QuickImporter'
import Config from '../../config'
import chromeExtensionInstallPopup from '../base/js/chromeExtensionInstallPopup'
import authorizeEbay from '../../actions/authorizeEbay'

import {
  Link,
} from 'react-router-dom'

Modal.setAppElement('#root')

const NUM_STEPS = 3

class Onboarding extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      blackVeilShown: false,
      showMerchantConnections: true,

      ebayLoading: false,
    }

    this.renderOnboardingPage = this.renderOnboardingPage.bind(this)
    this.renderOnboardingFooter = this.renderOnboardingFooter.bind(this)

  }

  async componentDidMount() {

    // Look for code from ebay auth flow
    let code = ''

    if (typeof URLSearchParams !== 'undefined') {
      const params = new URLSearchParams(window.location.search)
      if (params && typeof params.get !== 'undefined') {
        code = params.get('code')
        this.props.history.push(window.location.pathname)
        this.setState({
          ebayLoading: true
        })
      }
    }

    if (code) {
      console.log('About to authorize ebay')
      try {
        await authorizeEbay(code)
      }
      catch (e) {
        console.error('Failed authorizing ebay: ', e)
      }
    }
    this.setState({
      ebayLoading: false
    })
  }

  renderOnboardingPage({ history, match }) {
    switch (match.params.step) {
      case 'chrome':
        return (
          <Fragment>
            <Typography variant="h1" style={{ textAlign: 'center' }}>
              Install Chrome Extension
            </Typography>
            <Grid container justify='center' alignItems='center'>
                <Grid item style={{
                    margin: 40,
                }}>
                <ChromeExtensionVerifier>
                {
                  ({ isInstalled }) => {
                    return (
                      !isInstalled ?
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            textAlign: 'center',
                          }}
                          onClick={() => {
                            this.setState({
                              blackVeilShown: true
                            })
                            chromeExtensionInstallPopup()
                          }}
                        ><AddIcon /> Add to Chrome</Button> :
                        <Alert>Successfully installed the Chrome Extension!</Alert>
                    )
                  }
                }
                </ChromeExtensionVerifier>
              </Grid>
            </Grid>
          </Fragment>
        )
      case 'marketplaces':
        return (

          <Fragment>
            <Typography variant="h1" style={{ textAlign: 'center', paddingBottom: 30 }}>
              Connect your Marketplaces
            </Typography>
            <p style={{
              padding: '0 40px',
              color: '#999',
              textAlign: 'center',
              paddingBottom: 40
            }}>
              Authorize any marketplaces where you'd like to crosslist your listings. You can always connect more marketplaces later in your ListingJoy settings.
            </p>
            <Grid container>
              <Grid item md={12} lg={12}>
                <MerchantConnections
                  hideButtonsWhenConnected={true}
                  ebayLoading={this.state.ebayLoading}
                />
              </Grid>
            </Grid>
          </Fragment>
        )
      case 'import':
        return (
          <Fragment>
            <Typography variant="h1" style={{ textAlign: 'center', paddingBottom: 60 }}>
              Last Step: Import listings
            </Typography>
            <Grid container>
              <Grid item md={12} lg={12}>
              </Grid>
            </Grid>
          </Fragment>
        )
    }
  }

  renderOnboardingFooter({ history, match }) {
    switch (match.params.step) {
      case 'chrome':
        return (
          <Fragment>
            <ChromeExtensionVerifier>
            {
              ({ isInstalled }) => {
                const topRowFragment = (
                <Grid container justify='center' alignItems='center'>
                  <Grid item style={{ margin: 20 }}>
                    <Link
                      to='/onboarding/marketplaces'
                      onClick={(e) => {
                        if (!isInstalled) {
                          e.preventDefault()
                        }
                      }}
                    >
                      <Button
                        disabled={!isInstalled}
                        variant="contained"
                        color="secondary"
                        size="large"
                        style={{ textAlign: 'center' }}
                      >
                        Next Step
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
                )
                return (
                  <Fragment>
                    { topRowFragment }
                    {
                      !isInstalled &&
                      <Grid container justify='center' alignItems='center'>
                        <Grid item>
                          <Typography variant="body2" style={{ textAlign: 'center' }}>
                            Install the extension to continue
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  </Fragment>
                )
              }
            }
            </ChromeExtensionVerifier>
            <Grid container justify='center' alignItems='center'>
              <Grid item md={8}>
                <LinearProgress value={(1 / NUM_STEPS) * 100} color='primary' variant='determinate' style={{ marginTop: 20 }} />
                <Typography variant="body2" style={{ textAlign: 'center', marginTop: 20 }}>
                  Step 1 of 3
                </Typography>
              </Grid>
            </Grid>
          </Fragment>
        )
      case 'marketplaces':
        return (
          <Fragment>
            <Grid container justify='center' alignItems='center'>
              <Grid item md={8}>
                <Grid container justify='center' alignItems='center' style={{ marginTop: 40 }}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ textAlign: 'center', margin: 20, marginBottom: 40, }}
                      size='large'
                      onClick={() => { history.push('/' /*onboarding/import'*/) }}
                    >
                      Next Step
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justify='center' alignItems='center'>
              <Grid item>
                <Typography variant="body2" style={{ textAlign: 'center' }}>
                  Connect at least one marketplace before continuing
                </Typography>
              </Grid>
            </Grid>
            <Grid container justify='center' alignItems='center'>
              <Grid item md={8}>
                <LinearProgress value={(2 / NUM_STEPS) * 100} color='primary' variant='determinate' style={{ marginTop: 20 }} />
                <Typography variant="body2" style={{ textAlign: 'center', marginTop: 20 }}>
                  Step 2 of 3
                </Typography>
              </Grid>
            </Grid>
          </Fragment>
        )
      case 'import':
        return (
          <Fragment>
            <Grid container justify='center' alignItems='center'>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ textAlign: 'center', margin: 20 }}
                  onClick={() => { history.goBack() }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ textAlign: 'center', margin: 20 }}
                  onClick={() => { history.push('/') }}
                >
                  Done
                </Button>
              </Grid>
            </Grid>
            <Grid container justify='center' alignItems='center'>
              <Grid item md={8}>
                <LinearProgress value={(3 / NUM_STEPS) * 100} color='primary' variant='determinate' style={{ marginTop: 20 }} />
                <Typography variant="body2" style={{ textAlign: 'center', marginTop: 20 }}>
                  Step 3 of 3
                </Typography>
              </Grid>
            </Grid>
          </Fragment>
        )
    }
  }

  render() {
    const { history, match } = this.props
    return (
      <Page>
        <Grid container>
          <Grid item sm={1} md={1} lg={2} xl={2} />
          <Grid item sm={10} md={10} lg={8} xl={8}>
            {
              this.renderOnboardingPage({ history, match })
            }
          </Grid>
          <Grid item sm={1} md={1} lg={2} xl={2} />
        </Grid>
        {
          this.renderOnboardingFooter({ history, match })
        }
        <Modal
          isOpen={this.state.blackVeilShown}
          onRequestClose={() => {
            this.setState({ blackVeilShown: false })
          }}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              minHeight: 200,
              minWidth: 500,
              transform: 'translate(-50%, -50%)'
            },
          }}
        >
          <Grid container alignItems='center' justify='center'>
            <Grid item>
              <a href={Config.chromeExtensionURL} target='_blank'>
                <img src='/img/chrome.png' style={{ width: '50%', margin: '16px 25%' }} />
              </a>
              <Typography variant='body2' style={{ textAlign: 'center' }}>
                Install by clicking the blue “Add to Chrome” button in the <a href={Config.chromeExtensionURL} target='_blank'>Chrome Web Store</a>
              </Typography>
            </Grid>
          </Grid>
        </Modal>
      </Page>
    )
  }
}

export default Onboarding
