import React from 'react'

import {
  Grid,
} from '@material-ui/core'

const ItemFormImages = ({ imageURLs, merchantName }) => {
  return <Grid container spacing={5}>
    {
      imageURLs.map((url, i) => {
        return (
          <Grid key={`${merchantName}${i}`} item xs={6} sm={4} md={3} lg={3}>
            <img 
              style={{ width: '100%' }} 
              src={url} 
              alt={'product'} />
          </Grid>
        )
      })
    }
  </Grid>
}

export default ItemFormImages
