import React from 'react'

import {
  Button,
} from '@material-ui/core'

import { 
  Alert,
} from '@material-ui/lab'

import config from '../../config'

import ChromeExtensionVerifier from './ChromeExtensionVerifier'

const linkStyle = {
  display: 'inline',
}

const ChromeExtensionAlert = ({ style = {} }) => (
  <div style={style}>
    <ChromeExtensionVerifier>
    { 
        ({ isConfidentlyNotInstalled, version }) => {
          if (isConfidentlyNotInstalled) {
            return (
              <Alert severity='warning'
                action={  
                  <Button 
                    color="inherit" 
                    href={config.chromeExtensionURL}
                    target='_blank'
                  >
                    INSTALL NOW
                  </Button>
                }
              >
                Install the ListingJoy Chrome Extension to enable listing on marketplaces like Poshmark and Mercari
              </Alert>
            )
          }
          if (version && version < config.chromeExtensionVersion) {
            return (
              <Alert 
                severity='warning' 
                action={  
                  <Button 
                    color="inherit" 
                    href={config.chromeExtensionURL}
                    target='_blank'
                  >
                    UPGRADE NOW
                  </Button>
                }
              >
                Upgrade to the latest Chrome Extension version ({config.chromeExtensionVersion})
              </Alert>
            )
          }
          return null
        }
    }
    </ChromeExtensionVerifier>
  </div>
)

export default ChromeExtensionAlert