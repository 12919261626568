
import React from 'react'
import iconStyles from './iconStyles'

const MercariIcon = ({ style }) => (
  <div style={{
    ...iconStyles,
    ...style,
  }}>
    <svg viewBox="0 0 40 40"><g fill="none"><path fill="#5E6DF2" d="M10.286.056h19.428C35.395.056 40 4.66 40 10.34v19.43c0 5.68-4.605 10.286-10.286 10.286H10.286C4.605 40.056 0 35.45 0 29.77V10.341C0 4.661 4.605.056 10.286.056z"></path><path fill="#FFF" d="M23.863 10.814l1.457-3.451 3.451 1.456-1.456 3.452zm-3.834 12.002l4.052-7.745h3.233v14.306h-3.19v-8.168h-.037l-3.07 5.522h-2.06l-3.068-5.509h-.038v8.16H12.66v-14.31h3.229l4.08 7.744z"></path></g></svg>
  </div>
)

export default MercariIcon
