
import React from 'react'
import {
  Grid,
} from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';

const FormValidationErrors = (props) => (
  <Grid container direction="row" style={{ marginTop: 30, marginBottom: 10 }}>
    <Grid item sm={12} md={12} lg={12} xl={12}>
      <Alert severity="error" variant="outlined" style={{ backgroundColor: '#FFEAE7' }}>
        <AlertTitle>Form Validation</AlertTitle>
        {
          props.errorFragments || props.children // temporary while we get this migrated over fully to props.children
        }
      </Alert>
    </Grid>
  </Grid>
)

export default FormValidationErrors
