import React from 'react'
import { Message } from '../../message'
import { ListingJoyExternalEvent } from '../../constants'

const shouldLog = false
const log = (v) => shouldLog && console.log(v)

class ChromeExtensionVerifier extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isInstalled: false,
      // We check for it for a while and are confident after X ms
      isConfidentlyNotInstalled: false,
      version: null,
    }

    this.checkInvervalMs = 250

    // Can optionally set checkStronglyConfidentMs to null
    this.checkStronglyConfidentMs = props.checkStronglyConfidentMs || 1500

    // Can optionally set checkDurationMaximumMs to null
    this.checkDurationMaximumMs = props.checkDurationMaximumMs || 5 * 1000
  }

  componentDidMount() {
    log('[CHROME] checker mounted')

    // Check using native chrome extension methods
    // https://www.twilio.com/blog/2018/03/detect-chrome-extension-installed.html
    // Message.notifyExtension(ListingJoyExternalEvent.SEND.CHECK_INSTALL, {}, (response) => {
    //   log('[CHROME] EXTENSION received callback from external event: ', response)
    // })

    const startCheckTime = +(new Date())
    this.intervalToken = setInterval(() => {
      log('[CHROME] check for chrome')

      const versionEl = document.getElementById('listingjoy-extension')
      if (
        versionEl && 
        !this.state.isInstalled
      ) {
        log('[CHROME] found it')

        // TEST sean debug
        // const chromeExtensionID = Message.runningChromeExtensionID()
        // log(`[CHROME] extension ID found: ${chromeExtensionID}`)

        clearInterval(this.intervalToken)
        return this.setState({
          isInstalled: true,
          isConfidentlyNotInstalled: false,
          version: versionEl.getAttribute('data-version'),
        })
      } else if (!versionEl) {
        log('[CHROME] didn\'t find the listingjoy version el')
      }

      if (
        this.checkStronglyConfidentMs && 
        !this.state.isInstalled &&
        (+(new Date()) - startCheckTime > this.checkStronglyConfidentMs)
      ) {
        log('[CHROME] strongly confident that the extension is not installed')
        return this.setState({
          isConfidentlyNotInstalled: true,
        })
      }

      if (
        this.checkDurationMaximumMs &&
        (+(new Date()) - startCheckTime > this.checkStronglyConfidentMs)
      ) {
        log('[CHROME] done checking')
        return clearInterval(this.intervalToken)
      }
    }, this.checkInvervalMs)
  }

  componentWillUnmount() {
    if (this.intervalToken) {
      clearInterval(this.intervalToken)
    }
  }

  render() {
    return this.props.children(this.state)
  }
}

export default ChromeExtensionVerifier