import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

const disconnectEtsy = () => {

  ReactGA.event({
    category: 'Connections',
    action: 'Disconnect Etsy',
  })

  return Firebase.firestore()
    .collection('users')
    .doc(Firebase.auth().currentUser.uid)
    .set({ etsyConnected: false, etsyToken: null }, { merge: true})
    .then( () => true)
    .catch( error => {
      alert(`Whoops, couldn't disconnect Etsy: ${error.message}`)
    })
}

export default disconnectEtsy
