import React from 'react'
import CategoryIcon from '@material-ui/icons/Category'
import ItemFormSectionHeader from '../ItemFormSectionHeader'

const ItemFormSectionHeaderCategory = ({ number, text }) => (
  <ItemFormSectionHeader
    icon={<CategoryIcon style={{ marginBottom: -6 }}/>}
    number={String(number)}
    text={text || 'Category'}
  />
)

export default ItemFormSectionHeaderCategory
