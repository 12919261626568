
import React from 'react'
import iconStyles from './iconStyles'

const TradesyIcon = ({ style }) => (
  <div style={{
    ...iconStyles,
    ...style,
  }}>
    <img src='/img/tradesy.jpg' alt='tradesy icon' style={{ height: '100%', borderRadius: 10 }} />
  </div>
)

export default TradesyIcon
