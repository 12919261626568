import React from 'react'
import _ from 'underscore'
import merge from 'deepmerge'
import CategoryHelpers from './categoryHelpers'

class ItemFormMerchantValues {

  constructor(item, merchantName = '') {
    if (!item) {
      throw new Error('Must have item available in constructor')
    }

    this.item = item
    this.merchantName = merchantName
  }

  get(key, fallback = null) {
    if (this.item.merchants &&
        this.item.merchants[this.merchantName] &&
        this.item.merchants[this.merchantName].overrides &&
        this.item.merchants[this.merchantName].overrides[key] !== undefined) {

      // Exceptions:
      // - If the merchant has an unfilled weight/dimension, the return the main weight/dimension
      const value = this.item.merchants[this.merchantName].overrides[key]

      // Even if the dimensions are invalid, only ignore the override if it is [0,0,0]
      if (key === 'dimensions' && value.filter(d => +d === 0).length === 3) {
        // no-op (easier to do this then invert the logic)
      } else if (key === 'weight' && +value.oz === 0 && +value.lb === 0) {
        // no-op
      } else {
        // Return the override if it isn't one of the above exceptions
        return this.item.merchants[this.merchantName].overrides[key]
      }
    }
    if (this.item.merchants &&
        this.item.merchants[this.merchantName] &&
        this.item.merchants[this.merchantName].marketplaceSpecifics &&
        this.item.merchants[this.merchantName].marketplaceSpecifics[key] !== undefined) {
      return this.item.merchants[this.merchantName].marketplaceSpecifics[key]
    }
    // Special fallback handling mapping a default listingjoy/poshmark categoryPath to an path specific to the marketplace
    if (this.item[key] && key === 'categoryPath') {
      return CategoryHelpers.mappedCategoryPath(this.item[key], this.merchantName)
    } else if (this.item[key]) {
      return this.item[key]
    }
    return fallback
  }

  getMerchantListingValue(key, fallback = null) {
    if (this.item.merchants &&
        this.item.merchants[this.merchantName] &&
        this.item.merchants[this.merchantName][key] !== undefined) {
      return this.item.merchants[this.merchantName][key]
    }
    return fallback
  }

  // Flattens the overrides / marketplaceSpecifics to all be at the top level
  getFlattenedData(updatedData = {}) {
    const merchantName = this.merchantName
    let itemData = this.item
    let merchantData = ((itemData.merchants || {})[merchantName] || {})
    let updatedMerchantData = ((updatedData.merchants || {})[merchantName] || {})
    return {
      ...itemData,
      ...(merchantData.marketplaceSpecifics || {}),
      ...(merchantData.overrides || {}),
      ...(updatedMerchantData.marketplaceSpecifics || {}),
      ...(updatedMerchantData.overrides || {}),
    }
  }

  getUpdatedItem(updatedValues, item) {
    const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray
    return merge(
        item || {},
        updatedValues || {},
        { arrayMerge: overwriteMerge }
    )
  }

  // TODO Sean simplify?
  // I think _.isEqual might be able to cover all cases
  buildOverrides(merchantValues, itemValues, merchantKeysToExclude = []) {
    const merchantKeys = Object.keys(merchantValues).filter(key => !merchantKeysToExclude.includes(key));
    let overrides = {}

    for (let key of merchantKeys) {
      if (
        // If fields are arrays, use array equality: categoryPath, dimensions
        (Array.isArray(merchantValues[key]) && !arraysEqual(merchantValues[key], itemValues[key])) ||
        // If field is object: weight
        (typeof merchantValues[key] === 'object' && !_.isEqual(merchantValues[key], itemValues[key])) ||
        // Regular bool / string / numbers
        (merchantValues[key] !== itemValues[key] && merchantValues[key] !== undefined)
      ) {
        overrides[key] = merchantValues[key]
      }
    }
    return overrides
  }

  getBrand(elements) {
    const brandEl = elements[`${this.merchantName}brand`]
    const brandResultEl = brandEl.parentElement.parentElement.parentElement.querySelector('div[class*=singleValue]')
    if (brandResultEl) {
      return brandResultEl.textContent
    }
    if (brandEl.value) {
      return brandEl.value
    }
    return null
  }

  getCategoryPath(elements) {
    const merchantName = this.merchantName

    const categoryEls = [1, 2, 3, 4, 5, 6, 7].map(num => elements[`${merchantName}categoryKey${num}`])
    const categoryPath = categoryEls.map(el => {
      if (!el) {
        return null
      }
      return el.parentElement.parentElement.parentElement.getAttribute("data-key")
    }).filter(Boolean)

    return categoryPath
  }


  // Currently used only for mercari nested categories
  getAspectChildId(elements) {
    const el = document.getElementById(`${this.merchantName}ItemAspectForCategoryChild`)
    if (!el) {
      return null
    }
    return el.getAttribute('data-json') || null
  }

  // Return an object with
  // key: {String} aspectID
  // value: {Object} r
  // getItemAspectForCategory(elements) {
  //   return Array.from(elements)
  //     .reduce((memo, el) => {
  //       if (!el.id) {
  //         return memo
  //       }
  //       const match = (/ItemAspectForCategory\[([^\]]*)\]/).exec(el.id)
  //       if (!match || match.length < 2) {
  //         return memo
  //       }
  //       const aspectID = match[1]
  //       const jsonString = el.getAttribute('data-json') || null
  //       memo[aspectID] = JSON.parse(jsonString)
  //       return memo
  //     }, {})
  // }


  getTagsArray(elements) {
    const merchantName = this.merchantName
    const tags = elements[`${merchantName}tags`]
    const tagsDataString = tags.parentElement.getAttribute('data-tags') || ''
    return tagsDataString && tagsDataString.length > 0 ? tagsDataString.split(',') : []
  }

  getDimensionsArray(elements) {
    const merchantName = this.merchantName
    const [
      d1,
      d2,
      d3,
    ] = [
      elements[`${merchantName}d1`],
      elements[`${merchantName}d2`],
      elements[`${merchantName}d3`],
    ]
    return [
      d1 ? d1.value : 0,
      d2 ? d2.value : 0,
      d3 ? d3.value : 0,
    ]
  }

  getWeight(elements) {
    const merchantName = this.merchantName
    const [
      lb,
      oz,
    ] = [
      elements[`${merchantName}weightLbs`],
      elements[`${merchantName}weightOzs`],
    ]
    return {
      lb: +lb.value || 0,
      oz: +oz.value || 0,
    }
  }
}

// https://stackoverflow.com/a/16436975
function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length != b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export default ItemFormMerchantValues
