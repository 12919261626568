import React, { Fragment } from 'react'

import Iframe from 'react-iframe'

import {
  PoshmarkEvent,
} from '../../constants'

import store from '../../store'

const DEBUG = false

class ConnectionVerifierPoshmark extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,

      responses: 0,
      data: {},
    }

    this.onReceiveLoginData = this.onReceiveLoginData.bind(this)
    this._hasCalledOnReceiveLoginDataCallback = false
  }

  componentDidMount() {
    document.addEventListener(PoshmarkEvent.RECEIVE.GET_USER_INFO, this.onReceiveLoginData)
  }

  componentWillUnmount() {
    document.removeEventListener(PoshmarkEvent.RECEIVE.GET_USER_INFO, this.onReceiveLoginData)
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.responses < 2 && this.state.responses === 2 && this.props.onReceiveLoginData) {
      store.setUserDetailsPoshmark(this.state.data)
      document.removeEventListener(PoshmarkEvent.RECEIVE.GET_USER_INFO, this.onReceiveLoginData)
      this.props.onReceiveLoginData(this.state.data)
    }  
  }

  onReceiveLoginData(e) {
    const data = e.detail.data
    const { debug = DEBUG } = this.props

    console.log('Poshmark received login data: ', data)

    this.setState(prevState => {
      return {
        loading: debug ? true : (prevState.responses + 1) < 2,
        responses: prevState.responses + 1,
        data: {
          ...omitNull(prevState.data),
          ...omitNull(data)
        },
      }
    })
  }

  // We need to hit two endpoints to reliably get all the data we want
  render() {

    const { debug = DEBUG } = this.props
    const side = debug ? 500 : 1
    return (
      this.state.loading ?
        <Fragment>
          <Iframe 
            id={debug ? null : 'iframe-poshmark'}
            url={`https://poshmark.com/feed?${PoshmarkEvent.SEND.GET_USER_INFO}=1`}
            width={side} 
            height={side}
            display="hidden" 
            position="relative"
          />
          <Iframe 
            id={debug ? null : 'iframe-poshmark-two'}
            url={`https://poshmark.com/closet/?${PoshmarkEvent.SEND.GET_USER_INFO}=1`}
            width={side} 
            height={side}
            display="hidden" 
            position="relative"
          />
        </Fragment>
       : null
    )
  }
}

// https://stackoverflow.com/a/51404883
function omitNull (obj) {
  const keys = Object.keys(obj);
  const _obj = {};
  for (const key of keys) {
    const value = obj[key];
    if (value !== null) {
      _obj[key] = value;
    }
  }
  return _obj;
}


export default ConnectionVerifierPoshmark