import EventEmitter from 'wolfy87-eventemitter'
import config from './config'

const EE = new EventEmitter()

const Events = {

    ItemListFilterBar: {
      DID_CHANGE_FILTER_SEARCH: 'filter:search',
      DID_CHANGE_FILTER_SORT: 'filter:sort',
      DID_CHANGE_FILTER_TYPE: 'filter:type',
      DID_CHANGE_VIEW_STYLE: 'view:style:change',

      DID_HIDE_MINI_NAV: 'ItemListFilterBar:did_hide_mini_nav',
      DID_SHOW_MINI_NAV: 'ItemListFilterBar:did_show_mini_nav',
    },

    ListingsFetcherMercari: {
      DID_GET_LISTINGS: 'ListingsFetcherMercari:did_get_listings',
    },

    ListingsFetcherPoshmark: {
      DID_GET_LISTINGS: 'ListingsFetcherPoshmark:did_get_listings',
    },

    ItemForm: {
      DID_NAVIGATE_TO_MERCHANT_FORM: 'ItemForm:did_navigate_to_merchant_form',
      // Emit this when we have provided a "main"/poshmark category path for the first time
      // Merchant forms listen to this and update/autofill if they are empty
      DID_PROVIDE_POSHMARK_CATEGORY_PATH: 'ItemForm:did_provide_poshmark_category_path',
    },

    ItemList: {
      DID_UPDATE_ITEM_COUNT_STRING: 'ItemList:did_update_item_count_string',
      DID_ADD_ITEMS: 'ItemList:did_add_items',
      SHOULD_UPDATE_MINI_NAV_VISIBILITY: 'ItemList:should_update_mini_nav_visibility',
    },

    ItemListerFacebook: {
      CREATE_LISTING: 'ItemListerFacebook:create_listing',
      INITIALIZE: 'ItemListerFacebook:initialize',

      DID_CREATE_LISTING: 'ItemListerFacebook:did_create_listing',
    },

    ItemListerMercari: {
      CREATE_LISTING: 'ItemListerMercari:create_listing',
      INITIALIZE: 'ItemListerMercari:initialize',

      DID_CREATE_LISTING: 'ItemListerMercari:did_create_listing',
    },

    ItemListerPoshmark: {
      CREATE_LISTING: 'ItemListerPoshmark:create_listing',
      INITIALIZE: 'ItemListerPoshmark:initialize',

      DID_CREATE_LISTING: 'ItemListerPoshmark:did_create_listing',
    },

    ItemDelisterPoshmark: {
      DELIST: 'ItemDelisterPoshmark:delist',
      DID_DELIST: 'ItemDelisterPoshmark:did_delist',
    },

    ItemDelisterMercari: {
      DELIST: 'ItemDelisterMercari:delist',
      DID_DELIST: 'ItemDelisterMercari:did_delist',
    },


    ItemSidebarMerchantListItem: {
      DELIST_EBAY: 'ItemSidebarMerchantListItem:delist_ebay',
      DELIST_ETSY: 'ItemSidebarMerchantListItem:delist_etsy',
      DELIST_MERCARI: 'ItemSidebarMerchantListItem:delist_mercari',
      DELIST_POSHMARK: 'ItemSidebarMerchantListItem:delist_poshmark',
      DELIST_FACEBOOK: 'ItemSidebarMerchantListItem:delist_facebook',
      CANCEL_MARK_LISTED: 'ItemSidebarMerchantListItem:cancel_mark_listed',
    },

}

const Message = {

  runningChromeExtensionID: () => {
    let chromeExtensionID = config.chromeExtensionID
    const versionEl = document.getElementById('listingjoy-extension')
    if (versionEl) {
      const runningExtensionID = versionEl.getAttribute('data-id')
      if (runningExtensionID && chromeExtensionID !== runningExtensionID) {
        console.log('Overriding default extension to use running extension ID: ', runningExtensionID)
        chromeExtensionID = runningExtensionID
      }
    }
    return chromeExtensionID
  },

  send: (name, data = null) => {
    const event = new CustomEvent(name, {
      detail: {
        data,
      },
    })
    document.dispatchEvent(event)
  },

  notifyExtension: (name, data = {}, callback = () => {}) => {
    const chromeExtensionID = Message.runningChromeExtensionID()
    try {
      // Emit an update for 3rd party websites
      console.log('Emit an update to 3rd party websites: ', name, data, chromeExtensionID)
      window.chrome.runtime.sendMessage(chromeExtensionID, {
        type: name,
        data,
      }, {} /* options */, (response) => {
        console.log('Response from extension: ', response)
        callback(response)
      })
    } catch (e) {
      console.error('Error emitting update to 3rd party website', e)
    }
  },
}


export {
  // For docoment events (for now, do this only when talking to/from chrome extension frames)
  Message,
  Events,
  EE,
}
