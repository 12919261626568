
import React from 'react'
import iconStyles from './iconStyles'

const FacebookIcon = ({ style }) => (
  <div style={{
    ...iconStyles,
    ...style,
  }}>
    <img src='/img/facebook.png' alt='facebook icon' style={{ height: '100%' }} />
  </div>
)

export default FacebookIcon
