import React from 'react'
import DescriptionIcon from '@material-ui/icons/Description';
import ItemFormSectionHeader from '../ItemFormSectionHeader'

const ItemFormSectionHeaderDetails = ({ number, text }) => (
  <ItemFormSectionHeader
    icon={<DescriptionIcon style={{ marginBottom: -6 }}/>}
    number={String(number)}
    text={text || 'Details'}
  />
)

export default ItemFormSectionHeaderDetails
