// a generic error page to show whenever something goes wrong in other views

import React from 'react'

const LegalAlert = ({error}) => (
  <div style={{ padding: 50 }}>
    <h1>Notice</h1>
    <p>ListingJoy is undergoing a legal dispute and will be shut down until further notice. We're very sorry for the inconvenience.</p>
  </div>
)

export default LegalAlert
