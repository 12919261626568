import { injectGlobal } from 'styled-components'
import colors from './colors'

// https://www.styled-components.com/docs/api#injectglobal
injectGlobal`
  @import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

  *:after,
  * {
    box-sizing: border-box;
    font-family: sans-serif;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  html,
  body {
    background: rgb(248, 248, 248);
    font-family: 'Cabin', sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.4;
  }

  html,
  body,
  #root {
    min-height: 100vh;
  }

  .listingjoy-logo {
    height: 34px;
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 34px;
    letter-spacing: 0.72px;
    color: ${colors.listingjoyhex};
  }

  .cardMerchantIcon:hover {
    cursor: pointer;
  }

  p {
    margin: 0 0 1rem;
  }

  hr {
    border: none;
    height: 1px;
    background: #eee;
    margin: 1rem 0;
  }

  .iframe-poshmark-import-item {
    left: -2000px;
  }
  .iframe-list-facebook {
    /* we discovered that if the FB frame is "visible", it loads 10x faster */
    left: 0;
  }
  #iframe-mercari,
  #iframe-mercari-two,
  #iframe-mercari-import-item,
  #iframe-list-mercari,
  #iframe-delist-mercari {
    left: -300%;
  }
  #iframe-mercari-listings {
    left: 150%;
  }
  #iframe-facebook,
  #iframe-poshmark,
  #iframe-poshmark-two,
  #iframe-list-poshmark,
  #iframe-delist-poshmark {
    left: -300%;
  }

  #form-fill-iframe {
    height: 100%;
    width: 100%;
  }

  /* Autocomplete */
  fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
    border-bottom-color: rgba(0, 0, 0, 0.42);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition-property: border-bottom-color;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms;
  }

  div.MuiAutocomplete-inputRoot {
    padding: 2px !important;
  }

  /* shimmer effect */
  .shine {
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) !important;
    background-repeat: no-repeat !important;
    background-size: 800px 104px !important;
    display: inline-block !important;
    position: relative !important;

    -webkit-animation-duration: 1s !important;
    -webkit-animation-fill-mode: forwards !important;
    -webkit-animation-iteration-count: infinite !important;
    -webkit-animation-name: placeholderShimmer !important;
    -webkit-animation-timing-function: linear !important;
  }

  .form-loading .MuiInput-underline:before {
    border-bottom: none;
  }

  @-webkit-keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }

  .listingStateSelector .MuiInputBase-inputSelect {
    padding-right: 18px;
  }

  .listingStateSelector .MuiSvgIcon-root
    margin-top: -1px;
    margin-right: -2px;
  }

  .listingStateSelector .MuiInput-underline::before {
    border-bottom: none;
  }

  .image-list-item:hover {
    background: rgba(116,151,240, 0.85) !important;
  }
  .image-list-item:hover .text {
    color: #efefef;
  }
  .image-list-item:hover .count {
    color: #efefef;
  }

  /* for input weight / dimensions */
  .MuiInputAdornment-positionEnd {
    margin-left: 0 !important;
  }

  @media screen and (max-width: 959px) {
    .sort-select-container {
      margin-top: -24px !important;
    }
  }

  .Toastify__toast {
    padding: 0 !important;
    margin-bottom: 0 !important;
    min-height: 30px !important;
  }

  /* Top nav */
  .MuiTabs-scroller.MuiTabs-fixed {
    display: inherit;
  }

  /* Error style for input components */
  .MuiInputBase-root.Mui-error {
    background: #FFEAE7 !important; /* rgba(229, 57, 53, 0.15) */
  }
  .MuiInputBase-root.Mui-error .MuiInputBase-input {
    background: #FFEAE7 !important;
  }
  .MuiAutocomplete-root.Mui-error {
    background: #FFEAE7 !important;
  }

  .ItemSidebarMerchantListItem-fixedPositionSidebar {
    position: fixed;
    top: 12px;
    width: 100%;
    padding-right: 26px;
  }

  @media screen and (min-width: 1091px) {
    .ItemSidebarMerchantListItem-fixedPositionSidebar {
      max-width: 273px !important;
    }
  }

  /* Mini nav fade in */
  .fade-in-mini-nav {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.1s;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

/*
  .import-table-container ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  .import-table-container ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
  */
