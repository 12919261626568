import React, { Fragment } from 'react'

import {
  Checkbox,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import InputLabel from '../../../base/InputLabel'
import { Alert } from '@material-ui/lab'

import TextFieldWithSavedNote from '../TextFieldWithSavedNote'

import ItemFormEbayHandlingTime from './shipping/ItemFormEbayHandlingTime'
import ItemFormEbayPaypalEmail from './shipping/ItemFormEbayPaypalEmail'
import ItemFormEbayReturnPolicy from './shipping/ItemFormEbayReturnPolicy'
import ItemFormEbayShippingService from './shipping/ItemFormEbayShippingService'
import ItemFormEbayShippingType from './shipping/ItemFormEbayShippingType'
import ItemFormEbayBusinessPolicies from './shipping/ItemFormEbayBusinessPolicies'
import SaveDefaultBadge from '../../../base/SaveDefaultBadge'
import Store from '../../../../store'

import FormAlert from '../../../base/FormAlert'

class ItemFormEbayShipping extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      shippingType: props.defaultShippingType,
      freeShipping: props.defaultFreeShipping,
      zipCode: props.defaultZipCode,

      savedDefaultZipCode: Store.getDefault('shipsFrom'),
    }

    this.onChangeShippingType = this.onChangeShippingType.bind(this)
    this.onChangeFreeShipping = this.onChangeFreeShipping.bind(this)
    this.onChangeZipCode = this.onChangeZipCode.bind(this)
  }

  onChangeFreeShipping = function(e) {
    this.setState({ freeShipping: e.target.checked })

    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  onChangeShippingType = function(e) {
    this.setState({ shippingType: e.target.value })

    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  onChangeZipCode = function(e) {
    this.setState({ zipCode: e.target.value })

    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {
    const {
      policyOptions = {},
      onChange = () => {},
      defaultFulfillmentPolicy,
      defaultPaymentPolicy,
      defaultReturnPolicy,
      defaultZipCode,
    } = this.props

    const {
      zipCode,
      savedDefaultZipCode,
    } = this.state

    const zipCodeHasError = !/\d\d\d\d\d/.exec(zipCode)

    console.log('Got policy options: ', policyOptions)
    return (
      <Fragment>

        { (
            Object.keys(policyOptions).length === 0 ||
            (
              policyOptions.fulfillmentPolicies && policyOptions.fulfillmentPolicies.length === 0 &&
              policyOptions.paymentPolicies && policyOptions.paymentPolicies.length === 0 &&
              policyOptions.returnPolicies && policyOptions.returnPolicies.length === 0
            )
          ) ? /* not opted into business policies */
          <Fragment>
            <FormAlert
              style={{ marginBottom: 20 }}
              type='error'
              body={<span>To list on eBay with ListingJoy, ensure you have <a target="_blank" href="https://www.bizpolicy.ebay.com/businesspolicy/policyoptin">opted into eBay business polices</a>. Learn more about eBay business polices <a target="_blank" href="https://www.ebay.com/help/policies/business-policy/business-policies?id=4212">here</a>. After you have opted in to eBay business policies, <a href='/settings'>reconnect</a> your eBay connection and you will be able to list.</span>}
            />
          </Fragment> :
          <ItemFormEbayBusinessPolicies
            policyOptions={policyOptions}
            defaultFulfillmentPolicy={defaultFulfillmentPolicy}
            defaultPaymentPolicy={defaultPaymentPolicy}
            defaultReturnPolicy={defaultReturnPolicy}
            onChange={onChange}
          />
        }
        <Grid container direction="row" style={{ marginTop: 20 }}>
          <Grid container>
            <InputLabel>Shipping Location (Zip Code)</InputLabel>
            { !zipCodeHasError &&
              <SaveDefaultBadge
                style={{ marginLeft: 10 }}
                name='shipsFrom'
                value={zipCode}
                isDefault={savedDefaultZipCode === zipCode}
                onSaved={savedDefaultZipCode => this.setState({ savedDefaultZipCode })}
              />
            }
          </Grid>
          <Grid container>
            <TextFieldWithSavedNote
              type="number"
              name="shipsFrom"
              fullWidth
              inputProps={{ maxLength: 5 }}
              defaultValue={defaultZipCode}
              variant="filled"
              error={zipCodeHasError}
              onChange={this.onChangeZipCode}
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

/* Non-business policy stuff that we are skipping */
  // <Fragment>
  //   <Grid container direction="row" style={{ marginTop: 10 }} spacing={3}>
  //     <Grid item xs={12} sm={6} md={6}>
  //       <Grid container>
  //         <Grid item xs={12} sm={12} md={12}>
  //           <ItemFormEbayShippingType
  //             onChange={this.onChangeShippingType}
  //             defaultValue={this.state.shippingType}
  //           />
  //         </Grid>
  //         <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }}>
  //           <ItemFormEbayHandlingTime
  //             defaultValue={this.props.defaultHandlingTime}
  //             onChange={onChange}
  //           />
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //     <Grid item xs={12} sm={6} md={6}>
  //       <FormControlLabel
  //         control={<Checkbox id='freeShipping' value='freeShipping' onChange={this.onChangeFreeShipping} checked={this.state.freeShipping} />}
  //         label={<InputLabel optional style={{ marginTop: 10 }}>Free Shipping</InputLabel>}
  //       />
  //       <Alert severity='info'>
  //         If you select Free shipping, Insurance, Package and handling charges will not be applicable.
  //       </Alert>
  //     </Grid>
  //   </Grid>

  //   <Grid container direction="row" style={{ marginTop: 10 }} spacing={3}>

  //     <Grid item xs={12} sm={6} md={6}>
  //       { this.state.shippingType === 'Flat' &&
  //         <Fragment>
  //           <Grid container>
  //             <InputLabel>Shipping Cost</InputLabel>
  //           </Grid>
  //           <Grid container>
  //             {/* TODO this is a number field */}
  //             <TextFieldWithSavedNote
  //               type="number"
  //               name="shippingCost"
  //               fullWidth
  //               defaultValue={''}
  //               variant="filled"
  //               error={false}
  //               aria-describedby="form-item-title-helper"
  //               onChange={onChange}
  //             />
  //           </Grid>
  //         </Fragment>
  //       }
  //     </Grid>
  //   </Grid>

  //   <Grid container direction="row" style={{ marginTop: 10 }} spacing={3}>
  //     <Grid item xs={12} sm={12} md={12}>
  //       <ItemFormEbayShippingService
  //         defaultValue={this.props.defaultShippingService}
  //         onChange={onChange}
  //       />
  //     </Grid>
  //   </Grid>

  //   <Grid container direction="row" style={{ marginTop: 10 }} spacing={3}>
  //     <Grid item xs={12} sm={6} md={6}>
  //       <ItemFormEbayPaypalEmail
  //         defaultValue={this.props.defaultPaypalEmail}
  //         onChange={onChange}
  //       />
  //     </Grid>
  //     <Grid item xs={12} sm={6} md={6} style={{ marginTop: 10 }}>
  //       <ItemFormEbayReturnPolicy
  //         defaultValue={this.props.defaultAcceptReturns}
  //         onChange={onChange}
  //       />
  //     </Grid>
  //   </Grid>
  // <Fragment>

export default ItemFormEbayShipping
