import React, { Fragment } from 'react'
import {
  FormControl,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import SelectWithSavedNote from './SelectWithSavedNote'
import styles from './ItemFormStyles'
import Helpers from './ItemFormInputAspectHelpers'
import ItemFormInputAspect from './ItemFormInputAspect'
import {
  getElementIDForAspectValueSet,
} from './ItemFormInputAspectDOM'

class ItemFormInputAspectMultiSet extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      valueSetID: props.defaultValueSetID || '',
    }
    this.onChange = this.onChange.bind(this)
    this.renderSecondarySet = this.renderSecondarySet.bind(this)
  }

  onChange(e) {
    console.log('Set option with multi set!', e.target.value)
    if (this.props.onChange) {
        this.props.onChange()
    }
  }

  renderSecondarySet() {
    const { aspect } = this.props
    const { valueSetID } = this.state

    if (!valueSetID || valueSetID === '') {
      return null
    }

    const valueSets = Object.values(aspect.valueSets)
    const selectedValueSet = valueSets.find(valueSet => valueSet.id === valueSetID)

    if (!selectedValueSet) {
      return null
    }

    // Render the secondary value set as a standalone aspect
    const subAspect = {
      ...aspect,
      valueSets: {
        [selectedValueSet.id]: { ...selectedValueSet }
      }
    }

    return (
      <ItemFormInputAspect
        {...this.props}
        aspect={subAspect}
      />
    )
  }

  render() {
    const {
      aspect,
      merchantName,
      classes,
      defaultValue = '',
      onChange = () => {},
    } = this.props

    const { valueSetID } = this.state

    if (!aspect || !aspect.valueSets) {
      return null
    }

    const valueSets = Object.values(aspect.valueSets)
    return (
      <Fragment>
        <FormControl
          className={classes.conditionControl}
          fullWidth
        >
          <SelectWithSavedNote
            name={getElementIDForAspectValueSet(aspect, merchantName)}
            defaultValue={valueSetID}
            required={aspect.constraint.required}
            fullWidth
            onChange={(e) => {
              this.setState({ valueSetID: e.target.value })
            }}
          >
            {
              valueSets.map(valueSet => {
                return (
                  <MenuItem
                    key={valueSet.id}
                    value={valueSet.id}
                  >{valueSet.name}</MenuItem>
                )
              })
            }
          </SelectWithSavedNote>
        </FormControl>
        {
          this.renderSecondarySet()
        }
      </Fragment>
    )
    return null
  }
}

export default ItemFormInputAspectMultiSet
