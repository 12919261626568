import React from 'react'

import Iframe from 'react-iframe'
import $ from 'jquery'

import {
  PoshmarkEvent,
  ListingJoyExternalEvent,
} from '../../constants'

import config from '../../config'
import { Message, EE, Events } from '../../message'
import updateItem from '../../actions/updateItem'

const LISTING_TIMEOUT_MS = 30 * 1000
const DEBUG = false

class ItemListerPoshmark extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      iframeLoaded: false,
      hasReceivedInitializeEvent: false,
    }

    this.listItem = this.listItem.bind(this)
    this.onCompletedListing = this.onCompletedListing.bind(this)
    this.onReceiveListingData = this.onReceiveListingData.bind(this)
    this.onReceiveInitialize = this.onReceiveInitialize.bind(this)

    // Queued up item data
    // Currently support only one item, eventually handle an array
    this.queuedData = null
    this.currentItemID = null
    this.currentTimeoutToken = null
  }

  componentDidMount() {
    document.addEventListener(PoshmarkEvent.RECEIVE.CREATE_LISTING, this.onCompletedListing)

    EE.addOnceListener(Events.ItemListerPoshmark.INITIALIZE, this.onReceiveInitialize)
    EE.addListener(Events.ItemListerPoshmark.CREATE_LISTING, this.onReceiveListingData)

    // After we are relatively confident the extension is set up
    setTimeout(() => {
      Message.notifyExtension(ListingJoyExternalEvent.SEND.SETUP_POSHMARK)
    }, 3000)
  }

  componentWillUnmount() {
    document.removeEventListener(PoshmarkEvent.RECEIVE.CREATE_LISTING, this.onCompletedListing)

    EE.removeListener(Events.ItemListerPoshmark.INITIALIZE, this.onReceiveInitialize)
    EE.removeListener(Events.ItemListerPoshmark.CREATE_LISTING, this.onReceiveListingData)
  }

  onReceiveInitialize() {
    console.log('Initializing poshmark lister')
    this.setState({
      hasReceivedInitializeEvent: true,
    })
  }

  listItem(data) {
    if (this.currentItemID) {
      return EE.emitEvent(Events.ItemListerPoshmark.DID_CREATE_LISTING, [{ 
        errors: [{ 
          message: 'Currently listing another item on Poshmark. Wait for that to finish first.' 
        }]
      }, null, data.item.id])
    }

    this.queuedData = null
    this.currentItemID = data.item.id

    Message.send(PoshmarkEvent.SEND.CREATE_LISTING, data)

    this.currentTimeoutToken = setTimeout(() => {

      EE.emitEvent(Events.ItemListerPoshmark.DID_CREATE_LISTING, [{ 
        errors: [{ 
          message: 'Timed out listing to Poshmark',
        }]
      }, null, data.item.id])

      this.reset()
    }, config.listingTimeoutInSeconds * 1000)
  }

  onReceiveListingData(data) {

    if (!this.state.iframeLoaded) {
      console.log('Item lister not yet iframeLoaded. Queue the data')
      this.queuedData = data
      return
    }

    this.listItem(data)
  }

  onCompletedListing(e) {
    const data = e.detail.data

    console.log('Received data from create listing action: ', data)

    // TODO there is an issue where we could receive an event from another item ID
    if (!this.currentItemID) {
      console.error('No current item ID in poshmark item lister. May have timed out')
      return
    }

    const success = data && (!data.errors || data.errors.length === 0) && data.listingID
    if (success) {
      updateItem(this.currentItemID, {
        merchants: {
          poshmark: {
            dateCreated: new Date(),
            dateLastModified: new Date(),
            listed: true,
            listedID: data.listingID,
            listingURL: `https://poshmark.com/listing/${data.listingID}`,
          },
        }
      }).then(updatedValues => {
        EE.emitEvent(Events.ItemListerPoshmark.DID_CREATE_LISTING, [data, updatedValues, data.listingID])
      }).catch(error => {
        EE.emitEvent(Events.ItemListerMercari.DID_CREATE_LISTING, [{ errors: [{ message: error.message || 'Unknown error listing on Poshmark' }]}])
      })
    } else {
      EE.emitEvent(Events.ItemListerPoshmark.DID_CREATE_LISTING, [data])
    }

    if (this.props.onCompletedListing) {
      this.props.onCompletedListing(data)
    }

    this.reset()
  }

  reset() {
    this.currentItemID = null
    if (this.currentTimeoutToken) {
      clearTimeout(this.currentTimeoutToken)
      this.currentTimeoutToken = null
    }   
  }

 /* Need to pass data as follows:
   1) this code to background
   2) background to content script iframeLoaded in poshmark
   3) content script back to background
   4) background back listingjoy content script
   5) LJ content script dispatches an event that we are listening for in this component */
  render() {
    if (!config.merchantsAvailable.poshmark) {
      return null
    }
    return (
      this.state.hasReceivedInitializeEvent &&
      <Iframe 
        id={ DEBUG ? null : 'iframe-list-poshmark'}
        url={`https://poshmark.com/create-listing?${PoshmarkEvent.SEND.CREATE_LISTING}=1`}
        width={400} 
        height={400}
        display="initial" 
        position="absolute"
        onLoad={() => { 
          console.log('Item listed iframeLoaded')
          this.setState({ iframeLoaded: true }) 
          if (this.queuedData) {
            console.log('Item lister finished loading. List with queued data')
            this.listItem(this.queuedData)
          }
        }}
      />
    )
  }
}

export default ItemListerPoshmark