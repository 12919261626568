import firebase from 'firebase/app'
import 'firebase/firestore'
import ReactGA from 'react-ga'

import { prepareDocForCreate } from './helpers/firestoreHelpers'

import uploadImage from './uploadImage'

const createItem = async (values = {}) => {

  ReactGA.event({
    category: 'Item',
    action: 'Create item',
  })

  const imageFiles = values.imageFiles
  if (imageFiles && imageFiles.length > 0) {
    values.imageURLs = await Promise.all(imageFiles.map(file => uploadImage(file, 'unknown')))
  }

  const currentUser = firebase.auth().currentUser
  if (!currentUser) {
    return console.error('Trying to create item when not logged in')
  }
  
  values.userId = currentUser.uid
  console.log('Creating item with values: ', values)

  return firebase.firestore()
    .collection('items')
    .add(prepareDocForCreate(values))
    .then((result) => {
      values.id = result.id
      return values
    })
    .catch( error => {
      alert(`Whoops, couldn't create the item: ${error.message}`)
    })
}

export default createItem
