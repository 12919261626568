import React from 'react'

import Iframe from 'react-iframe'

import {
  TradesyEvent,
} from '../../constants'

import store from '../../store'

class ConnectionVerifierTradesy extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }

    this.onReceiveLoginData = this.onReceiveLoginData.bind(this)
  }

  componentDidMount() {
    document.addEventListener(TradesyEvent.RECEIVE.GET_USER_INFO, this.onReceiveLoginData)
  }

  componentWillUnmount() {
    document.removeEventListener(TradesyEvent.RECEIVE.GET_USER_INFO, this.onReceiveLoginData)
  }

  onReceiveLoginData(e) {
    document.removeEventListener(TradesyEvent.RECEIVE.GET_USER_INFO, this.onReceiveLoginData)

    console.log('ConnectionVerifierTradesy got login data: ', e.detail.data)

    const data = e.detail.data
    this.setState({
      loading: false
    })
    
    store.setUserDetailsTradesy(data)

    if (this.props.onReceiveLoginData) {
      this.props.onReceiveLoginData(data)
    }  
  }

  render() {
    return (
      this.state.loading ?
      <Iframe 
        id='iframe-tradesy'
        url={`https://www.tradesy.com/me#${TradesyEvent.SEND.GET_USER_INFO}=1`}
        width={1} 
        height={1}
        display="hidden" 
        position="relative"
      /> : null
    )
  }
}

export default ConnectionVerifierTradesy