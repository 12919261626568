
const shippingClasses = [{
    id: 1001,
    name: "0.25 lb",
    minWeight: 0,
    maxWeight: 4,
    fee: 925,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$10.92",
    packageSize: 64,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1002,
    name: "0.50 lb",
    minWeight: 5,
    maxWeight: 8,
    fee: 925,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$10.92",
    packageSize: 64,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1003,
    name: "1 lb",
    minWeight: 9,
    maxWeight: 16,
    fee: 925,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$10.92",
    packageSize: 64,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1004,
    name: "2 lb",
    minWeight: 17,
    maxWeight: 32,
    fee: 955,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$11.64",
    packageSize: 252,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1005,
    name: "3 lb",
    minWeight: 33,
    maxWeight: 48,
    fee: 962,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$12.35",
    packageSize: 392,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1006,
    name: "4 lb",
    minWeight: 49,
    maxWeight: 64,
    fee: 1090,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$14.23",
    packageSize: 504,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1007,
    name: "5 lb",
    minWeight: 65,
    maxWeight: 80,
    fee: 1100,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$15.01",
    packageSize: 693,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1008,
    name: "6 lb",
    minWeight: 81,
    maxWeight: 96,
    fee: 1110,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$16.64",
    packageSize: 770,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1009,
    name: "7 lb",
    minWeight: 97,
    maxWeight: 112,
    fee: 1120,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$17.10",
    packageSize: 924,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1010,
    name: "8 lb",
    minWeight: 113,
    maxWeight: 128,
    fee: 1130,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$17.55",
    packageSize: 1078,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1011,
    name: "9 lb",
    minWeight: 129,
    maxWeight: 144,
    fee: 1140,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$17.85",
    packageSize: 1176,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1012,
    name: "10 lb",
    minWeight: 145,
    maxWeight: 160,
    fee: 1150,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$20.62",
    packageSize: 1280,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1013,
    name: "11 lb",
    minWeight: 161,
    maxWeight: 176,
    fee: 1250,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$22.48",
    packageSize: 1440,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1014,
    name: "12 lb",
    minWeight: 177,
    maxWeight: 192,
    fee: 1325,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$23.83",
    packageSize: 1584,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1015,
    name: "13 lb",
    minWeight: 193,
    maxWeight: 208,
    fee: 1400,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$24.93",
    packageSize: 1683,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1016,
    name: "14 lb",
    minWeight: 209,
    maxWeight: 224,
    fee: 1450,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$26.32",
    packageSize: 1836,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1017,
    name: "15 lb",
    minWeight: 225,
    maxWeight: 240,
    fee: 1475,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$27.71",
    packageSize: 2040,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1018,
    name: "16 lb",
    minWeight: 241,
    maxWeight: 256,
    fee: 1525,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$28.73",
    packageSize: 2210,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1019,
    name: "17 lb",
    minWeight: 257,
    maxWeight: 272,
    fee: 1550,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$30.33",
    packageSize: 2340,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1020,
    name: "18 lb",
    minWeight: 273,
    maxWeight: 288,
    fee: 1600,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$31.68",
    packageSize: 2470,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1021,
    name: "19 lb",
    minWeight: 289,
    maxWeight: 304,
    fee: 1650,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$33.11",
    packageSize: 2520,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1022,
    name: "20 lb",
    minWeight: 305,
    maxWeight: 320,
    fee: 1700,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$34.62",
    packageSize: 2660,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1023,
    name: "30 lb",
    minWeight: 321,
    maxWeight: 480,
    fee: 2500,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$49.67",
    packageSize: 4050,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1024,
    name: "40 lb",
    minWeight: 481,
    maxWeight: 640,
    fee: 3e3,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$63.29",
    packageSize: 5440,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1025,
    name: "50 lb",
    minWeight: 641,
    maxWeight: 800,
    fee: 4400,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$73.00",
    packageSize: 6840,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1026,
    name: "60 lb",
    minWeight: 801,
    maxWeight: 960,
    fee: 4700,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$79.06",
    packageSize: 8316,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1027,
    name: "70 lb",
    minWeight: 961,
    maxWeight: 1120,
    fee: 4900,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$84.20",
    packageSize: 9660,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1028,
    name: "80 lb",
    minWeight: 1121,
    maxWeight: 1280,
    fee: 5500,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$107.50",
    packageSize: 11109,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1029,
    name: "90 lb",
    minWeight: 1281,
    maxWeight: 1440,
    fee: 1e4,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$119.12",
    packageSize: 12480,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1030,
    name: "100 lb",
    minWeight: 1441,
    maxWeight: 1600,
    fee: 15500,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$197.23",
    packageSize: 13824,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1031,
    name: "110 lb",
    minWeight: 1601,
    maxWeight: 1760,
    fee: 15500,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$208.30",
    packageSize: 15232,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1032,
    name: "120 lb",
    minWeight: 1761,
    maxWeight: 1920,
    fee: 16e3,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$218.72",
    packageSize: 16576,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1033,
    name: "130 lb",
    minWeight: 1921,
    maxWeight: 2080,
    fee: 16500,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$228.84",
    packageSize: 18032,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1034,
    name: "140 lb",
    minWeight: 2081,
    maxWeight: 2240,
    fee: 17e3,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$239.94",
    packageSize: 19456,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1035,
    name: "150 lb",
    minWeight: 2241,
    maxWeight: 2400,
    fee: 17500,
    carrier: "fedex",
    carrierDisplayName: "FedEx",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$251.51",
    packageSize: 20720,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1036,
    name: "0.25 lb",
    minWeight: 0,
    maxWeight: 4,
    fee: 299,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "First-class",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$3.82",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1037,
    name: "0.50 lb",
    minWeight: 5,
    maxWeight: 8,
    fee: 425,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "First-class",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$4.57",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1038,
    name: "1 lb",
    minWeight: 9,
    maxWeight: 16,
    fee: 499,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "First-class",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$8.15",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1039,
    name: "2 lb",
    minWeight: 17,
    maxWeight: 32,
    fee: 1077,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$11.20",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1040,
    name: "3 lb",
    minWeight: 33,
    maxWeight: 48,
    fee: 1097,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$12.60",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1041,
    name: "4 lb",
    minWeight: 49,
    maxWeight: 64,
    fee: 1645,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$16.45",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1042,
    name: "5 lb",
    minWeight: 65,
    maxWeight: 80,
    fee: 1771,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$18.70",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1043,
    name: "6 lb",
    minWeight: 81,
    maxWeight: 96,
    fee: 1776,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$20.80",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1044,
    name: "7 lb",
    minWeight: 97,
    maxWeight: 112,
    fee: 1781,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$23.05",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1045,
    name: "8 lb",
    minWeight: 113,
    maxWeight: 128,
    fee: 1785,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$26.15",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1046,
    name: "9 lb",
    minWeight: 129,
    maxWeight: 144,
    fee: 1790,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$28.45",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1047,
    name: "10 lb",
    minWeight: 145,
    maxWeight: 160,
    fee: 1795,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$30.75",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1048,
    name: "11 lb",
    minWeight: 161,
    maxWeight: 176,
    fee: 3300,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$33.00",
    packageSize: 1728,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1049,
    name: "12 lb",
    minWeight: 177,
    maxWeight: 192,
    fee: 3531,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$35.90",
    packageSize: 1992,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1050,
    name: "13 lb",
    minWeight: 193,
    maxWeight: 208,
    fee: 3541,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$38.55",
    packageSize: 2158,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1051,
    name: "14 lb",
    minWeight: 209,
    maxWeight: 224,
    fee: 3551,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$40.70",
    packageSize: 2324,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1052,
    name: "15 lb",
    minWeight: 225,
    maxWeight: 240,
    fee: 3561,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$42.45",
    packageSize: 2490,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1053,
    name: "16 lb",
    minWeight: 241,
    maxWeight: 256,
    fee: 3571,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$44.80",
    packageSize: 2656,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1054,
    name: "17 lb",
    minWeight: 257,
    maxWeight: 272,
    fee: 3581,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$47.10",
    packageSize: 2822,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1055,
    name: "18 lb",
    minWeight: 273,
    maxWeight: 288,
    fee: 3591,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$49.60",
    packageSize: 2988,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1056,
    name: "19 lb",
    minWeight: 289,
    maxWeight: 304,
    fee: 3600,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$50.55",
    packageSize: 3154,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1057,
    name: "20 lb",
    minWeight: 305,
    maxWeight: 320,
    fee: 3610,
    carrier: "shippo_usps",
    carrierDisplayName: "USPS",
    requestClassDisplayName: "Priority",
    version: null,
    etaForSeller: "1 - 3 days",
    retailRate: "$51.75",
    packageSize: 3320,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1058,
    name: "0.25 lb",
    minWeight: 0,
    maxWeight: 4,
    fee: 2e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 64,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1059,
    name: "0.50 lb",
    minWeight: 5,
    maxWeight: 8,
    fee: 2e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 64,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1060,
    name: "1 lb",
    minWeight: 9,
    maxWeight: 16,
    fee: 2e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 64,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1061,
    name: "2 lb",
    minWeight: 17,
    maxWeight: 32,
    fee: 2e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 252,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1062,
    name: "3 lb",
    minWeight: 33,
    maxWeight: 48,
    fee: 2e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 392,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1063,
    name: "4 lb",
    minWeight: 49,
    maxWeight: 64,
    fee: 3e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 504,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1064,
    name: "5 lb",
    minWeight: 65,
    maxWeight: 80,
    fee: 3e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 693,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1065,
    name: "6 lb",
    minWeight: 81,
    maxWeight: 96,
    fee: 3e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 770,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1066,
    name: "7 lb",
    minWeight: 97,
    maxWeight: 112,
    fee: 3e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 924,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1067,
    name: "8 lb",
    minWeight: 113,
    maxWeight: 128,
    fee: 3e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 1078,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1068,
    name: "9 lb",
    minWeight: 129,
    maxWeight: 144,
    fee: 3e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 1176,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1069,
    name: "10 lb",
    minWeight: 145,
    maxWeight: 160,
    fee: 3e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 1280,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1070,
    name: "11 lb",
    minWeight: 161,
    maxWeight: 176,
    fee: 4e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 1440,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1071,
    name: "12 lb",
    minWeight: 177,
    maxWeight: 192,
    fee: 4e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 1584,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1072,
    name: "13 lb",
    minWeight: 193,
    maxWeight: 208,
    fee: 4e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 1683,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1073,
    name: "14 lb",
    minWeight: 209,
    maxWeight: 224,
    fee: 4e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 1836,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1074,
    name: "15 lb",
    minWeight: 225,
    maxWeight: 240,
    fee: 4e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 2040,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1075,
    name: "16 lb",
    minWeight: 241,
    maxWeight: 256,
    fee: 4e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 2210,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1076,
    name: "17 lb",
    minWeight: 257,
    maxWeight: 272,
    fee: 4e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 2340,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1077,
    name: "18 lb",
    minWeight: 273,
    maxWeight: 288,
    fee: 4e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 2470,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1078,
    name: "19 lb",
    minWeight: 289,
    maxWeight: 304,
    fee: 4e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 2520,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1079,
    name: "20 lb",
    minWeight: 305,
    maxWeight: 320,
    fee: 4e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 2660,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1080,
    name: "30 lb",
    minWeight: 321,
    maxWeight: 480,
    fee: 5e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 4050,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1081,
    name: "40 lb",
    minWeight: 481,
    maxWeight: 640,
    fee: 5e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 5440,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1082,
    name: "50 lb",
    minWeight: 641,
    maxWeight: 800,
    fee: 9e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 6840,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1083,
    name: "60 lb",
    minWeight: 801,
    maxWeight: 960,
    fee: 9e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 8316,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1084,
    name: "70 lb",
    minWeight: 961,
    maxWeight: 1120,
    fee: 9e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 9660,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1085,
    name: "80 lb",
    minWeight: 1121,
    maxWeight: 1280,
    fee: 12e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 11109,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1086,
    name: "90 lb",
    minWeight: 1281,
    maxWeight: 1440,
    fee: 12e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 12480,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1087,
    name: "100 lb",
    minWeight: 1441,
    maxWeight: 1600,
    fee: 16e3,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 13824,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1088,
    name: "110 lb",
    minWeight: 1601,
    maxWeight: 1760,
    fee: 18500,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 15232,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1089,
    name: "120 lb",
    minWeight: 1761,
    maxWeight: 1920,
    fee: 18500,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 16576,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1090,
    name: "130 lb",
    minWeight: 1921,
    maxWeight: 2080,
    fee: 18500,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 18032,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1091,
    name: "140 lb",
    minWeight: 2081,
    maxWeight: 2240,
    fee: 18500,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 19456,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1092,
    name: "150 lb",
    minWeight: 2241,
    maxWeight: 2400,
    fee: 18500,
    carrier: "ups",
    carrierDisplayName: "The UPS Store",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: null,
    packageSize: 20720,
    handlingType: "pack_and_ship",
    __typename: "ShippingClass"
}, {
    id: 1093,
    name: "0.25 lb",
    minWeight: 0,
    maxWeight: 4,
    fee: 966,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$15.68",
    packageSize: 64,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1094,
    name: "0.50 lb",
    minWeight: 5,
    maxWeight: 8,
    fee: 966,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$15.68",
    packageSize: 64,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1095,
    name: "1 lb",
    minWeight: 9,
    maxWeight: 16,
    fee: 966,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$15.68",
    packageSize: 64,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1096,
    name: "2 lb",
    minWeight: 17,
    maxWeight: 32,
    fee: 968,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$16.90",
    packageSize: 252,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1097,
    name: "3 lb",
    minWeight: 33,
    maxWeight: 48,
    fee: 970,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$17.73",
    packageSize: 392,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1098,
    name: "4 lb",
    minWeight: 49,
    maxWeight: 64,
    fee: 1025,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$18.25",
    packageSize: 504,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1099,
    name: "5 lb",
    minWeight: 65,
    maxWeight: 80,
    fee: 1040,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$19.03",
    packageSize: 693,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1100,
    name: "6 lb",
    minWeight: 81,
    maxWeight: 96,
    fee: 1050,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$19.35",
    packageSize: 770,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1101,
    name: "7 lb",
    minWeight: 97,
    maxWeight: 112,
    fee: 1060,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$19.61",
    packageSize: 924,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1102,
    name: "8 lb",
    minWeight: 113,
    maxWeight: 128,
    fee: 1070,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$20.03",
    packageSize: 1078,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1103,
    name: "9 lb",
    minWeight: 129,
    maxWeight: 144,
    fee: 1080,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$20.36",
    packageSize: 1176,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1104,
    name: "10 lb",
    minWeight: 145,
    maxWeight: 160,
    fee: 1125,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$20.67",
    packageSize: 1280,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1105,
    name: "11 lb",
    minWeight: 161,
    maxWeight: 176,
    fee: 1282,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$21.19",
    packageSize: 1440,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1106,
    name: "12 lb",
    minWeight: 177,
    maxWeight: 192,
    fee: 1350,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$21.76",
    packageSize: 1584,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1107,
    name: "13 lb",
    minWeight: 193,
    maxWeight: 208,
    fee: 1450,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$22.27",
    packageSize: 1683,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1108,
    name: "14 lb",
    minWeight: 209,
    maxWeight: 224,
    fee: 1500,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$23.25",
    packageSize: 1836,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1109,
    name: "15 lb",
    minWeight: 225,
    maxWeight: 240,
    fee: 1200,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$24.25",
    packageSize: 2040,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1110,
    name: "16 lb",
    minWeight: 241,
    maxWeight: 256,
    fee: 1575,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$24.86",
    packageSize: 2210,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1111,
    name: "17 lb",
    minWeight: 257,
    maxWeight: 272,
    fee: 1625,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$25.69",
    packageSize: 2340,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1112,
    name: "18 lb",
    minWeight: 273,
    maxWeight: 288,
    fee: 1625,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$26.56",
    packageSize: 2470,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1113,
    name: "19 lb",
    minWeight: 289,
    maxWeight: 304,
    fee: 1625,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$27.22",
    packageSize: 2520,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1114,
    name: "20 lb",
    minWeight: 305,
    maxWeight: 320,
    fee: 1625,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$27.97",
    packageSize: 2660,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1115,
    name: "30 lb",
    minWeight: 321,
    maxWeight: 480,
    fee: 2e3,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$37.42",
    packageSize: 4050,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1116,
    name: "40 lb",
    minWeight: 481,
    maxWeight: 640,
    fee: 2500,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$45.66",
    packageSize: 5440,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1117,
    name: "50 lb",
    minWeight: 641,
    maxWeight: 800,
    fee: 5e3,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$52.90",
    packageSize: 6840,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1118,
    name: "60 lb",
    minWeight: 801,
    maxWeight: 960,
    fee: 5e3,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$54.21",
    packageSize: 8316,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1119,
    name: "70 lb",
    minWeight: 961,
    maxWeight: 1120,
    fee: 5e3,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$58.61",
    packageSize: 9660,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1120,
    name: "80 lb",
    minWeight: 1121,
    maxWeight: 1280,
    fee: 6e3,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$77.33",
    packageSize: 11109,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1121,
    name: "90 lb",
    minWeight: 1281,
    maxWeight: 1440,
    fee: 6500,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$84.53",
    packageSize: 12480,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1122,
    name: "100 lb",
    minWeight: 1441,
    maxWeight: 1600,
    fee: 6500,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$166.83",
    packageSize: 13824,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1123,
    name: "110 lb",
    minWeight: 1601,
    maxWeight: 1760,
    fee: 7e3,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$172.54",
    packageSize: 15232,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1124,
    name: "120 lb",
    minWeight: 1761,
    maxWeight: 1920,
    fee: 9e3,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$179.41",
    packageSize: 16576,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1125,
    name: "130 lb",
    minWeight: 1921,
    maxWeight: 2080,
    fee: 9e3,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$186.08",
    packageSize: 18032,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1126,
    name: "140 lb",
    minWeight: 2081,
    maxWeight: 2240,
    fee: 9e3,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$193.15",
    packageSize: 19456,
    handlingType: "standard",
    __typename: "ShippingClass"
}, {
    id: 1127,
    name: "150 lb",
    minWeight: 2241,
    maxWeight: 2400,
    fee: 9e3,
    carrier: "ups",
    carrierDisplayName: "UPS",
    requestClassDisplayName: "Ground",
    version: null,
    etaForSeller: "1 - 5 days",
    retailRate: "$200.71",
    packageSize: 20720,
    handlingType: "standard",
    __typename: "ShippingClass"
}]

export default shippingClasses