import React, { Fragment } from 'react'
import { FirestoreCollection } from '../base/react-firestore'

import Error from '../misc/Error'
import FirebaseAuth from '../misc/FirebaseAuth'

import ClipLoader from 'react-spinners/ClipLoader'

import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

import {
  Link
} from 'react-router-dom'

import ItemListFilterBar from './ItemListFilterBar'
import ItemTable from './ItemTable'
import { QuickImporter } from './import/QuickImporter'
import Config from '../../config'
import Store from '../../store'
import { EE, Events } from '../../message'
import Login from '../account/Login'
import * as JsSearch from 'js-search'
import ItemNewImport from './ItemNewImport'
import Button from '../components/Button'
import AddIcon from '@material-ui/icons/Add'


import styled from 'styled-components'
import Nav from '../layout/Nav'

// TODO dedupe this from Layout.js
const HeaderFooterWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: max-content auto max-content;
  min-height: 100vh;
`
const Header = styled.div`
  padding: 2rem 1rem 1rem;
`
const Page = styled.div`
  padding: 1rem;
`
const Footer = styled.div`
  padding: 1rem;
  text-align: center;
  opacity: .3;
`

// list width: min(window.innerWidth, 1094)
//
//
class ItemList extends React.Component {

  constructor(props) {
    super(props)

    this.onFilterSortChanged = this.onFilterSortChanged.bind(this)
    this.onFilterTypeChanged = this.onFilterTypeChanged.bind(this)
    this.onFilterSearchChanged = this.onFilterSearchChanged.bind(this)
    this.onViewStyleChanged = this.onViewStyleChanged.bind(this)
    this.addItemsToSearchIndex = this.addItemsToSearchIndex.bind(this)
    this.addItemsToLocalImportedCache = this.addItemsToLocalImportedCache.bind(this)

    // Sync based on link (rather than automatically syncing based on there being no items)
    this.triggerSync = window.location.search.indexOf('poshmark_sync') > -1

    const lastSortType = Store.getLastSortType() || 'createdOn:desc'

    this.state = {
      sortType: lastSortType,
      filterType: 'all',
      search: Store.getLastFilterSearch() || '',
    }

    this.searchIndexItemIDs = {}
    this.importedItemIDs = {}

    this.search = new JsSearch.Search('id')
    this.search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    this.search.addIndex('title')
    this.search.addIndex('sku')

    const poshmarkDetails = (Store.getUserDetailsPoshmark() || {})
    this.usernamePoshmark = poshmarkDetails.username
    this.uidPoshmark = poshmarkDetails.uid
  }

  componentDidMount() {
    EE.addListener(Events.ItemListFilterBar.DID_CHANGE_FILTER_SORT, this.onFilterSortChanged)
    EE.addListener(Events.ItemListFilterBar.DID_CHANGE_FILTER_TYPE, this.onFilterTypeChanged)
    EE.addListener(Events.ItemListFilterBar.DID_CHANGE_FILTER_SEARCH, this.onFilterSearchChanged)
    EE.addListener(Events.ItemListFilterBar.DID_CHANGE_VIEW_STYLE, this.onViewStyleChanged)

    window.analytics.page('Inventory')
  }

  componentWillUnmount() {
    EE.removeListener(Events.ItemListFilterBar.DID_CHANGE_FILTER_SORT, this.onFilterSortChanged)
    EE.removeListener(Events.ItemListFilterBar.DID_CHANGE_FILTER_TYPE, this.onFilterTypeChanged)
    EE.removeListener(Events.ItemListFilterBar.DID_CHANGE_FILTER_SEARCH, this.onFilterSearchChanged)
    EE.removeListener(Events.ItemListFilterBar.DID_CHANGE_VIEW_STYLE, this.onViewStyleChanged)
  }

  onFilterSortChanged(value) {
    console.log('Filter sort changed: ', value)

    Store.setLastSortType(value)

    this.setState({
      sortType: value,
    })
  }
  onFilterTypeChanged(value) {
    this.setState({
      filterType: value,
    })

    console.log('Filter type changed: ', value)
  }
  onFilterSearchChanged(value = '') {
    this.setState({
      search: value,
    })

    console.log('Filter search changed: ', value)
  }
  onViewStyleChanged(value) {
    console.log('View style changed: ', value)
  }

  // Add to search index
  addItemsToSearchIndex(items) {
    const itemsToAdd = items.filter(item => !this.searchIndexItemIDs[item.id])
    itemsToAdd.forEach(item => this.searchIndexItemIDs[item.id] = true)
    this.search.addDocuments(itemsToAdd)
  }

  addItemsToLocalImportedCache(items) {
    if (!items || !items.length) {
      return
    }

    const itemsToAdd = items.filter(item => !this.importedItemIDs[item.id])
    itemsToAdd.forEach(item => this.importedItemIDs[item.id] = true)
    Store.addToImportedItemIDsAllMerchants(itemsToAdd)
  }

  render() {
    const { history } = this.props
    const isListed = (item, merchantName) => {
      return item.merchants && item.merchants[merchantName] && item.merchants[merchantName].listed
    }
    const filterItems  = (items, filterType, search = '') => {
      return filterItemsBySearch(filterItemsByType(items, filterType), search)
    }
    const filterItemsByType = (items, filterType) => {
      switch (filterType) {
        case 'all':
          return items

        // consider refactoring using regex so we don't need to handle every arbitrary merchant as they're added
        // perf-wise, the status quo is actually better
        //
        case 'ebay:listed':
          return items.filter(i => isListed(i, 'ebay'))
        case 'ebay:not_listed':
          return items.filter(i => !isListed(i, 'ebay'))
        case 'poshmark:listed':
          return items.filter(i => isListed(i, 'poshmark'))
        case 'poshmark:not_listed':
          return items.filter(i => !isListed(i, 'poshmark'))
        case 'etsy:listed':
          return items.filter(i => isListed(i, 'etsy'))
        case 'etsy:not_listed':
          return items.filter(i => !isListed(i, 'etsy'))
        case 'mercari:listed':
          return items.filter(i => isListed(i, 'mercari'))
        case 'mercari:not_listed':
          return items.filter(i => !isListed(i, 'mercari'))
        case 'facebook:listed':
          return items.filter(i => isListed(i, 'facebook'))
        case 'facebook:not_listed':
          return items.filter(i => !isListed(i, 'facebook'))
        default:
          console.error('Invalid filter type', filterType)
          return items
      }
    }
    const filterItemsBySearch = (items, search = '') => {
      if (search.length === 0) {
        return items
      }

      this.addItemsToSearchIndex(items)

      return this.search.search(search)

      // Old method, brute force
      // const lowerSearch = search.toLowerCase()
      // return items.filter(item => {
      //   return (
      //     (
      //       item.title &&
      //       item.title.toLowerCase().indexOf(lowerSearch) !== -1
      //     ) ||
      //     (
      //       item.sku &&
      //       item.sku.toLowerCase().indexOf(lowerSearch) !== -1
      //     )
      //   )
      // })
    }

    const loadingFragment = (
      <div style={{ height: 100, width: '100%', textAlign: 'center', margin: '50px 0' }}><ClipLoader /></div>
    )
    return (
      <div className='item-list'>

        <FirebaseAuth>
        { ({isLoading, error, auth}) => {

          if (error) {
            return 'Error fetching user'
          } else if (isLoading) {
            return loadingFragment
          } else if (!auth) {
            return null
          }

          return (
            <FirestoreCollection
              path={'items'}
              filter={[
                ['userId', '==', auth.uid],
              ]}
              sort={this.state.sortType}
              onAddedItems={(items) => {
                console.log('Added items!', items)
                EE.emitEvent(Events.ItemList.DID_ADD_ITEMS, [items])
              }}
            >
              { ({error, isLoading, data}) => {

                if (isLoading || !data) {
                  return loadingFragment
                }
                if (data.length === 0 || this.triggerSync) {
                  EE.emitEvent(QuickImporter.Events.INITIALIZE, [data])
                }

                const filteredItems = filterItems(data, this.state.filterType, this.state.search)
                const itemCountString = (
                  filteredItems.length === data.length ?
                            `${data.length} item${data.length === 1 ? '' : 's'} shown` :
                            `${filteredItems.length} item${filteredItems.length === 1 ? '' : 's'} shown out of ${data.length} (filtering)`
                )
                EE.emitEvent(Events.ItemList.DID_UPDATE_ITEM_COUNT_STRING, [itemCountString])

                if (data.length > 0) {
                  return (
                    <ItemTable
                      items={filteredItems}
                      itemCountString={itemCountString}
                    />
                  )
                }
                return (
                  <Fragment>
                    <HeaderFooterWrapper>
                      <Header>
                        <Nav
                          windowWidth={window.innerWidth}
                        />
                      </Header>
                      <Grid container alignItems='center' justify='center' style={{ marginTop: -40 }}>
                        <Grid item>
                          <p style={{ fontSize: 24, color: '#555' }}>Get started using ListingJoy by importing an item from another marketplace!</p>
                          <div style={{ margin: 30 }}>
                            <ItemNewImport
                              history={history}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </HeaderFooterWrapper>
                  </Fragment>
                )
              }}
              </FirestoreCollection>
            )
          }}
        </FirebaseAuth>
      </div>
    )
  }
}

export default ItemList
