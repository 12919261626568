import React, { Fragment } from 'react'

import { 
  Grid,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import InputLabel from '../../../base/InputLabel'
import SelectWithSavedNote from '../SelectWithSavedNote'
import { withStyles } from '@material-ui/core/styles'
import styles from '../ItemFormStyles'
import Store from '../../../../store'
import SaveDefaultBadge from '../../../base/SaveDefaultBadge'


class ItemFormEtsyRenewalOptions extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: props.defaultValue || '',
      savedDefault: Store.getDefault('renewalOption'),
    }
  }

  onChange = function(e) {
    this.setState({ value: e.target.value })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {   
    const { classes, defaultValue } = this.props; 
    return (
      <Fragment>
        <Grid container direction="row">
          <InputLabel id="form-item-renewal-option">Renewal Options</InputLabel>
          { this.state.value !== '' &&
            <SaveDefaultBadge 
              name='renewalOption'
              value={this.state.value}
              isDefault={this.state.savedDefault === this.state.value}
              onSaved={savedDefault => this.setState({ savedDefault })}
            />
          }
        </Grid>
        <FormControl 
          className={classes.conditionControl}
          fullWidth
        >
          <SelectWithSavedNote
            name="renewalOption"
            value={this.state.value}
            onChange={this.onChange.bind(this)}
            required={true}
            fullWidth
          >
            <MenuItem value={'automatic'}>Automatic</MenuItem>
            <MenuItem value={'manual'}>Manual</MenuItem>
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormEtsyRenewalOptions)