import React from 'react'

import {
  FormHelperText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import styles from './ItemFormStyles'
import TextFieldWithSavedNote from './TextFieldWithSavedNote'
import InputLabel from '../../base/InputLabel'


class ItemFormInputDescription extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      description: props.defaultDescription || '',
    }
  }

  onChange = function(e) {
    this.setState({ description: e.target.value })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {
    const { classes, defaultDescription, maxCharacters, loading } = this.props;
    const hasError = !loading  && (this.state.description.length === 0 || (maxCharacters !== undefined ? this.state.description.length > maxCharacters : false))
    return (
      <div>
          <InputLabel>Description</InputLabel>
          <TextFieldWithSavedNote
            multiline={true}
            name="description"
            defaultValue={defaultDescription || ''}
            onChange={this.onChange.bind(this)}
            className={classes.textarea}
            variant="filled"
            error={hasError}
            loading={loading}
          />
          <FormHelperText
            style={{
              marginBottom: 20,
              color: hasError ? 'red' : 'inherit',
            }}
          >
          { maxCharacters ?
            `${this.state.description.length} of ${maxCharacters} characters` :
            `${this.state.description.length} character${this.state.description.length === 1 ? '' : 's'}`
          }
          </FormHelperText>
      </div>
    )
  }

}

export default withStyles(styles)(ItemFormInputDescription)
