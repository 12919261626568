import React, { Fragment } from 'react'

import { 
  FormControl,
  MenuItem,
} from '@material-ui/core';
import InputLabel from '../../../../base/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../ItemFormStyles'
import SelectWithSavedNote from '../../SelectWithSavedNote'

class ItemFormEbayHandlingTime extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: props.defaultValue || undefined,
    }
  }

  onChange = function(e) {
    this.setState({ value: e.target.value })

    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {   
    const { classes } = this.props; 
    return (
      <Fragment>
        <InputLabel>Handling Time</InputLabel>
        <FormControl 
          className={classes.valueControl}
          required
          fullWidth
        >
          <SelectWithSavedNote
            name="handlingTime"
            value={this.state.value || ''}
            required={true}
            onChange={this.onChange.bind(this)}
          >
            {
              handlingTimeOptions.map(option => {
                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              })
            }
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

const handlingTimeOptions = [
   {
      value:"0",
      label:"Same Business Day"
   },
   {
      value:"1",
      label:"1 Business Day"
   },
   {
      value:"2",
      label:"2 Business Days"
   },
   {
      value:"3",
      label:"3 Business Days"
   },
   {
      value:"4",
      label:"4 Business Days"
   },
   {
      value:"5",
      label:"5 Business Days"
   }
]

export default withStyles(styles)(ItemFormEbayHandlingTime)