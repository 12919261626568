import React, { Fragment } from 'react'

import { 
  FormControl,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import styles from './ItemFormStyles'
import SelectWithSavedNote from './SelectWithSavedNote'
import InputLabel from '../../base/InputLabel'

class ItemFormInputCondition extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      condition: props.defaultCondition || undefined,
    }

    this.conditions = this.props.merchantName === 'mercari' ? [
      'New (new with tags)',
      'Like new (new without tags)',
      'Good (gently used)',
      'Fair (used)',
      'Poor (major flaws)',
    ] : [
      'New with Tags/Box',
      'New without Tags/Box',
      'New with defects',
      'Pre-owned',
      'Poor (major flaws)',
    ]
  }

  onConditionChange = function(e) {
    this.setState({ condition: e.target.value })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {   
    const { classes, loading } = this.props;

    return (
      <Fragment>
        <InputLabel>Condition</InputLabel>
        <FormControl 
          className={classes.conditionControl}
          required
          fullWidth
        >
          <SelectWithSavedNote
            name="condition"
            value={this.state.condition || ''}
            onChange={this.onConditionChange.bind(this)}
            required={true}
            loading={loading}
          >
            <MenuItem value={1}>{this.conditions[0]}</MenuItem>
            <MenuItem value={2}>{this.conditions[1]}</MenuItem>
            <MenuItem value={3}>{this.conditions[2]}</MenuItem>
            <MenuItem value={4}>{this.conditions[3]}</MenuItem>
            <MenuItem value={5}>{this.conditions[4]}</MenuItem>
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormInputCondition)