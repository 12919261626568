import React, { Fragment } from 'react'

import { 
  Grid,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import InputLabel from '../../../base/InputLabel'
import SelectWithSavedNote from '../SelectWithSavedNote'
import { withStyles } from '@material-ui/core/styles'
import styles from '../ItemFormStyles'
import Store from '../../../../store'
import SaveDefaultBadge from '../../../base/SaveDefaultBadge'


class ItemFormEtsyListingState extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      listingState: props.defaultValue || undefined,
      savedDefault: Store.getDefault('listingState'),
    }
  }

  onChange = function(e) {
    this.setState({ listingState: e.target.value })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {   
    const { classes, defaultValue } = this.props; 
    return (
      <Fragment>
        <Grid container direction="row">
          <InputLabel id="form-item-listing-state-label">Listing State</InputLabel>
          { this.state.listingState !== undefined &&
            <SaveDefaultBadge 
              name='listingState'
              value={this.state.listingState}
              isDefault={this.state.savedDefault === this.state.listingState}
              onSaved={savedDefault => this.setState({ savedDefault })}
            />
          }
        </Grid>

        <FormControl 
          className={classes.conditionControl}
          fullWidth
        >
          <SelectWithSavedNote
            labelId="form-item-listing-state-label"
            name="listingState"
            value={this.state.listingState || ''}
            required={true}
            onChange={this.onChange.bind(this)}
          >
            <MenuItem value={'draft'}>Draft Listing</MenuItem>
            <MenuItem value={'active'}>Live Listing</MenuItem>
            <MenuItem value={'inactive'}>Inactive</MenuItem>
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormEtsyListingState)