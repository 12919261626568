import React, { Fragment } from 'react'

import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import InputLabel from '../../../base/InputLabel'
import SaveDefaultBadge from '../../../base/SaveDefaultBadge'

import SelectWithSavedNote from '../SelectWithSavedNote'
import TextFieldWithSavedNote from '../TextFieldWithSavedNote'

import ItemFormInputDimensions from '../ItemFormInputDimensions'
import ItemFormInputSwitch from '../ItemFormInputSwitch'

import ItemFormMerchantValues from '../../merchants/helpers/ItemFormMerchantValues'
import ItemFormInputWeight from '../ItemFormInputWeight'

import { withStyles } from '@material-ui/core/styles'
import styles from '../ItemFormStyles'

import shippingClasses from '../../../data/mercari-shipping-classes'
import store from '../../../../store'

class ItemFormMercariShipping extends React.Component {

  constructor(props) {
    super(props)

    this.onChangeOfferFreeShipping = this.onChangeOfferFreeShipping.bind(this)
    this.onChangeShippingLabelType = this.onChangeShippingLabelType.bind(this)
    this.onChangeShippingClassType = this.onChangeShippingClassType.bind(this)
    this.onChangeWeightOrDimensions = this.onChangeWeightOrDimensions.bind(this)

    this.formRef = this.props.formRef
    this.shipsFromRef = React.createRef()
    this.merchantName = 'mercari'
    this.values = new ItemFormMerchantValues(this.props.item, this.merchantName)

    const availableShippingClasses = ItemFormMercariShipping.calculateAvailableShippingOptions(this.props.defaultWeight, this.props.defaultDimensions)

    let shippingClassType = this.props.defaultShippingClassType || ''
    if (!shippingClassType && availableShippingClasses.length > 0) {
      shippingClassType = availableShippingClasses[0].id
    }


    this.state = {
      offerFreeShipping: this.props.defaultOfferFreeShipping === true, // boolean (NOT a yes/no string)
      availableShippingClasses: availableShippingClasses,
      shippingLabelType: this.props.defaultShippingLabelType || 1, // pre-paid
      shippingClassType: shippingClassType,
      shipsFrom: this.props.defaultShipsFrom || '',

      weight: this.props.defaultWeight,
      dimensions: this.props.defaultDimensions,

      savedDefaultShipsFrom: store.getDefault('shipsFrom'),
      savedDefaultOfferFreeShipping: store.getDefault('offerFreeShipping') === true,
    }
    console.log('mercari defaultOfferFreeShipping', this.props.defaultOfferFreeShipping)
    console.log('mercari state offerFreeShipping', this.state.offerFreeShipping)
  }

  onChangeOfferFreeShipping(e) {
    const offerFreeShipping = e.target.value === 'yes'

    console.log('Offer free shipping: ', offerFreeShipping, e.target.value)
    console.log('Offer free shipping default: ', this.offerFreeShippingDefault)

    this.setState({
      offerFreeShipping,
    })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  onChangeShippingLabelType(e) {
    this.setState({
      shippingLabelType: e.target.value,
    })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  onChangeShippingClassType(e) {
    this.setState({
      shippingClassType: e.target.value
    })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  onChangeShipsFrom(e) {
    this.setState({
      shipsFrom: e.target.value,
    })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  onChangeWeightOrDimensions(e) {

    const { elements } = this.formRef.current
    const { shippingClassType } = this.state
    const dimensions = this.values.getDimensionsArray(elements, this.merchantName).map(v => +v) // cast to number
    const weight = this.values.getWeight(elements, this.merchantName)

    const availableShippingClasses = ItemFormMercariShipping.calculateAvailableShippingOptions(weight, dimensions)

    let newState = {
      availableShippingClasses,
      dimensions,
      weight,
    }
    if (availableShippingClasses.length === 1) {
      newState.shippingClassType = availableShippingClasses[0].id || ''
    } else if (availableShippingClasses.length > 1) {

      const matchingClassType = availableShippingClasses.find(c => c.id === shippingClassType)
      if (matchingClassType === undefined) {
        // find the minimum
        let lowestPriceClassType = null
        for (let classType of availableShippingClasses) {
          if (!lowestPriceClassType) {
            lowestPriceClassType = classType
          } else if (lowestPriceClassType.fee > classType.fee) {
            lowestPriceClassType = classType
          }
        }
        newState.shippingClassType = lowestPriceClassType.id || ''
      }
    }

    console.log('Available shipping classes: ', availableShippingClasses, newState.shippingClassType)

    this.setState(newState, () => {
      if (this.props.onChange) {
        this.props.onChange()
      }
    })
  }

  static calculateAvailableShippingOptions(weight, dimensions) {

    if (weight.oz === 0 && weight.lb === 0) {
      return []
    }
    if (
      dimensions.length < 3 ||
      dimensions[0] === 0 ||
      dimensions[1] === 0 ||
      dimensions[2] === 0
    ) {
      dimensions = [1, 1, 1] // Assume the package is small if we don't have valid dimensions
    }

    const volume = dimensions[0] * dimensions[1] * dimensions[2]
    const ounces = (weight.lb * 16) + weight.oz

    return shippingClasses.filter(ship => {
      return (
        ounces >= ship.minWeight &&
        ounces <= ship.maxWeight &&
        volume <= ship.packageSize
      )
    })
  }

  render() {
    const {
      defaultDimensions,
      defaultOfferFreeShipping,
      defaultShipsFrom,
      defaultWeight,
      classes,
      merchantValues,
    } = this.props

    const {
      shipsFrom,
      weight,
      dimensions,
    } = this.state

    const shipsFromHasError = !shipsFrom || !/\d\d\d\d\d/.exec(shipsFrom) /* zip code regex */
    const weightHasError = (weight.lb === 0 && weight.oz === 0)

    return (
      <Fragment>
        <Grid container direction="row" spacing={5}>
          <Grid item sm={6} md={6}>

            <Grid container direction="row">
              <InputLabel>Ships From</InputLabel>
              { !shipsFromHasError &&
                <SaveDefaultBadge
                  style={{ marginLeft: 25 }}
                  name='shipsFrom'
                  value={this.state.shipsFrom}
                  isDefault={+this.state.savedDefaultShipsFrom === +this.state.shipsFrom}
                  onSaved={savedDefaultShipsFrom => this.setState({ savedDefaultShipsFrom })}
                />
              }
            </Grid>
            <Grid container direction="row" >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl
                  fullWidth
                >
                  <TextFieldWithSavedNote
                    placeholder='Zip Code (e.g. 10001)'
                    name="shipsFrom"
                    defaultValue={defaultShipsFrom}
                    inputProps={{ maxLength: 5 }}
                    onChange={this.onChangeShipsFrom.bind(this)}
                    fullWidth
                    error={shipsFromHasError}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <div style={{ marginTop: 20 }}>
              <ItemFormInputWeight
                merchantName={'mercari'}
                defaultWeight={defaultWeight}
                onChange={this.onChangeWeightOrDimensions}
                merchantValues={merchantValues}
              />
            </div>
          </Grid>
          <Grid item sm={6} md={6}>
            <Grid container direction="row">
              <InputLabel>Free Shipping?</InputLabel>
              <SaveDefaultBadge
                style={{ marginLeft: 10 }}
                name='offerFreeShipping'
                value={this.state.offerFreeShipping}
                isDefault={this.state.savedDefaultOfferFreeShipping === this.state.offerFreeShipping}
                onSaved={savedDefaultOfferFreeShipping => this.setState({ savedDefaultOfferFreeShipping })}
              />
            </Grid>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl
                  className={classes.conditionControl}
                  required
                  fullWidth
                >
                  <SelectWithSavedNote
                    name="offerFreeShipping"
                    value={this.state.offerFreeShipping ? 'yes' : 'no'}
                    onChange={this.onChangeOfferFreeShipping}
                    required={true}
                  >
                    <MenuItem value={'yes'}>Yes</MenuItem>
                    <MenuItem value={'no'}>No</MenuItem>
                  </SelectWithSavedNote>
                </FormControl>
              </Grid>
            </Grid>

            <div style={{ marginTop: 20 }}>
              <ItemFormInputDimensions
                optional
                defaultValues={defaultDimensions}
                merchantName='mercari'
                onChange={this.onChangeWeightOrDimensions}
                merchantValues={merchantValues}
              />
            </div>
          </Grid>
        </Grid>
        { !weightHasError &&
          <Grid container direction="row">
            <Grid item xs={12} sm={6} md={6}>
              <Fragment>
                <Grid container direction="row">
                  <InputLabel>How do you want to ship your item?</InputLabel>
                </Grid>
                <Grid container direction="row">
                  {/* Shipping Label */}
                  <FormControl
                    className={classes.conditionControl}
                    required
                    fullWidth
                  >
                    <SelectWithSavedNote
                      name="shippingLabelType"
                      required={true}
                      value={this.state.shippingLabelType}
                      onChange={this.onChangeShippingLabelType}
                    >
                      <MenuItem value={1}>Pre-paid label</MenuItem>
                      { this.state.offerFreeShipping &&
                        <MenuItem value={2}>Ship your own</MenuItem>
                      }
                    </SelectWithSavedNote>
                  </FormControl>
                </Grid>
              </Fragment>
            </Grid>
          </Grid>
        }
        { !weightHasError && this.state.shippingLabelType === 1 &&
          <Grid container direction="row" style={{ marginTop: 10 }}>
            <Grid item xs={12} sm={6} md={6}>
              <Fragment>
                {/* Shipping Options */}
                <Grid container direction="row">
                  <InputLabel>Which carrier would you like to use?</InputLabel>
                </Grid>
                <Grid container direction="row">
                  {
                    this.state.availableShippingClasses.length === 0 ?
                    <Alert severity='info'>Fill out weight and dimensions to see remaining shipping options</Alert> :
                    <FormControl
                      className={classes.conditionControl}
                      required
                      fullWidth
                    >
                        <SelectWithSavedNote
                          name="shippingClassType"
                          value={this.state.shippingClassType}
                          required={true}
                          onChange={this.onChangeShippingClassType}
                        >
                          {
                            this.state.availableShippingClasses.map(ship => {
                              return (
                                <MenuItem key={ship.id} value={ship.id}>${Math.floor(ship.fee / 100)}.{ship.fee % 100} - {ship.carrierDisplayName} {ship.requestClassDisplayName}</MenuItem>
                              )
                            })
                          }
                        </SelectWithSavedNote>
                    </FormControl>
                  }
                </Grid>
              </Fragment>
            </Grid>
          </Grid>
        }
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormMercariShipping)
