import React from 'react'

import { 
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import ViewComfyIcon from '@material-ui/icons/ViewComfy'
import ListIcon from '@material-ui/icons/List'
import SearchIcon from '@material-ui/icons/Search'
import config from '../../config'

import SearchBar from './filter/SearchBar'
import SelectBar from './filter/SelectBar'
import Apps from '@material-ui/icons/Apps'
import List from '@material-ui/icons/List'
import Menu from '@material-ui/icons/Menu'
import Money from '@material-ui/icons/Money';
import ImportExport from '@material-ui/icons/ImportExport'
import { withStyles } from '@material-ui/core'
import { EE, Events } from '../../message'
import Store from '../../store'
import windowSize from 'react-window-size'


class ItemListFilterBar extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      sort: props.defaultSort || Store.getLastSortType() || 'createdOn:desc',
      filter: props.defaultFilter || 'all',
      search: props.defaultSearch || Store.getLastFilterSearch() || '',
      viewStyle: props.defaultViewStyle || 'grid',
    }

    this.onSortChange = this.onSortChange.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onViewStyleChange = this.onViewStyleChange.bind(this)

    this.instanceID = Math.floor(Math.random() * 1000000)
    this.searchInputRef = React.createRef()
  }

  componentDidMount() {
    EE.addListener(Events.ItemListFilterBar.DID_CHANGE_FILTER_SEARCH, this.onSearchChange)


    this.timeoutToken = setTimeout(() => {
      if (!this.timeoutToken) {
        return
      }
      const lastFilterSearch = Store.getLastFilterSearch()
      if (
        this.state.search.length === 0 && 
        lastFilterSearch &&
        lastFilterSearch.length > 0 &&
        this.searchInputRef && 
        this.searchInputRef.current
      ) {

        this.searchInputRef.current.value = lastFilterSearch
      }

      // if (this.searchInputRef && this.searchInputRef.current) {
      //   this.searchInputRef.current.focus()
      // }
    }, 0)
  }

  componentWillUnmount() {
    EE.removeListener(Events.ItemListFilterBar.DID_CHANGE_FILTER_SEARCH, this.onSearchChange)

    if (this.timeoutToken) {
      clearTimeout(this.timeoutToken)
      this.timeoutToken = null
    }
  }

  onSortChange = function(value) {
    this.setState({ sort: value })
    EE.emitEvent(Events.ItemListFilterBar.DID_CHANGE_FILTER_SORT, [value])
  }

  onFilterChange = function(value) {

    this.setState({ filter: value })
    EE.emitEvent(Events.ItemListFilterBar.DID_CHANGE_FILTER_TYPE, [value, this.instanceID])
  }

  onSearchChange = function(value, instanceID) {
    if (instanceID === this.instanceID) {
      return
    }

    Store.setLastFilterSearch(value)
    this.setState({ search: value })

    if (!instanceID) {
      // Only emit the event for the original filter change event
      // Not for the other instances of the filter bar that are listening for change events
      EE.emitEvent(Events.ItemListFilterBar.DID_CHANGE_FILTER_SEARCH, [value, this.instanceID])
    } else if (this.searchInputRef && this.searchInputRef.current) {
      // Sync both filter search inputs
      this.searchInputRef.current.value = value
    }

  }

  onViewStyleChange = function(value) {
    this.setState({ viewStyle: value })
    EE.emitEvent(Events.ItemListFilterBar.DID_CHANGE_VIEW_STYLE, [value])
  }

  render() {
    const { classes, windowWidth, onlySearch = false } = this.props
    const largeWindow = windowWidth > 960
    return (
      <div className={classes.layout}>
        <Grid container style={{ marginBottom: largeWindow ? 0 : 12 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchBar
              inputRef={this.searchInputRef}
              onChange={(e) => { this.onSearchChange(e.target.value) }}
              defaultValue={this.state.search}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
          { largeWindow && !onlySearch &&
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5} style={{ marginTop: -8 }}>
              <SelectBar 
                menu={[
                  {
                    name: 'Grid',
                    icon: <Apps className={classes.selectIcon} />,
                    value: 'grid',
                  },
                  {/*{
                    name: 'List',
                    icon: <List className={classes.selectIcon} />,
                    value: 'table',
                  },*/}
                ]} 
                label='View:'
                onChange={(e) => { this.onViewStyleChange(e.target.value) }}
              />
            </Grid>
          }
          { !onlySearch &&
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className='sort-select-container' style={{ marginTop:  -8 }}>
              <div className={classes.flexBox}>
                <div className={classes.sortOrder}>
                  <SelectBar 
                    menu={[
                      {
                        name: 'Date Created (new)',
                        value: 'createdOn:desc',
                      },
                      {
                        name: 'Date Created (old)',
                        value: 'createdOn:asc',
                      },
                      {
                        name: 'Price (high)',
                        value: 'price:desc',
                      },
                      {
                        name: 'Price (low)',
                        value: 'price:asc',
                      },
                      {
                        name: 'Title (A-Z)',
                        value: 'title:asc',
                      },
                      {
                        name: 'Title (Z-A)',
                        value: 'title:desc',
                      },
                    ]} 
                    label={ windowWidth > 1120 ? 'Sort By:' : 'Sort:' }
                    onChange={(e) => { this.onSortChange(e.target.value) }}
                    defaultValue={this.state.sort}
                  />
                </div>
                { false &&
                  <div className={classes.importButton}>
                    <ImportExport className={classes.sortIcon} />
                  </div>
                }
              </div>
            </Grid>
          }
        </Grid>
      </div>
    )
  }

}

export default windowSize(withStyles({
  layout: {
    padding: 14
  },
  selectIcon: {
    position: 'relative',
    top: 7,
    color: 'rgba(25, 25, 25, 0.497296)'
  },
  sortIcon: {
    fontSize: '1.9rem',
    position: 'relative',
    top: '11px',
    color: 'rgba(25, 25, 25, 0.497296)'
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  sortOrder: {
    flex: 1
  }
})(ItemListFilterBar));

/*
class ItemListFilterBar extends React.Component {

  constructor(props) {
    super(props)


  render() {   
    return (
      <Paper style={{
        padding: 15,
        marginBottom: 20,
      }}>
        <Grid container direction='row'>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl
              fullWidth
            >
              <TextField
                type='text' 
                name='search'
                value={this.state.search} 
                onChange={this.onSearchChange}
                placeholder='Search your inventory'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              /> 
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction='row' style={{ marginTop: 20 }}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography variant='body2'>
              Sort:
            </Typography>
            <FormControl>
              <Select
                name='sort'
                value={this.state.sort}
                onChange={this.onSortChange}
              >
                <MenuItem key={0} value={'createdOn:desc'}>Date Created (newest)</MenuItem>
                <MenuItem key={1} value={'createdOn:asc'}>Date Created (oldest)</MenuItem>
                <MenuItem key={2} value={'price:desc'}>Price (highest)</MenuItem>
                <MenuItem key={3} value={'price:asc'}>Price (lowest)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Typography variant='body2'>
              View:
            </Typography>
            <FormControl>
              <Select
                name='viewStyle'
                value={this.state.viewStyle}
                onChange={this.onViewStyleChange}
              >
                <MenuItem key={0} value={'grid'}><ViewComfyIcon /></MenuItem>
                <MenuItem key={1} value={'table'}><ListIcon /></MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Typography variant='body2'>
              Filter:
            </Typography>
            <FormControl>
              <Select
                name='filter'
                value={this.state.filter}
                onChange={this.onFilterChange}
              >
                <MenuItem key={0} value={'all'}>View All</MenuItem>
                {
                  config.merchantsAvailable.ebay && [
                    <MenuItem key={1} value={'ebay:listed'}>Ebay Listed</MenuItem>,
                    <MenuItem key={2} value={'ebay:not_listed'}>Ebay Not Listed</MenuItem>,
                  ]
                }
                {
                  config.merchantsAvailable.poshmark && [
                    <MenuItem key={3} value={'poshmark:listed'}>Poshmark Listed</MenuItem>,
                    <MenuItem key={4} value={'poshmark:not_listed'}>Poshmark Not Listed</MenuItem>,
                  ]
                }
                {
                  config.merchantsAvailable.etsy && [
                    <MenuItem key={5} value={'etsy:listed'}>Etsy Listed</MenuItem>,
                    <MenuItem key={6} value={'etsy:not_listed'}>Etsy Not Listed</MenuItem>,
                  ]
                }
                {
                  config.merchantsAvailable.mercari && [
                    <MenuItem key={7} value={'mercari:listed'}>Mercari Listed</MenuItem>,
                    <MenuItem key={8} value={'mercari:not_listed'}>Mercari Not Listed</MenuItem>,
                  ]
                }
                {
                  config.merchantsAvailable.facebook && [
                    <MenuItem key={9} value={'facebook:listed'}>Facebook Listed</MenuItem>,
                    <MenuItem key={10} value={'facebook:not_listed'}>Facebook Not Listed</MenuItem>,
                  ]
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}
*/

//export default ItemListFilterBar
