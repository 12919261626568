import React from 'react'

import {
  FormHelperText,
} from '@material-ui/core';
import InputLabel from '../../base/InputLabel'
import ChipInput from 'material-ui-chip-input'
import { withStyles } from '@material-ui/core/styles'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn'

const styles = theme => ({
  root: {
  },
  inputRoot: {
    borderRadius: 3,
    background: 'rgb(245, 245, 245)',
  },
  input: {

  },
  chipContainer: {
  },
  label: {
  },
  helperText: {

  },
  chip: {
    border: '1px solid rgb(200, 200, 200)',
    background: 'rgb(236, 236, 236)',
  },
})

class ItemFormInputTags extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      initialTags: props.defaultTags || [],
      tags: props.defaultTags || [],
      maxTags: props.maxTags,
    }
  }

  onHandleChange = function(chips) {
    this.setState({
      tags: chips,
    })
    if (this.props.onChange) {
      setTimeout(() => {
        this.props.onChange(chips)
      }, 0)
    }
  }

  render() {
    const { defaultTags, maxTags, merchantName, classes  } = this.props;
    const letterLimitText = merchantName === 'etsy' ? ' (20 letter limit per tag)' : ''

    return (
      <div>
          <InputLabel
            optional
            htmlFor="form-item-tags"
          >Tags</InputLabel>
          <ChipInput
            classes={classes}
            id={`${merchantName}tags`}
            defaultValue={defaultTags}
            onChange={(chips) => this.onHandleChange(chips)}
            fullWidth
            newChipKeyCodes={[13, 188]}
            variant="filled"
            error={maxTags ? this.state.tags.length > maxTags : false}
            InputProps={{
              'data-tags': this.state.tags || [],
              style: {
                paddingTop: 8,
              },
            }}

          />
          { this.state.initialTags.join(',') !== this.state.tags.join(',') && <FormHelperText>Not Saved</FormHelperText> }
          <FormHelperText id="form-item-tags-helper">
          { maxTags ?
            <span>
              Press Enter <KeyboardReturnIcon fontSize='small' style={{ fontSize: 14, marginBottom: -4 }} /> to separate tags. Use up to {maxTags} tags.{letterLimitText}
            </span>
             : null
          }
          </FormHelperText>

      </div>
    )
  }

}

export default withStyles(styles)(ItemFormInputTags)
