import React, { Fragment } from 'react'

import {
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core'

import { makeStyles, withStyles } from '@material-ui/core/styles'

import ClipLoader from 'react-spinners/ClipLoader';

import Modal from 'react-modal'
import Iframe from 'react-iframe'

import {
  MercariEvent,
  PoshmarkEvent,
} from '../../../constants'

import MerchantHelper from '../../../merchant'
import Store from '../../../store'
import Config from '../../../config'

Modal.setAppElement('#root')

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  leftTitle: {
    marginBottom: theme.spacing(1),
  },
  left: {
    paddingRight: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const styles = theme => ({
  row: {
    cursor: 'pointer',
  },
  itemImage: {
    maxWidth: 75,
    maxHeight: 75,
    padding: 0,
    margin: 0,
  },
  modal: {
    zIndex: 1000,
    background: 'white',
  },
})

class FormFillIframeButton extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      modalIsOpen: false,
    }

    if (
      !this.props.onFormFillIframeLoaded ||
      typeof this.props.onFormFillIframeLoaded !== 'function'
    ) {
      console.error('Must provide onListingIframeLoaded function to component')
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  onFormFillIframeLoaded() {
    if (this.props.onFormFillIframeLoaded) {
      this.props.onFormFillIframeLoaded()
    }
  }

  _listingURL() {
    switch(this.props.merchantName) {
      case 'etsy':
        return ``
      case 'poshmark':
        return `https://poshmark.com/create-listing?${PoshmarkEvent.SEND.FILL_NEW_LISTING}=1`
      case 'ebay':
        return ``
      case 'mercari':
        return ``
      default:
        console.error('Error: Unhandled merchant type', this.props.merchantName)
        return ``
    }    
  }
 
  async afterOpenModal() {
    console.log('After open modal')
    this.setState({
      iframeURL: this._listingURL()
    })
  }

  openModal() {
    this.setState({ 
      modalIsOpen: true,
    })
  }
 
  onRequestClose() {
    this.setState({ 
      modalIsOpen: false,
    })
  }

  render() {
    const { classes, merchantName, item } = this.props; 
    return (
      <div>
        <Button 
          onClick={this.openModal.bind(this) /*window.open(this._listingURL(), '_blank') }/*this.openModal.bind(this)*/}
          color="secondary"
          variant="outlined" 
        >
          Copy to {MerchantHelper.merchantNameFormatted(merchantName)}
        </Button>
        <div style={{
          maxWidth: 250,
          marginTop: 10,
        }}>
          <Typography
            variant='body2'
          >
            We will copy/paste the data to make listing as easy as possible
          </Typography>
        </div>
        { true &&
          <Box component="span" m={1} xs={12} spacing={3}>
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal.bind(this)}
              onRequestClose={this.onRequestClose.bind(this)}
              style={{
                overlay: {
                  backgroundColor: '#ccc',
                },
              }}
            >
              { this.state.modalIsOpen && this.state.iframeURL &&
                <Iframe 
                  id="form-fill-iframe"
                  onLoad={this.onFormFillIframeLoaded.bind(this)}
                  url={this.state.iframeURL}
                  height="800px" 
                  display="initial" 
                  position="initial"
                />
              }
            </Modal>
          </Box>
        }

      </div>
    )
  }
}


export default withStyles(styles)(FormFillIframeButton)