import React, { Fragment } from 'react'

import { 
  FormControl,
  MenuItem,
} from '@material-ui/core';
import InputLabel from '../../../base/InputLabel'
import SelectWithSavedNote from '../SelectWithSavedNote'
import { withStyles } from '@material-ui/core/styles'
import styles from '../ItemFormStyles'

class ItemFormEtsyWhoMade extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      whoMade: props.defaultState || 'someone_else',
    }
  }

  onChange = function(e) {
    this.setState({ whoMade: e.target.value })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {   
    const { classes } = this.props; 
    return (
      <Fragment>
        <InputLabel id="form-item-who-made-label">Who Made It?</InputLabel>
        <FormControl 
          className={classes.conditionControl}
          fullWidth
        >
          <SelectWithSavedNote
            labelId="form-item-who-made-label"
            id="form-item-who-made"
            name="whoMade"
            value={this.state.whoMade || ''}
            required={true}
            onChange={this.onChange.bind(this)}
          >

            <MenuItem value={'i_did'}>I did</MenuItem>
            <MenuItem value={'collective'}>A member of my shop</MenuItem>
            <MenuItem value={'someone_else'}>Another company or person</MenuItem>
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormEtsyWhoMade)