import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

const disconnectEbay = () => {

  ReactGA.event({
    category: 'Connections',
    action: 'Disconnect Ebay',
  })

  return Firebase.firestore()
    .collection('users')
    .doc(Firebase.auth().currentUser.uid)
    .set({ 
      ebayConnectionExpiration: null,
      ebayPolicies: {},
    }, { merge: true})
    .then(() => true)
    .catch( error => {
      alert(`Whoops, couldn't disconnect Ebay: ${error.message}`)
    })
}

export default disconnectEbay
