import React from 'react'

import Iframe from 'react-iframe'
import $ from 'jquery'

import {
  FacebookEvent,
} from '../../constants'

import config from '../../config'
import { Message, EE, Events } from '../../message'
import updateItem from '../../actions/updateItem'

const DEBUG = false

class ItemListerFacebook extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      iframeLoaded: false,
      hasReceivedInitializeEvent: false,
    }

    this.listItem = this.listItem.bind(this)
    this.listNextItem = this.listNextItem.bind(this)
    this.onCompletedListing = this.onCompletedListing.bind(this)
    this.onReceiveCompletedListingEvent = this.onReceiveCompletedListingEvent.bind(this)
    this.onReceiveListingData = this.onReceiveListingData.bind(this)
    this.onReceiveInitialize = this.onReceiveInitialize.bind(this)

    // Queued up item data
    this.queuedData = []
    this.currentItemID = null
    this.currentTimeoutToken = null

    // Autofill with this when loaded
    this.fillDataOnLoad = null

    // For deduping listing success events.
    // This is needed because on the extension side, we were seeing some unreliability of the sent messages being received
    // by ListingJoy, so it sends the receive:facebook:create_listing event multiple times.
    this.processedListings = {} // facebook listing id -> bool
  }

  componentDidMount() {
    document.addEventListener(FacebookEvent.RECEIVE.CREATE_LISTING, this.onReceiveCompletedListingEvent)

    EE.addListener(Events.ItemListerFacebook.INITIALIZE, this.onReceiveInitialize)
    EE.addListener(Events.ItemListerFacebook.CREATE_LISTING, this.onReceiveListingData)
  }

  componentWillUnmount() {
    console.log('Facebook item lister will unmount')

    document.removeEventListener(FacebookEvent.RECEIVE.CREATE_LISTING, this.onReceiveCompletedListingEvent)

    EE.removeListener(Events.ItemListerFacebook.INITIALIZE, this.onReceiveInitialize)
    EE.removeListener(Events.ItemListerFacebook.CREATE_LISTING, this.onReceiveListingData)
  }

  onReceiveInitialize(data) {
    this.fillDataOnLoad = data

    if (this.state.hasReceivedInitializeEvent) {
      return
    }

    console.log('Initializing facebook lister')
    this.setState({
      hasReceivedInitializeEvent: true,
    })
  }

  listNextItem() {
    if (this.currentItemID) {
      return console.error('Called listNextItem when we are currently listing an item')
    }
    if (this.queuedData.length === 0) {
      // No next item in queue
      return
    }
    const item = this.queuedData.shift()
    this.listItem(item)
  }

  listItem(data) {

    console.log('List item with data: ', data)

    this.currentItemID = data.id

    Message.send(FacebookEvent.SEND.CREATE_LISTING, data)

    this.currentTimeoutToken = setTimeout(() => {

      EE.emitEvent(Events.ItemListerFacebook.DID_CREATE_LISTING, [{
        errors: [{
          message: 'Timed out listing to Facebook. Please try it again, it often works on the second/third try. We\'re working on making this more reliable',
        }]
      }, null, data.id])

      this.reset()
    }, config.listingTimeoutInSecondsFacebook * 1000)
  }

  onReceiveListingData(data) {
    console.log('Received FB listing data in lister')

    if (!this.state.iframeLoaded || this.currentItemID) {

      if (!this.state.iframeLoaded) {
        console.log('Facebook Item lister not yet iframeLoaded. Queue the data')
      } else if (this.currentItemID) {
        console.log('Facebook Item lister currently listing an item. Queue the new data')
      }

      this.queuedData.push(data)
      return
    }

    this.listItem(data)
  }

  onReceiveCompletedListingEvent(e) {
    this.onCompletedListing(e.detail.data)
  }

  onCompletedListing(data) {
    console.log('Received data from create listing action: ', data)
    const success = data && data.listingID

    if (success) {

      if (this.processedListings[data.listingID]) {
        console.log('Deduping create listing action.')
        return
      }

      this.processedListings[data.listingID] = true

      updateItem(this.currentItemID, {
        merchants: {
          facebook: {
            dateCreated: new Date(),
            dateLastModified: new Date(),
            listed: true,
            listedID: data.listingID,
            listingURL: `https://facebook.com/marketplace/item/${data.listingID}`,
          },
        }
      }).then(updatedValues => {
        EE.emitEvent(Events.ItemListerFacebook.DID_CREATE_LISTING, [data, updatedValues, data.listingID])
      }).catch(error => {
        EE.emitEvent(Events.ItemListerFacebook.DID_CREATE_LISTING, [{ errors: [{ message: error.message || 'Unknown error listing on Facebook' }]}])
      })
    } else {
      EE.emitEvent(Events.ItemListerFacebook.DID_CREATE_LISTING, [data])
    }

    if (this.props.onCompletedListing) {
      this.props.onCompletedListing(data)
    }

    this.reset()
  }

  reset() {
    this.fillDataOnLoad = null
    this.currentItemID = null
    if (this.currentTimeoutToken) {
      clearTimeout(this.currentTimeoutToken)
      this.currentTimeoutToken = null
    }

    // Reset the iframe url. Toggle initialized
    this.setState({
      hasReceivedInitializeEvent: false,
      iframeLoaded: false,
    }, () => {
      this.setState({
        hasReceivedInitializeEvent: true,
      })
    })
  }


 /* Need to pass data as follows:
   1) this code to background
   2) background to content script iframeLoaded in facebook
   3) content script back to background
   4) background back listingjoy content script
   5) LJ content script dispatches an event that we are listening for in this component */
  render() {
    if (!config.merchantsAvailable.facebook) {
      return null
    }
    return (
      this.state.hasReceivedInitializeEvent &&
      <Iframe
        className={ !DEBUG ? 'iframe-list-facebook' : null }
        url={`https://m.facebook.com/marketplace/selling/item/?${FacebookEvent.SEND.CREATE_LISTING}=1`}
        width={DEBUG ? 400 : 1}
        height={DEBUG ? 400 : 1}
        display="initial"
        position="absolute"
        onLoad={() => {
          console.log('Facebook Item lister iframeLoaded')
          this.setState({ iframeLoaded: true })
          if (this.queuedData.length > 0) {
            console.log('Facebook Item lister finished loading. List with queued data')
            this.listNextItem()
          } else if (this.fillDataOnLoad) {
            console.log('Facebook item lister finished loading with no queued data. Autofill with fill data', this.fillDataOnLoad)
            Message.send(FacebookEvent.SEND.FILL_NEW_LISTING, this.fillDataOnLoad)
          }
        }}
      />
    )
  }
}

export default ItemListerFacebook
