import ReactGA from 'react-ga'
import queryString from 'query-string'
import config from '../config'

import { LJ } from '../constants'

const fetchBrands = async (brandQuery) => {

  ReactGA.event({
    category: 'Fetch',
    action: 'Fetch Brand',
  })

  if (!brandQuery || brandQuery.length === 0) {
    return [{
        "value": 1,
        "label": "104+ Octane Boost"
    }, {
        "value": 2,
        "label": "20/10 Products"
    }, {
        "value": 3,
        "label": "303 Products"
    }, {
        "value": 4,
        "label": "3.1 Phillip Lim"
    }, {
        "value": 5,
        "label": "3-In-One"
    }, {
        "value": 6,
        "label": "3M"
    }, {
        "value": 7,
        "label": "47 Brand"
    }, {
        "value": 8,
        "label": "525 America"
    }, {
        "value": 9,
        "label": "55DSL"
    }, {
        "value": 10,
        "label": "5th & Ocean"
    }]
  }

  try {
    const queryParams = queryString.stringify({
      brand: brandQuery
    })
    const result = await fetch(`${config.cloudFunctionBaseURL}/searchBrand?${queryParams}`)
    const json = await result.json()
    return json
  } catch (e) {
    console.error(e);
  }
}

export default fetchBrands
