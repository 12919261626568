import React, { Fragment } from 'react'

import { DndProvider } from 'react-dnd'
import { DropzoneArea } from '../../dropzone'
import ItemFormImageList from './ItemFormImageList'
import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'
import update from 'immutability-helper'
import cuid from 'cuid'

import uploadImage from '../../../actions/uploadImage'

const isTouchDevice = () => {
  if ('ontouchstart' in window) {
    return true
  }
  return false
}
const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend

class ItemFormInputDropzone extends React.Component {

  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onInitialLoad = this.onInitialLoad.bind(this)
    this.onDataStructureChange = this.onDataStructureChange.bind(this)

    this.fileMap = new ItemFormInputFileMap(this.onDataStructureChange, this.props.itemID)
  }

  onChange(fileObjects) {
    const newFileObjects = this.fileMap.fresh(fileObjects)
    this.fileMap.addToUploading(newFileObjects)

    if (this.props.onChangeImageFileObjects) {
      this.props.onChangeImageFileObjects(fileObjects)
    }
  }

  onReorder(fileObjects) {

    this.fileMap.setOrdering(fileObjects)


    if (this.props.onChangeImages) {
      this.props.onChangeImages(this.fileMap.data.ordered)
    }
  }

  onDataStructureChange(data) {
    console.log('Upload change: ', data)

    if (this.props.onChangeImages) {
      this.props.onChangeImages(data.ordered)
    }
  }


  onInitialLoad(fileObjects) {
    this.fileMap.setInitialUploadedData(fileObjects)

    console.log('On initial load: ', this.fileMap.data)

    if (this.props.onChangeImageFileObjects) {
      this.props.onChangeImageFileObjects(fileObjects)
    }
  }

  render() {
    const { initialFiles } = this.props
    return (
      <Fragment>
        <DropzoneArea 
          name='photos'
          acceptedFiles={['image/*']}
          maxFileSize={5000000}
          filesLimit={12}
          onLoad={this.onInitialLoad}
          onChange={this.onChange}
          onDelete={this.fileMap.remove.bind(this.fileMap)}
          onReorder={this.onReorder.bind(this)}
          initialFiles={initialFiles}
          showPreviewsInDropzone={false}
          showPreviews={true}
        >
        </DropzoneArea>
      </Fragment>
    )
  }
}

// Data structure that lets us see which files/images are not already uploaded
let ItemFormInputFileMap = class {

  constructor(onChange = () => {}, itemID = 'unknown') {
    // hash -> File object
    this.uploaded = {}
    this.uploading = {}
    // URL strings
    this.initialImages = []
    this.urlsToAdd = []
    this.urlsToRemove = []
    // URL to File object
    this.urlToFileObject = {}

    this.onChange = onChange
    this.itemID = itemID

    this.explicitOrdering = null
  }

  get data() {
    return {
      uploaded: Object.values(this.uploaded),
      uploading: Object.values(this.uploading),
      urlsToAdd: this.urlsToAdd,
      urlsToRemove: this.urlsToRemove,
      urlToFileObject: this.urlToFileObject,
      ordered: this.defaultOrdering(this.urlsToAdd, this.urlsToRemove)
    }
  }

  defaultOrdering(urlsToAdd, urlsToRemove) {
    let urls = this.initialImages.concat(urlsToAdd)
    let imageURLsMap = urls.reduce((memo, url) => {
      memo[url] = true
      return memo
    }, {})
    urlsToRemove.forEach(url => delete imageURLsMap[url])
    
    let ordering = Object.keys(imageURLsMap)

    if (this.explicitOrdering && this.explicitOrdering.length === ordering.length) {
      return this.explicitOrdering.map(fileObject => {
        if (fileObject.url) {
          return fileObject.url
        }
        for (const [url, uploadedFileObject] of Object.entries(this.urlToFileObject)) {
          if (uploadedFileObject.id === fileObject.id) {
            return url
          }
        }
        // We're setting the ordering before an image is done uploading
        // filter it out
        return false 
      }).filter(Boolean)
    }
    return ordering
  }

  setOrdering(fileObjects) {
    this.explicitOrdering = fileObjects.map(fileObject => {
      if (fileObject.url) {
        return fileObject
      }
      // If it exists and has no url, then it has been uploaded recently (without having been saved).
      const uploadedFileObject = this.uploaded[fileObject.id]
      if (uploadedFileObject && uploadedFileObject.url) {
        return uploadedFileObject
      }

      // If the image is still uploading, return the file object as is. If it has finished uploading when
      // we try to save it, we can find the URL from the this.uploaded map
      return fileObject
    })
  }

  // Return the files that do not exist in the data structure
  fresh(fileObjects = []) {
    if (!Array.isArray(fileObjects)) { 
      fileObjects = [fileObjects]
    }

    return fileObjects.filter(fileObject => !this.exists(fileObject))
  }

  remove(removedFileObject) {
    for (const [url, fileObject] of Object.entries(this.urlToFileObject)) {
      if (fileObject.id === removedFileObject.id) {
        this.urlsToRemove.push(url)
        delete this.urlToFileObject[url]
        this.onChange(this.data)
      }
    }
  }

  addToUploading(fileObjects = []) {
    if (!Array.isArray(fileObjects)) { 
      fileObjects = [fileObjects]
    }

    let changed = false
    for (let fileObject of fileObjects) {
      if (!this.exists(fileObject)) {
        this.uploading[fileObject.id] = fileObject
        this._upload(fileObject)
        changed = true
      }
    }
    if (changed) {
      this.onChange(this.data)
    }
  }

  setInitialUploadedData(fileObjects = []) {
    if (!Array.isArray(fileObjects)) { 
      fileObjects = [fileObjects]
    }

    this.initialImages = fileObjects.map(fileObject => fileObject.url)

    let changed = false
    for (let fileObject of fileObjects) {
      let url = fileObject.url
      if (!this.exists(fileObject)) {
        this.uploaded[fileObject.id] = fileObject
        this.urlToFileObject[url] = fileObject
        changed = true
      }
    }

    if (changed) {
      this.onChange(this.data)
    }
  }

  exists(fileObject) {
    return this.uploaded[fileObject.id] || this.uploading[fileObject.id]
  }

  // Set an uploading video to uploaded
  _setToUploaded(fileObject) {
    if (!this.uploaded[fileObject.id] && this.uploading[fileObject.id]) {
      delete this.uploading[fileObject.id]
      this.uploaded[fileObject.id] = fileObject

      this.onChange(this.data)
    }
  }

  _upload(fileObject) {
    uploadImage(fileObject.file, this.itemID).then((url) => {
      this.urlsToAdd.push(url)
      this.urlToFileObject[url] = fileObject
      this._setToUploaded(fileObject)
    })
  }
}

export default ItemFormInputDropzone