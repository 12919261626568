import React from 'react'
import firebase from 'firebase/app'

// From https://blog.bitsrc.io/authen-route-in-react-firebase-5d56dcb1fa37
// 
// 
// create a function that take a path and then return HOC
// const withRedirectWithAuthed = redirectPath => HOC
const withRedirectWithAuthed = redirectPath => WrappedComponent => {
  class withRedirectWithAuthed extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isLoading: true,
        currentUser: null,
      }
    }
    componentDidMount() {
      this.unsubscribe = firebase.auth().onAuthStateChanged(this.handleAuth, (e) => { console.error(e)} /* handleError */)
    
      if (window.location.hash === '#google') {
        this.props.history.push('/')
      }
    }
    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe()
      }
    }
    handleAuth = currentUser => {
      // use history from parent.
      const { history } = this.props;
      if (currentUser) {
        // no auth at the beginning of the app, redirect them to login.
        return history.push(redirectPath)
      }

      this.setState({
        isLoading: false,
        currentUser,
        error: null,
      })
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
   
  return withRedirectWithAuthed
}

export default withRedirectWithAuthed