import React, { Fragment } from 'react'

import {
  MercariEvent,
} from '../../../constants'

import Iframe from 'react-iframe'
import importItem from '../../../actions/importItem'
import config from '../../../config'

class ItemImporterMercari extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      complete: false,
    }

    this.onReceiveImportItemData = this.onReceiveImportItemData.bind(this)

    if (this.props.onImportFailed) {
      this.timeoutToken = setTimeout(() => {
        console.error('Timed out importing mercari item: ', this.props.platformID)
        this.props.onImportFailed(this.props.platformID)
        this.timeoutToken = null
      }, config.importItemTimeoutInSeconds * 1000)
    }
  }

  componentDidMount() {
    document.addEventListener(MercariEvent.RECEIVE.GET_LISTING_DETAILS, this.onReceiveImportItemData)
  }

  componentWillUnmount() {
    document.removeEventListener(MercariEvent.RECEIVE.GET_LISTING_DETAILS, this.onReceiveImportItemData)
  }

  onReceiveImportItemData = async (event) => {
    const data = event.detail.data || {}

    if (!data || data.platformID !== this.props.platformID) {
      console.error('Do not perform callback for this Mercari import. Got different platform ID.')
      return
    }

    console.log('Received imported Mercari item data: ', data)
    const item = await importItem(data)

    console.log('Successfully imported item:', item)
    this.setState({ 
      complete: true
    })

    if (this.timeoutToken) {
      clearTimeout(this.timeoutToken)
      this.timeoutToken = null
    }

    if (this.props.onImportComplete) {
      this.props.onImportComplete(item)
    }
  }

  render() {
    const { platformID } = this.props
    return (
      <Fragment>
      {
        !this.state.complete &&
        <Iframe 
          id="iframe-mercari-import-item"
          url={`https://www.mercari.com/sell/edit/${platformID}/?${MercariEvent.SEND.GET_LISTING_DETAILS}=1`}
          height="800px" 
          display="initial" 
          position="absolute"
        />
      }
      </Fragment>
    )
  }
}

export default ItemImporterMercari