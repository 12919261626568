import React, { Fragment } from 'react'

import TextFieldWithSavedNote from './TextFieldWithSavedNote'
import InputLabel from '../../base/InputLabel'

const ItemFormInputSku = ({ defaultValue, optional, loading, onChange = () => {} }) => (
  <Fragment>
      <InputLabel optional={optional}>SKU</InputLabel>
      <TextFieldWithSavedNote 
        name="sku"
        defaultValue={defaultValue || ''} 
        fullWidth
        variant="filled"
        loading={loading}
        onChange={onChange}
      />          
  </Fragment>
)

export default ItemFormInputSku