
export default {
  merchantsAvailable: {
    etsy: process.env.REACT_APP_MERCHANT_ENABLED_ETSY === '1',
    mercari: process.env.REACT_APP_MERCHANT_ENABLED_MERCARI === '1',
    poshmark: process.env.REACT_APP_MERCHANT_ENABLED_POSHMARK === '1',
    facebook: process.env.REACT_APP_MERCHANT_ENABLED_FACEBOOK === '1',

    ebay: process.env.REACT_APP_MERCHANT_ENABLED_EBAY === '1',
    tradesy: process.env.REACT_APP_MERCHANT_ENABLED_TRADESY === '1',
    grailed: process.env.REACT_APP_MERCHANT_ENABLED_GRAILED === '1',
  },

  merchantImportAvailable: {
    etsy: process.env.REACT_APP_MERCHANT_IMPORT_ENABLED_ETSY === '1',
    mercari: process.env.REACT_APP_MERCHANT_IMPORT_ENABLED_MERCARI === '1',
    poshmark: process.env.REACT_APP_MERCHANT_IMPORT_ENABLED_POSHMARK === '1',
    facebook: process.env.REACT_APP_MERCHANT_IMPORT_ENABLED_FACEBOOK === '1',

    ebay: process.env.REACT_APP_MERCHANT_IMPORT_ENABLED_EBAY === '1',
    tradesy: process.env.REACT_APP_MERCHANT_IMPORT_ENABLED_TRADESY === '1',
    grailed: process.env.REACT_APP_MERCHANT_IMPORT_ENABLED_GRAILED === '1',
  },

  requiresSubscription: process.env.REACT_APP_REQUIRES_SUBSCRIPTION === '1',

  chromeExtensionVersion: process.env.REACT_APP_CHROME_EXTENSION_VERSION,
  chromeExtensionID: process.env.REACT_APP_CHROME_EXTENSION_ID,
  chromeExtensionURL: process.env.REACT_APP_CHROME_EXTENSION_URL,

  chromeExtensionDebugID: 'jkidajnmeheioophfmlmpkkccfnapgmi',
  chromeExtensionProductionID: 'hhcmgjflfmmcceihiimlmmcepemekkge',

  cloudFunctionBaseURL: process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL,

  listingTimeoutInSeconds: 20,
  listingTimeoutInSecondsFacebook: 30,

  importItemTimeoutInSeconds: 20,
  connectionVerifyTimeoutInSeconds: 12,

  showDebugUI: process.env.REACT_APP_SHOW_DEBUG_UI === '1',
}
