import firebase from 'firebase/app'
import ReactGA from 'react-ga'
import queryString from 'query-string'
import decode from 'unescape'
import config from '../config'

const fetchEtsy = async ({ status, page, search, shop } = {}) => {

  ReactGA.event({
    category: 'Fetch',
    action: 'Fetch Etsy',
  })

  const currentUser = firebase.auth().currentUser

  if (!currentUser) {
    console.log('Not logged in')
    return []
  }

  const userDoc = await firebase.firestore()
    .collection('users')
    .doc(currentUser.uid)
    .get()

  if (!userDoc.exists || !userDoc.data().etsyToken) {
    console.log('User doesnt exist')
    return []
  }

  const etsyToken = userDoc.data().etsyToken
  const token = await currentUser.getIdToken(true)

  try {
    const queryParams = queryString.stringify({
      status,
      page,
      search,
      shop,
      token,
      etsyToken,
    })
    const result = await fetch(`${config.cloudFunctionBaseURL}/etsyItem?${queryParams}`)
    
    let json = await result.json()
    const data = json.results.map(item => {
      let res = item

      // HTML unescape
      res.title = decode(res.title)
      res.description = decode(res.description)
      res.imageURL = item.MainImage.url_170x135
      res.platform = 'etsy'
      return res
    })
    return data
  } catch (e) {
    console.error(e);
  }
}

export default fetchEtsy
