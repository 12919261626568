import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

const signUpWithEmail = (email, password) => () => {

  ReactGA.event({
    category: 'User',
    action: 'Sign up with Email',
  })

  console.log('Attempting sign up with: ', email, password)

  return Firebase.auth()
    .createUserWithEmailAndPassword(email, password)
    .then( result => {
      console.log(`signed up as ${result.user.displayName}`)
      return result
    })
}

export default signUpWithEmail
