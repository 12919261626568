import React, { Fragment } from 'react'

import {
  FormHelperText,
  Grid,
  Paper,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import InputLabel from '../../../base/InputLabel'
import ItemFormInputPrice from '../ItemFormInputPrice'

import { withStyles } from '@material-ui/core/styles'
import styles from '../../form/ItemFormStyles'

class ItemFormPoshmarkPrice extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      originalPrice: this.props.defaultOriginalPrice,
      price: this.props.defaultPrice,
    }

    this.onChangeOriginalPrice = this.onChangeOriginalPrice.bind(this)
    this.onChangePrice = this.onChangePrice.bind(this)
  }

  onChangeOriginalPrice = function(e) {
    this.setState({ originalPrice: e.target.value })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  onChangePrice = function(e) {
    this.setState({ price: e.target.value })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {
    const { defaultOriginalPrice, defaultPrice, classes } = this.props
    return (
      <Fragment>
        <Grid item sm={12} md={9} className={classes.rowContent}>
          <Grid container direction="row">
            <InputLabel>Original Price</InputLabel>
          </Grid>
          <Grid container direction="row">
            <ItemFormInputPrice
              name="originalPrice"
              defaultPrice={defaultOriginalPrice}
              onChange={this.onChangeOriginalPrice}
            />
          </Grid>
          <Grid container direction="row">
            <FormHelperText>Retail price of item</FormHelperText>
          </Grid>
        </Grid>
        <Grid item md={3} />
        <Grid item sm={12} md={9}>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <InputLabel htmlFor="form-item-price">Listing Price</InputLabel>
          </Grid>
          <Grid container direction="row">
            <ItemFormInputPrice
              defaultPrice={defaultPrice}
              onChange={this.onChangePrice}
              minPrice={3}
            />
          </Grid>
          <Grid container direction="row">
            <FormHelperText>Price you are selling your item</FormHelperText>
          </Grid>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Alert severity='info'>Poshmark takes a $2.95 flat commission for sales under $15, and 20% of the sale price on sales over $15.</Alert>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormPoshmarkPrice)
