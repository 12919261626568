import firebase from 'firebase/app'
import ReactGA from 'react-ga'

const logInWithEmail = (email, password) => () => {

  ReactGA.event({
    category: 'User',
    action: 'Log in with Email',
  })

  return firebase.auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      return firebase.auth().signInWithEmailAndPassword(email, password)
    })
    .then(result => {
      console.log(`logged in as ${result.user.displayName}`)
      return result
    })
}

export default logInWithEmail
