import React, { Fragment } from 'react'

import { 
  FormControl,
  MenuItem,
} from '@material-ui/core'
import InputLabel from '../../../base/InputLabel'
import SelectWithSavedNote from '../SelectWithSavedNote'
import { withStyles } from '@material-ui/core/styles'
import styles from '../ItemFormStyles'


class ItemFormEtsyWhenMade extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      whenMade: props.defaultState || undefined,
    }
  }

  onChange = function(e) {
    this.setState({ whenMade: e.target.value })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {   
    const { classes } = this.props; 
    return (
      <Fragment>
        <InputLabel id="form-item-when-made-label">When Was It Made?</InputLabel>
        <FormControl 
          className={classes.conditionControl}
          fullWidth
        >
          <SelectWithSavedNote
            labelId="form-item-when-made-label"
            id="form-item-when-made"
            name="whenMade"
            value={this.state.whenMade || ''}
            required={true}
            onChange={this.onChange.bind(this)}
          >
            <MenuItem value={'made_to_order'}>Made to Order</MenuItem>
            <MenuItem value={'2020_2020'}>2020</MenuItem>
            <MenuItem value={'2010_2019'}>2010 - 2019</MenuItem>
            <MenuItem value={'2001_2009'}>2001 - 2009</MenuItem>
            <MenuItem value={'before_2001'}>Before 2001</MenuItem>
            <MenuItem value={'2000_2000'}>2000</MenuItem>
            <MenuItem value={'1990s'}>1990s</MenuItem>
            <MenuItem value={'1980s'}>1980s</MenuItem>
            <MenuItem value={'1970s'}>1970s</MenuItem>
            <MenuItem value={'1960s'}>1960s</MenuItem>
            <MenuItem value={'1950s'}>1950s</MenuItem>
            <MenuItem value={'1940s'}>1940s</MenuItem>
            <MenuItem value={'1930s'}>1930s</MenuItem>
            <MenuItem value={'1920s'}>1920s</MenuItem>
            <MenuItem value={'1910s'}>1910s</MenuItem>
            <MenuItem value={'1900s'}>1900s</MenuItem>
            <MenuItem value={'1800s'}>1800s</MenuItem>
            <MenuItem value={'1700s'}>1700s</MenuItem>
            <MenuItem value={'before_1700'}>Before 1700</MenuItem>
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormEtsyWhenMade)