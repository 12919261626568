import React, { Fragment, useState } from 'react'

import {
  Grid,
} from '@material-ui/core'

import ItemFormSectionHeaderExtra from '../headers/ItemFormSectionHeaderExtra'
import ItemFormPoshmarkAdditionalDetails from './ItemFormPoshmarkAdditionalDetails'

const ItemFormPoshmarkAdditionalDetailsSection = props => {
  const [hidden, setHidden] = useState(!props.defaultSku && !props.defaultCostPrice && !props.defaultOtherInfo)
  return (
    <Fragment>
      <ItemFormSectionHeaderExtra
        number={5}
        text='Additional Details (Private)'
        detailsHidden={hidden}
        onShowDetails={(value) => setHidden(value)}
      />
      {
        !hidden &&
        <Grid item xs={12} sm={12} md={12}>
          <ItemFormPoshmarkAdditionalDetails
            defaultSku={props.defaultSku}
            defaultCostPrice={props.defaultCostPrice}
            defaultOtherInfo={props.defaultOtherInfo}
          />
        </Grid>
      }
    </Fragment>
  )
}

export default ItemFormPoshmarkAdditionalDetailsSection
