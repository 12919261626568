
import React from 'react'
import BadgeLabel from './BadgeLabel'

import saveUserDefault from '../../actions/saveUserDefault'

class SaveDefaultBadge extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    console.log('init save default badge with default:', props.isDefault)

    this.onClick = this.onClick.bind(this)
  }

  onClick = async () => {
    const { name, isDefault, value, onSaved } = this.props
    if (isDefault) {
      return
    }

    console.log('Saving user default: ', name, value)
    this.setState({
      loading: true,
    })
    const success = await saveUserDefault(name, value)

    if (success && onSaved) {
      onSaved(value)
    }

    this.setState({
      loading: false,
    })
    console.log('Saved user default: ', name, success)
  }

  render() {
    const { style, isDefault } = this.props
    console.log('Render default badge with default:', isDefault)
    return (
      <div
        onClick={this.onClick}
        style={{ marginTop: -5 }}
      >
        <BadgeLabel 
          text={this.state.loading ? '' : (isDefault ? 'Using default' : 'Save as Default')} 
          loading={this.state.loading}
          style={{
            cursor: isDefault ? 'inherit' : 'pointer',
            fontSize: 9,
            marginLeft: 8,
            marginBottom: 4,
            color: isDefault ? 'rgb(30, 70, 32)' : 'rgb(12, 54, 91)', // same as material ui success/info alert
            backgroundColor: isDefault ? 'rgb(237, 247, 237)' : 'rgb(232, 243, 252)', // ditto above
            ...(style || {}),
          }}
        />
      </div>
    )
  }
}

export default SaveDefaultBadge
