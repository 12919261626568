import React, { Fragment } from 'react'

import { 
  FormControl,
  MenuItem,
} from '@material-ui/core';
import InputLabel from '../../../../base/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../ItemFormStyles'
import SelectWithSavedNote from '../../SelectWithSavedNote'

class ItemFormEbayShippingService extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: props.defaultValue || undefined,
    }
  }

  onChange = function(e) {
    this.setState({ value: e.target.value })
    
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {   
    const { classes } = this.props; 
    return (
      <Fragment>
        <InputLabel>Shipping Service</InputLabel>
        <FormControl 
          className={classes.valueControl}
          required
          fullWidth
        >
          <SelectWithSavedNote
            name="shippingService"
            value={this.state.value || ''}
            onChange={this.onChange.bind(this)}
          >
            {
              ebayShippingOptions.map(option => {
                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              })
            }
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

const ebayShippingOptions = [
   {
      value:"US_DGMSmartMailExpedited",
      label:"DGM SmartMail Expedited (2 to 5 business days)"
   },
   {
      value:"US_DGMSmartMailGround",
      label:"DGM SmartMail Ground (3 to 8 business days)"
   },
   {
      value:"Other",
      label:"Economy Shipping (1 to 10 business days)"
   },
   {
      value:"US_EconomyShippingFromGC",
      label:"Economy Shipping from China/Hong Kong/Taiwan to worldwide (11 to 35 business days)"
   },
   {
      value:"US_EconomyShippingFromIN",
      label:"Economy Shipping from India (8 to 13 business days)"
   },
   {
      value:"EconomyShippingFromOutsideUS",
      label:"Economy Shipping from outside US (11 to 23 business days)"
   },
   {
      value:"US_EconomySppedPAK",
      label:"Economy SpeedPAK from China/Hong Kong/Taiwan (10 to 15 business days)"
   },
   {
      value:"ShippingMethodExpress",
      label:"Expedited Shipping (1 to 3 business days)"
   },
   {
      value:"US_ExpeditedShippingFromGC",
      label:"Expedited Shipping from China/Hong Kong/Taiwan to worldwide (2 to 7 business days)"
   },
   {
      value:"US_ExpeditedShippingFromIN",
      label:"Expedited Shipping from India (3 to 9 business days)"
   },
   {
      value:"ExpeditedShippingFromOutsideUS",
      label:"Expedited Shipping from outside US (1 to 4 business days)"
   },
   {
      value:"US_ExpeditedSppedPAK",
      label:"Expedited SpeedPAK from China/Hong Kong/Taiwan (5 to 9 business days)"
   },
   {
      value:"FedEx2Day",
      label:"FedEx 2Day (1 to 2 business days)"
   },
   {
      value:"FedExExpressSaver",
      label:"FedEx Express Saver (1 to 3 business days)"
   },
   {
      value:"FedExHomeDelivery",
      label:"FedEx Ground or FedEx Home Delivery (1 to 5 business days)"
   },
   {
      value:"US_FedExIntlEconomy",
      label:"FedEx International Economy (2 to 4 business days)"
   },
   {
      value:"FedExPriorityOvernight",
      label:"FedEx Priority Overnight (1 business day)"
   },
   {
      value:"FedExSmartPost",
      label:"FedEx SmartPost (2 to 8 business days)"
   },
   {
      value:"FedExStandardOvernight",
      label:"FedEx Standard Overnight (1 business day)"
   },
   {
      value:"FlatRateFreight",
      label:"Flat Rate Freight"
   },
   {
      value:"US_MailServiceFromIndia",
      label:"Mail Service from India (14 to 27 business days)"
   },
   {
      value:"ShippingMethodOvernight",
      label:"One-day Shipping (1 business day)"
   },
   {
      value:"ShippingMethodStandard",
      label:"Standard Shipping (1 to 5 business days)"
   },
   {
      value:"US_StandardShippingFromGC",
      label:"Standard Shipping from China/Hong Kong/Taiwan to worldwide (7 to 19 business days)"
   },
   {
      value:"US_StandardShippingFromIN",
      label:"Standard Shipping from India (5 to 12 business days)"
   },
   {
      value:"StandardShippingFromOutsideUS",
      label:"Standard Shipping from outside US (5 to 10 business days)"
   },
   {
      value:"US_StandardSppedPAK",
      label:"Standard SpeedPAK from China/Hong Kong/Taiwan (8 to 12 business days)"
   },
   {
      value:"UPS2ndDay",
      label:"UPS 2nd Day Air (2 business days)"
   },
   {
      value:"UPS3rdDay",
      label:"UPS 3 Day Select (3 business days)"
   },
   {
      value:"UPSGround",
      label:"UPS Ground (1 to 5 business days)"
   },
   {
      value:"UPSNextDayAir",
      label:"UPS Next Day Air (1 business day)"
   },
   {
      value:"UPSNextDay",
      label:"UPS Next Day Air Saver (1 business day)"
   },
   {
      value:"US_UPSSurePost",
      label:"UPS Surepost (1 to 6 business days)"
   },
   {
      value:"USPSFirstClass",
      label:"USPS First Class Package (2 to 3 business days)"
   },
   {
      value:"USPSMedia",
      label:"USPS Media Mail (2 to 8 business days)"
   },
   {
      value:"USPSParcel",
      label:"USPS Parcel Select Ground (2 to 9 business days)"
   },
   {
      value:"USPSPriority",
      label:"USPS Priority Mail (1 to 3 business days)"
   },
   {
      value:"USPSExpressMail",
      label:"USPS Priority Mail Express (1 business day)"
   },
   {
      value:"USPSExpressFlatRateEnvelope",
      label:"USPS Priority Mail Express Flat Rate Envelope (1 business day)"
   },
   {
      value:"USPSExpressMailLegalFlatRateEnvelope",
      label:"USPS Priority Mail Express Legal Flat Rate Envelope (1 business day)"
   },
   {
      value:"USPSPriorityFlatRateEnvelope",
      label:"USPS Priority Mail Flat Rate Envelope (1 to 3 business days)"
   },
   {
      value:"USPSPriorityMailLargeFlatRateBox",
      label:"USPS Priority Mail Large Flat Rate Box (1 to 3 business days)"
   },
   {
      value:"USPSPriorityMailLegalFlatRateEnvelope",
      label:"USPS Priority Mail Legal Flat Rate Envelope (1 to 3 business days)"
   },
   {
      value:"USPSPriorityFlatRateBox",
      label:"USPS Priority Mail Medium Flat Rate Box (1 to 3 business days)"
   },
   {
      value:"USPSPriorityMailPaddedFlatRateEnvelope",
      label:"USPS Priority Mail Padded Flat Rate Envelope (1 to 3 business days)"
   },
   {
      value:"USPSPriorityMailSmallFlatRateBox",
      label:"USPS Priority Mail Small Flat Rate Box (1 to 3 business days)"
   },
   {
      value:"USPSStandardPost",
      label:"USPS Retail Ground (2 to 9 business days)"
   }
]

export default withStyles(styles)(ItemFormEbayShippingService)