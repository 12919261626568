import firebase from 'firebase/app'
import 'firebase/firestore'
import ReactGA from 'react-ga'

const fetchItemAspectsForCategory = (merchantName, categoryID, timeoutMs = 8000) => {
  if (!categoryID) {
    return Promise.resolve(null)
  }

  ReactGA.event({
    category: 'Item Aspects For Category',
    action: 'Fetch Item Aspects For Category',
  })

  switch (merchantName) {
    case 'ebay':
    case 'etsy':
    case 'mercari':
    case 'poshmark':
      break
    case 'facebook':
      // no specifics for merchant
      return Promise.resolve(null)
    default:
      console.error('Invalid merchantName provided in fetchItemAspectsForCategory: ', merchantName)
      return Promise.resolve(null)
  }

  const capitalizedMerchantName = merchantName.charAt(0).toUpperCase() + merchantName.substring(1)
  console.log('Fetching for merchant name: ', capitalizedMerchantName)

  const firestorePromise = new Promise((resolve, reject) => {
    firebase.firestore()
      .collection(`itemAspectsForCategory${capitalizedMerchantName}`)
      .doc(String(categoryID))
      .get()
      .then(doc => {
        if (!doc || !doc.exists || doc.data().message === 'error' || !doc.data().aspects) {
          return resolve(null)
        }

        const formattedData = formatItemAspectsForCategory(doc.data().aspects)
        console.log(`Formatted data for ${merchantName}: `, formattedData)

        return resolve(formattedData)
      })
      .catch( error => {
        console.error(`Couldn't get the aspects for category ${categoryID} for merchant ${merchantName}: ${error.message}`, firebase.auth().currentUser.id)
        return resolve(null)
      })
  })
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(resolve, timeoutMs, null)
  })

  return Promise.race([firestorePromise, timeoutPromise])
}

// We build this data structure with helpful mappings so that we don't need to build these data structures
// later in our components
//
// Resulting shape:
//
// {
//   aspects: {
//     [aspectID]: {
//       id: string/number,
//       name: string,
//       constraint: {
//         maxLength: 'number', // default 1
//         mode: 'FREE_TEXT', 'SELECTION_ONLY' // default selection
//         required: 'boolean', // default yes
//         usage: 'RECOMMENDED', 'OPTIONAL', // default recommended
//         cardinality: 'MULTI', 'SINGLE', // default single
//         dataType: 'date', 'number', 'string', // default null
//         format: 'string', // default null

//         // Note: if valueconstraint.length > 0, debug/log so I can see example
//       },
//       valueSets: {
//         [valueSetId] or 'none': {
//           id: string,
//           name: string or 'none',
//           values: [
            // {
            //   id: string,
            //   name: string,
            // }
            // ...
//           ]
//         }
//       }
//     },
//    ...
//    },
//
//    childAspectForOption: {
//      [optionID]: childAspect // full object
//    },
//  }
//
//
//

// Build a nested data structure instead of the default flat structure
// If there are parent/child dependencies for the inputs, build that into the data structure
const formatItemAspectsForCategory = (aspects) => {

  if (!aspects.length) {
    return null
  }

  // A hash of the aspects with { aspectID -> aspect }
  let aspectsResult = {}

  // Create map of aspects with a parent/child dependency
  // If applicableForAspectValueID is null, there is no dependency
  let aspectsWithParentDependencies = {} // parentAspectValueID => { id: aspectID, data: data }

  for (let aspect of aspects) {
    let parentAspectValueID = aspect.constraint && aspect.constraint.applicableForAspectValueID || null

    if (parentAspectValueID && parentAspectValueID.length > 0) {
      // First pass, do not add these to main category object
      // Add them on the next pass as children
      // Currently we assume only one level of nesting
      aspectsWithParentDependencies[aspect.constraint.applicableForAspectValueID] = { ...aspect } // clone
    } else {
      aspectsResult[aspect.id] = { ...aspect } // clone
    }
  }

  for (let aspect of aspects) {
    // This is O(n^2). Make more efficient if this is an issue
    // Second pass
    if (!aspect.valueSets) {
      continue
    }

    for (let [valueSetID, valueSetData] of Object.entries(aspect.valueSets)) {

      for (let [valueID, valueData] of Object.entries(valueSetData.values)) {

        const child = aspectsWithParentDependencies[valueID]
        if (child) {

          // If a nested category exists for this ID, add it to the nested result data
          if (!aspectsResult[aspect.id].valueSets[valueSetID].values[valueID].child) {
            // initialize object if it doesn't exist
            aspectsResult[aspect.id].valueSets[valueSetID].values[valueID].child = {}
          }
          aspectsResult[aspect.id].valueSets[valueSetID].values[valueID].child[child.id] = { ...child } // clone
        }
      }
    }
  }

  return {
    aspects: aspectsResult,
    // Map with valueID -> childAspect
    childAspectForOption: aspectsWithParentDependencies,
  }
}

export default fetchItemAspectsForCategory
