
import React from 'react'

import {
  Button,
  Link,
} from '@material-ui/core'

import NotesIcon from '@material-ui/icons/Notes'
import ItemFormSectionHeader from '../ItemFormSectionHeader'
import { withStyles } from '@material-ui/core/styles'
import styles from '../ItemFormStyles'

const ItemFormSectionHeaderExtra = ({ classes, number, text, detailsHidden, onShowDetails = () => {} }) => (
  <ItemFormSectionHeader
    icon={<NotesIcon style={{ marginBottom: -6 }}/>}
    number={String(number)}
    text={text || 'Extra'}
    hideHr={detailsHidden}
    button={
      <Link
        className={classes.formSectionHeaderLink}
        onClick={() => { onShowDetails(!detailsHidden) }}
      >
        {detailsHidden ? 'Show Details' : 'Hide Details'}
      </Link>
    }
  />
)

export default withStyles(styles)(ItemFormSectionHeaderExtra)
