
import React from 'react'
import iconStyles from './iconStyles'

const ListingJoyIcon = ({ style, innerStyle = {} }) => (
  <div style={{
    ...iconStyles,
    ...style,
  }}>
    <svg
      style={{
        ...innerStyle,
      }}
      viewBox="0 0 283.98 283.98"
      xmlns="http://www.w3.org/2000/svg"
    ><circle cx="141.99" cy="141.99" fill="#efcadc" r="141.99"/><path d="m177.76 117.84a56.52 56.52 0 0 1 -10.09 32.32q-.81 1.18-1.68 2.31h-98.45v-34.63z" fill="#2d79ad"/><path d="m67.54 152.47v-34.63a61 61 0 0 0 61-61h34.63a95.6 95.6 0 0 1 -95.6 95.6z" fill="#2d79ad"/><path d="m216.44 117.84a95.6 95.6 0 0 1 -95.59 95.6v-30.58a65.09 65.09 0 0 0 53.48-28.09c.53-.75 1-1.52 1.53-2.3a64.6 64.6 0 0 0 10-34.63z" fill="#e27d7d"/></svg>
  </div>
)

export default ListingJoyIcon
