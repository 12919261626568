import firebase from 'firebase/app'
import ReactGA from 'react-ga'

const logInWithGoogle = () => {

  ReactGA.event({
    category: 'User',
    action: 'Log in with Facebook',
  })

  let provider = new firebase.auth.FacebookAuthProvider()
  
  return firebase.auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      return firebase.auth().signInWithRedirect(provider)
    })
    .then( result => {
      console.log(`logged in as ${result.user.displayName}`)
      return result
    })
}

export default logInWithGoogle
