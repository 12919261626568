import React from 'react'

import MailIcon from '@material-ui/icons/Mail'
import ItemFormSectionHeader from '../ItemFormSectionHeader'

const ItemFormSectionHeaderPackage = ({ number, text }) => (
  <ItemFormSectionHeader
    icon={<MailIcon style={{ marginBottom: -6 }}/>}
    number={String(number)}
    text={text || 'Package'}
  />
)

export default ItemFormSectionHeaderPackage
