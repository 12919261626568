import React, { Fragment } from 'react'
import CreatableSelect from 'react-select/creatable'
import styles from './ItemFormCreateableSelectStyles'
import {
  ItemFormInputAspectDOM,
} from './ItemFormInputAspectDOM'
import Helpers from './ItemFormInputAspectHelpers'

class ItemFormInputAspectSelectFreeText extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue || null,
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange(selectedValue) {
    console.log('Selected aspect value with free text: ', selectedValue)

    this.setState({
      value: selectedValue,
    })

    if (this.props.onChange) {
      this.props.onChange(selectedValue)
    }
  }

  render() {
    const {
      aspect,
      merchantName,
      values,
      defaultValue = null,
    } = this.props

    const { value } = this.state
    const hasError = aspect.constraint.required && (value === null || value === '')

    if (!values) {
      return null
    }
    return (
      <Fragment>
        <CreatableSelect
          isClearable
          options={values.map(v => ({ value: v.id, label: v.name }))}
          placeholder=''
          onChange={this.onChange}
          defaultValue={defaultValue}
          styles={styles(hasError)}
          required={aspect.constraint.required}
          formatCreateLabel={(label) => `Use custom value "${label}"`}
        />
        { value !== null &&
          <ItemFormInputAspectDOM
            aspect={aspect}
            merchantName={merchantName}
            value={JSON.stringify({
              value: value.value,
              label: value.label,
            })}
            hasError={hasError}
          />
        }
      </Fragment>
    )
  }
}

export default ItemFormInputAspectSelectFreeText
