import firebase from 'firebase/app'
import ReactGA from 'react-ga'

import Store from '../store'

const fetchUser = async () => {

  ReactGA.event({
    category: 'Fetch',
    action: 'Fetch user',
  })

  const currentUser = firebase.auth().currentUser
  if (!currentUser) {
    console.error('No current user in fetchUser')
    return Promise.resolve({})
  }

  return new Promise((resolve, reject) => {
    firebase.firestore()
      .collection('users')
      .doc(currentUser.uid)
      .get()
      .then(doc => {
        const data = doc.data()
        Store.setUserInfo(data)
        resolve(data)
      })
      .catch(e => {
        reject(e)
      })
  })
}

export default fetchUser
