import firebase from 'firebase/app'
import ReactGA from 'react-ga'
import queryString from 'query-string'
import config from '../config'

import delistEbay from './delistEbay'
import delistEtsy from './delistEtsy'
import { EE, Events } from '../message'
import ItemFormMerchantValues from '../views/posts/merchants/helpers/ItemFormMerchantValues'

const delistItemForMerchant = (item, merchantName) => {
  if (!item) {
    return console.error('No item provided to delistItemForMerchant')
  }

  const values = new ItemFormMerchantValues(item, merchantName)

  switch (merchantName) {
    case 'ebay': {
      if (!item.id) {
        return console.error('Must have item id')
      }
      const offerID = values.getMerchantListingValue('offerID')
      if (!offerID) {
        return console.error('Must have offerID')
      }
      return delistEbay(offerID, item.id)
    }
    case 'etsy': {
      const listedID = values.getMerchantListingValue('listedID')
      if (!listedID) {
        return console.error('Must have listedID')
      }
      return delistEtsy(listedID)
    }
    case 'mercari': {
      return EE.emitEvent(Events.ItemSidebarMerchantListItem.DELIST_MERCARI)
    }
    case 'poshmark': {
      return EE.emitEvent(Events.ItemSidebarMerchantListItem.DELIST_POSHMARK)
    }
    case 'facebook':
    case 'tradesy':
    default:
      return console.error(`Unsupported merchant to delist ${merchantName} in delistItemForMerchant`)
  }
}

export default delistItemForMerchant
