// Instead of having a model class with Merchant helper methods,
// just put everything in here
import config from './config'

const Merchant = {

  enabledMerchants: () => {
    return Object.keys(config.merchantsAvailable).filter(key => config.merchantsAvailable[key])
  },

  listedMerchantsForItem: (item) => {
    if (
      !item ||
      !item.merchants
    ) {
      return []
    }

    let listed = []
    for (let [merchantName, merchantValues] of Object.entries(item.merchants)) {
      if (merchantValues && merchantValues.listed) {
        listed.push(merchantName)
      }
    }
    return listed
  },

  merchantNameFormatted: (merchant, longName = false) => {
    switch (merchant) {
      case 'listingjoy':
        return 'ListingJoy'
      case 'ebay':
        return 'eBay'
      case 'etsy':
        return 'Etsy'
      case 'facebook':
        return longName ? 'FB Marketplace' : 'Facebook'
      case 'mercari':
        return 'Mercari'
      case 'poshmark':
        return 'Poshmark'
      case 'tradesy':
        return 'Tradesy'
      default:
        console.error('Unhandled merchant name in merchantNameFormatted: ', merchant)
        return ''
    }
  },

  validListingURLExample: (merchant) => {
    switch (merchant) {
      case 'ebay':
        return 'https://www.ebay.com/itm/name/number'
      case 'etsy':
        return 'https://www.etsy.com/listing/number/name'
      case 'facebook':
        return 'https://www.facebook.com/marketplace/item/number/'
      case 'mercari':
        return 'https://www.mercari.com/us/item/m-number'
      case 'poshmark':
        return 'https://poshmark.com/listing/name-number'
      case 'tradesy':
        return 'https://www.tradesy.com/i/name/number/'
      default:
        console.error('Unhandled merchant name in validListingURLExample: ', merchant)
        return ''
    }
  },

  regexMatchForMerchant: (merchant, url) => {
    switch (merchant) {
      case 'ebay':
        return /^https?:\/\/[^.]*\.?ebay\.com.+?itm.*\/(\d{8,})$/.exec(url)
      case 'etsy':
        return /^https:\/\/[^.]*\.?etsy\.com.+?listing\/(\d*)\//.exec(url)
      case 'facebook':
        return /^https:\/\/www\.facebook\.com\/marketplace\/item\/(\d+)/.exec(url)
      case 'mercari':
        return /^https:\/\/[^.]*\.?mercari\.com\/[^\/]*\/item\/m(\d*)/.exec(url)
      case 'poshmark':
        return /^https:\/\/[^.]*\.?poshmark\.com\/listing\/.*-([a-zA-Z0-9]*)/.exec(url)
      case 'tradesy':
        return /^https:\/\/[^.]*\.?tradesy\.com\/i\/[^\/]*\/([0-9]*)/.exec(url)
      default:
        console.error('Unhandled merchant name in regexMatchForMerchant: ', merchant)
        return null
    }    
  },

  listingDataFromMerchantURL: (merchant, url) => {
    let match = Merchant.regexMatchForMerchant(merchant, url)
    if (!match || match.length < 2) {
      return {}
    }
    const id = match[1]
    return {
      id
    }
  }
}

export default Merchant