import React from 'react'

import InputAdornment from '../../base/InputAdornment'
import TextFieldWithSavedNote from './TextFieldWithSavedNote'


class ItemFormInputPrice extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      price: props.defaultPrice,
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange = function(e) {
    this.setState({ price: +e.target.value })
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  render() { 
    const { 
      name, 
      defaultPrice, 
      required,
      loading,
      minPrice = 0, // inclusive
      maxPrice = 9999, // inclusive
    } = this.props
    const { price } = this.state

    return (
      <TextFieldWithSavedNote 
        name={name || 'price'}
        defaultValue={defaultPrice}
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          type: 'number',
          min: minPrice,
          max: maxPrice,
        }}
        onChange={this.onChange}
        loading={loading}
        error={
          (required && !price) ||
          (price < minPrice) ||
          (price > maxPrice)
        }
        required={required}
      />
    )
  }
}

export default ItemFormInputPrice
