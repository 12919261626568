import React, { Fragment } from 'react'

import { 
  FormControl,
  MenuItem,
} from '@material-ui/core';
import InputLabel from '../../../../base/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../ItemFormStyles'
import SelectWithSavedNote from '../../SelectWithSavedNote'

const isFixed = (format) => {
  return format === 'FixedPriceItem'
}

class ItemFormEbayDuration extends React.Component {

  constructor(props) {
    super(props)

    // TODO handle passed in value
    const defaultDuration = isFixed(props.format) ? fixedOptions[0].value : auctionOptions[0].value

    this.state = {
      value: defaultDuration,
    }
  }

  onChange = function(e) {
    this.setState({ value: e.target.value })
  }

  render() {   
    /* format: enum(FixedPriceItem, Chinese) */
    const { classes, format = 'fixed' } = this.props; 
    const fixed = isFixed(format)
    const options = fixed ? fixedOptions : auctionOptions

    // If the value is not a valid option, default to empty
    const value = options.map(o => o.value).indexOf(this.state.value) > -1 ? this.state.value : ''
    return (
      <Fragment>
        <InputLabel>Duration</InputLabel>
        <FormControl 
          className={classes.valueControl}
          fullWidth
        >
          <SelectWithSavedNote
            name="duration"
            value={value}
            onChange={this.onChange.bind(this)}
            required={true}
            style={{ marginTop: 0, marginLeft: 0 }}
            hasError={value === ''}
          >
            {
              options.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
            }
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

const fixedOptions = [
   {
      value:"GTC",
      label:"Good 'Til Cancelled"
   }
]

const auctionOptions = [
   {
      value:"Days_3",
      label:"3 Days"
   },
   {
      value:"Days_5",
      label:"5 Days"
   },
   {
      value:"Days_7",
      label:"7 Days"
   },
   {
      value:"Days_10",
      label:"10 Days"
   }
]

export default withStyles(styles)(ItemFormEbayDuration)