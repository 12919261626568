import React, { Fragment } from 'react'

import { 
  FormControl,
  MenuItem,
} from '@material-ui/core';
import InputLabel from '../../../../base/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../ItemFormStyles'
import SelectWithSavedNote from '../../SelectWithSavedNote'

class ItemFormEbayPricingFormat extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: props.defaultValue || 'FixedPriceItem',
    }
  }

  onChange = function(e) {
    this.setState({ value: e.target.value })

    if (this.props.onChange) {
      this.props.onChange(e) 
    }
  }

  render() {   
    const { classes } = this.props; 
    return (
      <Fragment>
        <InputLabel>Pricing Format</InputLabel>
        <FormControl 
          className={classes.valueControl}
          fullWidth
        >
          <SelectWithSavedNote
            name="pricingFormat"
            value={this.state.value || ''}
            onChange={this.onChange.bind(this)}
            style={{ marginTop: 0, marginLeft: 0 }}
          >
            {
              pricingFormatOptions.map(option => {
                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              })
            }
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

const pricingFormatOptions = [
   // {
   //    value:"Chinese",
   //    label:"Auction Style"
   // },
   {
      value:"FixedPriceItem",
      label:"Fixed Price"
   }
]

export default withStyles(styles)(ItemFormEbayPricingFormat)