import firebase from 'firebase/app'
import ReactGA from 'react-ga'
import config from '../config'

const connectEbay = async () => {

  ReactGA.event({
    category: 'Connections',
    action: 'Connect Ebay',
  })

  const token = await firebase.auth().currentUser.getIdToken(true)

  return new Promise(async (resolve, reject) => {
    if (!token) {
      const error = new Error('No id token for user')
      console.error(error)
      return reject(error)
    }
    try {
      const result = await fetch(`${config.cloudFunctionBaseURL}/ebay?token=${encodeURIComponent(token)}`, { 
         headers: new Headers({
           'Authorization': `Bearer ${token}`,
         }),
       })
      const json = await result.json()
      const url = json.url
      return resolve(url)
    }
    catch (e) {
      return reject(e)
    }
  })
}

export default connectEbay
