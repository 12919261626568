import Firebase from 'firebase/app'
import ReactGA from 'react-ga'
import config from '../config'

const connectEtsy = async () => {

  ReactGA.event({
    category: 'Connections',
    action: 'Connect Etsy',
  })

  const token = await Firebase.auth().currentUser.getIdToken(true)
  window.location.replace(`${config.cloudFunctionBaseURL}/etsy?token=${encodeURIComponent(token)}&redirect=${encodeURIComponent(window.location.href)}`)
}

export default connectEtsy
