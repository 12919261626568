import React from 'react'
import FirebaseAuth from '../misc/FirebaseAuth'
import { FirestoreDocument } from '../base/react-firestore'

import connectEtsy from '../../actions/connectEtsy'
import disconnectEtsy from '../../actions/disconnectEtsy'
import connectEbay from '../../actions/connectEbay'
import disconnectEbay from '../../actions/disconnectEbay'

import MerchantConnectionItemAPI from './MerchantConnectionItemAPI'
import MerchantConnectionItemChrome from './MerchantConnectionItemChrome'

import ClipLoader from 'react-spinners/ClipLoader'

import Config from '../../config'
import Store from '../../store'

import {
  Button,
  Grid,
} from '@material-ui/core';

const nowTimestamp = Math.floor((Date.now())/1000)

const MerchantConnections = (props) => {

  const loadingFragment = (
    <Grid container>
      <Grid item md={12}>
        <div style={{ height: 100, width: '100%', textAlign: 'center', margin: '80px 0' }}>
          <ClipLoader />
        </div>
      </Grid>
    </Grid>
  )
  return <FirebaseAuth>
    { ({isLoading, error, auth }) => {

      if (error) {
        return 'Error fetching user'
      }
      if (isLoading) {
        return loadingFragment
      }
      if (!auth) {
        return 'Logged out.'
      }
      if (auth) {
        return (
          <FirestoreDocument
            path={`users/${auth.uid}`}
          >
            { ({error, isLoading, data}) => {

              if (error) {
                return 'Error fetching doc'
              }
              if (isLoading) {
                return loadingFragment
              }

              return (
                <div>
                  { Config.showDebugUI &&
                    <Grid container alignItems='center' justify='center'>
                      <Grid item>
                        <Button
                          onClick={() => {
                            Store.setUserDetails('facebook', {})
                            Store.setUserDetails('mercari', {})
                            Store.setUserDetails('poshmark', {})
                            window.location.reload()
                          }}
                          variant="outlined" 
                          color="primary" 
                          style={{ marginBottom: 30, }}
                        >
                          [debug] Clear local user details cache
                        </Button>
                      </Grid>
                    </Grid>
                  }
                  { Config.merchantsAvailable.poshmark &&
                    <MerchantConnectionItemChrome
                      merchantName='poshmark'
                      hideButtonsWhenConnected={props.hideButtonsWhenConnected}
                      showPoshmarkSync={props.showPoshmarkSync}
                      history={props.history}
                    />
                  }
                  { Config.merchantsAvailable.mercari &&
                    <MerchantConnectionItemChrome
                      merchantName='mercari'
                      hideButtonsWhenConnected={props.hideButtonsWhenConnected}
                    />
                  }
                  { Config.merchantsAvailable.facebook &&
                    <MerchantConnectionItemChrome
                      merchantName='facebook'
                      hideButtonsWhenConnected={props.hideButtonsWhenConnected}
                    />
                  }
                  { Config.merchantsAvailable.tradesy &&
                    <MerchantConnectionItemChrome
                      merchantName='tradesy'
                      hideButtonsWhenConnected={props.hideButtonsWhenConnected}
                    />
                  }
                  { Config.merchantsAvailable.ebay &&
                    <MerchantConnectionItemAPI
                      merchantName='ebay'
                      connectAction={connectEbay}
                      disconnectAction={disconnectEbay}
                      connected={data && data.ebayConnectionExpiration && data.ebayConnectionExpiration > nowTimestamp}
                      hideButtonsWhenConnected={props.hideButtonsWhenConnected}
                      forceLoading={props.ebayLoading}
                    />
                  }
                  { Config.merchantsAvailable.etsy &&
                    <MerchantConnectionItemAPI
                      merchantName='etsy'
                      connectAction={connectEtsy}
                      disconnectAction={disconnectEtsy}
                      connected={data && data.etsyConnected}
                      hideButtonsWhenConnected={props.hideButtonsWhenConnected}
                    />
                  }
                </div>
              )
            }}
          </FirestoreDocument>
        )
      }
      return 'User is logged out'
    }}
  </FirebaseAuth>
}

export default MerchantConnections