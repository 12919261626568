import React, { Fragment } from 'react'

import Iframe from 'react-iframe'
import $ from 'jquery'

import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import ConnectionBadgeLabel from '../base/ConnectionBadgeLabel'
import ButtonLoader from '../base/ButtonLoader'
import SuccessIndicator from '../base/SuccessIndicator'

import { withStyles } from '@material-ui/core/styles'

import MerchantIcon from '../icons/MerchantIcon'
import RefreshIcon from '@material-ui/icons/Refresh'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import ClipLoader from 'react-spinners/ClipLoader'
import popup from '../base/js/popup'

import Store from '../../store'
import Merchant from '../../merchant'

const styles = theme => ({
  root: {
    margin: theme.spacing(3, 0),
  },
  connectButton: {
    margin: theme.spacing(1),
  },
});

let popupModal = null

class MerchantConnectionItem extends React.Component {


  constructor(props) {
    super(props)

    const userDetails = Store.getUserDetails(props.merchantName) || {}

    console.log('Get user details from for merchant: ', props.merchantName, userDetails)

    this.state = {
      username: userDetails.username,
      extensionVersion: undefined,

      loadingInternal: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Finished force loading (for ebay connection)
    if (
      prevProps.forceLoading &&
      !this.props.forceLoading &&
      this.state.loadingInternal
    ) {
      this.setState({
        loadingInternal: false
      })
    }

    if (
      popupModal &&
      this.props.connected
    ) {
      popupModal.close()
      popupModal = null
    }
  }

  render() {
    const { merchantName, connected, disconnectAction, connectAction, classes, hideButtonsWhenConnected, forceLoading } = this.props
    const merchantNameFormatted = Merchant.merchantNameFormatted(merchantName)

    const { loadingInternal, username } = this.state
    const loading = loadingInternal || forceLoading

    if (merchantName === 'ebay') {
      console.log('eBay connection item props/state', this.props, this.state)
    }

    return (
      <Paper key={merchantName} className={classes.root}>
        <Grid
          container
          spacing={3}
          justify='space-between'
          alignItems='center'
        >
          <Grid item xs={12} sm={3}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <MerchantIcon
                  merchantName={merchantName}
                  style={{
                    height: 60,
                    width: 60,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            {
              (username || connected) ?

                <Alert severity='success' style={{ marginTop: 10, marginBottom: 20 }}>
                  <span>{username ? `Connected as: ${username}` : 'Connected'}</span>
                </Alert> :

                (
                  loading ?
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <div style={{
                        height: 40,
                      }}>
                        <ClipLoader />
                      </div>
                    </Grid>
                  </Grid> :
                  <Alert severity='warning' style={{ marginTop: 10, marginBottom: 20 }}>
                    <span>Not connected to {merchantNameFormatted}.</span>
                  </Alert>
                )

            }
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
            { connected && hideButtonsWhenConnected ?
              <Grid item><SuccessIndicator /></Grid> :
              <Fragment>
                { !this.state.username &&
                  <Grid item>
                    <Button
                      onClick={async () => {
                        this.setState({ loadingInternal: true })

                        if (merchantName === 'ebay') {
                          const url = await connectAction()
                          popupModal = popup({
                            url,
                            title: 'Connect ListingJoy to eBay',
                            w: 900,
                            h: 500,
                          })

                          let intervalToken = setInterval(() => {

                            if (!intervalToken || !popupModal || !popupModal.closed) {
                              return
                            }
                            clearInterval(intervalToken)
                            popupModal = null

                            this.setState({
                              loadingInternal: false,
                            })
                          }, 200)

                        } else {
                          connectAction()
                        }
                      }}
                      variant="outlined"
                      color="primary"
                      className={classes.connectButton}
                      disabled={loading}
                      size="small"
                      startIcon={<RefreshIcon />}
                    >
                      { !connected && loading ?
                          <Fragment>Connecting.. <ButtonLoader /></Fragment> :
                          (
                            connected ? 'Reconnect' : 'Connect'
                          )
                      }
                    </Button>
                  </Grid>
                }
                { connected &&
                  <Grid item>
                    <Button
                      onClick={disconnectAction}
                      variant="outlined"
                      className={classes.connectButton}
                      size="small"
                      startIcon={<RemoveCircleOutlineIcon />}
                    >
                      Disconnect
                    </Button>
                  </Grid>
                }
              </Fragment>
            }

            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(MerchantConnectionItem)
