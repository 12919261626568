import React from 'react'

import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import {
  Link
} from 'react-router-dom'

import { makeStyles, withStyles } from '@material-ui/core/styles'

import fetchEtsy from '../../actions/fetchEtsy'
import fetchListedItemIDs from '../../actions/fetchListedItemIDs'
import importItem from '../../actions/importItem'
import MerchantHelper from '../../merchant'
import ClipLoader from 'react-spinners/ClipLoader';

import Modal from 'react-modal'
import Iframe from 'react-iframe'

import ItemImporterMercari from './import/ItemImporterMercari'
import ItemImporterPoshmark from './import/ItemImporterPoshmark'
import BulkItemImporter from './import/BulkItemImporter'

import ListingsFetcherMercari from '../account/ListingsFetcherMercari'
import ListingsFetcherPoshmark from '../account/ListingsFetcherPoshmark'

import {
  MercariEvent,
  PoshmarkEvent,
} from '../../constants'

import Store from '../../store'
import config from '../../config'

const { merchantsAvailable, merchantImportAvailable } = config

Modal.setAppElement('#root')

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  leftTitle: {
    marginBottom: theme.spacing(1),
  },
  left: {
    paddingRight: theme.spacing(3),
  },
  button: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
const styles = theme => ({
  row: {
    cursor: 'pointer',
  },
  itemImage: {
    maxWidth: 75,
    maxHeight: 75,
    padding: 0,
    margin: 0,
  },
  modal: {
    zIndex: 1000,
    background: 'white',
  },
})

class ItemNewImport extends React.Component {

  constructor(props) {
    super(props)

    const poshmarkDetails = Store.getUserDetails('poshmark') || {}
    this.state = {
      modalIsOpen: false,
      modalMerchant: undefined,

      modalMerchantListedIDs: {
        etsy: {}, // id -> bool
        mercari: {}, // id -> bool
        poshmark: {}, // id -> bool
      },

      loadingItems: false,
      items: [],

      // Uid can replace username without ill-effect for Poshmark
      poshmarkUsername: poshmarkDetails.username || poshmarkDetails.uid,
      mercariUsername: (Store.getUserDetails('mercari') || {}).username,
      etsyConnected: (Store.getUserInfo || {}).etsyConnected,

      selectedItems: {}, // ID -> Bool

      currentlyImportingItemID: null,

      currentlyBulkImportingItemIDs: [],
      currentBulkImportProgress: 0,
    }

    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.importItem = this.importItem.bind(this)
    this.onReceiveMercariData = this.onReceiveMercariData.bind(this)
    this.onClickBulkImport = this.onClickBulkImport.bind(this)
    this.handleNewListings = this.handleNewListings.bind(this)
    this.onImportItemComplete = this.onImportItemComplete.bind(this)
    this.onImportItemFailed = this.onImportItemFailed.bind(this)
  }

  componentDidMount() {
    document.addEventListener(MercariEvent.RECEIVE.GET_LISTINGS, this.onReceiveMercariData)
  }

  componentWillUnmount() {
    document.removeEventListener(MercariEvent.RECEIVE.GET_LISTINGS, this.onReceiveMercariData)
  }

  onReceiveMercariData(event) {

    console.log('Receive closet data: ', event.detail.data)

    this.handleNewListings(event.detail.data, 'mercari')
  }

  handleNewListings(items, merchantName) {

    if (merchantName === 'poshmark' && this.state.items.length) {
      items = this.state.items.concat(items) // TODO filter out dupes if needed
    } else if (merchantName === 'mercari' && this.state.items.length) {

      const currentItemsMap = this.state.items.reduce((memo, item) => {
        memo[item.id] = true
        return memo
      }, {})
      items = this.state.items.concat(items.filter(item => !currentItemsMap[item.id]))
    }

    this.setState({
      items: items,
      loadingItems: false,
      modalMerchant: merchantName,
    })
  }

  async afterOpenModal() {

    // Prevent window scroll when modal visible
    window.document.body.style.overflow = 'hidden';

    console.log('After open modal')

    let items = []
    switch(this.state.modalMerchant) {
      case 'etsy':
        // Etsy is currently the only API-based import merchant so far
        // If we have an additional one, include this logic with that merchant
        this.setState({
          loadingItems: true,
        })
        items = await fetchEtsy()
        console.log(`Got items from ${this.state.modalMerchant}: `, items)
        break
      case 'poshmark':
      case 'ebay':
      case 'mercari':
      default:
        return
    }

    this.setState({
      items: items || [],
      loadingItems: false,
    })
  }

  // Sean handle when the merchant is not connected
  openModalGenerator(merchantName) {
    return (async () => {

      const existingIDs = Store.getImportedItemIDs(merchantName)
      const existingIDsMap = (existingIDs || []).reduce((memo, id) => { memo[id] = true; return memo }, {})

      console.log('Setting state with merchant: ', merchantName, existingIDsMap)
      this.setState(prevState => {
        return {
          modalIsOpen: true,
          modalMerchant: merchantName,

          modalMerchantListedIDs: {
            ...prevState.modalMerchantListedIDs,
            [merchantName]: existingIDsMap,
          },

          // TODO Sean check if this is right
          loadingItems: true,

        }
      })

      const listedIDs = await fetchListedItemIDs(merchantName)
      Store.addToImportedItemIDs(merchantName, listedIDs)

      const listedIDsMap = (listedIDs || []).reduce((memo, id) => { memo[id] = true; return memo }, {})

      // Deselect any selected items that have already been listed
      let selectedItems = { ...this.state.selectedItems }; // semi-colon is needed here
      (listedIDs || []).forEach(id => {
        if (selectedItems[id]) {
          selectedItems[id] = false
        }
      })

      this.setState(prevState => {
        return {
          ...prevState,
          modalMerchantListedIDs: {
            ...prevState.modalMerchantListedIDs,
            [merchantName]: listedIDsMap,
          },
          selectedItems: selectedItems,
        }
      })

    })
  }

  onSelectItemCheckboxGenerator(itemID) {
    return (() => {

      let selectedItems = this.state.selectedItems
      selectedItems[itemID] = !selectedItems[itemID]

      this.setState({
        selectedItems,
      })
    })
  }

  closeModal() {

    this.unlockScroll()
    this.setState({
      modalIsOpen: false,
      modalMerchant: undefined,
      currentlyImportingItemID: null,
      items: [],
      loadingItems: false,
      selectedItems: {},
    })
  }

  onClickBulkImport() {
    console.log('Click bulk import')

    const { selectedItems, items } = this.state

    const itemsToImport = items.filter(item => selectedItems[item.id])

    console.log('Import items: ', itemsToImport)

    if (itemsToImport.length === 0) {
      return
    }

    const itemIDs = itemsToImport.map(item => item.id)
    this.setState({
      currentlyBulkImportingItemIDs: itemIDs,
    })

    console.log('About to import items:', itemIDs)
  }

  importItem = async (item) => {
    console.log('Clicked import item!', item)

    if (item && item.platform === 'etsy') {
      const result = await importItem(item)

      this.unlockScroll()
      // Ensure the DOM transformation happens before the redirect
      setTimeout(() => {
        this.props.history.push(`/item/${result.id}`)
      }, 10)
      return
    }

    this.setState({
      currentlyImportingItemID: item.id,
    })
  }

  onImportItemComplete = (item) => {
    this.unlockScroll()
    // Ensure the scroll unlock DOM transformation happens before redirecting
    setTimeout(() => {
      this.props.history.push(`/item/${item.id}`)
    }, 10)
  }

  onImportItemFailed = (itemID, error = null) => {
    this.unlockScroll()

    this.setState({
      currentlyImportingItemID: null
    })

    if (error && error.message) {
      if (window.confirm(error.message)) {
        this.props.history.push(`/settings`)
      }
    }
  }

  unlockScroll() {
    // Undo prevent window scroll
    window.document.body.style.removeProperty('overflow')
  }

  render() {
    const { classes, history } = this.props;
    const selectedItemCount = Object.values(this.state.selectedItems).filter(i => i).length

    const bulkImportJobSize = this.state.currentlyBulkImportingItemIDs.length
    const isBulkImporting = bulkImportJobSize > 0
    const isItemImported = (id) => {
      return (
        this.state.modalMerchant &&
        this.state.modalMerchantListedIDs[this.state.modalMerchant] &&
        this.state.modalMerchantListedIDs[this.state.modalMerchant][id]
      )
    }

    return (
      <Box component="span" display="flex" m={1} xs={12} spacing={3}>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              zIndex: 2,
            },
            content: {
              height: '85vh',
              width: '80vw',
              maxWidth: 800,
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              overflowX: this.state.modalMerchant === 'mercari' ? 'hidden' : 'inherit',
            },
          }}
        >
          { this.state.modalMerchant &&

            <Grid container justify='space-between'>
              <Grid item>
                <Typography variant="h2">
                  {MerchantHelper.merchantNameFormatted(this.state.modalMerchant)} Inventory
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={this.closeModal}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          }
          {/* TODO refactor into a helper function instead of the duplication for multple merchants */}
          {
            ((this.state.modalMerchant === 'poshmark' && !this.state.poshmarkUsername) ||
             (this.state.modalMerchant === 'mercari' && !this.state.mercariUsername) ||
             (this.state.modalMerchant === 'etsy' && !this.state.etsyConnected)) &&
            <Grid container style={{ marginTop: 10, marginBottom: 20 }}>
              <Grid item sm={12} md={12} lg={12} xl={12}>
                <Alert severity="error" style={{ marginTop: 10, marginBottom: 20 }}>
                  <span>Couldn't find your connected {MerchantHelper.merchantNameFormatted(this.state.modalMerchant)} account. Go to your <Link to={'/settings'} style={{ display: 'inline' }} onClick={this.unlockScroll}>merchant connections</Link> and ensure your {MerchantHelper.merchantNameFormatted(this.state.modalMerchant)} account is connected to ListingJoy</span>
                </Alert>
              </Grid>
            </Grid>
          }
          <Grid container style={{ marginTop: 10, marginBottom: 20 }}>
            <Typography variant="body2">
              { this.state.loadingItems ?
                'Loading items..' :
                `${this.state.items.length} item${this.state.items.length === 1 ? '' : 's'} found`
              }
            </Typography>
          </Grid>
          { this.state.loadingItems &&
            <Grid container>
              <div style={{ height: 100, width: '100%', textAlign: 'center', margin: '50px 0' }}><ClipLoader /></div>
            </Grid>
          }
          { !this.state.loadingItems &&
                <Table
                  size={'medium'}
                  style={{
                    border: '1px solid rgb(203, 203, 203)',
                    borderRadius: 4,
                    flex: '1 1 0%',
                    overflowY: 'auto',
                    display: 'block'
                  }}
                >
                  <TableBody>
                  {this.state.items.map(item => (
                      <TableRow
                        hover
                        key={item.id || item.imageURL}
                        className={classes.row}
                      >
                        { this.state.modalMerchant === 'poshmark' &&
                          <TableCell padding="none">
                            <Checkbox
                              onChange={this.onSelectItemCheckboxGenerator(item.id).bind(this)}
                              value="primary"
                              disabled={isItemImported(item.id)}
                            />
                          </TableCell>
                        }
                        <TableCell padding="none">
                          { item.imageURL ?
                            <img
                              src={item.imageURL}
                              alt="product"
                              className={classes.itemImage}
                            /> : null
                          }
                        </TableCell>
                        <TableCell align="left" style={{ width: 600 }}>
                          {item.title}
                        </TableCell>
                        <TableCell align="left">
                          ${item.price}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            disabled={
                              this.state.currentlyImportingItemID === item.id ||
                              isItemImported(item.id)
                            }
                            onClick={() => { this.importItem(item) }}
                            variant="contained"
                            color="secondary"
                          >
                            {
                              isItemImported(item.id) ?
                                <span>Imported</span> :
                                (
                                  this.state.currentlyImportingItemID === item.id ?
                                  <div>Importing.. <ClipLoader size={12}/></div> :
                                  <span>Import</span>
                                )
                            }
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
          }
          <Grid container style={{ marginTop: 30, marginBottom: 20 }}>
            <Grid item>

              { selectedItemCount > 0 &&
                <Button
                  disabled={isBulkImporting}
                  onClick={this.onClickBulkImport}
                  variant="contained"
                  color="secondary"
                >
                  {
                    isBulkImporting ?
                      <div>Importing.. ({this.state.currentBulkImportProgress}/{bulkImportJobSize}) <ClipLoader size={12}/></div> :
                      `Import ${selectedItemCount} item${selectedItemCount > 1 ? 's' : ''}`
                    }
                </Button>
              }
            </Grid>
          </Grid>

          { this.state.modalMerchant === 'poshmark' && this.state.poshmarkUsername &&
            <ListingsFetcherPoshmark
              poshmarkUsername={this.state.poshmarkUsername}
              onReceiveListings={this.handleNewListings}
            />
          }
          { this.state.modalMerchant === 'poshmark' &&
            this.state.currentlyImportingItemID &&
            <ItemImporterPoshmark
              platformID={this.state.currentlyImportingItemID}
              onImportComplete={this.onImportItemComplete}
              onImportFailed={this.onImportItemFailed}
            />
          }
          {
            this.state.modalMerchant === 'poshmark' &&
            this.state.currentlyBulkImportingItemIDs.length > 0 &&
            <BulkItemImporter
              merchantName={this.state.modalMerchant}
              itemIDs={this.state.currentlyBulkImportingItemIDs}
              onProgress={(id) => {
                const { modalMerchant, currentBulkImportProgress } = this.state
                if (!modalMerchant) {
                  return
                }

                this.setState(prevState => {
                  // Update the imported state of the given ID
                  let importedIDs = prevState.modalMerchantListedIDs[modalMerchant] || {}
                  importedIDs[id] = true
                  return {
                    ...prevState,
                    currentBulkImportProgress: currentBulkImportProgress + 1,
                    modalMerchantListedIDs: {
                      ...prevState.modalMerchantListedIDs,
                      [modalMerchant]: importedIDs,
                    },
                  }
                })
              }}
              onComplete={() => {
                const jobSize = this.state.currentlyBulkImportingItemIDs.length

                this.setState({
                  currentlyBulkImportingItemIDs: [],
                  currentBulkImportProgress: 0,
                  selectedItems: {},
                }, () => {

                  // Let DOM update with recent state change first
                  setTimeout(() => {
                    alert(`Finished importing ${jobSize} items!`)
                  }, 10)
                })
              }}
              onFailure={(error) => {
                // Hard failure (logged out, etc)
                this.setState({
                  currentlyBulkImportingItemIDs: [],
                  currentBulkImportProgress: 0,
                  selectedItems: {},
                }, () => {

                  // Let DOM update with recent state change first
                  setTimeout(() => {
                    if (window.confirm(`Failed importing items. Go to Merchant Connections and refresh your ${this.state.modalMerchant} connection`)) {
                      this.props.history.push(`/settings`)
                    }
                  }, 10)
                })
              }}
            />
          }

          {/*The size of the iframe determines whether the image div appears. the height determines the number of items */}
          { this.state.modalMerchant === 'mercari' &&
            <ListingsFetcherMercari
              onReceiveListings={this.handleNewListings}
            />
          }
          { this.state.modalMerchant === 'mercari' && this.state.currentlyImportingItemID &&
            <ItemImporterMercari
              platformID={this.state.currentlyImportingItemID}
              onImportComplete={this.onImportItemComplete}
            />
          }

        </Modal>
        <ImportListingRow
          onClickPoshmark={this.openModalGenerator('poshmark').bind(this)}
          onClickEtsy={this.openModalGenerator('etsy').bind(this)}
          onClickMercari={this.openModalGenerator('mercari').bind(this)}
          onClickEbay={this.openModalGenerator('ebay').bind(this)}
        />
      </Box>
    )
  }
}

function ImportListingRow(props) {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={3} className={classes.left}>
          <Typography variant="h3" className={classes.leftTitle}>
            Import Listings
          </Typography>
          <Typography component="p" variant="body2">
            You can import an existing listing into ListingJoy
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>

          { merchantsAvailable.ebay && merchantImportAvailable.ebay &&
            <Button onClick={props.onClickEbay} className={classes.button} variant="outlined" color="primary">
              From eBay
            </Button>
          }
          { merchantsAvailable.etsy && merchantImportAvailable.etsy &&
            <Button onClick={props.onClickEtsy} className={classes.button} variant="outlined" color="primary">
              From Etsy
            </Button>
          }
          { merchantsAvailable.poshmark && merchantImportAvailable.poshmark &&
            <Button onClick={props.onClickPoshmark} className={classes.button} variant="outlined" color="primary">
              From Poshmark
            </Button>
          }
          { merchantsAvailable.mercari && merchantImportAvailable.mercari &&
            <Button onClick={props.onClickMercari} className={classes.button} variant="outlined" color="primary">
              From Mercari
            </Button>
          }
          { merchantsAvailable.facebook && merchantImportAvailable.facebook &&
            <Button onClick={() => {}} className={classes.button} variant="outlined" color="primary">
              From Facebook
            </Button>
          }
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withStyles(styles)(ItemNewImport)


    /*
    {
   "listing_id":738688514,
   "state":"active",
   "user_id":23738513,
   "category_id":69195261,
   "title":"Men&#39;s shoes real leather, English model, hand made",
   "description":"Men&#39;s shoes made by hand, English model, made in Italy.\nThese shoes have been handcrafted by me, with high quality leathers, cut and glued by hand; The bending of the edge is also carried out by hand without the aid of machinery. The lining is also in leather, the bottom is in leather.\nThe leather used is brushed by hand to give the\nFootwear that particular aged effect, creating a color game.",
   "creation_tsz":1573590131,
   "ending_tsz":1584040931,
   "original_creation_tsz":1573589930,
   "last_modified_tsz":1574447195,
   "price":"600.00",
   "currency_code":"USD",
   "quantity":1,
   "sku":[
      "shoes"
   ],
   "tags":[
      "brown"
   ],
   "category_path":[
      "Clothing",
      "Shoes",
      "Men"
   ],
   "category_path_ids":[
      69150353,
      68889926,
      69195261
   ],
   "materials":[
      "leather"
   ],
   "shop_section_id":null,
   "featured_rank":null,
   "state_tsz":1573589930,
   "url":"https://www.etsy.com/listing/738688514/mens-shoes-real-leather-english-model?utm_source=listingjoy&utm_medium=api&utm_campaign=api",
   "views":6,
   "num_favorers":0,
   "shipping_template_id":null,
   "processing_min":30,
   "processing_max":40,
   "who_made":"i_did",
   "is_supply":"false",
   "when_made":"made_to_order",
   "item_weight":"48",
   "item_weight_unit":"oz",
   "item_length":"12",
   "item_width":"9",
   "item_height":"6",
   "item_dimensions_unit":"in",
   "is_private":false,
   "recipient":null,
   "occasion":null,
   "style":null,
   "non_taxable":false,
   "is_customizable":false,
   "is_digital":false,
   "file_data":"",
   "should_auto_renew":true,
   "language":"en-US",
   "has_variations":false,
   "taxonomy_id":1470,
   "taxonomy_path":[
      "Shoes",
      "Men's Shoes",
      "Oxfords & Wingtips"
   ],
   "used_manufacturer":false,
   "is_vintage":false
}
*/
