import importItem from '../../../actions/importItem'
import { EE, Events } from '../../../message'
const fetchRetry = require('fetch-retry')(fetch)

let importedMap = {}
const MAX_RETRIES = 3
const FETCH_TIMEOUT_MS = 5 * 1000

class BulkItemImporterPoshmarkAPI {

  constructor(uid, callbacks = {
    onReceiveListings: () => {},
    onProgress: () => {},
    onFailure: () => {},
  }) {
    if (!uid) {
      throw new Error('Must provide uid to BulkItemImporterPoshmarkAPI constructor')
    }
    this.uid = uid
    this.existingItemsMap = {}
    this.onReceiveListings = callbacks.onReceiveListings
    this.onProgress = callbacks.onProgress
    this.onFailure = callbacks.onFailure

    this.cleanedUp = false
  }

  setExistingItemsMap(existingItemsMap) {
    this.existingItemsMap = existingItemsMap
  }

  cleanup() {
    EE.removeListener(Events.ItemList.DID_ADD_ITEMS, this.handleAddItems.bind(this))

    this.cleanedUp = true
  }

  handleAddItems(items) {
    if (this.cleanedUp) {
      return
    }

    for (let item of items) {
      // Dedupe
      if (!importedMap[item.id]) {
        importedMap[item.id] = true
        this.onProgress(item.id)
      }
    }
  }

  async start() {

    let itemData = await this.fetchAllListings()
    itemData = itemData.map(item => {
      item.platform = 'poshmark_api'
      return item
    })

    console.log('Ready to import items: ', itemData)
    this.onReceiveListings([])

    EE.addListener(Events.ItemList.DID_ADD_ITEMS, this.handleAddItems.bind(this))

    for (let item of itemData) {
      // Sean the callbacks after this promise don't happen until they have all finished
      // That's why we're using the DID_ADD_ITEMS listener to get new item updates from the top level
      // firestore listener
      importItem(item)
    }
  }

  async fetchAllListings() {

    let items = []
    const addItems = (json) => {
      if (json && json.data && json.data.length) {

        const availableItems = json.data.filter(i => i.inventory && i.inventory.status === 'available')
        const newItems = availableItems.filter(i => !this.existingItemsMap[i.id])
        items = items.concat(newItems.slice(0))

        if (availableItems.length > 0) {
          // Send all available listings to callback
          this.onReceiveListings(availableItems)
        }
      } else {
        this.onReceiveListings([])
      }
    }

    let maxID = null
    let json = await this.fetchListings()
    addItems(json)

    while (json && json.more && json.more.next_max_id) {
      maxID = json.more.next_max_id
      json = await this.fetchListings(maxID)
      addItems(json)
    }

    return items
  }

  async fetchListings(maxID = null) {

    const fetchListingsInner = (maxID, retryCount = 0) => {
      const fetchPromise = new Promise(async (resolve) => {
        try {
          const maxIDParam = maxID ? `&max_id=${maxID}` : ''
          const result = await fetchRetry(`https://poshmark.com/vm-rest/users/${this.uid}/posts?exp=all&base_exp=all${maxIDParam}`)
          const json = await result.json()
          return resolve(json)
        } catch (e) {
          console.error('Error fetching listings: ', e);
          return resolve({ data: [], more: null })
        }      
      })
      const timeoutPromise = new Promise((resolve, reject) => {

        // if (Math.random() > 0.8) {
        //   console.error('Force failure for debug..')
        //   return reject(retryCount + 1)
        // }

        setTimeout(() => {
          if (retryCount >= (MAX_RETRIES - 1)) {
            return resolve({ data: [], more: null })
          } else {
            return reject(retryCount + 1)
          }
        }, FETCH_TIMEOUT_MS, null)
      })
      return Promise.race([fetchPromise, timeoutPromise])
    }

    // hilariously ugly
    try {
      return await fetchListingsInner(maxID, 0)
    } catch (e) {
      console.log('Error fetching listings 1: ', e)
      try {
        return await fetchListingsInner(maxID, 1)
      } catch (e) {
        console.log('Error fetching listings 2: ', e)
        try {
          return await fetchListingsInner(maxID, 2)
        } catch (e) {
          console.log('Error fetching listings 3: ', e)
          return { data: [], more: null }
        }
      }
    }
  }
}

export default BulkItemImporterPoshmarkAPI