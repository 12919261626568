import React, { Fragment } from 'react'

import { 
  FormControl,
  MenuItem,
} from '@material-ui/core';
import InputLabel from '../../../base/InputLabel'
import SelectWithSavedNote from '../SelectWithSavedNote'
import { withStyles } from '@material-ui/core/styles'
import styles from '../ItemFormStyles'


class ItemFormEtsyWhatIs extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      whatIs: props.defaultState || 'product',
    }
  }

  onChange = function(e) {
    this.setState({ whatIs: e.target.value })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {   
    const { classes } = this.props; 
    return (
      <Fragment>
        <InputLabel id="form-item-what-is-label">What Is It?</InputLabel>
        <FormControl 
          className={classes.conditionControl}
          fullWidth
        >
          <SelectWithSavedNote
            labelId="form-item-what-is-label"
            id="form-item-what-is"
            name="whatIs"
            value={this.state.whatIs || ''}
            required={true}
            onChange={this.onChange.bind(this)}
          >
            <MenuItem value={'product'}>A finished product</MenuItem>
            <MenuItem value={'supply'}>A supply or tool to make things</MenuItem>
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormEtsyWhatIs)