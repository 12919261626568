import React, { Fragment } from 'react'

import ItemImporterMercari from './ItemImporterMercari'
import ItemImporterPoshmark from './ItemImporterPoshmark'

class BulkItemImporter extends React.Component {

  constructor(props) {
    super(props)

    this.remainingIDsMap = props.itemIDs.reduce((memo, id) => {
      memo[id] = true;
      return memo
    }, {})


    this.onImportComplete = this.onImportComplete.bind(this)
    this.onImportFailed = this.onImportFailed.bind(this)
    this.executeNextBatchIfNeeded = this.executeNextBatchIfNeeded.bind(this)

    this.MAX_BATCH_SIZE = 8

    this.state = {
      currentImportBatch: this.setupNextBatch(),

      // Toggle this on/off when we want a complete re-render
      // we added this value because without it, if we want to retry importing an
      // item, the Importer for that item won't re-render (and re-initialize/mount)
      reset: false, 
    }
  }

  // HACK Sort of a mess that this has a side effect
  setupNextBatch() {
    console.log('Setting up batch of size: ', this.MAX_BATCH_SIZE)
    const batch = Object.keys(this.remainingIDsMap).slice(0, this.MAX_BATCH_SIZE)

    this.currentImportBatchMap = batch.reduce((memo, id) => {
      memo[id] = true;
      return memo
    }, {})

    return batch
  }

  executeNextBatchIfNeeded() {

    const currentBatchSize = Object.keys(this.currentImportBatchMap).length
    if (currentBatchSize > 0) { 
      return
    }

    this.setState({
      currentImportBatch: this.setupNextBatch(),
      reset: true,
    }, () => {
      // Toggle reset on/off to re-render all importers
      this.setState({
        reset: false,
      })
    })
  }

  onImportFailed(platformID, error = null) {

    const { onFailure } = this.props
    if (error && error.code) {
      console.error('Hard error in bulk import: ', error)
      if (onFailure) {
        return onFailure(error)
      } else {
        return console.error('Unhandled hard error in bulk import', error)
      }
    }

    // Remove from the current import batch so we can retry
    delete this.currentImportBatchMap[platformID]

    const currentBatchSize = Object.keys(this.currentImportBatchMap).length
    console.log('Handle Failure. current batch size:', currentBatchSize, this.currentImportBatchMap)

    this.executeNextBatchIfNeeded()
  }

  onImportComplete(item) {
    const { merchantName } = this.props
    if (
      !item || 
      !item.merchants ||
      !item.merchants[merchantName] ||
      !item.merchants[merchantName].listedID
    ) {
      return console.error('Import error: no id found for imported item: ', item)
    }
    const id = item.merchants[merchantName].listedID

    if (!this.remainingIDsMap[id]) {
      return console.error('This should not happen. Received an import complete callback on an item we have already imported')
    }

    delete this.remainingIDsMap[id]
    delete this.currentImportBatchMap[id]

    const currentBatchSize = Object.keys(this.currentImportBatchMap).length
    console.log('Handle import. Current batch size:', currentBatchSize, id)

    const remainingItemCount = Object.keys(this.remainingIDsMap).length
    console.log('Remaining item count: ', remainingItemCount)

    if (this.props.onProgress) {
      this.props.onProgress(id)
    }
    if (remainingItemCount === 0) {
      console.log('Completed importing items! ', this.props.itemIDs)
      if (this.props.onComplete) {
        this.props.onComplete()
      }
      return
    }

    this.executeNextBatchIfNeeded()
  }

  itemImporter(id) {
    switch (this.props.merchantName) {
      case 'poshmark':
        return (
          <ItemImporterPoshmark
            platformID={id}
            onImportComplete={this.onImportComplete}
            onImportFailed={this.onImportFailed}
          />
        )
      case 'mercari':
        return (
          <ItemImporterMercari
            platformID={id}
            onImportComplete={this.onImportComplete}
            onImportFailed={this.onImportFailed}
          />
        )
      default:
        console.error(`Unhandled merchant name ${this.props.merchantName} in BulkItemImporter`)
        return null
    }
  }

  render() {
    return (
      <Fragment>
      {
        !this.state.reset && this.state.currentImportBatch.map(id => <div key={id}>{this.itemImporter(id)}</div>)
      }
      </Fragment>
    )
  }
}

export default BulkItemImporter