
import popup from './popup'
import config from '../../../config'

export default () => {
  return popup({
    url: config.chromeExtensionURL,
    title: 'ListingJoy Chrome Extension', 
    w: 900, 
    h: 500,
  })
}