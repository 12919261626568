import Firebase from 'firebase/app'
import ReactGA from 'react-ga'
import { prepareDocForUpdate } from './helpers/firestoreHelpers'

const updateItem = (itemId, values, fullExistingItem = null) => {

  ReactGA.event({
    category: 'Item',
    action: 'Update post',
  })

  console.log('About to update with values: ', values)

  const updatedValues = prepareDocForUpdate(values, fullExistingItem)

  return Firebase.firestore()
    .collection('items')
    .doc(itemId)
    .set(updatedValues, { merge: true })
    .then(() => updatedValues)
    .catch( error => {
      alert(`Whoops, couldn't edit the post: ${error.message}`)
    })
}

export default updateItem
