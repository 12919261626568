import React, { Fragment } from 'react'

import { 
  Grid,
  FormHelperText,
} from '@material-ui/core';
import InputLabel from '../../../../base/InputLabel'
import SaveDefaultBadge from '../../../../base/SaveDefaultBadge'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../ItemFormStyles'
import TextFieldWithSavedNote from '../../TextFieldWithSavedNote'
import store from '../../../../../store'


class ItemFormEbayPaypalEmail extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: props.defaultValue || '',

      //savedDefaultShipsFrom: store.getDefault('shipsFrom'),
    }
  }

  onChange = function(e) {
    this.setState({ value: e.target.value })

    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {
    return (
      <Fragment>
        <Grid container>
          <InputLabel>PayPal Email</InputLabel>
{/*          { !shipsFromHasError &&
            <SaveDefaultBadge 
              style={{ marginLeft: 25 }}
              name='shipsFrom'
              value={this.state.shipsFrom}
              isDefault={+this.state.savedDefaultShipsFrom === +this.state.shipsFrom}
              onSaved={savedDefaultShipsFrom => this.setState({ savedDefaultShipsFrom })}
            />
          }*/}
        </Grid>
        <TextFieldWithSavedNote
          type="text" 
          name="paypalEmail"
          fullWidth
          defaultValue={this.state.value} 
          onChange={this.onChange.bind(this)}
          variant="filled"
          error={/* TODO email validation */ false}
          aria-describedby="form-ebay-paypal-email"
        />         
        <FormHelperText id="form-ebay-paypal-email">
        {/* todo put error message here */}
        </FormHelperText>
      </Fragment>
    )
  } 

}

export default withStyles(styles)(ItemFormEbayPaypalEmail)