import React from 'react'
import { FirestoreDocument } from '../base/react-firestore'
import Error from '../misc/Error'
import deleteItem from '../../actions/deleteItem'
import updateItem from '../../actions/updateItem'
import ItemForms from './ItemForms'
import { ListingJoyExternalEvent } from '../../constants'
import config from '../../config'
import {
  Page,
} from '../../styles/layout'

import { 
  Grid,
} from '@material-ui/core'

import ChromeExtensionAlert from '../base/ChromeExtensionAlert'

import ItemSidebarMerchantList from './ItemSidebarMerchantList'
import _ from 'lodash'

class ItemEdit extends React.Component {

  constructor(props) {
    super(props)
    this.renderItem = this.renderItem.bind(this)

    this.state = {
      item: null
    }

    this.onSubmitItemUpdateGenerator = this.onSubmitItemUpdateGenerator.bind(this)
  }

  onSubmitItemUpdateGenerator(data, currentItem) {
    return (values) => {
      if (!data || !data.id) {
        console.error('No data found in onSubmitItemUpdateGenerator', data, currentItem)
        return currentItem
      }
      return updateItem(data.id, values, data).then(updatedValues => {
        const updatedItem = _.merge(currentItem, updatedValues)
        this.setState({
          item: updatedItem,
        })
        return updatedItem
      })
    }
  }

  renderItem({ isLoading, data, error }) {
    const { match, history } = this.props

    if (error) {
      return <Error error={error} />
    }

    const currentItem = data || this.state.item    
    return (
      <div>
        <Grid container spacing={3}>
          <ItemSidebarMerchantList 
            title="Edit Item" 
            item={currentItem}
            match={match}
            loading={isLoading}
            onChangeListingState={this.onSubmitItemUpdateGenerator(data, currentItem)}
          />
          <Grid item xs={12} sm={12} md={9}>
            <div>
              <ChromeExtensionAlert style={{ marginBottom: 30 }}/>
              <ItemForms
                item={currentItem}
                history={history}
                match={match}
                onSubmit={this.onSubmitItemUpdateGenerator(data, currentItem)}
                onDelete={() => deleteItem(data).then( () => history.push(`/`))}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    )    
  }

  render() {
    const { match } = this.props
    return (
      <Page>
        <FirestoreDocument
          path={`items/${match.params.id}`}
          render={this.renderItem}
        />
      </Page>
    )
  }
}

export default ItemEdit
