import React, { Fragment } from 'react'

import {
  Button,
  Grid,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import InputLabel from '../../base/InputLabel'
import SelectWithSavedNote from './SelectWithSavedNote'
import TextFieldWithSavedNote from './TextFieldWithSavedNote'
import { withStyles } from '@material-ui/core/styles'
import styles from './ItemFormStyles'
import Item from '../../../item'
import ItemFormInputAspectSection from './ItemFormInputAspectSection'

class ItemFormInputAspects extends React.Component {

  constructor(props) {
    super(props)

    const defaultValues = Item.itemAspects(props.item, props.merchantName, props.defaultValues)
    console.log(`default values for ${props.merchantName} `, defaultValues, props.defaultValues)

    this.state = {
      valueSetID: null, // Used when there are multiple size/value sets

      hideOptionalFields: props.merchantName === 'etsy' || props.merchantName === 'ebay',

      // Currently used only for Mercari (nested child categories)
      // We're assuming we can only have one visible child at once which may not be correct
      child: (() => {
        let initialChild = null
        Object.values(defaultValues).forEach(option => {
          if (option && option.id && props.childAspectForOption && props.childAspectForOption[option.id]) {
            initialChild = props.childAspectForOption
          }
        })
        return initialChild
      })(),

      defaultValues,

      values: {}, // input id => option id

      reset: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (
      // The props defaultValues are the autofilled values passed from the parent
      prevProps.defaultValues &&
      Object.keys(prevProps.defaultValues).length === 0 &&

      this.props.defaultValues &&
      Object.keys(this.props.defaultValues).length > 0
    ) {

      // We have default autofill values for the first time
      const defaultValues = Item.itemAspects(
        this.props.item,
        this.props.merchantName,
        this.props.defaultValues
      )

      if (Object.keys(defaultValues) > Object.keys(this.state.defaultValues)) {
        // SEAN TODO investigate do we also have to update the initialChild property??
        this.setState({
          defaultValues,
          reset: true,
        }, () => {
          this.setState({
            reset: false,
          })
        })
      }
    }
  }

  /**
   * Types of aspects:
   * 1) 1 value set, SelectionOnly (most common)
   * 2) >1 value sets, SelectionOnly (eg poshmark dresses: Maternity, Petite, Standard)
   * 3) 1 value set, FreeText (eg. ebay select from options, with ability to create own option)
   * 4) 0 value sets, FreeText (e.g. ebay MPN. manufacturer part number. just a text field)
   * 5) >1 values sets (value sets have no options), FreeText
   *    (e.g. etsy perfume, categoryID 2160. scale represents volume and there is an associated number value)
   *    (a value set with any options is a "unit")
   *
   * Also:
   * - parent -> child (mercari dress style types. Child only appears if parent is selected)
   */
  // renderInputForAspect(aspect, isChild = false) {
  //   const { merchantName, classes } = this.props

  //   if (!aspect.valueSets) {
  //     return null
  //   }
  //   const { defaultValues, reset } = this.state
  //   if (reset) {
  //     return null
  //   }

  //   const valueSets = Object.values(aspect.valueSets)
  //   // Value is full JSON of option (for poshmark)
  //   const defaultValue = defaultValues[aspect.id] ? JSON.stringify(defaultValues[aspect.id]) : ''
  //   const isRequired = aspect.constraint.required
  //   const isFreeText = aspect.constraint.selectionMode === 'FREE_TEXT'


  //   // #1
  //   if (valueSets.length === 1 && !isFreeText) {

  //   }

  //   // #2
  //   if (valueSets.length > 1 && !isFreeText) {
  //     // Refactor things so we can use the ItemFormInputAspectSelect component
  //   }

  //   // #3
  //   if (valueSets.length === 1 && isFreeText) {
  //     // ItemFormInputAspectSelectFreeText
  //   }

  //   // #4
  //   if (valueSets.length === 0 && isFreeText) {
  //     // ebay MPN
  //     return null
  //   }

  //   // #5
  //   if (valueSets.length > 1 && isFreeText) {
  //     // Etsy perfume
  //     return null
  //   }

  //   // SEAN multiple scales
  //   //
  //   //
  //   //
  //   // For multiple valueSets, our default scale value is the selected size_set_tag (poshmark only)
  //   // TODO handle non poshmark
  //   // TODO handle multiple inputs. Currently it handles only one additional field
  //   // valueSetID should be changed to a map of { categorySpecificID: valueSetID }
  //   let defaultScale = this.state.valueSetID || ''
  //   if (
  //     merchantName === 'poshmark' &&
  //     defaultValues[aspect.id] &&
  //     defaultValues[aspect.id].size_set_tags &&
  //     defaultValues[aspect.id].size_set_tags.length
  //   ) {
  //     defaultScale = defaultValues[aspect.id].size_set_tags[0]
  //   }

  //   // This handles valueSets with multiple options (targeted given etsy system)
  //   // Unlike poshmark, we don't save the scale ID on the backend yet explicitly, so we need to find the corresponding
  //   // option within the scale schema
  //   // Example: categorySpecificsEtsy/443 inseam in/cm + all options
  //   if (defaultValues[aspect.id]) {

  //     const scale = valueSets.find(scale => {
  //       const optionValue = defaultValues[aspect.id]
  //       return optionValue && optionValue.id && scale.options && Object.values(scale.options).find(option => option.id === optionValue.id) !== undefined
  //     })
  //     if (scale && scale.id) {
  //       defaultScale = scale.id
  //     }
  //   }

  //   // This handles when there is a select scale without any options
  //   // For Etsy, sometimes valueSets exist without options, so we just set the scale's value to the value
  //   // Example: categorySpecificsEtsy/299 Volume valueSets
  //   if (
  //     defaultValues[aspect.id] &&
  //     valueSets.find(scale => scale.id === defaultValues[aspect.id]) !== undefined
  //   ) {
  //     defaultScale = defaultValues[aspect.id]
  //   }

  //   const options = defaultScale && defaultScale !== '' ? Object.values((valueSets.find(scale => scale.id === defaultScale) || {}).options || {}) : []

  //   console.log('Sean scale + options', defaultScale, options)

  //   return (
  //     <Fragment>
  //       <FormControl
  //         className={classes.conditionControl}
  //         fullWidth
  //       >
  //         <SelectWithSavedNote
  //           name={`${merchantName}CategorySpecificsScale[${aspect.id}]`}
  //           defaultValue={defaultScale}
  //           required={isRequired}
  //           fullWidth
  //           onChange={(e) => {
  //             console.log('Set option with multi valueSets!', e.target.value)
  //             this.setState({ valueSetID: e.target.value })
  //           }}
  //         >
  //           {
  //             valueSets.map(scale => {
  //               return (
  //                 <MenuItem
  //                   key={scale.id}
  //                   value={scale.id}
  //                 >{scale.display}</MenuItem>
  //               )
  //             })
  //           }
  //         </SelectWithSavedNote>
  //       </FormControl>
  //       { options.length > 0 &&
  //         <FormControl
  //           className={classes.conditionControl}
  //           fullWidth
  //         >
  //           <SelectWithSavedNote
  //             name={`${merchantName}CategorySpecifics[${aspect.id}]`}
  //             required={isRequired}
  //             defaultValue={defaultValue}
  //             fullWidth
  //             onChange={(e) => {
  //               console.log('Set option!', e.target.value)
  //             }}
  //           >
  //             {
  //               options.map(option => {
  //                 return <MenuItem key={option.id} value={AspectHelpers.normalizeValueForMerchant(merchantName, option, defaultScale, defaultValue)}>{option.display}</MenuItem>
  //               })
  //             }
  //           </SelectWithSavedNote>
  //         </FormControl>
  //       }
  //       {
  //         isFreeText &&
  //         <TextFieldWithSavedNote
  //           defaultValue='0'
  //           fullWidth
  //         />
  //       }
  //     </Fragment>
  //   )
  //   return null
  // }

  render() {
    const { aspectsData = {}, classes, merchantName } = this.props
    const { hideOptionalFields, defaultValues = {} } = this.state

    // Sort so that required inputs appear first (optional ones are last)
    const sortedAspects = Object.values(aspectsData.aspects || {}).sort((inputA, inputB) => {
      const requiredA = inputA.constraint.required
      const requiredB = inputB.constraint.required

      const sortedByNameResult = (() => {
        if (inputA.name < inputB.name) {
          return -1
        }
        if (inputA.name > inputB.name) {
          return 1
        }
        return 0
      })()

      if (requiredA && requiredB) {
        return sortedByNameResult
      }
      if (!requiredA && !requiredB) {
        // Recommended come before optional
        const recommendedA = inputA.constraint.usage === 'RECOMMENDED'
        const recommendedB = inputB.constraint.usage === 'RECOMMENDED'

        if (recommendedA === recommendedB) {
          // Alphabetical by name
          return sortedByNameResult
        }
        if (recommendedA && !recommendedB) {
          return -1
        }
        return 1
      }
      if (requiredA && !requiredB) {
        return -1
      }
      return 1
    })

    const aspectFragments = (
      <Grid container spacing={2} style={{ marginTop: 20 }}>
      {
        sortedAspects
          .map(aspect => {
            const visible = (
              !hideOptionalFields ||
              aspect.constraint.required ||
              defaultValues[aspect.id] // if we have an existing value for it
            )
            return (
              <ItemFormInputAspectSection
                key={aspect.id}
                aspect={aspect}
                classes={classes}
                merchantName={merchantName}
                hidden={!visible}
                defaultValue={defaultValues[aspect.id]}
              />
            )
          })
      }
      </Grid>
    )

    if (hideOptionalFields) {
      return (
        <Fragment>
          { aspectFragments }
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Button
              onClick={() => { this.setState({ hideOptionalFields: false })}}
              variant="outlined"
              style={{
                margin: 10,
              }}
            >
              Show Optional Fields
            </Button>
          </Grid>
        </Fragment>
      )
    }
    return aspectFragments
  }
}

export default withStyles(styles)(ItemFormInputAspects)
