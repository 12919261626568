import React, { Fragment } from 'react'

import Iframe from 'react-iframe'

import {
  MercariEvent,
} from '../../constants'

import store from '../../store'

const DEBUG = false
const DEBUG_FORCE_TAB_BASED_LISTING = false

class ConnectionVerifierMercari extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }

    this.onReceiveLoginData = this.onReceiveLoginData.bind(this)
  }

  componentDidMount() {
    document.addEventListener(MercariEvent.RECEIVE.GET_USER_INFO, this.onReceiveLoginData)
  }

  componentWillUnmount() {
    document.removeEventListener(MercariEvent.RECEIVE.GET_USER_INFO, this.onReceiveLoginData)
  }

  onReceiveLoginData(e) {
    document.removeEventListener(MercariEvent.RECEIVE.GET_USER_INFO, this.onReceiveLoginData)

    let data = e.detail.data
    if (DEBUG_FORCE_TAB_BASED_LISTING) {
      // Force connection verifier always fails
      data = {}
    }

    this.setState({
      loading: DEBUG ? true : false,
    })


    store.setUserDetailsMercari(data)
    if (this.props.onReceiveLoginData) {
      this.props.onReceiveLoginData(data)
    }
  }

  render() {
    const { debug = DEBUG } = this.props
    const side = debug ? 500 : 1
    return (
      this.state.loading || debug ?
      <Fragment>
        <Iframe
          id={ debug ? null : "iframe-mercari"}
          url={`https://www.mercari.com/mypage/`}
          width={side}
          height={side}
          display="hidden"
          position="relative"
        />
        <Iframe
          id={ debug ? null : "iframe-mercari-two"}
          url={`https://www.mercari.com/sell/`}
          width={side}
          height={side}
          display="hidden"
          position="relative"
        />
      </Fragment> : null
    )
  }
}

export default ConnectionVerifierMercari
