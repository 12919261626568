
import React from 'react'

import AutorenewIcon from '@material-ui/icons/Autorenew'
import ItemFormSectionHeader from '../ItemFormSectionHeader'

const ItemFormSectionHeaderRenewal = ({ number, text }) => (
  <ItemFormSectionHeader
    icon={<AutorenewIcon style={{ marginBottom: -6 }}/>}
    number={String(number)}
    text={text || 'Listing & Renewal'}
  />
)

export default ItemFormSectionHeaderRenewal
