import React from 'react'

import EbayIcon from './EbayIcon'
import EtsyIcon from './EtsyIcon'
import FacebookIcon from './FacebookIcon'
import ListingJoyIcon from './ListingJoyIcon'
import MercariIcon from './MercariIcon'
import PoshmarkIcon from './PoshmarkIcon'
import TradesyIcon from './TradesyIcon'

import iconStyles from './iconStyles'

const MerchantIcon = ({ style, merchantName = ''}) => {
  switch (merchantName.toLowerCase()) {
    case 'ebay':
      return <EbayIcon style={style} />
    case 'etsy':
      return <EtsyIcon style={style} />
    case 'mercari':
      return <MercariIcon style={style} />
    case 'poshmark':
      return <PoshmarkIcon style={style} />
    case 'facebook':
      return <FacebookIcon style={style} />
    case 'tradesy':
      return <TradesyIcon style={style} />
    case 'listingjoy':
      return <ListingJoyIcon style={style} />
      // return (
      //   <div style={{
      //     ...style,
      //   }}>
      //     <img
      //       src='/img/logo.png'
      //       style={{
      //         ...iconStyles,
      //       }}
      //     />
      //   </div>
      // )
    default:
      console.error('Unhandled merchantName for MerchantIcon', merchantName)
      return null;
  }
}

export default MerchantIcon
