import React from 'react'

import { 
  Grid,
  Typography,
} from '@material-ui/core';

import Store from '../../store'
import MerchantHelpers from '../../merchant'
import ButtonLoader from '../base/ButtonLoader'
import MerchantIcon from '../icons/MerchantIcon'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'

import { withStyles } from '@material-ui/core/styles'

import {
  Link
} from 'react-router-dom'

const styles = theme => ({
  imageContainer: {
    position: 'relative',
  },
  image: {
    height: 44,
    width: 44,
  },
  merchantIconContainer: {
    position: 'absolute',
    bottom: -5,
    right: 0,
  },
})

class ListerItem extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      listingProgress: 0.01,
    }
  }

  render() {
    const { merchantName, classes, item, success, error } = this.props
    const merchantNameFormatted = MerchantHelpers.merchantNameFormatted(merchantName)

    console.log('Rendering lister item with props: ', this.props)

    return (
      <Link
        to={`/item/${item.id}/${merchantName}`}
        key={item.id}
      >
        <Grid container justify='space-between' alignItems='center'>
          <Grid item md={2}>
            <div className={classes.imageContainer}>
              { item.imageURLs && item.imageURLs.length > 0 &&
                <img src={item.imageURLs[0]} className={classes.image} />
              }
              <div className={classes.merchantIconContainer}>
                <MerchantIcon merchantName={merchantName} style={{ height: 16, width: 16 }} />
              </div>
            </div>
          </Grid>
          <Grid item md={8}>
            <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              <Typography variant='body1'>
                <b>Listing:</b> {item.title}
              </Typography>
            </div>
          </Grid>
          <Grid item md={2}>
              <div style={{ height: '100%', width: '100%', textAlign: 'center', }}>
              {
                success && <CheckCircleIcon />
              }
              {
                error && <ErrorIcon />
              }
              { 
                !success && !error && 
                  <ButtonLoader />
              }
              </div>
          </Grid>
        </Grid>
      </Link>
    )
  }
}

export default withStyles(styles)(ListerItem)