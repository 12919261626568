
import React from 'react'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import styles from './form/ItemFormStyles'
import BadgeLabel from '../base/BadgeLabel'

import ItemHelpers from '../../item'
import MerchantHelpers from '../../merchant'

const getTitle = (item, itemIsNew, merchantName, loading) => {
  if (loading) {
    return 'Loading..'
  }
  if (itemIsNew) {
    return 'Create a New Item'
  }
  if (merchantName) {
    if (ItemHelpers.isListedForMerchant(item, merchantName)) {
      return `${MerchantHelpers.merchantNameFormatted(merchantName)} Listing`
    } else {
      return `List on ${MerchantHelpers.merchantNameFormatted(merchantName)}`
    }
  }
  return 'Item Details'
}

const ItemFormHeader = ({ loading, merchantName, item, classes, itemIsNew }) => (
  <Grid
    container
    direction="row"
    justify="space-between"
  >
    <Grid item>
      <Typography variant="h1" className={classes.title}>
        {
          getTitle(item, itemIsNew, merchantName, loading)
        }
      </Typography>
    </Grid>
    <Grid item>
      { loading &&
        <BadgeLabel loading={loading} text={loading ? 'Loading..' : ItemHelpers.listingStateForMerchant(item, merchantName)} />
      }
    </Grid>
  </Grid>
)

export default withStyles(styles)(ItemFormHeader)
