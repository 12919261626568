
import React, { Fragment } from 'react'
import ListingJoyIcon from './ListingJoyIcon'

const ListingJoyIconWithText = () => (
  <Fragment>
    <ListingJoyIcon
      style={{ height: 30, marginRight: 6 }}
      innerStyle={{ marginBottom: -6, height: 32 }}
    />
    <span className='listingjoy-logo'>ListingJoy</span>
  </Fragment>
)

export default ListingJoyIconWithText
