
import React from 'react'
import MoonLoader from 'react-spinners/MoonLoader';

// grey
const defaultStyle = {
  color: 'rgb(101, 101, 101)',
  background: 'rgb(244, 244, 244)',
}

// blue
const infoStyle = {
  color: 'rgb(12, 54, 91)',
  background: 'rgb(232, 243, 252)',
}

// green
const successStyle = {
  color: 'rgb(30, 70, 32)',
  background: 'rgb(237, 247, 237)',
}

// deep green
const soldStyle = {
  color: 'rgb(245, 245, 245)',
  background: 'rgb(51, 132, 43)',
}

const BadgeLabel = ({ 
  loading, 
  text = '', 
  style, 
  textStyle, 

  // style types
  info = false,
  success = false,
  sold = false,

  onClick = () => {},
}) => (
  <div style={{
    display: 'inline-block',
    fontSize: 10,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontWeight: 'bold',
    padding: 5,
    borderRadius: 3,
    ...defaultStyle,
    ...(info && infoStyle || {}),
    ...(success && successStyle || {}),
    ...(sold && soldStyle || {}),
    ...(style || {})
  }}>
    {typeof text === 'string' ? text.replace('_', ' ') : text}
    { loading && 
      <div 
        style={{
          display: 'inline-block',
          marginLeft: 5,
          ...(textStyle || {})
        }}
        onClick={onClick}
      >
        <MoonLoader size={12}/>
      </div> 
    }
  </div>
)

export default BadgeLabel
