import React, { Fragment } from 'react'

import {
  FormHelperText,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import InputLabel from '../../../base/InputLabel'
import ItemFormInputPrice from '../ItemFormInputPrice'
import TextFieldWithSavedNote from '../TextFieldWithSavedNote'

import { withStyles } from '@material-ui/core/styles'
import styles from '../ItemFormStyles'
import ItemFormInputSku from '../ItemFormInputSku'

const ItemFormPoshmarkAdditionalDetails = props => {
  const { defaultSku, defaultCostPrice, defaultOtherInfo, classes } = props
  return (
    <Fragment>
      <Grid container direction="row" spacing={5}>
        <Grid item sm={6} md={6}>
          <ItemFormInputSku
            optional={true}
            defaultValue={defaultSku}
          />
        </Grid>
        <Grid item sm={6} md={6}>
          <InputLabel optional>Cost Price</InputLabel>
          <TextFieldWithSavedNote
            name="costPrice"
            defaultValue={defaultCostPrice}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              type: 'number',
              min: 0,
            }}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={5} style={{ marginTop: 20 }} >
        <Grid item sm={12} md={12}>
          <InputLabel optional>Other Info</InputLabel>
          <TextFieldWithSavedNote
            multiline={true}
            fullWidth
            name="otherInfo"
            defaultValue={defaultOtherInfo}
            variant="filled"
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default withStyles(styles)(ItemFormPoshmarkAdditionalDetails)
