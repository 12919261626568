import React from 'react'
import {
  FormControl,
  MenuItem,
  Select,
  withStyles,
  InputLabel
} from '@material-ui/core'

const SelectBar = props => {
  const { classes, menu, label, defaultValue } = props
  return (
    <FormControl variant='outlined' className={classes.formControl}>
      <InputLabel id='demo-simple-select-outlined-label'>{label}</InputLabel>
      <Select
        name='sort'
        labelId='demo-simple-select-filled-label'
        id='demo-simple-select-filled'
        className={classes.view}
        defaultValue={defaultValue || menu[0].value}
        onChange={props.onChange}
      >
        {menu.map((item, index) => {
          return (
            <MenuItem
              className={classes.list}
              key={index}
              name={item.name}
              value={item.value}
            >
                <span className={classes.options}>{item.name}</span>
                <span className={classes.optionIcon}>{item.icon}</span>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default withStyles(theme => ({
  formControl: {
    width: '100%',

    '& .MuiInputLabel-formControl': {
      position: 'relative',
      top: 33,
      left: 28,
      zIndex: 2,
      textAlign: 'left',
      transform: 'none',
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '20px',
      letterSpacing: '0.426667px',
      color: 'rgba(25, 25, 25, 0.497296)'
    },

    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiSelect-root': {
      padding: 0,
      height: '2.8rem',
      '&:focus': {
        background: '#ffffff'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.08)!important'
    }
  },
  optionIcon: {
    float: 'right',
    marginTop: 4,
  },
  options: {
    position: 'relative',
    fontFamily: 'Quicksand',
    left: 6,
    fontSize: 16,
    lineHeight: '44px',
    float: 'right',
    marginRight: 45,
    letterSpacing: '0.426667px',
    color: ' rgba(25, 25, 25, 0.497296)',
  },
  view: {
    background: ' #FFFFFF',
    border: 'none',
    boxSizing: 'border-box',
    boxShadow: ' 0px 2px 4px rgba(116, 151, 240, 0.237161)',
    borderRadius: 7,
    display: 'flex',
    textAlign: 'center'
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiSvgIcon-root': {
      top: 0
    }
  }
}))(SelectBar)