// Helper functions for working with Firebase Firestore

import Firebase from 'firebase/app'
import 'firebase/auth'
import emojiStrip from 'emoji-strip'
import Merchant from '../../merchant'
import { EE, Events } from '../../message'
import CategoryHelpers from '../../views/posts/merchants/helpers/categoryHelpers'

const prepareDocForCreate = doc => {

  // timestamps
  doc.createdBy = Firebase.auth().currentUser ? Firebase.auth().currentUser.uid : null
  doc.createdOn = Firebase.firestore.Timestamp.now()

  delete doc.imageFiles

  // If our title/description has emojis, strip them specifically for Mercari bc
  // it doesn't support emojis
  const emojiStripFields = ['title', 'description']
  for (let key of emojiStripFields) {
    if (doc[key] && doc[key].length > 0) {
      const strippedField = emojiStrip(doc[key])
      if (strippedField.length !== doc[key].length) {
        if (!doc.merchants) {
          doc.merchants = {}
        }
        if (!doc.merchants.mercari) {
          doc.merchants.mercari = {}
        }
        if (!doc.merchants.mercari.overrides) {
          doc.merchants.mercari.overrides = {}
        }
        doc.merchants.mercari.overrides[key] = strippedField
      }
    }
  }
  
  return doc
}

const prepareDocForUpdate = (doc, fullExistingDoc = null) => {

  // timestamps
  doc.updatedBy = Firebase.auth().currentUser ? Firebase.auth().currentUser.uid : null
  doc.updatedOn = Firebase.firestore.Timestamp.now()

  // don't save the id as part of the document
  delete doc.id
  delete doc.imageFiles

  // don't save values that start with an underscore (these are calculated by the backend)
  Object.keys(doc).forEach( key => {
    if (key.indexOf('_') === 0) {
      delete doc[key]
    }
  })

  if (fullExistingDoc && fullExistingDoc.categoryPath && !fullExistingDoc.categoryPath.length) {

    // TODO put this whole code block into CategoryHelpers
    // and use it to autofill categories even when we are not saving
    // the item. i.e. when we navigate to a merchant form
    // 
    // 
    if (doc.categoryPath && doc.categoryPath.length) {
      // Provided a main category path for the first time
    } else if (
      doc &&
      doc.merchants &&
      doc.merchants.poshmark &&
      doc.merchants.poshmark.overrides &&
      doc.merchants.poshmark.overrides.categoryPath &&
      doc.merchants.poshmark.overrides.categoryPath.length
    ) {
      // If we added a poshmark category and there is still no main category, add it
      doc.categoryPath = doc.merchants.poshmark.overrides.categoryPath.slice(0)
    } else if (
      doc &&
      doc.merchants
    ) {

      // If a category path was added for another merchant, update the main category using
      // backwards category mapping
      const enabledMerchants = Merchant.enabledMerchants().filter(m => m !== 'poshmark')
      for (let merchantName of enabledMerchants) {
        if (
          doc.merchants[merchantName] &&
          doc.merchants[merchantName].overrides &&
          doc.merchants[merchantName].overrides.categoryPath &&
          doc.merchants[merchantName].overrides.categoryPath.length
        ) {
          const merchantPath = doc.merchants[merchantName].overrides.categoryPath
          const merchantCategoryKey = merchantPath[merchantPath.length - 1]
          const poshmarkCategoryPath = CategoryHelpers.poshmarkCategoryPathForMerchantKey(merchantCategoryKey, merchantName)
          if (poshmarkCategoryPath && poshmarkCategoryPath.length) {
            // We found a mapping from the merchant key and a poshmark/main category path
            doc.categoryPath = merchantPath.slice(0)
            break
          }
        }
      }
    }

    if (doc.categoryPath && doc.categoryPath.length) {
      // We have a new category path (previously the main category path was empty).
      // Notify merchant forms so they can autofill based on the new path
      EE.emitEvent(Events.ItemForm.DID_PROVIDE_POSHMARK_CATEGORY_PATH, [doc.categoryPath])
    }
  }

  //  Make updates that we think should happen automatically to make the UX more convenient
  //  Only execute this if we receive the optional full item
  if (fullExistingDoc && (!fullExistingDoc.weight || (fullExistingDoc.weight.lb === 0 && fullExistingDoc.weight.oz === 0))) {
    // If we have no valid weight at the top level, use any merchant weight here
    // I can't think of any reason why this convenience method would hurt
    
    const merchants = Object.keys(doc.merchants || {})
    merchants.forEach(merchantName => {

      if (
        !doc.merchants ||
        !doc.merchants[merchantName] ||
        !doc.merchants[merchantName].overrides ||
        !doc.merchants[merchantName].overrides.weight ||
        (doc.merchants[merchantName].overrides.weight.lb === 0 &&
         doc.merchants[merchantName].overrides.weight.oz === 0)
      ) {
        return
      }

      doc.weight = doc.merchants[merchantName].overrides.weight
    })
  }
  if (fullExistingDoc && (!fullExistingDoc.dimensions || (fullExistingDoc.dimensions.filter(d => +d === 0).length > 0))) {
    // If we have invalid dimensions at the top level, use any valid merchant dimensions here
    // I can't think of any reason why this convenience method would hurt
    
    const merchants = Object.keys(doc.merchants || {})
    merchants.forEach(merchantName => {

      if (
        !doc.merchants ||
        !doc.merchants[merchantName] ||
        !doc.merchants[merchantName].overrides ||
        !doc.merchants[merchantName].overrides.dimensions ||
        doc.merchants[merchantName].overrides.dimensions.filter(d => +d === 0).length > 0
      ) {
        return
      }

      doc.dimensions = doc.merchants[merchantName].overrides.dimensions
    })
  }
  // Do the same as above for regular fields
  // List of keys, where if we see a new overrides value and no main value, we save it as a main value
  const otherKeys = ['title', 'description', 'brand', 'colorPrimary', 'colorSecondary', 'sku', 'price'] 
  if (doc && doc.merchants && fullExistingDoc) {
    for (let key of otherKeys) {
      if (doc[key]) {
        // Skip if we already have a value for this key
        continue
      }

      // If we are saving a new merchant override and we have no main value for this key, save it as a main value
      for (let [merchantName, merchantValues] of Object.entries(doc.merchants || {})) {
        if (merchantValues && merchantValues.overrides && merchantValues.overrides[key]) {
          doc[key] = merchantValues.overrides[key]
          // Finish loop, we found one
          break
        }
      }
    }
  }

  return doc
}

export {
  prepareDocForCreate,
  prepareDocForUpdate,
}
