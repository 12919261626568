import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

const deleteItem = item => {

  ReactGA.event({
    category: 'Item',
    action: 'Delete Item',
  })

  return Firebase.firestore()
    .collection('items')
    .doc(item.id)
    .delete()
    .catch( error => {
      alert(`Whoops, couldn't delete the item: ${error.message}`)
    })
}

export default deleteItem
