import React, { Fragment } from 'react'

import {
  Grid,
} from '@material-ui/core';
import TextFieldWithSavedNote from './TextFieldWithSavedNote'
import InputAdornment from '../../base/InputAdornment'
import InputLabel from '../../base/InputLabel'
import { EE, Events } from '../../../message'


class ItemFormInputWeight extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      lb: props.defaultWeight.lb,
      oz: props.defaultWeight.oz,
    }

    this.onChangePounds = this.onChangePounds.bind(this)
    this.onChangeOunces = this.onChangeOunces.bind(this)
    this.onReceivedUpdate = this.onReceivedUpdate.bind(this)

    this.merchantValues = this.props.merchantValues
  }

  componentDidMount() {
    EE.addListener(Events.ItemForm.DID_NAVIGATE_TO_MERCHANT_FORM, this.onReceivedUpdate)

  }

  componentWillUnmount() {
    EE.removeListener(Events.ItemForm.DID_NAVIGATE_TO_MERCHANT_FORM, this.onReceivedUpdate)
  }

  onReceivedUpdate(data) {

    if (!data || !data.weight || (!data.weight.lb && !data.weight.oz)) {
      return
    }

    const { lb, oz } = this.state
    if (lb || oz) {
      return
    }

    this._updateWithWeight(data.weight)
  }

  componentDidUpdate(prevProps, prevState) {

    // TODO investigate using this method since it is way way way cleaner
    // if (prevState.lb === 0 && prevState.oz === 0 && (this.state.lb !== 0 || this.state.oz !== 0)) {
    //     try {
    //       document.querySelector(`input[name=${this.props.merchantName}weightLbs]`).value = this.state.lb
    //       document.querySelector(`input[name=${this.props.merchantName}weightOzs]`).value = this.state.oz
    //     } catch (e) {
    //       console.error('Error updating weight: ', e)
    //     }
    // } 

    // console.log(`Weight component updated for ${this.props.merchantName}, new weight`, 
    //   this.merchantValues && this.merchantValues.get('weight', { lb: 0, oz: 0 }), 
    //   'state: ', this.state,
    //   'prev state: ', prevState,
    //   'prev props: ', prevProps)
    // Since we opted to use uncontrolled components, we have to manually detect whether we want to update
    // our state value here in response to a parent / other merchant form's change
    const { lb, oz } = this.state
    if (!+lb && !+oz && this.merchantValues) {
      const weight = this.merchantValues.get('weight', { lb: 0, oz: 0 })
      if (weight.lb !== 0 || weight.oz !== 0) {
        // Update the field if we found a better possible value
        this._updateWithWeight(weight)
      }
    }
  }

  _updateWithWeight(weight) {
    this.setState({
      lb: weight.lb,
      oz: weight.oz,
    })

    const { merchantName = '' } = this.props
    try {
      document.querySelector(`input[name=${merchantName}weightLbs]`).value = weight.lb
      document.querySelector(`input[name=${merchantName}weightOzs]`).value = weight.oz
    } catch (e) {
      console.error('Error updating weight: ', e)
    } 
  }

  onChangePounds(e) {
    this.setState({
      lb: +e.target.value,
    })

    if (this.props.onChange) {
      this.props.onChange(this.state)
    }
  }

  onChangeOunces(e) {
    this.setState({
      oz: +e.target.value,
    })
    
    if (this.props.onChange) {
      this.props.onChange(this.state)
    }
  }

  render() {   
    const { defaultWeight, loading, merchantName = '', optional = false } = this.props
    const selectOnFocus = (event) => event.target.select()
    return (
      <Fragment>
        <Grid container direction="row">
          <InputLabel optional={optional}>Package Weight</InputLabel>
        </Grid>
        <Grid container direction="row" spacing={3} style={{ marginBottom: 20 }}>
          <Grid item sm={4} md={4}>
            <TextFieldWithSavedNote 
              required={!optional}
              name={`${merchantName}weightLbs`}
              defaultValue={defaultWeight.lb} 
              InputProps={{
                endAdornment: <InputAdornment position="end">lb</InputAdornment>,
                type: 'number',
              }}
              inputProps={{
                min: 0,
                onFocus: selectOnFocus,
              }}
              onChange={this.onChangePounds}
              error={!optional && this.state.lb === 0 && this.state.oz === 0}
              loading={loading}
            />
          </Grid>
          <Grid item sm={4} md={4}>
            <TextFieldWithSavedNote 
              required={!optional}
              name={`${merchantName}weightOzs`}
              defaultValue={defaultWeight.oz}  
              InputProps={{
                endAdornment: <InputAdornment position="end">oz</InputAdornment>,
                type: 'number',
              }}
              inputProps={{
                min: 0,
                onFocus: selectOnFocus,
              }}
              onChange={this.onChangeOunces}
              error={!optional && this.state.lb === 0 && this.state.oz === 0}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default ItemFormInputWeight