import React, { Fragment } from 'react'

import { 
  FormControl,
  MenuItem,
  Grid,
} from '@material-ui/core';
import InputLabel from '../../../../base/InputLabel'

import { withStyles } from '@material-ui/core/styles'
import styles from '../../ItemFormStyles'
import SaveDefaultBadge from '../../../../base/SaveDefaultBadge'
import SelectWithSavedNote from '../../SelectWithSavedNote'
import Store from '../../../../../store'

const selectStyles = {
  marginLeft: 0,
  marginTop: 0,
}

class ItemFormEbayBusinessPolicies extends React.Component {

  constructor(props) {
    super(props)
    let {
      defaultFulfillmentPolicy,
      defaultPaymentPolicy,
      defaultReturnPolicy,
      policyOptions = {
        fulfillmentPolicies: [], 
        paymentPolicies: [], 
        returnPolicies: [] 
      },
    } = props

    this.state = {
      shippingPolicyID: defaultFulfillmentPolicy || policyOptions.fulfillmentPolicies[0] && policyOptions.fulfillmentPolicies[0].fulfillmentPolicyId || '',
      paymentPolicyID: defaultPaymentPolicy || policyOptions.paymentPolicies[0] && policyOptions.paymentPolicies[0].paymentPolicyId || '',
      returnPolicyID: defaultReturnPolicy || policyOptions.returnPolicies[0] && policyOptions.returnPolicies[0].returnPolicyId || '',

      savedDefaultShippingPolicyID: Store.getDefault('shippingPolicy'),
      savedDefaultPaymentPolicyID: Store.getDefault('paymentPolicy'),
      savedDefaultReturnPolicyID: Store.getDefault('returnPolicy'),
    }

    this.onChangeShippingPolicy = this.onChangeShippingPolicy.bind(this)
    this.onChangePaymentPolicy = this.onChangePaymentPolicy.bind(this)
    this.onChangeReturnPolicy = this.onChangeReturnPolicy.bind(this)
  }

  onChangeShippingPolicy = function(e) {
    console.log('Changing shipping policy value from ', this.state.shippingPolicy, ' to ', e.target.value)
    this.setState({ shippingPolicyID: e.target.value })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }
  onChangePaymentPolicy = function(e) {
    this.setState({ paymentPolicyID: e.target.value })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }
  onChangeReturnPolicy = function(e) {
    this.setState({ returnPolicyID: e.target.value })
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {
    const { 
      classes, 
      policyOptions = {},
    } = this.props
    const {
      shippingPolicyID,
      paymentPolicyID,
      returnPolicyID,

      savedDefaultShippingPolicyID,
      savedDefaultPaymentPolicyID,
      savedDefaultReturnPolicyID,
    } = this.state

    let {
      defaultFulfillmentPolicy,
      defaultPaymentPolicy,
      defaultReturnPolicy,
    } = this.props
    const shippingPolicies = policyOptions.fulfillmentPolicies || []
    const paymentPolicies = policyOptions.paymentPolicies || []
    const returnPolicies = policyOptions.returnPolicies || []

    // Payment policy - make sure that the provided value exists in the options
    const paymentPolicyIDValue = paymentPolicies.find(p => p.paymentPolicyId === paymentPolicyID) ? paymentPolicyID : ''

    console.log('Shipping policy asdfasfd', shippingPolicyID)

    return (
      <Fragment>
        <Grid container direction="row" style={{ marginTop: 15 }} spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container>
              <InputLabel>Shipping Policy</InputLabel>
              { shippingPolicyID !== '' &&
                <SaveDefaultBadge 
                  style={{ marginLeft: 10 }}
                  name='shippingPolicy'
                  value={shippingPolicyID}
                  isDefault={savedDefaultShippingPolicyID === shippingPolicyID}
                  onSaved={savedDefaultShippingPolicyID => this.setState({ savedDefaultShippingPolicyID })}
                />
              }
            </Grid>
            <Grid container>
              <FormControl 
                className={classes.valueControl}
                required
                fullWidth
              >
                <SelectWithSavedNote
                  name="shippingPolicy"
                  required={true}
                  value={shippingPolicyID}
                  onChange={this.onChangeShippingPolicy.bind(this)}
                  style={selectStyles}
                >
                  {
                    shippingPolicies.map(option => {
                      return <MenuItem key={option.fulfillmentPolicyId} value={option.fulfillmentPolicyId}>{option.name}</MenuItem>
                    })
                  }
                </SelectWithSavedNote>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ marginTop: 15 }} spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container>
              <InputLabel>Payment Policy</InputLabel>
              { paymentPolicyIDValue !== '' &&
                <SaveDefaultBadge 
                  style={{ marginLeft: 10 }}
                  name='paymentPolicy'
                  value={paymentPolicyIDValue}
                  isDefault={savedDefaultPaymentPolicyID === paymentPolicyIDValue}
                  onSaved={savedDefaultPaymentPolicyID => this.setState({ savedDefaultPaymentPolicyID })}
                />
              }
            </Grid>
            <Grid container>
              <FormControl 
                className={classes.valueControl}
                required
                fullWidth
              >
                <SelectWithSavedNote
                  name="paymentPolicy"
                  required={true}
                  value={paymentPolicyIDValue}
                  onChange={this.onChangePaymentPolicy.bind(this)}
                  style={selectStyles}
                >
                  {
                    paymentPolicies.map(option => {
                      return <MenuItem key={option.paymentPolicyId} value={option.paymentPolicyId}>{option.name}</MenuItem>
                    })
                  }
                </SelectWithSavedNote>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ marginTop: 15 }} spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container>
              <InputLabel>Return Policy</InputLabel>
              { returnPolicyID !== '' &&
                <SaveDefaultBadge 
                  style={{ marginLeft: 10 }}
                  name='returnPolicy'
                  value={returnPolicyID}
                  isDefault={savedDefaultReturnPolicyID === returnPolicyID}
                  onSaved={savedDefaultReturnPolicyID => this.setState({ savedDefaultReturnPolicyID })}
                />
              }
            </Grid>
            <Grid>
              <FormControl 
                className={classes.valueControl}
                required
                fullWidth
              >
                <SelectWithSavedNote
                  name="returnPolicy"
                  required={true}
                  value={this.state.returnPolicyID}
                  onChange={this.onChangeReturnPolicy.bind(this)}
                  style={selectStyles}
                >
                  {
                    returnPolicies.map(option => {
                      return <MenuItem key={option.returnPolicyId} value={option.returnPolicyId}>{option.name}</MenuItem>
                    })
                  }
                </SelectWithSavedNote>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormEbayBusinessPolicies)