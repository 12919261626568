import React from 'react'

import Iframe from 'react-iframe'

import {
  ListingJoyExternalEvent,
  MercariEvent,
} from '../../constants'

import config from '../../config'
import Store from '../../store'
import { Message, EE, Events } from '../../message'
import updateItem from '../../actions/updateItem'

class ItemListerMercari extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      iframeLoaded: false,

      hasReceivedInitializeEvent: false,
    }

    this.listItem = this.listItem.bind(this)
    this.onCompletedListing = this.onCompletedListing.bind(this)
    this.onReceiveListingData = this.onReceiveListingData.bind(this)
    this.onReceiveInitialize = this.onReceiveInitialize.bind(this)

    // Queued up item data
    // Currently support only one item, eventually handle an array
    this.queuedData = null
    this.currentItemID = null
    this.currentTimeoutToken = null

    // Autofill with this when loaded
    this.fillDataOnLoad = null
  }

  componentDidMount() {
    document.addEventListener(MercariEvent.RECEIVE.CREATE_LISTING, this.onCompletedListing)

    EE.addListener(Events.ItemListerMercari.INITIALIZE, this.onReceiveInitialize)
    EE.addListener(Events.ItemListerMercari.CREATE_LISTING, this.onReceiveListingData)
  }

  componentWillUnmount() {
    document.removeEventListener(MercariEvent.RECEIVE.CREATE_LISTING, this.onCompletedListing)

    EE.removeListener(Events.ItemListerMercari.INITIALIZE, this.onReceiveInitialize)
    EE.removeListener(Events.ItemListerMercari.CREATE_LISTING, this.onReceiveListingData)
  }

  listItem(data) {
    this.queuedData = null
    this.currentItemID = data.id

    Message.send(MercariEvent.SEND.CREATE_LISTING, data)

    this.currentTimeoutToken = setTimeout(() => {

      EE.emitEvent(Events.ItemListerMercari.DID_CREATE_LISTING, [{
        errors: [{
          message: 'Timed out listing to Mercari. Please try it again, it often works on the second/third try. We\'re working on making this more reliable',
        }]
      }, null, data.id])

      this.reset()
    }, config.listingTimeoutInSeconds * 1000)
  }

  listItemWithExternalTab(data) {
    this.currentItemID = data.id

    console.log('About to create new mercari tab to list', data)
    Message.notifyExtension(ListingJoyExternalEvent.SEND.MERCARI.CREATE_LISTING_EXTERNAL, {
      url: `https://www.mercari.com/sell/?${MercariEvent.SEND.CREATE_LISTING}=1`,
      data,
    })

    this.currentTimeoutToken = setTimeout(() => {

      EE.emitEvent(Events.ItemListerMercari.DID_CREATE_LISTING, [{
        errors: [{
          message: 'Timed out listing to Mercari. Please try it again, it often works on the second/third try. We\'re working on making this more reliable',
        }]
      }, null, data.id])

      this.reset()
    }, config.listingTimeoutInSeconds * 1000)
  }

  onReceiveInitialize(data) {
    this.fillDataOnLoad = data

    const isExternalSession = (Store.getUserDetailsMercari() || {}).isExternalSession || false
    if (isExternalSession) {
      return console.log('Mercari using external session. Do not load iframe')
    }

    if (this.state.hasReceivedInitializeEvent) {
      return
    }

    console.log('Initializing mercari lister')
    this.setState({
      hasReceivedInitializeEvent: true,
    })
  }

  onReceiveListingData(data) {
    const isExternalSession = (Store.getUserDetailsMercari() || {}).isExternalSession || false
    if (isExternalSession) {
      console.log('Mercari should list using new tab: ', data)
      return this.listItemWithExternalTab(data)
    }

    if (!this.state.iframeLoaded) {
      console.log('Mercari Item lister not yet iframeLoaded. Queue the data')
      this.queuedData = data
      return
    }

    this.listItem(data)
  }

  onCompletedListing(e) {
    const data = e.detail.data
    const itemID = (' ' + this.currentItemID).slice(1) // Copy https://stackoverflow.com/a/31733628

    console.log('Received data from create listing action: ', data)

    const success = data && (!data.errors || data.errors.length === 0) && data.createListing && data.createListing.id
    if (success) {
      updateItem(itemID, {
        merchants: {
          mercari: {
            dateCreated: new Date(),
            dateLastModified: new Date(),
            listed: true,
            listedID: data.createListing.id,
            listingURL: `https://www.mercari.com/us/item/${data.createListing.id}`,
          },
        }
      }).then(updatedValues => {
        EE.emitEvent(Events.ItemListerMercari.DID_CREATE_LISTING, [data, updatedValues, itemID])
      }).catch(error => {
        EE.emitEvent(Events.ItemListerMercari.DID_CREATE_LISTING, [{ errors: [{ message: error.message || 'Unknown error listing on Mercari' }]}, null, this.currentItemID])
      })
    } else {

      let errorData = data
      if (data.errors.length > 0) {
        const error = data.errors[0]
        if (error.message.indexOf('valid credit') > -1) {
          errorData = { errors: [{ message: <span>Mercari requires that you <a target="_blank" href="https://www.mercari.com/mypage/card/create/">add a valid credit card or debit card</a> on the Mercari app or website to create/edit listings. Mercari often requires this once you have many listings or if you are listing a high value item.</span> }]}
        }
      }
      EE.emitEvent(Events.ItemListerMercari.DID_CREATE_LISTING, [errorData, null, itemID])
    }

    if (this.props.onCompletedListing) {
      this.props.onCompletedListing(data)
    }

    this.reset()
  }

  reset() {
    this.fillDataOnLoad = null
    this.currentItemID = null
    if (this.currentTimeoutToken) {
      clearTimeout(this.currentTimeoutToken)
      this.currentTimeoutToken = null
    }

    const { hasReceivedInitializeEvent } = this.state
    if (!hasReceivedInitializeEvent) {
      // No need to reset the iframe URL. We have not loaded the iframe
      // This occurs when we have external auth
      return
    }

    // Reset the iframe url. Toggle initialized
    this.setState({
      hasReceivedInitializeEvent: false,
      iframeLoaded: false,
    }, () => {
      this.setState({
        hasReceivedInitializeEvent: true,
      })
    })
  }

 /* Need to pass data as follows:
   1) this code to background
   2) background to content script iframeLoaded in mercari
   3) content script back to background
   4) background back listingjoy content script
   5) LJ content script dispatches an event that we are listening for in this component */
  render() {
    if (!config.merchantsAvailable.mercari) {
      return null
    }
    return (
      this.state.hasReceivedInitializeEvent &&
      <Iframe
        id="iframe-list-mercari"
        url={`https://www.mercari.com/sell/?${MercariEvent.SEND.CREATE_LISTING}=1`}
        width={800}
        height={800}
        display="initial"
        position="absolute"
        onLoad={() => {
          console.log('Mercari Item lister iframeLoaded')
          this.setState({ iframeLoaded: true })
          if (this.queuedData) {
            console.log('Mercari Item lister finished loading. List with queued data')
            this.listItem(this.queuedData)
          } else if (this.fillDataOnLoad) {
            console.log('Mercari item lister finished loading with no queued data. Autofill with fill data', this.fillDataOnLoad)
            Message.send(MercariEvent.SEND.FILL_NEW_LISTING, this.fillDataOnLoad)
          }
        }}
      />
    )
  }
}

export default ItemListerMercari
