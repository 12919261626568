import React, { Fragment } from 'react'

import { Alert } from '@material-ui/lab'
import ButtonLoader from '../../base/ButtonLoader'
import ItemImporterPoshmark from './ItemImporterPoshmark'
import { Message } from '../../../message'
import {
  ListingJoyExternalEvent,
} from '../../../constants'
      
class ItemImportAlertPoshmark extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      failed: false,
    }

    this.onImportComplete = this.onImportComplete.bind(this)
    this.onImportFailed = this.onImportFailed.bind(this)
  }

  onImportComplete(item) {
    const { destinationMerchant, history } = this.props
    const merchantPath = destinationMerchant || ''

    Message.notifyExtension(ListingJoyExternalEvent.SEND.DID_UPDATE_LISTING, { 
      item,
    })

    history.push(`/item/${item.id}/${merchantPath}`)
  }

  onImportFailed() {
    this.setState({
      failed: true
    })
  }

  render() {
    const { id } = this.props
    const { failed } = this.state
    return (
      <Fragment>
        {
          failed ?
            <Alert severity='error'>
              <span>Failed syncing Poshmark item. Please try refreshing your connection in <a href="/settings">Merchant Connections</a>.</span>
            </Alert> :
            <Alert severity='info'>
              <span>Automatically syncing Poshmark Item. Please wait.. <ButtonLoader/></span>
            </Alert>
        }
        <ItemImporterPoshmark
          platformID={id}
          onImportComplete={this.onImportComplete}
          onImportFailed={this.onImportFailed}
        />
      </Fragment>
    )
  }
}

export default ItemImportAlertPoshmark