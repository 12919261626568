
import React from 'react'
import { 
  Grid,
} from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';

const FormAlert = ({ type = 'warning', title = '', body = '', style = {}}) => (
  <Grid container direction="row" style={style}>
    <Grid item sm={12} md={12} lg={12} xl={12}>
      <Alert severity={type}>
        <AlertTitle>{title}</AlertTitle>
        { body }
      </Alert>
    </Grid>
  </Grid>
)

export default FormAlert
