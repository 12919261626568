import React, { Fragment } from 'react'

import {
  LinearProgress,
  Snackbar,
  Slide,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  Link,
} from 'react-router-dom'
import BulkItemImporter from './BulkItemImporter'

import ListingsFetcherMercari from '../../account/ListingsFetcherMercari'
import ListingsFetcherPoshmark from '../../account/ListingsFetcherPoshmark'
import BulkItemImporterPoshmarkAPI from './BulkItemImporterPoshmarkAPI'

import ButtonLoader from '../../base/ButtonLoader'

import Store from '../../../store'
import Merchant from '../../../merchant'
import { EE } from '../../../message'


let dismissedAlerts = {} // timestamp -> Bool
let globalInitialized = false

const DEBUG = false

class QuickImporterAlert extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      alertDismissed: false,

      initialized: false,
      items: [],
      isFetchingListings: true,
      importedCount: 0,

      snackbarOpen: false,

      error: null,
    }
  }

  componentDidMount() {
    EE.addListener(QuickImporter.Events.DID_UPDATE, this.onReceiveUpdate.bind(this))

    EE.emitEvent(QuickImporter.Events.REQUEST_UPDATE)

    this.setState({
      snackbarOpen: true,
      snackbarTransition: Slide,
    })
  }

  componentWillUnmount() {
    EE.removeListener(QuickImporter.Events.DID_UPDATE, this.onReceiveUpdate.bind(this))
  }

  onReceiveUpdate(data) {
    this.setState({
      ...data
    })

    const { importedCount, items } = data
    if (importedCount > items.length) {
      console.error('Imported count greater than items lenght in QuickImporter')
    }
  }

  render() {
    if (this.state.alertDismissed || !this.state.initialized) {
      return null
    }
    if (dismissedAlerts[this.state.initializedTimestamp]) {
      console.log('dont show quick import alert. we have already dismissed the alert')
      return null
    }

    const { importedCount, fetchedListingsCount, items, isFetchingListings, merchantName, error } = this.state
    const merchantNameFormatted = Merchant.merchantNameFormatted(merchantName)
    const completed = items.length > 0 && importedCount >= items.length // We should never see imported count > items.length
    const spanStyle = {
      fontSize: 18,
      lineHeight: '1.5em',
    }

    const alert = (
      <Alert
        onClose={() => {
          this.setState({ alertDismissed: true })
          dismissedAlerts[this.state.initializedTimestamp] = true
        }}
        severity={error ? 'error' : (completed ? 'success' : 'info')}
        style={{
          marginTop: 20,
          marginBottom: 20,
          border: '1px solid #999',
        }}
      >
        <div style={{ paddingRight: 20 }}>
          {
            error ?
            <span style={spanStyle}>Failed syncing your {merchantNameFormatted} inventory. Refresh your {merchantNameFormatted} connection in <Link to='/settings'>Merchant Connections</Link> and try again.</span> :
            <Fragment>
              {
                isFetchingListings &&
                <span style={spanStyle}>We're automatically syncing your {merchantNameFormatted} listings. Found {items.length} new listing{items.length !== 1 ? 's' : ''} out of {fetchedListingsCount}. <ButtonLoader/></span>
              }
              {
                !isFetchingListings && !items.length &&
                <span style={spanStyle}>No new listings found. Your {merchantNameFormatted} inventory is already synced with ListingJoy!</span>
              }
              {
                !isFetchingListings && items.length && !completed &&
                <Fragment>
                  <span style={spanStyle}>We're automatically syncing your {merchantNameFormatted} new listings. Imported {importedCount} of {items.length}. <ButtonLoader/></span>
                  <LinearProgress style={{ marginTop: 30 }} variant="determinate" value={(importedCount / items.length) * 100} />
                </Fragment> || null
              }
              {
                completed &&
                <Fragment>
                  <span style={spanStyle}>Finished syncing your {merchantNameFormatted} listings! Imported {importedCount} of {items.length}. Click on any item to start crosslisting!</span>
                  <LinearProgress style={{ marginTop: 30 }} variant="determinate" value={100} />
                </Fragment>
              }
            </Fragment>
          }
        </div>
      </Alert>
    )

    if (this.props.useSnackbar) {
      return (
        <Snackbar
          open={this.state.snackbarOpen}
          TransitionComponent={this.state.snackbarTransition}
          onClose={(e, reason) => {
          if (reason === 'clickaway') {
            return
          }
          this.setState({
            snackbarOpen: false,
          })
        }}>
          {alert}
        </Snackbar>
      )
    }
    return alert
  }
}

class QuickImporter extends React.Component {

  static Events = {
    INITIALIZE: 'QuickImporter:Events:initialize',

    REQUEST_UPDATE: 'QuickImporter:Events:request_update',
    DID_UPDATE: 'QuickImporter:Events:did_update',
  }

  constructor(props) {
    super(props)

    this.state = {
      initialized: false,

      // new items to import
      items: [],

      // first fetch the available listings, then when we finish fetching, we begin importing the full listing data
      isFetchingListings: true,
      fetchedListingsCount: 0,
      importedCount: 0,

      error: null,
    }

    this.existingPoshmarkItemsMap = {} // poshmark id -> boolean

    this.emitUpdate = this.emitUpdate.bind(this)
    this.onImportedItem = this.onImportedItem.bind(this)
    this.handleNewListings = this.handleNewListings.bind(this)
  }

  componentDidMount() {
    EE.addOnceListener(QuickImporter.Events.INITIALIZE, this.initialize.bind(this))
    EE.addListener(QuickImporter.Events.REQUEST_UPDATE, this.emitUpdate)
  }

  componentWillUnmount() {
    EE.removeListener(QuickImporter.Events.INITIALIZE, this.initialize.bind(this))
    EE.removeListener(QuickImporter.Events.REQUEST_UPDATE, this.emitUpdate)
  }

  componentDidUpdate() {
    if (!this.state.initialized) {
      return
    }
    this.emitUpdate()
  }

  async initialize(existingListingJoyItems = []) {

    const poshmarkDetails = Store.getUserDetailsPoshmark() || {}
    const poshmarkUid = poshmarkDetails.uid
    this.poshmarkUsername = poshmarkDetails.username || poshmarkDetails.uid
    this.usernameMercari = (Store.getUserDetailsMercari() || {}).username

    this.merchantName = null
    if (this.poshmarkUsername) {
      this.merchantName = 'poshmark'
    } else if (this.usernameMercari) {
      this.merchantName = 'mercari'
    }

    if (existingListingJoyItems.length > 0 && this.merchantName === 'mercari') {
      // Don't handle this yet. Currently we only handle the URL-based import for poshmark
      return
    }
    if (!this.merchantName) {
      return
    }
    this.merchantNameFormatted = Merchant.merchantNameFormatted(this.merchantName)

    console.log('Merchant name for quick importer: ', this.merchantName)

    // For deduping (currently only handle poshmark)
    if (existingListingJoyItems.length > 0 && this.merchantName === 'poshmark') {
      existingListingJoyItems.forEach(item => {
        if (
          item &&
          item.merchants &&
          item.merchants[this.merchantName] &&
          item.merchants[this.merchantName].listed &&
          item.merchants[this.merchantName].listedID
        ) {
          const poshmarkID = item.merchants[this.merchantName].listedID
          this.existingPoshmarkItemsMap[poshmarkID] = true
        }
      })
    }

    const initializedTimestamp = (new Date()).getTime()
    Store.setLastQuickImportRunTimestamp(initializedTimestamp)

    this.setState({
      initialized: true,
      initializedTimestamp,
      poshmarkUid,
    }, () => {

      if (poshmarkUid) {

        if (globalInitialized) {
          return
        }
        globalInitialized = true

        // Use the Poshmark API-based fetching
        this.poshmarkImporterAPI = new BulkItemImporterPoshmarkAPI(poshmarkUid, {
          onReceiveListings: this.handleNewListings,
          onProgress: this.onImportedItem,
          onFailure: (error) => {
            console.error('Failed importing using Poshmark API: ', error)
            // If we hit a hard (non-timeout) error
            this.setState({
              error,
            })
          },
        })
        this.poshmarkImporterAPI.setExistingItemsMap(this.existingPoshmarkItemsMap)
        this.poshmarkImporterAPI.start()
      }
    })
  }

  emitUpdate() {

    const {
      importedCount,
      items,
      isFetchingListings,
      initialized,
      fetchedListingsCount,
      initializedTimestamp,
      error,
    } = this.state
    EE.emitEvent(QuickImporter.Events.DID_UPDATE, [{
      merchantName: this.merchantName,
      importedCount,
      initialized,
      items,
      isFetchingListings,
      fetchedListingsCount,
      initializedTimestamp,
      error,
    }])
  }

  onImportedItem(id) {
    //console.log('Imported item: ', id, this)
    this.setState(prevState => {
      return {
        importedCount: prevState.importedCount + 1,
      }
    }, () => {

      const { importedCount, fetchedListingsCount } = this.state

      console.log('Imported count: ', importedCount)

      if (importedCount >= fetchedListingsCount) {
        // Done importing
        console.log('DONE IMPORTING')
        if (this.poshmarkImporterAPI) {
          this.poshmarkImporterAPI.cleanup()
          this.poshmarkImporterAPI = null
        }
      }
    })

  }

  handleNewListings(fetchedItems) {

    console.log('Got new listings!', fetchedItems)

    let isFetchingListings = this.state.isFetchingListings
    // Currently the below is equivalent to "if (true)"
    if (this.merchantName === 'poshmark' || this.merchantName === 'mercari') {
      isFetchingListings = fetchedItems.length > 0
    }

    this.setState(prevState => {
      return {
        items: prevState.items.concat(fetchedItems.filter(item => !this.existingPoshmarkItemsMap[item.id])),

        fetchedListingsCount: prevState.fetchedListingsCount + fetchedItems.length,

        // Debug the fetching ability
        isFetchingListings: DEBUG ? prevState.isFetchingListings : isFetchingListings,
      }
    })
  }

  render() {
    const { importedCount, items, isFetchingListings, poshmarkUid } = this.state

    if (!this.state.initialized) {
      return null
    }
    return (
      <div style={{
        position: 'relative',
      }}>
        <div style={DEBUG ? {
          position: 'absolute',
          left: -500,
          bottom: 0,
        } : {}}>
          {
            !isFetchingListings && items.length && !poshmarkUid && /* non-api based importing */
              <BulkItemImporter
                merchantName={this.merchantName}
                itemIDs={items.map(item => item.id)}
                onProgress={this.onImportedItem}
                onFailure={(error) => {
                  // If we hit a hard (non-timeout) error
                  this.setState({
                    error,
                  })
                }}
              />
          }
          { isFetchingListings && !poshmarkUid && this.poshmarkUsername && /* non-api based fetching */
            <ListingsFetcherPoshmark
              poshmarkUsername={this.poshmarkUsername}
              onReceiveListings={this.handleNewListings}
            />
          }
          { isFetchingListings && !poshmarkUid && !this.poshmarkUsername && this.usernameMercari && /* fallback to mercari if poshmark isn't linked */
            <ListingsFetcherMercari
              onReceiveListings={this.handleNewListings}
            />
          }
        </div>
      </div>
    )
  }
}

export {
  QuickImporter,
  QuickImporterAlert,
}

