import React from 'react'

import Iframe from 'react-iframe'
import $ from 'jquery'

import {
  PoshmarkEvent,
} from '../../constants'

import config from '../../config'
import { Message, EE, Events } from '../../message'
import updateItem from '../../actions/updateItem'

class ItemDelisterPoshmark extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentItemData: null, // { id: String, listingID: String }
    }

    this.delistItem = this.delistItem.bind(this)
    this.onCompleteDelist = this.onCompleteDelist.bind(this)
    this.onReceiveShouldDelistData = this.onReceiveShouldDelistData.bind(this)
  }

  componentDidMount() {
    document.addEventListener(PoshmarkEvent.RECEIVE.DELIST_LISTING, this.onCompleteDelist)

    EE.addListener(Events.ItemDelisterPoshmark.DELIST, this.onReceiveShouldDelistData)
  }

  componentWillUnmount() {
    document.removeEventListener(PoshmarkEvent.RECEIVE.DELIST_LISTING, this.onCompleteDelist)

    EE.removeListener(Events.ItemDelisterPoshmark.DELIST, this.onReceiveShouldDelistData)
  }

  delistItem(item) {

    if (this.state.currentItemData) {
      return EE.emitEvent(Events.ItemDelisterPoshmark.DID_DELIST, [{ 
        errors: [{ 
          message: 'Currently delisting another item from Poshmark. Wait for that to finish first.' 
        }]
      }, null, item.id])
    }

    console.log('Delist item with data: ', item)
    this.setState({
      currentItemData: {
        id: item.id,
        listingID: item.merchants.poshmark.listedID,
      }
    })
    // By loading this iframe with the given parameters, the content script
    // will automatically delist the listing
  }

  onReceiveShouldDelistData(data) {
    this.delistItem(data)
  }

  onCompleteDelist(e) {
    const data = e.detail.data
    const itemID = (' ' + this.state.currentItemData.id).slice(1) // Copy https://stackoverflow.com/a/31733628

    console.log('Received data from delist action: ', data)

    const success = data && data.success
    if (success) {
      updateItem(itemID, {
        merchants: {
          poshmark: {
            listed: false,
          },
        }
      }).then(updatedValues => {
        EE.emitEvent(Events.ItemDelisterPoshmark.DID_DELIST, [data, updatedValues, itemID])
      }).catch(error => {
        EE.emitEvent(Events.ItemDelisterPoshmark.DID_DELIST, [{ errors: [{ message: error.message || 'Unknown error delisting from Poshmark' }]}, null, itemID])
      })
    } else {
      EE.emitEvent(Events.ItemDelisterPoshmark.DID_DELIST, [data, null, itemID])
    }

    if (this.props.onCompleteDelist) {
      this.props.onCompleteDelist(data)
    }

    this.setState({
      currentItemData: null
    })
  }

 /* Need to pass data as follows:
   1) this code to background
   2) background to content script iframeLoaded in poshmark
   3) content script back to background
   4) background back listingjoy content script
   5) LJ content script dispatches an event that we are listening for in this component */
  render() {
    if (!config.merchantsAvailable.poshmark) {
      return null
    }
    const { currentItemData } = this.state
    return (
      currentItemData && currentItemData.listingID &&
      <Iframe 
        id="iframe-delist-poshmark"
        url={`https://poshmark.com/edit-listing/${currentItemData.listingID}/?${PoshmarkEvent.SEND.DELIST_LISTING}=${currentItemData.listingID}`}
        width={800} 
        height={800}
        display="initial" 
        position="absolute"
      />
    )
  }
}

export default ItemDelisterPoshmark