import React from 'react'
import moment from 'moment'

import { 
  Grid,
  Typography,
  TableCell,
  TableRow,
} from '@material-ui/core';

import {
  Link,
} from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'

import MercariIcon from '../icons/MercariIcon'
import PoshmarkIcon from '../icons/PoshmarkIcon'
import EbayIcon from '../icons/EbayIcon'
import EtsyIcon from '../icons/EtsyIcon'

import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import deleteItem from '../../actions/deleteItem'

const styles = theme => ({
  row: {
    cursor: 'pointer',
  },
  image: {
    maxWidth: 100,
    maxHeight: 100,
    padding: 0,
    margin: 0,
  },
  rowSubtitleItem: {
    marginRight: 20,
  },
})

class ItemTableRow extends React.Component {

  render() {
    const { classes, item } = this.props

    // TODO switch to Link without messing up styles
    const goToItem = (item) => () => window.location.replace(`/item/${item.id}`)
    return (
      <TableRow 
        hover
        key={item.id}
        className={classes.row}
      >
        <TableCell 
          padding="none" 
          style={{ lineHeight: 0 }}
          onClick={goToItem(item)}
        >
          <img 
            src={item.imageURLs && item.imageURLs.length > 0 ? item.imageURLs[0] : 'https://via.placeholder.com/150'} 
            className={classes.image}
            alt="product"
          />
        </TableCell>
        <TableCell 
          align="left"
          onClick={goToItem(item)}
        >
          <Grid container direction="column" justify="flex-start">
            <Grid item>
              <Typography variant="body1">
                {item.title}
              </Typography>
            </Grid>
            <Grid item sm={8} md={8} lg={8}>
              <Grid container direction="row">
                <Grid item sm={2} md={2} lg={2} className={classes.rowSubtitleItem}>
                  <Typography variant="body2">
                    {item.createdOn && item.createdOn.seconds ? moment(item.createdOn.seconds * 1000).format('MM/DD/YY') : ''}
                  </Typography>
                </Grid>
                <Grid item sm={2} md={2} lg={2} className={classes.rowSubtitleItem}>
                  <Typography variant="body2">
                    ${item.price}
                  </Typography>
                </Grid>
                <Grid item sm={4} md={4} lg={3} className={classes.rowSubtitleItem}>
                  { item.merchants && item.merchants.mercari && item.merchants.mercari.listed && <MercariIcon /> }
                  { item.merchants && item.merchants.poshmark && item.merchants.poshmark.listed && <PoshmarkIcon /> }
                  { item.merchants && item.merchants.etsy && item.merchants.etsy.listed && <EtsyIcon /> }
                  { item.merchants && item.merchants.ebay && item.merchants.ebay.listed && <EbayIcon /> }
                </Grid>
              { item.sku ?
                <Grid item sm={2} md={2} lg={2} className={classes.rowSubtitleItem}>
                  <Typography variant="body2">
                    SKU: {item.sku}
                  </Typography>
                </Grid> : null
              }
              </Grid>
            </Grid>
          </Grid>            
        </TableCell>
        <TableCell 
          align="right" 
          onClick={() => { 
            if (window.confirm(`Are you sure you want to delete "${item.title || ''}"?`)) {
              deleteItem(item)
            }
          }}
        >
          <DeleteIcon />
        </TableCell>
      </TableRow>
    )
  }
}

export default withStyles(styles)(ItemTableRow)
