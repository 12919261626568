import React, { Fragment } from 'react'

import {
  PoshmarkEvent,
} from '../../../constants'

import Iframe from 'react-iframe'
import importItem from '../../../actions/importItem'
import config from '../../../config'
import { importedMapPoshmark } from './ImportedMapPoshmark'
import ConnectionVerifierPoshmark from '../../account/ConnectionVerifierPoshmark'

const DEBUG = false
const DEBUG_TIMEOUT = false

class ItemImporterPoshmark extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      complete: false,
      refreshingConnection: false,
    }

    this.onReceiveImportItemData = this.onReceiveImportItemData.bind(this)
    this.onFailedImportItemData = this.onFailedImportItemData.bind(this)
    this.onRefreshedConnection = this.onRefreshedConnection.bind(this)
    this.clearTimeout = this.clearTimeout.bind(this)
  }

  componentDidMount() {
    document.addEventListener(PoshmarkEvent.RECEIVE.GET_LISTING_DETAILS, this.onReceiveImportItemData)

    // SEAN this debug code can be used to FORCE FAILURES so we can test robustness / reliability
    // when encountering failures / timeouts
    // 
    // if (Math.random() < 0.3) {
    //   // 30% of the time, force fail
    //   console.error('FORCE fail for item ', this.props.platformID)
    //   return this.onFailedImportItemData(this.props.platformID)
    // } else {
    //   console.error('Attempt SUCCESS for item ', this.props.platformID)
    // }

    const { platformID, onImportFailed } = this.props
    if (!DEBUG_TIMEOUT && onImportFailed) {
      this.timeoutToken = setTimeout(() => {
        console.error('Timed out importing poshmark item: ', platformID)
        this.onFailedImportItemData(platformID)
      }, config.importItemTimeoutInSeconds * 1000)
    }
  }

  componentWillUnmount() {
    document.removeEventListener(PoshmarkEvent.RECEIVE.GET_LISTING_DETAILS, this.onReceiveImportItemData)

    this.clearTimeout() 
  }

  clearTimeout = () => {
    if (this.timeoutToken) {
      clearTimeout(this.timeoutToken)
      this.timeoutToken = null
    }    
  }

  onFailedImportItemData = (platformID, error = null) => {
    console.error(`Failed importing item with poshmark ID: ${platformID}`, error)
    this.setState({
      complete: true
    })

    const { onImportFailed } = this.props
    if (onImportFailed) {
      onImportFailed(platformID, error)
    } else {
      console.error('Unhandled import failure. Add a failure handler to ItemImporterPoshmark')
    }
    this.clearTimeout()
  }

  onReceiveImportItemData = async (event) => {
    const data = event.detail.data || {}
    const { platformID } = this.props

    if (!data || data.platformID !== platformID) {
      return console.log('Do not perform callback for this importer. Got different platform ID.')
    }
    if (this.state.complete) {
      return console.error('Already failed / timed out. Do not import item: ', this.props.platformID)
    }
    if (importedMapPoshmark.hasImported(data.platformID)) {
      return console.log('We have already imported the item with ID: ', data.platformID)
    }
    if (data.errors && data.errors.length) {
      return this.onFailedImportItemData(platformID, data.errors[0])
    }

    importedMapPoshmark.add(data.platformID)

    console.log('Received imported Poshmark item data: ', data)
    const item = await importItem(data)

    if (!DEBUG) {
      this.setState({ 
        complete: true
      })      
    }

    this.clearTimeout()

    if (this.props.onImportComplete) {
      this.props.onImportComplete(item)
    }
  }

  onRefreshedConnection = (data) => {
    console.log('Refreshed connection with data')
  }

  render() {
    const { platformID } = this.props
    return (
      <Fragment>
      {
        !this.state.complete &&
        <Iframe 
          className={DEBUG ? null : "iframe-poshmark-import-item" }
          url={`https://poshmark.com/edit-listing/${platformID}?${PoshmarkEvent.SEND.GET_LISTING_DETAILS}=1`}
          width={DEBUG ? 500 : 1}
          height={DEBUG ? 500 : 1} 
          display="initial" 
          position="absolute"
        />
      }
      {
        this.state.refreshingConnection &&
        <ConnectionVerifierPoshmark onReceiveLoginData={this.onRefreshedConnection} debug={DEBUG} />
      }
      </Fragment>
    )
  }
}

export default ItemImporterPoshmark