import React from 'react'

import FirebaseAuth from '../misc/FirebaseAuth'
import Error from '../misc/Error'
import Login from './Login'
import Profile from './Profile'
import Subscription from './Subscription'
import {
  Page,
} from '../../styles/layout'

const Account = ({ history }) => (
  <Page>
    <FirebaseAuth>
      { ({isLoading, error, auth}) => {

        if (isLoading) {
          return <p>loading...</p>
        }

        if (error) {
          return <Error error={error} />
        }

        if (!auth) {
          return <Login history={history}/>
        }

        return <div>
          <Profile auth={auth} />
          <hr />
          <Subscription auth={auth} />
        </div>

      }}
    </FirebaseAuth>
  </Page>
)

export default Account
