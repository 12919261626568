import React from 'react'

import { Link } from 'react-router-dom'

import {
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core'

import BadgeLabel from '../base/BadgeLabel'

import MerchantIcon from '../icons/MerchantIcon'
import { withStyles } from '@material-ui/core/styles'
import colors from '../../styles/colors'

import MerchantHelpers from '../../merchant'
import { EE, Events } from '../../message'

const StyledIcon = withStyles({
  root: {
    width: '1.5em',
    height: '1.5em',
    marginRight: 20,
    backgroundColor: 'white',
    borderRadius: '0.75em',
  },
})(Icon)

const styles = theme => ({
  primarySelected: {
    color: 'white',
  },
})

const selectedListItemStyles = (selectedMerchant) => {
  return {
    backgroundColor: colors[selectedMerchant],
  }
}

class ItemSidebarMerchantListItem extends React.Component {

  static delistEventName = (merchantName) => {
    switch (merchantName) {
      case 'ebay':
        return Events.ItemSidebarMerchantListItem.DELIST_EBAY
      case 'etsy':
        return Events.ItemSidebarMerchantListItem.DELIST_ETSY
      case 'facebook':
        console.error('Trying to delist facebook listing via sidebar, ensure we have a listener set up')
        return Events.ItemSidebarMerchantListItem.DELIST_FACEBOOK
      case 'mercari':
        return Events.ItemSidebarMerchantListItem.DELIST_MERCARI
      case 'poshmark':
        return Events.ItemSidebarMerchantListItem.DELIST_POSHMARK
      default:
        console.error('Unhandled merchant name in delistEventName', merchantName)
        return ''
    }
  }

  constructor(props) {
    super(props)
    this.state = {

    }
  }

  onChangeListingStatus(e) {
    const status = e.target.value
    console.log('Change listing status: ', status)

    const { onChangeListingState, onDelistMerchants, merchantName, listingState, listedMerchants } = this.props
    const merchantNameFormatted = MerchantHelpers.merchantNameFormatted(merchantName)

    switch (status) {
      case 'sold': {

        const merchantsToDelist = listedMerchants.filter(n => n !== merchantName)
        const merchantsToAutoDelist = merchantsToDelist.filter(n => n !== 'facebook')
        const shouldDelistFacebook = merchantsToDelist.indexOf('facebook') > -1

        let autoDelistString = ''
        if (merchantsToAutoDelist.length > 0) {
          const namesString = merchantsToAutoDelist.map(n => MerchantHelpers.merchantNameFormatted(n)).join(', ')
          autoDelistString = `\n\nWe will try to automatically delist from ${namesString}.`
        }
        if (shouldDelistFacebook) {
          autoDelistString += `\n\nMake sure to delist your Facebook Marketplace listing. ListingJoy does not currently support auto-delisting from Facebook.`
        }

        if (window.confirm(`Are you sure you want to change to "Sold" on ${merchantNameFormatted}?${autoDelistString}`)) {

          onDelistMerchants(merchantsToDelist)
          onChangeListingState({
            merchants: {
              [merchantName]: {
                sold: true,
              },
            },
          })
        }
        break;
      }
      case 'listed': {
        let url = window.prompt(
`Have you already listed this item on ${merchantNameFormatted} and you want to manually mark this item as "Listed" within ListingJoy?

If so, enter a valid ${merchantNameFormatted} listing URL. It should look like this:
${MerchantHelpers.validListingURLExample(merchantName)}`
        )
        if (url) {
          let data = MerchantHelpers.listingDataFromMerchantURL(merchantName, url)
          if (data.id) {
            onChangeListingState({
              merchants: {
                [merchantName]: {
                  listed: true,
                  listedID: data.id,
                  listingURL: url,
                  sold: false,
                },
              },
            })
          } else {
            alert('Sorry, we didn\'t recognize that URL. Does it match the formatting of a valid listing URL?')
          }
        }
        console.log('Resulting url ', url)
        break;
      }
      case 'delisted': {
        if (listingState !== 'listed') {
          alert('The item must first be listed for you to delist it.')
        } else if (merchantName !== 'facebook' && window.confirm('Are you sure you want to change to "Delisted"?')) {
          EE.emitEvent(ItemSidebarMerchantListItem.delistEventName(merchantName))
        } else if (window.confirm('We do not yet support automatic delisting for Facebook. Do you want to manually set the item to "Delisted"?')) {
          EE.emitEvent(ItemSidebarMerchantListItem.delistEventName(merchantName))
        }
        break;
      }
      case 'not_listed': {
        if (window.confirm('Are you sure you want to change to "Not Listed"?')) {
          onChangeListingState({
            merchants: {
              [merchantName]: {
                listed: false,
                listedID: null,
                listingURL: null,
                sold: false,
              },
            },
          })
        }
        break;
      }
      default:
        console.error('Unhandled listing state type: ', status)
    }
  }

  render() {
    const { merchantName = 'listingjoy', listingState, itemID, selected, loading, isNew, classes, disabled } = this.props
    const isRegularMerchant = merchantName !== 'listingjoy'
    const isListingJoy = !isRegularMerchant
    let listItemStyles = {
      backgroundColor: 'rgb(255, 255, 255)',
      borderRadius: 8,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      marginBottom: isListingJoy ? 0 : 8,
    }
    if (selected) {
      listItemStyles = Object.assign(listItemStyles, selectedListItemStyles(merchantName))
    }

    return (
      <Link
        onClick={e => {
          // We previously did not bring the user to the merchant form when they clicked the dropdown
          // keep this code here in case we want to bring that functionality back
          // const isListingStateSelectEl = e.target.className && typeof e.target.className.indexOf === 'function' && e.target.className.indexOf('MuiSelect-root') !== -1
          if (loading || isNew) {
            return e.preventDefault()
          }

          // hacky way to figure out if we are canceling out of a listing state selection
          // (there is some popover with aria-hidden on the el when you are selecting with a select el)
          if (e.target && typeof e.target.getAttribute === 'function' && e.target.getAttribute('aria-hidden') === 'true') {
            return e.preventDefault()
          }
          // hacky way to figure out if we are confirming a listing state selection
          if (e.target && typeof e.target.value === 'string') {
            return e.preventDefault()
          }

          if (disabled) {
            return e.preventDefault()
          }

          console.log('Clicked link with target: ', e.target)
        }}
        to={{
          pathname: (itemID ?
                     `/item/${itemID}/${merchantName || ''}` :
                     `/new/${merchantName || ''}`),
          state: { selectedMerchant: merchantName },
        }}
        style={{
          textDecoration: 'none',
        }}
      >
        <ListItem style={listItemStyles} button>
          <ListItemIcon>
              <StyledIcon style={merchantName === 'mercari' || isListingJoy ? {
                backgroundColor: 'initial',
                borderRadius: merchantName === 'mercari' ? 0 : 12,
              } : {}}>
                <MerchantIcon merchantName={merchantName} />
            </StyledIcon>
          </ListItemIcon>
          <ListItemText
            primary={MerchantHelpers.merchantNameFormatted(merchantName)}
            classes={{
              primary: (selected ? classes.primarySelected : null),
            }}
          >
          </ListItemText>
          { !disabled && !isNew && !loading && isRegularMerchant ?

            <Select
              value={listingState}
              onChange={this.onChangeListingStatus.bind(this)}
              className={'listingStateSelector'}
              style={{
                // copied from BadgeLabel styles
                fontSize: 10,
                textTransform: 'uppercase',
                letterSpacing: 1,
                fontWeight: 'bold',
                color: 'rgb(101, 101, 101)',
                paddingLeft: 5,
                background: 'rgb(244, 244, 244)',
                borderRadius: 3,
              }}
              inputProps={{

              }}
                >
              <MenuItem value={'sold'}>Sold</MenuItem>
              <MenuItem value={'listed'}>Listed</MenuItem>
              <MenuItem value={'delisted'}>Delisted</MenuItem>
              <MenuItem value={'not_listed'}>Not Listed</MenuItem>
            </Select> :

            <BadgeLabel
              text={
                loading ? 'Loading..' : listingState
              }
            />
          }
        </ListItem>
      </Link>
    )
  }
}

export default withStyles(styles)(ItemSidebarMerchantListItem)
