import React from 'react'

import Iframe from 'react-iframe'

import {
  PoshmarkEvent,
  ListingJoyExternalEvent,
} from '../../constants'

import { Message, EE, Events } from '../../message'

/**
  * We can pass data from this iframe to this component by:
    1) fetching the data via a content script that loads within the iframe on Poshmark.com
    2) this content script passes the data to the background script of the extension
    3) the data is passed to the content script of listingjoy
    4) the listing joy content script dispatches an event on the window.document with the data (and this component is listing for that event)

    more reading: 
      https://developer.chrome.com/extensions/messaging#external-webpage
      https://developer.chrome.com/extensions/manifest/externally_connectable
 */

const DEBUG = false
const DEBUG_TIMEOUT = false
const TIMEOUT_MS = 8000

class ListingsFetcherPoshmark extends React.Component {

  constructor(props) {
    super(props)

    this.onReceiveListings = this.onReceiveListings.bind(this)
    this.handleReceiveItems = this.handleReceiveItems.bind(this)
    this.resetTimeoutInterval = this.resetTimeoutInterval.bind(this)
  }

  componentDidMount() {
    Message.notifyExtension(ListingJoyExternalEvent.SEND.SETUP_POSHMARK)

    this.resetTimeoutInterval()

    document.addEventListener(PoshmarkEvent.RECEIVE.GET_LISTINGS, this.onReceiveListings)
  }

  componentWillUnmount() {
    document.removeEventListener(PoshmarkEvent.RECEIVE.GET_LISTINGS, this.onReceiveListings)
  }

  onReceiveListings(e) {
    this.handleReceiveItems(e.detail.data)
  }

  handleReceiveItems(items = []) {
    console.log('Received listings from poshmark: ', items)

    if (this.props.onReceiveListings) {
      this.props.onReceiveListings(items, 'poshmark')
    }

    EE.emitEvent(Events.ListingsFetcherPoshmark.DID_GET_LISTINGS, [items, 'poshmark'])
  }

  resetTimeoutInterval() {
    if (DEBUG_TIMEOUT) {
      return
    }

    if (this.timeoutToken) {
      clearTimeout(this.timeoutToken)
    }

    this.timeoutToken = setTimeout(() => {

      console.log('Timed out fetching listings for Poshmark')
      if (this.timeoutToken) {
        clearTimeout(this.timeoutToken)
      }
      this.handleReceiveItems([])
    }, TIMEOUT_MS)
  }

 /* Need to pass data as follows:
   1) this code to background
   2) background to content script iframeLoaded in poshmark
   3) content script back to background
   4) background back listingjoy content script
   5) LJ content script dispatches an event that we are listening for in this component */
  render() {
    const { poshmarkUsername } = this.props
    return (
      <Iframe 
        id={ DEBUG ? null : "iframe-poshmark" }
        url={`https://poshmark.com/closet/${poshmarkUsername}?availability=available&${PoshmarkEvent.SEND.GET_LISTINGS}=1`} 
        width={ DEBUG ? 500 : 1 }
        height={ DEBUG ? 500 : 1 }
        display="initial" 
        position="relative"
      />
    )
  }
}

export default ListingsFetcherPoshmark