import React, { Fragment } from 'react'

import {
  Grid,
} from '@material-ui/core';

import InputLabel from '../../base/InputLabel'
import ItemFormInputAspect from './ItemFormInputAspect'
import {
  ItemFormInputAspectDOMSchema,
  ItemFormInputAspectDOMChild,
} from './ItemFormInputAspectDOM'

const ItemFormInputAspectSection = ({
  aspect,
  classes,
  merchantName,
  defaultValue,
  hidden = false,
  isChild = false,
}) => {
  const isRecommended = !aspect.constraint.required && aspect.constraint.usage === 'RECOMMENDED'
  return (
    <Grid
      item
      key={aspect.id}
      style={{ display: hidden ? 'none' : 'block' }}
      xs={12} sm={6} md={6} lg={6}
    >
      <InputLabel
        optional={!aspect.constraint.required}
        customLabelText={isRecommended ? '(Recommended)' : null}
        customLabelColor={isRecommended ? 'rgba(6, 84, 186, 0.7)' : null}
      >{aspect.name}</InputLabel>
        <ItemFormInputAspectDOMSchema
          merchantName={merchantName}
          aspect={aspect}
        />
        {
          isChild &&
          <ItemFormInputAspectDOMChild
            merchantName={merchantName}
            aspect={aspect}
          />
        }
        <ItemFormInputAspect
          aspect={aspect}
          merchantName={merchantName}
          classes={classes}
          defaultValue={defaultValue}
        />
    </Grid>
  )
}

export default ItemFormInputAspectSection
