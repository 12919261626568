import React, {Fragment} from 'react'

import {
  FormControl,
  MenuItem,
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles'
import styles from './ItemFormStyles'
import SelectWithSavedNote from './SelectWithSavedNote'
import InputLabel from '../../base/InputLabel'

// SEAN these are the poshmark colors
import {colors} from '../../data/colors'

class ItemFormInputColor extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      color: props.defaultColor || undefined,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.defaultColor &&
      prevProps.defaultColor !== this.props.defaultColor &&
      !this.state.color
    ) {
      // Default changed + is unfilled
      this.setColor(this.props.defaultColor)
    }
  }

  onColorChange = function (e) {
    this.setColor(e.target.value)
  }

  setColor(color) {
    this.setState({color})
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {
    const {classes, secondary, loading} = this.props;
    const type = secondary ? 'Secondary' : 'Primary';
    return (
      <Fragment>
        <InputLabel optional={secondary}>{type} Color</InputLabel>
        <FormControl
          className={classes.colorControl}
          required={!secondary}
          fullWidth
        >
          <SelectWithSavedNote
            labelId="form-item-color-label"
            name={`color${type}`}
            value={this.state.color || ''}
            onChange={this.onColorChange.bind(this)}
            required={!secondary}
            loading={loading}
            fullWidth
          >
            {colors.map(data =>

              <MenuItem key={data.display} value={data.display.toLowerCase()}>
                <div style={{
                  display: 'block',
                  width: '100%',
                }}>
                  {data.display}
                  <span style={{
                    height: 20,
                    width: 20,
                    backgroundColor: `${data.css}`,
                    borderColor:'rgb(180, 180, 180)',
                    borderWidth: 1,
                    borderRadius: '50%',
                    float: 'right',
                  }}></span>

                </div>

              </MenuItem>)
            }
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormInputColor)
