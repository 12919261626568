import firebase from 'firebase/app'
import ReactGA from 'react-ga'
import queryString from 'query-string'
import decode from 'unescape'
import config from '../config'

import createItem from './createItem'

const copyItem = async (item) => {

  ReactGA.event({
    category: 'Copy',
    action: 'Copy Item',
  })

  const currentUser = firebase.auth().currentUser

  if (!currentUser) {
    console.error('Not logged in, cannot copy item')
    return
  }

  let newItem = { ...item }
  newItem.title = 'Copy of ' + newItem.title
  newItem.merchants = {}
  delete newItem.updatedOn
  delete newItem.id

  return createItem(newItem)
}

export default copyItem
