import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import ReactGA from 'react-ga'


const imageHash = (file) => {
  const seed = `${file.name}${file.lastModified}${file.size}`
  var hash = 0, i, chr;
  for (i = 0; i < seed.length; i++) {
    chr   = seed.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const uploadImage = async (file, itemID) => {

  ReactGA.event({
    category: 'Image',
    action: 'Upload image',
  })

  const imageID = imageHash(file)

  console.log('Generated ID for file: ', file, imageID)
  // Get a reference to the storage service, which is used to create references in your storage bucket
  const storage = firebase.storage()
  const storageRef = storage.ref();
  const imageRef = storageRef.child(`images/itemID/${itemID}/imageID/${imageID}`);
  const task = imageRef.put(file, { contentType: file.contentType })

  return new Promise((resolve, reject) => {
    task.on('state_changed', (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      console.log('Upload is ' + progress + '% done')
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused')
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running')
          break;
        default:
          break;
      }
    }, (error) => {
      console.log('Error uploading: ', error)
      reject(error)
      // Handle unsuccessful uploads
    }, () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      console.log('Success!')
      task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        console.log('File available at', downloadURL);
        resolve(downloadURL)
      });
    })
  })
}

export default uploadImage
