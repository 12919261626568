
import React from 'react'
import iconStyles from './iconStyles'

const EbayIcon = ({ style }) => (
  <div style={{
    ...iconStyles,
    ...style,
  }}>
    <svg viewBox="0 0 150 150"><g fill="none"><path d="M30.6 61.4C22 61.4 15 65 15 75.8c0 8.5 4.8 14 15.8 14 13 0 13.8-8.6 13.8-8.6h-6.3s-1.4 4.6-8 4.6c-5.2 0-9-3.6-9-8.6h24V74c0-5-3.2-12.6-14.7-12.6zm-.3 4c5 0 8.6 3 8.6 7.8H21.3c0-5 4.5-7.8 9-7.8z" fill="#E53238"></path><path d="M45.4 51v33.3l-.2 4.5h6l.2-3.7s3 4.7 11 4.7c8.3 0 14-5.8 14-14.2 0-7.7-5.2-14-14-14-8.4 0-11 4.5-11 4.5V51h-6zm15.4 14.6c5.7 0 9.3 4.3 9.3 10 0 6-4 10-9.2 10-6 0-9.4-4.7-9.4-10 0-5 3-10 9.4-10z" fill="#0064D2"></path><path d="M91.2 61.4c-13 0-13.7 7-13.7 8H84s.2-4 6.8-4c4.2 0 7.5 2 7.5 5.5V72h-7.5c-10 0-15.3 3-15.3 8.7 0 5.6 5 8.7 11.5 8.7 9 0 12-4.8 12-4.8v3.7h5.8s-.3-2.3-.3-4v-13c0-8.5-7-10.3-13.3-10.3zm7 14.7v2c0 2-1.3 7.8-9.7 7.8-4.6 0-6.6-2.3-6.6-5 0-4.6 6.6-4.7 16.3-4.7z" fill="#F5AF02"></path><path fill="#86B817" d="M101 62.3h7l10.3 20.2 10.2-20.2h6.5l-18.6 36h-6.8l5.4-10"></path></g></svg>
  </div>
)

export default EbayIcon



