import React, { Fragment } from 'react'
import TextFieldWithSavedNote from './TextFieldWithSavedNote'
import { getElementIDForAspect } from './ItemFormInputAspectDOM'

const ItemFormInputAspectFreeText = (props) => {
  const {
    aspect,
    merchantName,
    defaultValue = '',
    onChange = () => {},
  } = props
  return (
    <TextFieldWithSavedNote
      placeholder={aspect.name}
      onChange={onChange}
      defaultValue={defaultValue}
      inputProps={{
        id: getElementIDForAspect(aspect, merchantName)
      }}
      fullWidth
    />
  )
}

export default ItemFormInputAspectFreeText
