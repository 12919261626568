import React, { Fragment } from 'react'

import { 
  Grid,
  Typography,
} from '@material-ui/core';

import ListerItem from './ListerItem'

import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { toast } from '../toast'

import colors from '../data/colors'
import { EE, Events } from '../../message'

const styles = theme => ({
  listerItemContainer: {
    maxHeight: 500,
    overflowY: 'scroll',
  },
})

const DEBUG_UI = false

class Lister extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      collapsed: false,
      data: DEBUG_UI ? DEBUG_UI_DATA : [],
    }

    this.onClickCollapseIcon = this.onClickCollapseIcon.bind(this)
    this.onClickCloseIcon = this.onClickCloseIcon.bind(this)

    this.onBeginCreateListing = this.onBeginCreateListing.bind(this)
    this.onDidCreateListing = this.onDidCreateListing.bind(this)

    this.hasPresentedToast = false
  }

  componentDidMount() {
    EE.addListener(Events.ItemListerMercari.CREATE_LISTING, this.onBeginCreateListing)
    EE.addListener(Events.ItemListerMercari.DID_CREATE_LISTING, this.onDidCreateListing)

    EE.addListener(Events.ItemListerFacebook.CREATE_LISTING, this.onBeginCreateListing)
    EE.addListener(Events.ItemListerFacebook.DID_CREATE_LISTING, this.onDidCreateListing)

    EE.addListener(Events.ItemListerPoshmark.CREATE_LISTING, this.onBeginCreateListing)
    EE.addListener(Events.ItemListerPoshmark.DID_CREATE_LISTING, this.onDidCreateListing)
  }

  componentWillUnmount() {
    EE.removeListener(Events.ItemListerMercari.CREATE_LISTING, this.onBeginCreateListing)
    EE.removeListener(Events.ItemListerMercari.DID_CREATE_LISTING, this.onDidCreateListing)

    EE.removeListener(Events.ItemListerFacebook.CREATE_LISTING, this.onBeginCreateListing)
    EE.removeListener(Events.ItemListerFacebook.DID_CREATE_LISTING, this.onDidCreateListing)

    EE.removeListener(Events.ItemListerPoshmark.CREATE_LISTING, this.onBeginCreateListing)
    EE.removeListener(Events.ItemListerPoshmark.DID_CREATE_LISTING, this.onDidCreateListing)
  }

  onBeginCreateListing(data) {

    let item = null
    if (data.merchantName === 'poshmark') {
      // Poshmark has differently formatted event data
      item = data.item
    } else {
      // All other have the item fields directly on data object
      item = data
    }

    this.setState(prevState => {
      return {
        data: this.state.data.concat([{
          item: {
            id: item.id,
            imageURLs: item.imageURLs,
            title: item.title,
            success: false,
            error: false,
          },
          merchantName: data.merchantName,
        }])
      }
    })
  }

  onDidCreateListing(data, updatedValues, id) {

    if (!id) {
      console.error('An item id is required in Lister onDidCreateListing listener')
      return
    }

    if (data && data.errors && data.errors.length) {
      return this._updateItemWithID(id, (item) => {
        let updatedItem = { ...item }
        updatedItem.error = true
        return updatedItem
      })
    }

    return this._updateItemWithID(id, (item) => {
      let updatedItem = { ...item }
      updatedItem.success = true
      return updatedItem
    })
  }

  _updateItemWithID(id, updateFunc) {

    this.setState(prevState => {
      const newData = prevState.data.map(item => {
        if (item.item.id === id) {
          return updateFunc(item)
        }
        return item
      })
      return {
        data: newData
      }
    })
  }

  onClickCollapseIcon() {
    this.setState(prevState => {
      return {
        collapsed: !prevState.collapsed,
      }
    })
  }

  onClickCloseIcon() {

  }

  _titleString() {
    const { data } = this.state

    const listingErrors = data.filter(item => item.error).length
    if (listingErrors > 0) {
      return `${listingErrors} listing error${listingErrors === 1 ? '' : 's'}`
    }

    const listingComplete = data.filter(item => item.success).length
    if (listingComplete > 0) {
      return `${listingComplete} listing${listingComplete === 1 ? '' : 's'} completed`
    }

    const count = data.length
    return `${count} listing${count === 1 ? '' : 's'} in progress`
  }

  render() {
    const { classes } = this.props
    if (this.state.data.length === 0) {
      return <p>hello world</p>//null
    }

    return (
      <Fragment>
        <Grid container justify='space-between' alignItems='center' style={{ backgroundColor: '#7497f0' }}>
          <Grid item md={8}>
            <div style={{ color: 'white' }}>
              {this._titleString()}
            </div>
          </Grid>
          <Grid item md={3}>
            <Grid container justify='space-between' alignItems='center'>
              <Grid item md={4}>
                {
                  this.state.collapsed ?
                  <KeyboardArrowUpIcon onClick={this.onClickCollapseIcon} /> :
                  <KeyboardArrowDownIcon onClick={this.onClickCollapseIcon} />
                }
              </Grid>
{/*              <Grid item md={4}>
                <CloseIcon />
              </Grid>*/}
            </Grid>
          </Grid>
        </Grid>

        { !this.state.collapsed &&
          <div className={classes.listerItemContainer}>
            { this.state.data.map(item => {
                return (
                  <ListerItem
                    key={item.id}
                    {...item}
                  />
                )
              })
            }
          </div>
        }
      </Fragment>
    )
  }
}

const DEBUG_UI_DATA = [
  {
    item: {
      id: 'GZXQRTiDb0Ikj8SWs0Iq',
      imageURLs: [
        "https://di2ponv0v5otw.cloudfront.net/posts/2019/10/10/5d9f8d04afc282236c4309af/m_5d9f8d062670a9c9b8c514cb.jpeg"
      ],
      title: "Mens I Can't Hold My Liquor",
      success: false,
      error: false,
    },
    merchantName: 'mercari',
  },
  {
    item: {
      id: 'GZXQRTiDb0Ikj8SWs0Iq',
      imageURLs: [
        "https://di2ponv0v5otw.cloudfront.net/posts/2019/10/10/5d9f8d04afc282236c4309af/m_5d9f8d062670a9c9b8c514cb.jpeg"
      ],
      title: "Mens I Can't Hold My Liquor",
      success: false,
      error: false,
    },
    merchantName: 'facebook',
  },
]

export default withStyles(styles)(Lister)