import firebase from 'firebase/app'
import ReactGA from 'react-ga'
import queryString from 'query-string'
import config from '../config'

const delistEtsy = (listingID) => {

  ReactGA.event({
    category: 'Delist',
    action: 'Delist Etsy',
  })

  return new Promise(async (resolve, reject) => {
    const currentUser = firebase.auth().currentUser
    if (!currentUser) {
      return reject(new Error('Not logged in'))
    }

    try {
      const userDoc = await firebase.firestore()
        .collection('users')
        .doc(currentUser.uid)
        .get()

      if (!userDoc.exists || !userDoc.data().etsyToken) {
        return reject(new Error('User not found'))
      }

      const etsyToken = userDoc.data().etsyToken
      const queryParams = queryString.stringify({ 
        etsyToken,
        listingID,
      })
      const result = await fetch(`${config.cloudFunctionBaseURL}/etsyDelist?${queryParams}`)

      console.log('Got result from delist: ', result)
      if (result.ok) {
        return resolve()
      } else {
        console.log('Got response code : ', result.status)
        return reject(new Error(`Got status code: ${result.status}`))
      }
    }
    catch (e) {
      return reject(e)
    }
  })
}

export default delistEtsy
