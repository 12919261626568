const styles = (hasError = false) => ({
  control: (styles) => ({
    ...styles,
    ...(hasError && { borderColor: 'red' }),

    borderRadius: 1,
    border: 'none',
    background: hasError ? '#FFEAE7' : 'rgb(245, 245, 245)',
    borderBottomColor: hasError ? '#e53935' : 'rgba(0, 0, 0, 0.42)',
    borderBottomWidth: hasError ? 2 : 1,
    borderBottomStyle: 'solid',
    cursor: 'pointer',
    transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transitionProperty: 'border-bottom-color',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDelay: '0ms',
    marginBottom: 20,
  }),
  option: (styles) => ({
    ...styles,
  }),
  input: (styles) => ({
    ...styles,
  }),
  placeholder: (styles) => ({
    ...styles,
  }),
  singleValue: (styles) => ({
    ...styles,
  }),
})

export default styles
