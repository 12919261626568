// the main routes of our app are defined here using react-router
// https://reacttraining.com/react-router/web/example/basic

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/auth'

import ItemList from './posts/ItemList'
import ItemNew from './posts/ItemNew'
import ItemEdit from './posts/ItemEdit'
import Onboarding from './settings/Onboarding'
import Settings from './settings/Settings'
import Account from './account/Account'
import Login from './account/Login'
import SignIn from './account/SignIn'
import SignUp from './account/SignUp'
import Error from './misc/Error'

import withAuthProtection from './account/withAuthProtection'
import withRedirectWhenAuthed from './account/withRedirectWhenAuthed'

const loginProtection = withAuthProtection('/login')
const LoginWithRedirectWhenAuthed = withRedirectWhenAuthed('/')(Login)
const SignInWithRedirectWhenAuthed = withRedirectWhenAuthed('/')(SignIn)
const RegisterWithRedirectWhenAuthed = withRedirectWhenAuthed('/')(SignUp)

const ProtectedItemList = loginProtection(ItemList)
const ProtectedItemNew = loginProtection(ItemNew)
const ProtectedItemEdit = loginProtection(ItemEdit)
const ProtectedOnboarding = loginProtection(Onboarding)
const ProtectedSettings = loginProtection(Settings)
const ProtectedAccount = loginProtection(Account)

class Routes extends React.Component {

    constructor() {
        super()
    }

    render() {
        return (
            <Switch>
                <Route 
                    exact 
                    path="/" 
                    render={props => <ProtectedItemList {...props} />}
                />
                <Route 
                    path="/onboarding/:step" 
                    render={props => <ProtectedOnboarding {...props} />}
                />
                <Route 
                    path="/settings" 
                    render={props => <ProtectedSettings {...props} />}
                />
                <Route 
                    path="/account" 
                    render={props => <ProtectedAccount {...props} />}
                />
                <Route 
                    path="/new/:selectedMerchant" 
                    render={props => <ProtectedItemNew {...props} />}
                />
                <Route 
                    path="/new" 
                    render={props => <ProtectedItemNew {...props} />}
                />
                <Route 
                    path="/settings" 
                    render={props => <ProtectedSettings {...props} />}
                />
                <Route 
                    path="/item/:id/:selectedMerchant" 
                    render={props => <ProtectedItemEdit {...props} />}
                />
                <Route 
                    path="/item/:id" 
                    render={props => <ProtectedItemEdit {...props} />}
                />
                <Route 
                    path="/sync/poshmark/:poshmarkID/:destinationMerchant" 
                    render={props => <ProtectedItemNew {...props} />}
                />
                <Route 
                    path="/sync/poshmark/:poshmarkID" 
                    render={props => <ProtectedItemNew {...props} />}
                />
                <Route 
                    path="/login" 
                    render={props => <SignInWithRedirectWhenAuthed {...props} />}
                />
                <Route 
                    path="/register" 
                    render={props => <RegisterWithRedirectWhenAuthed {...props} />}
                />
                <Route component={Error} />
            </Switch> 
        )       
    }
}

export default Routes
