import Firebase from 'firebase/app'
import ReactGA from 'react-ga'
import Store from '../store'

const logOut = () => {

  ReactGA.event({
    category: 'User',
    action: 'Log out',
  })

  Store.setUserDetails('facebook', {})
  Store.setUserDetails('mercari', {})
  Store.setUserDetails('poshmark', {})

  return Firebase.auth().signOut()
}

export default logOut
