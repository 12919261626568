// This is an uncontrolled React form, which is way simpler than
// the normal React controlled form
// https://reactjs.org/docs/uncontrolled-components.html
//
// You can use browser form validation these days, and just
// get the values from the form on submit.

import React from 'react'

import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import InputLabel from '../../base/InputLabel'
import Iframe from 'react-iframe'

import styles from '../form/ItemFormStyles'
import ItemFormHeader from '../ItemFormHeader'
import ItemFormListedBanner from '../ItemFormListedBanner'

import ItemFormSectionHeaderPhotos from '../form/headers/ItemFormSectionHeaderPhotos'
import ItemFormSectionHeaderDetails from '../form/headers/ItemFormSectionHeaderDetails'
import ItemFormSectionHeaderCategory from '../form/headers/ItemFormSectionHeaderCategory'
import ItemFormSectionHeaderPackage from '../form/headers/ItemFormSectionHeaderPackage'
import ItemFormSectionHeaderPrice from '../form/headers/ItemFormSectionHeaderPrice'

import ItemFormImages from '../form/ItemFormImages'
import ItemFormInputBrand from '../form/ItemFormInputBrand'
import ItemFormInputCategories from '../form/ItemFormInputCategoriesRecursive'
import ItemFormInputCondition from '../form/ItemFormInputCondition'
import ItemFormInputDescription from '../form/ItemFormInputDescription'
import ItemFormInputTags from '../form/ItemFormInputTags'
import ItemFormInputTitle from '../form/ItemFormInputTitle'
import ItemFormInputPrice from '../form/ItemFormInputPrice'

import ItemFormMercariShipping from '../form/mercari/ItemFormMercariShipping'

import {
  getItemAspectValues,
} from '../form/ItemFormInputAspectDOM'

import ItemFormMerchantValues from './helpers/ItemFormMerchantValues'
import ItemListerMercari from '../../account/ItemListerMercari'
import ItemSidebarMerchantListItem from '../ItemSidebarMerchantListItem'

import mercariCategoryLookup from '../../data/categories/mercari-categories.json'
import mercariBrandLookup from '../../data/mercari-brand-id-lookup.json'
import CategoryHelpers from '../merchants/helpers/categoryHelpers'

import FormValidationErrors from '../../base/FormValidationErrors'
import FormError from '../../base/FormValidationError'

import Store from '../../../store'
import { Message, EE, Events } from '../../../message'

import {
  ListingJoyExternalEvent,
  MercariEvent,
} from '../../../constants'

class ItemFormMercari extends React.Component {

  constructor(props) {
    super(props)

    if (props.item) {
      this.state = {
        item: props.item,
        initialFiles: props.item.imageURLs,
      }
    } else {
      this.state = {}
    }

    this._updateItem = this._updateItem.bind(this)
    this._formErrorFragments = this._formErrorFragments.bind(this)
    this.onFormChange = this.onFormChange.bind(this)
    this.onSubmitList = this.onSubmitList.bind(this)
    this.onSubmitDelist = this.onSubmitDelist.bind(this)
    this.onReceiveListingItemResponse = this.onReceiveListingItemResponse.bind(this)
    this.onReceiveDelistingItemResponse = this.onReceiveDelistingItemResponse.bind(this)

    this.merchantValues = new ItemFormMerchantValues(props.item, props.merchantName)
    this.formRef = React.createRef()
  }

  componentDidMount() {
    EE.addListener(Events.ItemListerMercari.DID_CREATE_LISTING, this.onReceiveListingItemResponse)
    EE.addListener(Events.ItemDelisterMercari.DID_DELIST, this.onReceiveDelistingItemResponse)
    EE.addListener(Events.ItemSidebarMerchantListItem.DELIST_MERCARI, this.onSubmitDelist)

    if (this.formRef.current) {
      this.onFormChange()
    }
    this._initializeListerIfNeeded()
  }

  componentWillUnmount() {
    EE.removeListener(Events.ItemListerMercari.DID_CREATE_LISTING, this.onReceiveListingItemResponse)
    EE.removeListener(Events.ItemDelisterMercari.DID_DELIST, this.onReceiveDelistingItemResponse)
    EE.removeListener(Events.ItemSidebarMerchantListItem.DELIST_MERCARI, this.onSubmitDelist)
  }

  componentDidUpdate() {
    this._initializeListerIfNeeded()
  }

  _initializeListerIfNeeded() {
    const { hidden, item } = this.props
    if (!hidden && item && !item.listed) {
      const eventData = this._eventData()
      EE.emitEvent(Events.ItemListerMercari.INITIALIZE, [eventData])
    }
  }

  onFormChange() {
    this.setState({
      errorFragments: this._formErrorFragments(),
    })
  }

  onReceiveListingItemResponse = (data, updatedValues = null) => {
    console.log('In react received data for listing item!!', data)
    if (!data) {
      return this.setState({
        listing: false,
      })
    }

    const errorFragments = (data.errors || []).map((error, idx) => <FormError nonBlocking={true} key={idx}>{error.message}</FormError>)
    if (errorFragments.length > 0) {

      return this.setState({
        listing: false,
        listingError: true,
        errorFragments: errorFragments,
      })
    }
    if (!data.createListing || !data.createListing.id) {
      console.error('Mercari invalid data:', data)
      return this.setState({
        listing: false,
        listingError: true,
        errorFragments: [<FormError nonBlocking={true} key='error'>{'Invalid data received in mercari form'}</FormError>],
      })
    }

    if (updatedValues) {
      this._updateItem(updatedValues, {
        listing: false,
        errorFragments: [],
      }, true)
    }
  }

  onReceiveDelistingItemResponse = (data, updatedValues, itemID) => {
    console.log('Received delisting item response', data, updatedValues, itemID)

    this.setState({
      delisting: false
    })

    const errorFragments = (data.errors || []).map((error, idx) => <FormError nonBlocking={true} key={idx}>{error.message || 'Unknown error delisting on Mercari'}</FormError>)
    if (errorFragments.length > 0) {
      return this.setState({
        errorFragments: errorFragments,
      })
    }

    if (updatedValues) {
      this._updateItem(updatedValues, {
        errorFragments: [],
      }, true)
      console.log('Delisted item!')
    }
  }

  _formErrorFragments = () => {
    const data = this._itemData()
    const itemData = this.merchantValues.getFlattenedData(data)
    const { merchantName } = this.props
    const categoryPath = data.merchants[merchantName].overrides.categoryPath

    return [
      !(Store.getUserDetailsMercari() || {}).username && <FormError key={'connection'} selector={`#connection`}>Mercari is not connected. Go to <Link to='/settings'>Merchant Connections</Link> to link your Mercari account.</FormError>,
      (!itemData.imageURLs || itemData.imageURLs.length === 0) && <FormError key='images' selector={`#${merchantName}images`}>Must supply at least one image</FormError>,
      (!itemData.title || itemData.title.length === 0) && <FormError key='title1' selector={`#${merchantName}title`}>Must supply a title</FormError>,
      (itemData.title && itemData.title.length > 40) && <FormError key={'title2'} selector={`#${merchantName}title`}>Title must be 40 characters or less</FormError>,
      (!itemData.description || itemData.description.length === 0) && <FormError key={'description'} selector={`#${merchantName}description`}>Must supply a description</FormError>,
      (itemData.description && itemData.description.length > 1000) && <FormError key={'title3'} selector={`#${merchantName}title`}>Description must be 500 characters or less</FormError>,
      (!itemData.brand || itemData.brand.length === 0) && <FormError key={'brand'} selector={`#${merchantName}brand`}>Must supply a brand</FormError>,
      (itemData.tags && itemData.tags.length > 3) && <FormError key={'brand'} selector={`#${merchantName}tags`}>Must have 3 tags or fewer</FormError>,
      (itemData.condition === undefined) && <FormError key={'condition'} selector={`#${merchantName}condition`}>Must supply the item's condition</FormError>,
      (!categoryPath || categoryPath.length === 0) && <FormError key={'category'} selector={`#${merchantName}categoryPath`}>Must fill out category</FormError>,
      (categoryPath && categoryPath.length > 0 && !CategoryHelpers.isCategoryPathCompleteForMerchant(categoryPath, merchantName)) && <FormError key={'category'} selector='#categoryPath'>Must finish filling out category</FormError>,
      (!itemData.shipsFrom || itemData.shipsFrom.length < 5) && <FormError key={'shipsFrom'} selector={`#${merchantName}shipsFrom`}>Must provide a shipping zip code</FormError>,
      (!itemData.weight || (itemData.weight.lb === 0 && itemData.weight.oz === 0)) && <FormError key={'weight1'} selector={`#${merchantName}weightLbs`}>Must fill out weight</FormError>,
      (!itemData.shippingLabelType || itemData.shippingLabelType.length === 0) && <FormError key={'shippingLabelType'} selector='#shippingLabelType'>Must supply a shipping label type</FormError>,
      (itemData.shippingLabelType && itemData.shippingLabelType === 1 /* pre-paid label */ &&
        (!itemData.shippingClassType ||
         itemData.shippingClassType.length === 0 ||
         ItemFormMercariShipping.calculateAvailableShippingOptions(itemData.weight, itemData.dimensions)
          .filter(ship => ship.id === itemData.shippingClassType)
          .length === 0)
      ) && <FormError key={'shippingClassType'} selector='#shippingClassType'>Must select a shipping option if using pre-paid label</FormError>,
      (!itemData.price) && <FormError key={'price'} selector={`#${merchantName}price`}>Must fill out listing price</FormError>,

      // TODO category specifics!
    ].filter(Boolean)
  }

  onSubmit = event => {
    event.preventDefault()
    const errorFragments = this._formErrorFragments()

    this.setState({
      saving: true,
      errorFragments: errorFragments,
    })

    const data = this._itemData()
    this.props.onSubmit(data).then(updatedValues => {
      this._updateItem(updatedValues)
    })
  }

  onSubmitList = () => {
    const errorFragments = this._formErrorFragments()
    if (errorFragments.length > 0) {
      this.setState({
        errorFragments: errorFragments,
      })
      return
    }
    this.setState({
      listing: true,
    })

    const data = this._itemData()
    const eventData = this._eventData()

    EE.emitEvent(Events.ItemListerMercari.CREATE_LISTING, [eventData])

    // Also save:
    this.props.onSubmit(data).then(updatedValues => {
      this._updateItem(updatedValues)
    })
  }

  _eventData() {
    const data = this._itemData()
    let eventData = this.merchantValues.getFlattenedData(data)

    if (eventData.imageURLs && eventData.imageURLs.length > 12) {
      eventData.imageURLs = eventData.imageURLs.slice(0, 12)
    }
    // If this gets to cumbersome, separate out into a "normalize" function, like we did for Poshmark
    eventData.colors = [eventData.colorPrimary, eventData.colorSecondary].filter(Boolean)
    eventData.condition = +eventData.condition
    eventData.dimensions = (eventData.dimensions || []).map(v => +v)
    eventData.categoryNames = eventData.categoryPath.map(key => {
      return (mercariCategoryLookup[key] || {}).display
    })
    eventData.brandId = mercariBrandLookup[eventData.brand] || null
    eventData.sizeId = (eventData.aspects && eventData.aspects['sizeId'] && eventData.aspects['sizeId'].id) || null
    eventData.merchantName = this.props.merchantName

    const childId = this.merchantValues.getAspectChildId(this.formRef.current.elements)
    if (childId) {
      eventData.metadataValueId = childId
    } else {
      const categoryMetadataIds = Object.keys(eventData.aspects || {}).filter(key => key !== 'sizeId')
      if (categoryMetadataIds.length === 1) {
        eventData.metadataValueId = categoryMetadataIds[0]
      } else if (categoryMetadataIds > 1) {
        console.error('Unhandled case where we have multiple non-child metadata/category Ids. Which one do we use?', categoryMetadataIds)
      }
    }
    return eventData
  }

  onSubmitDelist = () => {
    this.setState({
      delisting: true,
    })

    const itemData = this.merchantValues.getFlattenedData(this._itemData())
    EE.emitEvent(Events.ItemDelisterMercari.DELIST, [itemData])
  }

  _updateItem(updatedValues, additionalStateValues = {}, notify = false) {
    const updatedItem = this.merchantValues.getUpdatedItem(updatedValues, this.state.item)
    if (notify) {
      Message.notifyExtension(ListingJoyExternalEvent.SEND.DID_UPDATE_LISTING, {
        item: updatedItem,
      })
    }
    this.merchantValues = new ItemFormMerchantValues(updatedItem, this.props.merchantName)
    this.setState({
      item: updatedItem,
      saving: false,
      ...additionalStateValues,
    })
  }

  _itemData() {
    const elements = this.formRef.current.elements
    const { merchantName } = this.props
    const { item } = this.state
    const {
      title,
      description,
      condition,

      price,

      shipsFrom,
      offerFreeShipping,
      shippingLabelType,
      shippingClassType,

    } = elements

    // Consider doing a "deep diff" of the original props
    // https://stackoverflow.com/a/25651677
    const merchantValues = {
      title: title.value,
      description: description.value,
      brand: this.merchantValues.getBrand(elements),
      condition: condition.value,
      dimensions: this.merchantValues.getDimensionsArray(elements),
      categoryPath: this.merchantValues.getCategoryPath(elements),
      price: +price.value,
      weight: this.merchantValues.getWeight(elements),
    }
    const overrides = this.merchantValues.buildOverrides(merchantValues, item)

    return {
      merchants: {
        mercari: {
          type: "listing",
          marketplaceSpecifics: {
            tags: this.merchantValues.getTagsArray(elements, merchantName),
            shipsFrom: shipsFrom.value,
            aspects: getItemAspectValues(elements, merchantName),
            offerFreeShipping: offerFreeShipping.value === 'yes',
            shippingLabelType: shippingLabelType ? (+shippingLabelType.value || null) : null,
            shippingClassType: shippingClassType ? (+shippingClassType.value || null) : null,
          },
          overrides: overrides,
        },
      },
    };
  }

  render() {
    const { classes } = this.props
    const { item, errorFragments } = this.state
    return (
      <div className={classes.formRoot} style={{ display: this.props.hidden ? 'none' : 'inherit' }}>
        <Grid
          container
          spacing={1}
          direction="row"
        >
          <ItemFormHeader
            merchantName={this.props.merchantName}
            item={item}
          />
          <form ref={this.formRef} onSubmit={this.onSubmit.bind(this)}>

            {
              this.merchantValues.getMerchantListingValue('listed', false) ?
              <ItemFormListedBanner
                merchantName={this.props.merchantName}
                item={item}
                isListing={this.state.listing}
                isDelisting={this.state.delisting}
                onList={this.onSubmitList}
                onDelist={this.onSubmitDelist}
              /> : null
            }

            <Paper className={`${classes.formSection} ${classes.formSectionFirst} ${classes.rootMercari}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPhotos number={1} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormImages
                    merchantName={this.props.merchantName}
                    imageURLs={this.merchantValues.get('imageURLs', [])}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Paper className={`${classes.formSection} ${classes.rootMercari}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderDetails number={2} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputTitle
                    maxCharacters={40}
                    defaultTitle={this.merchantValues.get('title', '')}
                    onChange={this.onFormChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputDescription
                    maxCharacters={1000}
                    defaultDescription={this.merchantValues.get('description', '')}
                    onChange={this.onFormChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>

                  <Grid container direction="row" spacing={5}>
                    <Grid item sm={6} md={6}>
                      <ItemFormInputBrand
                        merchantName={this.props.merchantName}
                        defaultBrand={this.merchantValues.get('brand', null)}
                        onChange={this.onFormChange}
                      />
                    </Grid>

                    <Grid item sm={6} md={6}>
                      <ItemFormInputCondition
                        defaultCondition={this.merchantValues.get('condition', undefined)}
                        onChange={this.onFormChange}
                        merchantName={this.props.merchantName}
                      />
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputTags
                    merchantName={this.props.merchantName}
                    defaultTags={this.merchantValues.get('tags', [])}
                    maxTags={3}
                    onChange={this.onFormChange}
                  />
                </Grid>
              </Grid>
            </Paper>

            <Paper className={`${classes.formSection} ${classes.rootMercari}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderCategory number={3} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormInputCategories
                    item={item}
                    merchantName={this.props.merchantName}
                    defaultCategoryPath={this.merchantValues.get('categoryPath', [])}
                    onChange={this.onFormChange}
                    hidden={this.props.hidden}
                  />
                </Grid>
              </Grid>{/* Category */}
            </Paper>

            <Paper className={`${classes.formSection} ${classes.rootMercari}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPackage text='Shipping' number={4} />
                <Grid item xs={12} sm={12} md={12}>
                  <ItemFormMercariShipping
                    item={item}
                    defaultDimensions={this.merchantValues.get('dimensions', [0,0,0])}
                    defaultOfferFreeShipping={this.merchantValues.get('offerFreeShipping', Store.getDefault('offerFreeShipping')) /* use boolean, not string */}
                    defaultShippingLabelType={this.merchantValues.get('shippingLabelType', '')}
                    defaultShippingClassType={this.merchantValues.get('shippingClassType', '')}
                    defaultShipsFrom={this.merchantValues.get('shipsFrom', Store.getDefault('shipsFrom', ''))}
                    defaultWeight={this.merchantValues.get('weight', { lb: 0, oz: 0 })}
                    merchantValues={this.merchantValues}
                    formRef={this.formRef}
                    onChange={this.onFormChange}
                  />
                </Grid>
              </Grid>{/* Shipping */}
            </Paper>


            <Paper className={`${classes.formSection} ${classes.rootMercari}`}>
              <Grid container direction="row">
                <ItemFormSectionHeaderPrice number={5} />
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container direction="row">
                    <InputLabel>Listing Price</InputLabel>
                  </Grid>
                  <Grid container direction="row">
                    <ItemFormInputPrice
                      defaultPrice={this.merchantValues.get('price', '')}
                      minPrice={5}
                      maxPrice={2000}
                      onChange={this.onFormChange}
                    />
                  </Grid>
                  <Grid container direction="row" style={{ marginTop: 10 }}>
                    <Typography variant="body2">
                      (min. $5 / max. $2,000)
                    </Typography>
                  </Grid>
                  <Grid container direction="row" style={{ marginTop: 20 }}>
                    <Alert severity='info'>Mercari charges a 10% sales fee</Alert>
                  </Grid>
                </Grid>
              </Grid>{/* Pricing */}
            </Paper>

              { errorFragments && errorFragments.length > 0 &&
                <FormValidationErrors>
                  {errorFragments}
                </FormValidationErrors>
              }

            <ItemFormListedBanner
              merchantName={this.props.merchantName}
              item={item}
              onList={this.onSubmitList}
              onDelist={this.onSubmitDelist}
              isListing={this.state.listing}
              isDelisting={this.state.delisting}
              isListingDisabled={
                errorFragments &&
                errorFragments.length > 0 &&
                errorFragments.find(f => !f.props || !f.props.nonBlocking)
              }
              saveButton={
                <Button disabled={this.state.saving} variant="contained" color="primary" type="submit">
                  {this.state.saving ? 'Saving..' : 'Save item' }
                </Button>
              }
            />

            {/* Data flow is as follows:
               1) mercari content script detects action type listing ID in url
               3) content script passes result data to background
               4) background back listingjoy content script
               5) LJ content script dispatches an event that we are listening for in this component */}
            { this.state.delisting ?
              <Iframe
                id="iframe-delist-mercari"
                url={`https://www.mercari.com/sell/edit/${item.merchants.mercari.listedID}/?${MercariEvent.SEND.DELIST_LISTING}=${item.merchants.mercari.listedID}`}
                width={800}
                height={800}
                display="initial"
                position="absolute"
              /> : null
            }
          </form>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(ItemFormMercari)
