import React, { Fragment } from 'react'
import FormError from '../../base/FormValidationError'

//  HACK: For now, put this aspect JSON data in the DOM
//  Later, this will be more appropriate in Redux or some shared state

const getElementIDForAspect = (aspect, merchantName) => `${merchantName}ItemAspectForCategory[${aspect.id}]`
const getElementIDForAspectSchema = (aspect, merchantName) => `${merchantName}ItemAspectForCategorySchema[${aspect.id}]`
const getElementIDForAspectChild = (aspect, merchantName) => `${merchantName}ItemAspectForCategoryChild[${aspect.id}]`
const getElementIDForAspectValueSet = (aspect, merchantName) => `${merchantName}ItemAspectForCategoryValueSet[${aspect.id}]`

const _getItemAspectEls = (elements, merchantName) => {
  const isAspect = (val) => (
    val &&
    val.indexOf('ItemAspectForCategory') !== -1 &&
    val.indexOf(merchantName) !== -1
  )
  return Array.from(elements).filter(el => isAspect(el.id) || isAspect(el.name))
}

const _castValueToNumberIfNeeded = (value) => {
  return !isNaN(+value) ? +value : value
}

const getItemAspectValues = (elements, merchantName) => {

  let aspectData = {}

  // TODO Sean find/provide a real-world example of this
  //
  // Sometimes we encounter a valueSet that has no sub-options. In this case, we
  // set that valueSet's selected option as the value for the given aspect type
  //
  // Find any valueSet elements without any corresponding value elements
  Array.from(elements).forEach(el => {
    if (!el.id || el.id.indexOf('ItemAspectForCategoryValueSet') === -1) {
      return false
    }
    const match = (/ItemAspectForCategoryValueSet\[([^\]]*)\]/).exec(el.id)
    if (!match || match.length < 2) {
      return false
    }
    const id = match[1]
    const optionName = `${merchantName}ItemAspectForCategory[${id}]`
    if (elements[optionName]) {
      return false
    }
    if (!el.value || !el.value.length) {
      return false
    }

    let value = JSON.parse(el.value)
    // Cast to a number if valid: e.g. "13" -> 13
    value = !isNaN(+value) ? +value : value
    aspectData[id] = value
  })


  // Find the remaining option elements
  const aspectEls = _getItemAspectEls(elements, merchantName)
  aspectEls.forEach(el => {
    const match = (/ItemAspectForCategory\[([^\]]*)\]/).exec(el.id || el.name)
    if (!match || match.length < 2) {
      console.log('No matching name for input: ', el, match)
      return
    }
    const id = match[1]
    const elValue = (
      el.getAttribute('data-value-id') || // SelectFreeText
      el.value // Other Aspects
    )

    if (elValue && elValue.length > 0) {

      try {
        aspectData[id] = _castValueToNumberIfNeeded(JSON.parse(elValue))
      } catch (e) {
        // Assume it is a text field
        aspectData[id] = elValue
      }
    }
  })

  return aspectData
}

const getItemAspectErrorFragments = (elements, merchantName) => {
  // This sort of works but fails for the case below.
  // const schemas = this.getItemAspectForCategory(elements)
  // const values = this.getCategorySpecificsValues(elements)

  // let fragments = []
  // for (const [aspectID, schema] of Object.entries(schemas)) {
  //   const isRequired = schema.rules && schema.rules.minValues > 0
  //   if (isRequired && values[aspectID] === undefined) {
  //     fragments.push([
  //       <a key={aspectID} href='#'>Must provide a value for {schema.display.toLowerCase()}</a>
  //     ])
  //   }
  // }

  // We need to add errors for this case:
  // We could have a "size" specific that applies to a category path, but
  // we have changed the category path and that size is no longer valid.
  //
  // Use the DOM Mui-Error to determine this
  const els = _getItemAspectEls(elements, merchantName)
  return els.map(el => {
    const attributeId = el.name.replace(/[^[]*\[/, '').replace(']', '')
    if (el.parentNode.className.indexOf('Mui-error') > -1) {
      return <FormError key={el.name}>Must provide a value for {attributeId}</FormError>
    }
    return false
  }).filter(Boolean)
}

const ItemFormInputAspectDOM = (props) => {
  const { aspect, merchantName, value, hasError } = props
  return (
    <input
      style={{ display: 'none' }}
      id={getElementIDForAspect(aspect, merchantName)}
      data-value-id={value}
      data-has-error={hasError ? 'true' : 'false'}
    />
  )
}

const ItemFormInputAspectDOMSchema = (props) => {
  const { aspect, merchantName } = props
  return (
    <input
      style={{ display: 'none' }}
      id={getElementIDForAspectSchema(aspect, merchantName)}
      data-json={JSON.stringify(aspect)}
    />
  )
}

const ItemFormInputAspectDOMChild = (props) => {
  const { aspect, merchantName } = props
  return (
    <input
      style={{ display: 'none' }}
      id={getElementIDForAspectChild(aspect, merchantName)}
      data-json={String(aspect.id)}
    />
  )
}

export {
  ItemFormInputAspectDOM,
  ItemFormInputAspectDOMSchema,
  ItemFormInputAspectDOMChild,

  getElementIDForAspect,
  getElementIDForAspectChild,
  getElementIDForAspectValueSet,

  getItemAspectValues,
  getItemAspectErrorFragments,
}
