import React, { Fragment } from 'react'

import { 
  FormControl,
  MenuItem,
} from '@material-ui/core';
import InputLabel from '../../../../base/InputLabel'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../ItemFormStyles'
import SelectWithSavedNote from '../../SelectWithSavedNote'

class ItemFormEbayReturnPolicy extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: props.defaultValue || undefined,
    }
  }

  onChange = function(e) {
    this.setState({ value: e.target.value })
    
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {   
    const { classes } = this.props; 
    return (
      <Fragment>
        <InputLabel>Return Policy</InputLabel>
        <FormControl 
          className={classes.valueControl}
          required
          fullWidth
        >
          <SelectWithSavedNote
            name="acceptReturns"
            value={this.state.value || ''}
            required={true}
            onChange={this.onChange.bind(this)}
          >
            <MenuItem value={'ReturnsAccepted'}>30 Day Returns Accepted</MenuItem>
            <MenuItem value={'ReturnsNotAccepted'}>Returns Not Accepted</MenuItem>
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormEbayReturnPolicy)