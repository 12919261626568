
/* Example data
  { 
     "post":{ 
        "catalog":{ 
           "department":"01008c10d97b4e1245005764",
           "category":"08008c10d97b4e1245005764",
           "category_features":[ 
              "3b009813d97b4e3995005764"
           ]
        },
        "colors":[ 
           "Brown"
        ],
        "inventory":{ 
           "size_quantity_revision":0,
           "size_quantities":[ 
              { 
                 "size_obj":{ 
                    "id":"11.5",
                    "short":"11.5",
                    "long":"11.5",
                    "display":"11.5",
                    "display_with_size_set":"11.5"
                 },
                 "quantity_available":1,
                 "quantity_sold":0,
                 "size_set_tags":[ 
                    "standard"
                 ]
              }
           ]
        },
        "price_amount":{ 
           "val":"550",
           "currency_code":"USD",
           "currency_symbol":"$"
        },
        "original_price_amount":{ 
           "val":"650",
           "currency_code":"USD",
           "currency_symbol":"$"
        },
        "title":"Men's shoes real leather, English mode",
        "description":"Men's shoes made by hand. The lining is also in leather, the bottom is in leather.",
        "brand":"Allen",
        "condition":"nwt", // OR "not_nwt"
        "cover_shot":{ 
           "id":"5e0a3476878dc9645e4bdfe9"
        },
        "pictures":[ 
           { 
              "id":"5e0a3476f2e15f6065712b67"
           }
        ],
        "seller_private_info":{}
     }
  }
*/
const poshmarkChromeItemFromData = (data) => {
    let item = {
      catalog: {
        department: data.categoryPath[0],
        category: data.categoryPath[1],
        category_features: data.categoryPath[2]  ? [data.categoryPath[2]] : [],
      },
      // By default, the colors provided in the data object are poshmark colors, so we don't
      // need to do any normalization/mapping other that capitalization
      colors: _colors(data),       
      inventory: {
        size_quantity_revision: 0,
        size_quantities: _sizeQuantities(data),
      },
      price_amount: {
        val: String(data.price),
        currency_code: 'USD',
        currency_symbol: '$',
      },
      original_price_amount: {
        val: String(data.originalPrice),
        currency_code: 'USD',
        currency_symbol: '$',
      },
      title: data.title,
      description: data.description,
      brand: data.brand,
      condition: _condition(data), // nwt | not_nwt

      // cover_shot and pictures fields get filled out by the extension
      // after uploading images
      // cover_shot: has ID of first image
      // pictures: array of IDs of remaining images
      cover_shot: {
        id: ""
      },
      pictures: [],
      seller_private_info: {},
    }
    if (data.costPrice !== undefined) {
      item.seller_private_info.cost_price_amount = {
        val: String(data.costPrice),
        currency_code: 'USD',
        currency_symbol: '$',
      }
    }
    if (data.otherInfo) {
      item.seller_private_info.other_info = data.otherInfo
    }
    if (data.sku) {
      item.seller_private_info.sku = data.sku
    }
    return item
}

/**
 * Assume we have only one item, so we return an array length 1
 * @return 
 * Example shape:
     [{ 
         "size_obj":{ 
            "id":"11.5",
            "short":"11.5",
            "long":"11.5",
            "display":"11.5",
            "display_with_size_set":"11.5"
         },
         "quantity_available":1,
         "quantity_sold":0,
         "size_set_tags":[ 
            "standard"
         ]
      }]
 */
const _sizeQuantities = (data) => {
  if (!data.categorySpecifics || !data.categorySpecifics.size) {
    return []
  }
  const size = data.categorySpecifics.size
  return [{
      ...size, // { size_obj, size_set_tags }
      quantity_available: 1,
      quantity_sold: 0,
  }]
}

const _condition = (data) => {
  if (!data.condition || !(+data.condition)) {
    return 'not_nwt'
  }
  // Assume we get an int or string between [1...5] representing the Mercari-style condition values
  const condition = +data.condition
  return condition === 1 ? 'nwt' : 'not_nwt' 
}

const _colors = (data) => {
  let colors = []
  if (data.colorPrimary && data.colorPrimary.length > 0) {
    colors.push(_capitalize(data.colorPrimary))
  }
  if (data.colorSecondary && data.colorSecondary.length > 0) {
    colors.push(_capitalize(data.colorSecondary))
  }
  return colors
}

const _capitalize = (str) => {
  if (!str || str.length === 0) {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export {
  poshmarkChromeItemFromData,
}