import React from 'react'

const FormValidationError = ({ selector, children }) => (
  <div key={selector}>
    <p onClick={() => console.log('clicked on ', selector)} style={{ margin: 0 }}>
      {
        children
      }
    </p>
  </div>
)

export default FormValidationError
