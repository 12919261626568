
import ebayCategoryLookup from '../../../data/categories/ebay-categories.json';
import ebayCategories from '../../../data/categories/ebay-categories-two.json';
import etsyCategoryLookup from '../../../data/categories/etsy-categories.json';
import etsyCategories from '../../../data/categories/etsy-categories-two.json';

import facebookCategoryLookup from '../../../data/categories/facebook-categories.json'

import mercariCategoryLookup from '../../../data/categories/mercari-categories.json'
import mercariCategories from '../../../data/categories/mercari-categories-two.json'

import poshmarkCategoryLookup from '../../../data/categories/poshmark-categories.json'
import poshmarkCategories from '../../../data/categories/poshmark-categories-two.json'

import categoriesMapped from '../../../data/categories/categories-mapped.json'

const CategoryHelpers = {

  categoriesForMerchant: (merchantName = '') => {
    switch (merchantName) {
        case 'ebay':
          return ebayCategories
        case 'etsy':
          return etsyCategories
        case 'facebook':
          return facebookCategoryLookup // There is only one level so the lookup table works
        case 'mercari':
          return mercariCategories
        case 'tradesy':
          // TODO
          return null
        case 'poshmark':
        case '': // default
          return poshmarkCategories
        default:
          console.error('Unhandled merchant name given: ', merchantName)
          return null
    }
  },

  isCategoryPathCompleteForMerchant: (initialCategoryPath, merchantName) => {
    const startTime = (new Date()).getTime()
    let categoriesNode = CategoryHelpers.categoriesForMerchant(merchantName)
    let categoryPath = initialCategoryPath.slice(0)

    if (!categoryPath.length) {
      return false
    }
    let currentCategoryKey = categoryPath[0]

    while (categoryPath.length > 0) {

      // Be conservative about the incompleteness assertion
      // If we can't find the category key, just return complete/true
      if (!categoriesNode[currentCategoryKey]) {
        return true
      }
      if (
        categoriesNode[currentCategoryKey] &&
        categoriesNode[currentCategoryKey].children
      ) {
        categoriesNode = categoriesNode[currentCategoryKey].children
      } else {
        categoriesNode = null
      }
      categoryPath = categoryPath.slice(1) // array with first item chopped off
      currentCategoryKey = categoryPath[0]
    }

    if (categoriesNode) {
      return false
    }

    return true
  },

  lookupTableForMerchant: (merchantName = '') => {
    switch (merchantName) {
        case 'ebay':
          return ebayCategoryLookup
        case 'etsy':
          return etsyCategoryLookup
        case 'facebook':
          return facebookCategoryLookup
        case 'mercari':
          return mercariCategoryLookup
        case 'tradesy':
          // TODO
          return null
        case 'poshmark':
        case '': // default
          return poshmarkCategoryLookup
        default:
          console.error('Unhandled merchant name given: ', merchantName)
          return null
    }
  },

  poshmarkCategoryPathForMerchantKey: (categoryKey, merchantName) => {
    for (let [poshmarkCategoryKey, merchantKeys] of Object.entries(categoriesMapped)) {
      if (merchantKeys[merchantName] === categoryKey) {
        const poshmarkCategoryValues = poshmarkCategoryLookup[poshmarkCategoryKey]
        if (!poshmarkCategoryValues || !poshmarkCategoryValues.categoryPath) {
          return []
        }
        return poshmarkCategoryValues.categoryPath
      }
    }
    return []
  },

  mappedCategoryPath: (poshmarkCategoryPath, merchantName) => {
    if (!poshmarkCategoryPath || !poshmarkCategoryPath.length || !merchantName) {
      return []
    }
    const poshmarkCategoryKey = poshmarkCategoryPath[poshmarkCategoryPath.length - 1]
    const categoriesLookup = CategoryHelpers.lookupTableForMerchant(merchantName)

    if (!categoriesMapped[poshmarkCategoryKey] || !categoriesMapped[poshmarkCategoryKey][merchantName]) {
      console.log('No mapping found for key: ', poshmarkCategoryKey, merchantName)
      return []
    }

    const merchantCategoryKey = categoriesMapped[poshmarkCategoryKey][merchantName]
    if (categoriesLookup && categoriesLookup[merchantCategoryKey] && categoriesLookup[merchantCategoryKey].categoryPath) {
       return categoriesLookup[merchantCategoryKey].categoryPath
    }

    // Load heavier lookup table
    const categories = CategoryHelpers.categoriesForMerchant(merchantName)
    if (categories && categories[merchantCategoryKey] && categories[merchantCategoryKey].categoryPath) {
      return categories[merchantCategoryKey].categoryPath
    }

    return []
  },

  autoFilledAspectsForMerchant: (merchantName, item, aspectsData) => {
    const normalizedSize = CategoryHelpers.normalizedSizeForItem(item)
    switch (merchantName) {
      case 'mercari':
        return CategoryHelpers.mercariAspectsForNormalizedSize(normalizedSize, aspectsData)
      case 'ebay':
        return CategoryHelpers.ebayAspectsForNormalizedSize(normalizedSize, aspectsData)
      case 'etsy':
        return CategoryHelpers.etsyAspectsForNormalizedSize(normalizedSize, aspectsData)
      case 'poshmark':
        return CategoryHelpers.poshmarkAspectsForNormalizedSize(normalizedSize, aspectsData)
      default:
        return {}
    }
  },

  _valueSetIDForSizeAspect: (size, aspectsData, sizeKey = null) => {
    if (
      !size ||
      !aspectsData ||
      !aspectsData[sizeKey] ||
      !aspectsData[sizeKey].valueSets
    ) {
      return null
    }
    const valueSets = aspectsData[sizeKey].valueSets
    if (!size.valueSetID) {
      // standard / regular scale
      return Object.keys(valueSets).find(key => {
        const k = key.toLowerCase()
        return (
          k === 'na_scale' ||
          k === 'standard' ||
          k === 'regular'
        )
      })
    } else {
      // If we find the normalized scale name in the list of possible valueSets, use that
      // else try to fallback to the scale name
      return Object.keys(valueSets).find(key => {
        const k = key.toLowerCase()
        return k.indexOf(size.valueSetID) > -1
      }) || size.valueSetID
    }
  },

  _valuesForSizeAspect: (size, aspectsData, sizeKey = null) => {
    if (
      !size ||
      !aspectsData ||
      !aspectsData[sizeKey] ||
      !aspectsData[sizeKey].valueSets
    ) {
      return []
    }

    const valueSets = aspectsData[sizeKey].valueSets
    const valueSetID = CategoryHelpers._valueSetIDForSizeAspect(size, aspectsData, sizeKey)
    if (
      valueSets[valueSetID] &&
      valueSets[valueSetID].values
    ) {
      return Object.values(valueSets[valueSetID].values || {})
    }
    return []
  },

  _valueMatchForSizeAspect: (size, aspectsData, sizeKey = null) => {
    const options = CategoryHelpers._valuesForSizeAspect(size, aspectsData, sizeKey)
    const match = CategoryHelpers._sizeMatchForOptions(size, options)
    if (match) {
      return match
    }
    return null
  },

  _optionsForSizeType: (sizeType = {}) => {
    if (
      sizeType &&
      sizeType.valueSets &&
      sizeType.valueSets.na_scale &&
      sizeType.valueSets.na_scale.values
    ) {
      return Object.values(sizeType.valueSets.na_scale.values || {})
    }
    return []
  },

  _sizeMatchForOptions: (size, options = []) => {
    if (!size) {
      return null
    }

    let match = options.find(option => option.display === size.search || option.display === size.display)
    if (!match) {
      match = options.find(option => option.display.indexOf(size.search) > -1)
    }

    const assignMatchForMapping = (a, b) => {
      if (!match && size.display === a) {
        match = options.find(option => option.display.indexOf(b) > -1)
      }
      if (!match && size.display === b) {
        match = options.find(option => option.display.indexOf(a) > -1)
      }
    }

    assignMatchForMapping('XXL', '2XL')

    return match
  },

  ebayAspectsForNormalizedSize: (size, aspectsData = {}) => {
    if (!size) {
      return {}
    }
    const match = CategoryHelpers._valueMatchForSizeAspect(size, aspectsData, 'Size')
    let data = {}
    if (match) {
      data['Size'] = {
        display: match.display,
        id: match.id,
      }
    } else {
      const shoeSizeKey = Object.keys(aspectsData).find(k => k.indexOf('US Shoe Size') > -1)
      if (shoeSizeKey) {
        const shoeSizeOptions = CategoryHelpers._optionsForSizeType(aspectsData[shoeSizeKey])
        const shoeSizeMatch = CategoryHelpers._sizeMatchForOptions(size, shoeSizeOptions)
        if (shoeSizeMatch) {
          data[shoeSizeKey] = {
            display: shoeSizeMatch.display,
            id: shoeSizeMatch.id,
          }
        }
      }

      const bottomsSizeKey = Object.keys(aspectsData).find(k => k.indexOf('Bottoms Size') > -1)
      if (bottomsSizeKey) {
        const bottomsSizeOptions = CategoryHelpers._optionsForSizeType(aspectsData[bottomsSizeKey])
        const bottomsSizeMatch = CategoryHelpers._sizeMatchForOptions(size, bottomsSizeOptions)
        if (bottomsSizeMatch) {
          data[bottomsSizeKey] = {
            display: bottomsSizeMatch.display,
            id: bottomsSizeMatch.id,
          }
        }
      }
    }

    const sizeType = aspectsData['Size Type']
    const sizeTypeOptions = CategoryHelpers._optionsForSizeType(sizeType)
    const regularOption = sizeTypeOptions.find(o => o.id === 'Regular')
    const sizeTypeMatch = CategoryHelpers._sizeMatchForOptions(size, sizeTypeOptions)
    if (sizeTypeMatch) {
      // If we found a size type match, use it. Otherwise default to regular if it exists
      data['Size Type'] = {
        display: sizeTypeMatch.display,
        id: sizeTypeMatch.id,
      }
    } else if (regularOption) {
      data['Size Type'] = {
        display: regularOption.display,
        id: regularOption.id,
      }
    }

    return data
  },

  etsyAspectsForNormalizedSize: (size, aspectsData = {}) => {
    if (!size) {
      return {}
    }
    const sizeValues = Object.values(aspectsData).find(o => o.display === 'Size') || {}
    const sizeKey = +sizeValues.id
    if (!sizeKey) {
      return {}
    }
    const match = CategoryHelpers._valueMatchForSizeAspect(size, aspectsData, sizeKey)
    if (match) {
      return {
        [sizeKey]: {
          display: match.display,
          id: match.id,
        }
      }
    }
    return {}
  },

  mercariAspectsForNormalizedSize: (size, aspectsData) => {
    if (!size) {
      return {}
    }
    const match = CategoryHelpers._valueMatchForSizeAspect(size, aspectsData, 'sizeId')
    if (match) {
      return {
        sizeId: {
          display: match.display,
          id: match.id,
        }
      }
    }

    const options = CategoryHelpers._valuesForSizeAspect(size, aspectsData, 'sizeId')
    if (!options.length) {
      return {}
    }

    // Shoe sizes
    if (size.shoe_size_us && size.shoe_size_euro) {
      const mercariShoeSizeDisplay = `${size.shoe_size_us} (${size.shoe_size_euro})`
      let option = (
        options.find(option => option.display === mercariShoeSizeDisplay) ||
        options.find(option => option.display.indexOf(String(size.shoe_size_us)) === 0)
      )
      if (option) {
        return { sizeId: option }
      }
    }

    if (size.display === undefined || isNaN(+size.display)) {
      return {}
    }

    // Handle dress sizes
    // Format: `{?Size_Set} {Size_Name} ({Min_Size}-{Max_Size})`
    const mercariValueSetIDForNormalizedValueSet = (valueSetID) => {
      if (!valueSetID || !valueSetID.length) {
        return ''
      }
      // Mapping is conveniently just the uppercase of the normalized scale
      return `${valueSetID[0].toUpperCase()}${valueSetID.substr(1)}`
    }

    const sizeNumeric = +size.display
    const mercariScaleName = mercariValueSetIDForNormalizedValueSet(size.valueSetID)

    for (let option of options) {

      if (!option.display || option.display.indexOf(mercariScaleName) === -1) {
        continue
      }

      const match = / \([\d-]+\)/.exec(option.display)
      if (!match || match.length < 2) {
        continue
      }

      if (match[1].indexOf('-') > -1) {
        const [ minSizeName, maxSizeName ] = match[1].split('-')
        const [ minSizeNumeric, maxSizeNumeric ] = [ +minSizeName, +maxSizeName ]
        if (
          sizeNumeric >= minSizeNumeric &&
          sizeNumeric <= maxSizeNumeric
        ) {
          return { sizeId: option }
        }
      } else {
        const mercariSizeNumeric = +match[1]
        if (sizeNumeric === mercariSizeNumeric) {
          return { sizeId: option }
        }
      }
    }

    return {}
  },

  poshmarkAspectsForNormalizedSize: (size, aspectsData) => {
    if (!size) {
      return {}
    }

    // If we have no normalized size data, try to autofill obvious ones (e.g. One Size)
    if (
      !aspectsData ||
      !aspectsData.size ||
      !aspectsData.size.valueSets
    ) {
      return {}
    }

    const valueSets = Object.values(aspectsData.size.valueSets)

    if (
      valueSets.length === 1 &&
      valueSets[0].values ||
      Object.values(valueSets[0].values).length === 1
    ) {
      // There is exactly one option/scale
      const valueSet = valueSets[0]
      if (!valueSet.id) {
        return {}
      }
      const option = Object.values(valueSets[0].values)[0]
      // { size: { size_obj: { display: string, id: string... }, size_set_tags: [ "standard" ]}}
      return {
        size: {
          size_obj: option,
          size_set_tags: [ valueSet.id ],
        }
      }
    }

    const match = CategoryHelpers._valueMatchForSizeAspect(size, aspectsData, 'size')
    if (match) {
      const valueSetID = CategoryHelpers._valueSetIDForSizeAspect(size, aspectsData, 'size')
      return {
        size: {
          size_obj: match,
          size_set_tags: [ valueSetID ],
        }
      }
    }

    return {}
  },

  // A normalized format for the size
  // Take the first merchant input aspects we see and translate them to this format
  // {
  //   display: string, (full display name)
  //   search: string, (search term.. i.e. a number?)
  //   valueSetID string, (scale name, or null if "standard / regular / no_scale")
  //  }
  normalizedSizeForItem: (item) => {
    if (!item || !item.merchants) {
      return null
    }

    for (let [ merchantName, merchantValues ] of Object.entries(item.merchants)) {

      if (
        !merchantValues ||
        !merchantValues.marketplaceSpecifics ||
        !merchantValues.marketplaceSpecifics.aspects
      ) {
        continue
      }
      const aspects = merchantValues.marketplaceSpecifics.aspects

      switch (merchantName) {
        case 'poshmark': {
          if (
            !aspects.size ||
            !aspects.size.size_obj ||
            !aspects.size.size_set_tags ||
            !aspects.size.size_set_tags.length
          ) {
            continue
          }
          const sizeSet = aspects.size.size_set_tags[0]
          const { short, display } = aspects.size.size_obj

          // Handle shoes
          const match = /US ([\d\.]+) \(Euro ([\d\.]+)\)/.exec(display)
          let normalizedSize = {
            display,
            search: short || display,
            valueSetID: sizeSet === 'standard' ? null : sizeSet
          }
          if (match && match.length > 2) {
            normalizedSize.shoe_size_us = +match[1]
            normalizedSize.shoe_size_euro = +match[2]
          }
          return normalizedSize
        }
        case 'ebay': {
          const size = aspects['Size'] || {}
          const sizeType = aspects['Size Type'] || {}
          // Normalize to Poshmark valueSets
          const normalizedScale = (sizeType) => {
            switch (sizeType.id) {
              case 'Regular':
                return null
              case 'Plus':
                return 'plus'
              case 'Petites':
                return 'petite'
              case 'Tall':
                return null
              case 'Juniors':
                return 'juniors' // TODO consider handling boys/girls/baby
              case 'Maternity':
                return 'maternity'
              default:
                return null
            }
          }
          return {
            display: size.display,
            search: size.id,
            valueSetID: normalizedScale(sizeType)
          }
        }
        // TODO need to handle the rest of the merchants
        default:
          continue
      }
    }

    return null
  },


}

export default CategoryHelpers
