import React, { Fragment } from 'react'
import {
  FormControl,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import SelectWithSavedNote from './SelectWithSavedNote'
import styles from './ItemFormStyles'
import Helpers from './ItemFormInputAspectHelpers'
import ItemFormInputAspectSection from './ItemFormInputAspectSection'
import { getElementIDForAspect } from './ItemFormInputAspectDOM'

class ItemFormInputAspectSelect extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue || null,
    }
    this.onChange = this.onChange.bind(this)
    this.childAspect = this.childAspect.bind(this)
  }

  // If we've selected an option that has a child aspect, show the child
  childAspect() {

    const { value } = this.state

    if (!value || value === '') {
      return null
    }

    try {
      const { id } = JSON.parse(value)
      const { aspect, merchantName, classes, values } = this.props
      const matchingValue = values.find(v => v.id === id)

      if (!matchingValue || !matchingValue.child) {
        return null
      }

      const childAspects = Object.values(matchingValue.child)
      return childAspects.map(childAspect => (
        <ItemFormInputAspectSection
          aspect={childAspect}
          merchantName={merchantName}
          classes={classes}
          isChild={true}
        />
      ))
    }
    catch (e) {
      return null
    }
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    })

    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {
    const {
      aspect,
      merchantName,
      values,
      valueSetID,
      classes,
      defaultValue = null,
    } = this.props

    if (!aspect || !values || !valueSetID) {
      return null
    }

    const { value } = this.state
    const hasError = aspect.constraint.required && (value === null || value === '')

    return (
      <Fragment>
        <FormControl
          className={classes.conditionControl}
          fullWidth
        >
          <SelectWithSavedNote
            name={getElementIDForAspect(aspect, merchantName)}
            defaultValue={Helpers.normalizedDefaultValueForSelectInput(defaultValue)}
            required={aspect.constraint.required}
            onChange={this.onChange}
          >
            {
              values.map(value => {
                if (value.id === undefined) {
                  // Invalid value
                  console.error(`Invalid aspect value for merchant ${merchantName}, value set ${valueSetID}, aspect ${aspect.id}`, value)
                  return null
                }
                return (
                  <MenuItem
                    key={`${value.id}${valueSetID}`}
                    value={Helpers.normalizeValueForMerchant(merchantName, value, valueSetID, defaultValue)}
                  >
                    {value.name}
                  </MenuItem>
                )
              })
            }
          </SelectWithSavedNote>
        </FormControl>
        {
          this.childAspect()
        }
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormInputAspectSelect)
