import React from 'react'

import PhotoSizeSelectActualIcon from '@material-ui/icons/PhotoSizeSelectActual'
import ItemFormSectionHeader from '../ItemFormSectionHeader'

const ItemFormSectionHeaderPhotos = ({ number, text }) => (
  <ItemFormSectionHeader
    icon={<PhotoSizeSelectActualIcon style={{ marginBottom: -6 }}/>}
    number={String(number)}
    text={text || 'Photos'}
  />
)

export default ItemFormSectionHeaderPhotos
