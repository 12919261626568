import React, { Fragment } from 'react'

import {
  Grid,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import InputLabel from '../../../base/InputLabel'
import SelectWithSavedNote from '../SelectWithSavedNote'
import { withStyles } from '@material-ui/core/styles'
import styles from '../ItemFormStyles'
import Store from '../../../../store'
import SaveDefaultBadge from '../../../base/SaveDefaultBadge'

import connectEtsy from '../../../../actions/connectEtsy'


// Docs about shipping profiles
// https://www.etsy.com/developers/documentation/reference/shippinginfo

class ItemFormEtsyShippingProfile extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      value: props.defaultValue || '',
      savedDefault: Store.getDefault('shippingProfile'),
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange = function(e) {
    this.setState({ value: e.target.value })
    if (this.props.onChange) {

      setTimeout(() => {

        // sean TODO this is the hackiest shit but currently the Autocomplete onChange callback happens BEFORE category values
        // are available in the DOM (which is how we validate the form). So add this timeout here
        // so that the values are available when we trigger our onChange callback
        this.props.onChange()
      })
    }
  }

  render() {
    const { classes, data } = this.props;
    if (!data || data.length === 0) {
      return (
        <Grid container direction="row">
          <Alert severity="warning">
            <span>Create an <a href="https://help.etsy.com/hc/en-us/articles/115014115187?flash_digest=5aac7f863fdfb49fb40c9a6dbca71bcd12382bb7&segment=selling" target="_blank">Etsy shipping profile</a> with <a href="https://help.etsy.com/hc/en-us/articles/115013946647?segment=selling" target="_blank">calculated shipping</a> to list automatically with ListingJoy</span>

            <span style={{ marginTop: 20 }}>After you've done this, <a onClick={connectEtsy} href='#'>refresh your Etsy connection</a> to load your new shipping profiles into ListingJoy</span>
          </Alert>
        </Grid>
      )
    }
    return (
      <Fragment>
        <Grid container direction="row">
          <InputLabel id="form-item-shipping-profile-label">Shipping Profile</InputLabel>
          { this.state.value !== '' &&
            <SaveDefaultBadge
              name='shippingProfile'
              value={this.state.value}
              isDefault={+this.state.savedDefault === +this.state.value}
              onSaved={savedDefault => this.setState({ savedDefault })}
            />
          }
        </Grid>
        <FormControl
          className={classes.conditionControl}
          fullWidth
        >
          <SelectWithSavedNote
            labelId="form-item-shipping-profile-label"
            id="form-item-shipping-profile"
            name="shippingProfile"
            onChange={this.onChange}
            defaultValue={this.props.defaultValue || ''}
            required={true}
          >
            {
              data.map(profile => {
                return <MenuItem key={profile.shipping_template_id} value={profile.shipping_template_id}>{profile.title}</MenuItem>
              })
            }
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

export default withStyles(styles)(ItemFormEtsyShippingProfile)
