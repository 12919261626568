
// IMPORTANT:
// IF YOU ADD TO THIS FILE YOU SHOULD ADD IT TO THE extension/src/constants.js
// FILE IN THE CHROME EXTENSION AS WELL. THE FILES SHOULD BE IDENTICAL (except for exports:
// the react app has different conventions)

// TODO - create code that syncs these files without having to
// manually copy the constants twice

const PoshmarkEvent = {
  SEND: {

    // These events require having data passed down into the iframe
    // in order to complete the action.
    // When adding a new event of this type, here is the checklist of work needed:
    // 1) add to `eventTypesNeedingListeners`` in content_frame.js
    // 2) TODO sean fill this out
    CREATE_LISTING: 'send:poshmark:create_listing',
    UPDATE_LISTING: 'send:poshmark:update_listing',
    FILL_NEW_LISTING: 'send:poshmark:fill_listing',

    DELIST_LISTING: 'send:poshmark:delist_listing',

    GET_USER_INFO: 'send:poshmark:get_user_info',
    GET_LISTINGS: 'send:poshmark:get_listings',
    GET_LISTING_DETAILS: 'send:poshmark:get_listing_details',
  },
  RECEIVE: {
    CREATE_LISTING: 'receive:poshmark:create_listing',
    DELIST_LISTING: 'receive:poshmark:delist_listing',
    UPDATE_LISTING: 'receive:poshmark:update_listing',
    FILL_NEW_LISTING: 'receive:poshmark:fill_listing',

    GET_USER_INFO: 'receive:poshmark:get_user_info',
    GET_LISTINGS: 'receive:poshmark:get_listings',
    GET_LISTING_DETAILS: 'receive:poshmark:get_listing_details',

    // The content script tells the react app when it thinks a login occurred
    LOGIN: 'receive:poshmark:login',
  },
}

const MercariEvent = {
  SEND: {

    // See note above for these equivalent Poshmark events
    CREATE_LISTING: 'send:mercari:create_listing',
    UPDATE_LISTING: 'send:mercari:update_listing',
    FILL_NEW_LISTING: 'send:mercari:fill_listing',

    DELIST_LISTING: 'send:mercari:delist_listing',

    GET_USER_INFO: 'send:mercari:get_user_info',
    GET_LISTINGS: 'send:mercari:get_listings',
    GET_LISTING_DETAILS: 'send:mercari:get_listing_details',
  },
  RECEIVE: {
    CREATE_LISTING: 'receive:mercari:create_listing',
    DELIST_LISTING: 'receive:mercari:delist_listing',
    UPDATE_LISTING: 'receive:mercari:update_listing',
    FILL_NEW_LISTING: 'receive:mercari:fill_listing',

    GET_USER_INFO: 'receive:mercari:get_user_info',
    GET_LISTINGS: 'receive:mercari:get_listings',
    GET_LISTING_DETAILS: 'receive:mercari:get_listing_details',

    // The content script tells the react app when it thinks a login occurred
    LOGIN: 'receive:mercari:login',
  },
}

const FacebookEvent = {
  SEND: {
    CREATE_LISTING: 'send:facebook:create_listing',
    UPDATE_LISTING: 'send:facebook:update_listing',
    FILL_NEW_LISTING: 'send:facebook:fill_listing',

    DELIST_LISTING: 'send:facebook:delist_listing',

    GET_USER_INFO: 'send:facebook:get_user_info',
    GET_LISTINGS: 'send:facebook:get_listings',
    GET_LISTING_DETAILS: 'send:facebook:get_listing_details',
  },
  RECEIVE: {
    CREATE_LISTING: 'receive:facebook:create_listing',
    DELIST_LISTING: 'receive:facebook:delist_listing',
    UPDATE_LISTING: 'receive:facebook:update_listing',
    FILL_NEW_LISTING: 'receive:facebook:fill_listing',

    GET_USER_INFO: 'receive:facebook:get_user_info',
    GET_LISTINGS: 'receive:facebook:get_listings',
    GET_LISTING_DETAILS: 'receive:facebook:get_listing_details',
  },
}

const TradesyEvent = {
  SEND: {
    CREATE_LISTING: 'send:tradesy:create_listing',
    UPDATE_LISTING: 'send:tradesy:update_listing',
    FILL_NEW_LISTING: 'send:tradesy:fill_listing',

    DELIST_LISTING: 'send:tradesy:delist_listing',

    GET_USER_INFO: 'send:tradesy:get_user_info',
    GET_LISTINGS: 'send:tradesy:get_listings',
    GET_LISTING_DETAILS: 'send:tradesy:get_listing_details',
  },
  RECEIVE: {
    CREATE_LISTING: 'receive:tradesy:create_listing',
    DELIST_LISTING: 'receive:tradesy:delist_listing',
    UPDATE_LISTING: 'receive:tradesy:update_listing',
    FILL_NEW_LISTING: 'receive:tradesy:fill_listing',

    GET_USER_INFO: 'receive:tradesy:get_user_info',
    GET_LISTINGS: 'receive:tradesy:get_listings',
    GET_LISTING_DETAILS: 'receive:tradesy:get_listing_details',
  },
}

const VendooEvent = {
  SEND: {
    GET_LISTINGS: 'send:vendoo:get_listings',
  },
  RECEIVE: {
    GET_LISTINGS: 'receive:vendoo:get_listings',
  },
}

const ListingJoyExternalEvent = {
// Talking between ListingJoy.com and external 3rd party websites with existing tabs
  SEND: {
    CHECK_INSTALL: 'send:listingjoy_external:check_install',
    DID_UPDATE_LISTING: 'send:listingjoy_external:did_update_listing', // currently just used for after "listing"
    RELOAD: 'send:listingjoy_external:reload',
    SETUP_POSHMARK: 'send:listingjoy_external:setup_poshmark', // ensure we are in the "all" marketplace
    LOGIN: 'send:listingjoy_external:login',

    // Talking between ListingJoy.com and extension
    // Used to perform actions through newly created tabs
    MERCARI: {
      CREATE_LISTING_EXTERNAL: 'send:mercari:create_listing_external',
      DELIST_LISTING_EXTERNAL: 'send:mercari:delist_listing_external',
      GET_LISTINGS_EXTERNAL: 'send:mercari:get_listings_external',
      GET_LISTING_DETAILS_EXTERNAL: 'send:mercari:get_listing_details_external',
    },
  },
}

export {
  FacebookEvent,
  PoshmarkEvent,
  MercariEvent,
  TradesyEvent,
  VendooEvent,
  ListingJoyExternalEvent,
}
