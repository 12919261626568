import React, { Fragment } from 'react'

import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import InputLabel from '../../../base/InputLabel'

import ItemFormInputPrice from '../ItemFormInputPrice'

import ItemFormEbayDuration from './pricing/ItemFormEbayDuration'
import ItemFormEbayPricingFormat from './pricing/ItemFormEbayPricingFormat'

class ItemFormEbayPricing extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      pricingFormat: this.props.defaultPricingFormat || 'FixedPriceItem',
      allowBestOffer: this.props.defaultAllowBestOffer || false,
    }

    this.onChangePricingFormat = this.onChangePricingFormat.bind(this)
    this.onChangeAllowBestOffer = this.onChangeAllowBestOffer.bind(this)
  }

  onChangePricingFormat = function(e) {
    this.setState({ pricingFormat: e.target.value })
  }

  onChangeAllowBestOffer = function(e) {
    this.setState({ allowBestOffer: e.target.checked })
  }

  render() {

    const isAuction = this.state.pricingFormat === 'Chinese'

    return (
      <Fragment>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <ItemFormEbayPricingFormat
              onChange={this.onChangePricingFormat}
              defaultValue={this.state.pricingFormat}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ItemFormEbayDuration
              format={this.state.pricingFormat}
            />
          </Grid>
          <Grid item sm={12} md={12} style={{ marginBottom: 30 }}>
            <Alert severity="info">
              <span>Listings renew automatically once per calendar month. eBay charges an insertion fee when you list your item for the first time, and each time it renews. Fee amounts are based on the terms in effect when the listing goes live and when it renews. <a target="_blank" href="https://www.ebay.com/help/selling/fees-credits-invoices/selling-fees?id=4364">Read more</a>.</span>
            </Alert>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          {
            isAuction &&
            <Grid item xs={12} sm={6} md={6}>
              <InputLabel>Starting Price</InputLabel>
              <ItemFormInputPrice
                name="startingPrice"
                defaultPrice={0}
                onChange={() => {}}
              />
            </Grid>
          }
          <Grid item xs={12} sm={6} md={6}>
            <InputLabel optional={isAuction}>Buy It Now Price</InputLabel>
            <ItemFormInputPrice
              name="buyItNowPrice"
              defaultPrice={this.props.defaultPrice}
              onChange={() => {}}
              required={!isAuction}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3}>
          <Grid item sm={12} md={12} style={{ marginTop: 20, marginBottom: 20 }}>
            <FormControlLabel
              control={<Checkbox id='allowBestOffer' value='allowBestOffer' checked={this.state.allowBestOffer} onChange={this.onChangeAllowBestOffer} />}
              label={<InputLabel optional style={{ marginTop: 10 }}>Allow Best Offer</InputLabel>}
            />
          </Grid>
        </Grid>
        {
          this.state.allowBestOffer &&
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <InputLabel optional>Accept offers of at least</InputLabel>
              <ItemFormInputPrice
                name="offerMinimumAccept"
                defaultPrice={this.props.defaultOfferMinimumAccept}
                onChange={() => {}}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <InputLabel optional>Decline offers lower than </InputLabel>
              <ItemFormInputPrice
                name="offerMaximumDecline"
                defaultPrice={this.props.defaultOfferMaximumDecline}
                onChange={() => {}}
              />
            </Grid>
          </Grid>
        }

      </Fragment>
    )
  }
}

export default ItemFormEbayPricing
