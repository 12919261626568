import React, { Fragment } from 'react'

import {
  TextField,
  FormHelperText,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    root: {
        background: 'rgb(245, 245, 245)',
    },
})

class TextFieldWithSavedNote extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      text: String(props.defaultValue) || '',
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange = function(e) {
    this.setState({ text: String(e.target.value) })
    
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  render() { 
    const { classes, multiline, inputProps, InputProps, defaultValue, loading } = this.props  
    return (
      <Fragment>
        <TextField
          {...this.props}
          loading={String(this.props.loading)}
          error={loading ? false : this.props.error}
          classes={classes}
          onChange={this.onChange}
          InputProps={{
            style: (multiline ? {              
              paddingTop: 10,
              paddingLeft: 5,
              borderRadius: 3,
              background: 'rgb(245, 245, 245)',
            } : {}),
            ...(InputProps || {}),
          }}
          inputProps={{
            style: {
              paddingTop: 10,
              paddingLeft: 5,
              borderRadius: 3,
              background: 'rgb(245, 245, 245)',
            },
            className: loading ? 'shine' : '',
            disabled: loading,
            ...(inputProps || {}),
          }}
        />
        { false && String(defaultValue) !== this.state.text && 
          <FormHelperText 
            style={{ 

            }}
          >Not Saved</FormHelperText> 
        }
      </Fragment>
    )
  }

}

export default withStyles(styles)(TextFieldWithSavedNote)