import React, { Fragment } from 'react'

import {
  Grid,
} from '@material-ui/core'
import TextFieldWithSavedNote from './TextFieldWithSavedNote'
import InputLabel from '../../base/InputLabel'
import InputAdornment from '../../base/InputAdornment'
import { EE, Events } from '../../../message'

class ItemFormInputDimensions extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      d1: props.defaultValues[0],
      d2: props.defaultValues[1],
      d3: props.defaultValues[2],
    }

    this.onChangeD1 = this.onChangeD1.bind(this)
    this.onChangeD2 = this.onChangeD2.bind(this)
    this.onChangeD3 = this.onChangeD3.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onReceivedUpdate = this.onReceivedUpdate.bind(this)

    this.merchantValues = this.props.merchantValues
  }

  componentDidMount() {
    EE.addListener(Events.ItemForm.DID_NAVIGATE_TO_MERCHANT_FORM, this.onReceivedUpdate)

  }

  componentWillUnmount() {
    EE.removeListener(Events.ItemForm.DID_NAVIGATE_TO_MERCHANT_FORM, this.onReceivedUpdate)
  }

  onReceivedUpdate(data) {

    if (!data || !data.dimensions || !data.dimensions.length || data.dimensions.filter(d => !!+d).length === 0) {
      return
    }

    const { d1, d2, d3 } = this.state
    if (!+d1 && !+d2 && !+d3) {
      this._updateWithDimensions(data.dimensions)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(`Dimensions component updated for ${this.props.merchantName}, new dimensions`, this.merchantValues && this.merchantValues.get('dimensions', [0,0,0]))
    // Since we opted to use uncontrolled components, we have to manually detect whether we want to update
    // our state value here in response to a parent / other merchant form's change
    const { d1, d2, d3 } = this.state
    if (!+d1 && !+d2 && !+d3 && this.merchantValues) {
      const dimensions = this.merchantValues.get('dimensions', [0,0,0])
      if (dimensions.filter(d => +d === 0) < 3) {
        // Update the field if we found a better possible value
        this._updateWithDimensions(dimensions)
      }
    }
  }

  _updateWithDimensions(dimensions) {
    const updatedState = {
      d1: dimensions[0],
      d2: dimensions[1],
      d3: dimensions[2],
    }
    this.setState(updatedState)
    try {
      const merchantName = this.props.merchantName || ''
      document.querySelector(`input[name=${merchantName}d1]`).value = dimensions[0]
      document.querySelector(`input[name=${merchantName}d2]`).value = dimensions[1]
      document.querySelector(`input[name=${merchantName}d3]`).value = dimensions[2]
      this.onChange(updatedState)
    } catch (e) {
      console.error('Error updating dimensions: ', e)
    }
  }

  onChangeD1(e) {
    this.setState({
      d1: +e.target.value,
    })
    this.onChange()
  }
  onChangeD2(e) {
    this.setState({
      d2: +e.target.value,
    })
    this.onChange()
  }
  onChangeD3(e) {
    this.setState({
      d3: +e.target.value,
    })
    this.onChange()
  }

  onChange(e) {
    if (this.props.onChange) {
      this.props.onChange(this.state)
    }
  }

  render() {
    const { defaultValues, loading, merchantName = '', optional = false } = this.props
    const selectOnFocus = (event) => event.target.select()
    return (
      <Fragment>
        <InputLabel optional={optional}>Package Dimensions</InputLabel>
        <Grid container direction="row" spacing={3} style={{ marginBottom: 20 }}>
          <Grid item sm={4} md={4}>
            <TextFieldWithSavedNote 
              required={!optional}
              name={`${merchantName}d1`}
              defaultValue={defaultValues[0]} 
              InputProps={{
                endAdornment: <InputAdornment position="end">in</InputAdornment>,
                type: 'number',
              }}
              inputProps={{
                min: 0,
                onFocus: selectOnFocus,
              }}
              onChange={this.onChangeD1}
              error={!optional && this.state.d1 === 0}
              loading={loading}
            />
          </Grid>
          <Grid item sm={4} md={4}>
            <TextFieldWithSavedNote 
              required={!optional}
              name={`${merchantName}d2`}
              defaultValue={defaultValues[1]}
              InputProps={{
                endAdornment: <InputAdornment position="end">in</InputAdornment>,
                type: 'number',
              }}
              inputProps={{
                min: 0,
                onFocus: selectOnFocus,
              }}
              onChange={this.onChangeD2}
              error={!optional && this.state.d2 === 0}
              loading={loading}
            />
          </Grid>
          <Grid item sm={4} md={4}>
            <TextFieldWithSavedNote 
              required={!optional}
              name={`${merchantName}d3`}
              defaultValue={defaultValues[2]}
              InputProps={{
                endAdornment: <InputAdornment position="end">in</InputAdornment>,
                type: 'number',
              }}
              inputProps={{
                min: 0,
                onFocus: selectOnFocus,
              }}
              onChange={this.onChangeD3}
              error={!optional && this.state.d3 === 0}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default ItemFormInputDimensions
