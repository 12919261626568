
export default {

  normalizedDefaultValueForSelectInput: (value) => {

    if (value === '' || value === null || value === undefined) {
      return ''
    }

    // Select
    if (value.id && value.label) {
      return JSON.stringify({
        label: value.label,
        id: value.id,
      })
    }

    // SelectFreeText
    // if (value.value && value.label) {
    //   return JSON.stringify({
    //     value: value.value,
    //     label: value.label,
    //   })
    // }

    // Other
    return JSON.stringify(value)
  },

  normalizeValueForMerchant: (merchantName, option, valueSetID, defaultValue) => {

    switch (merchantName) {
      case 'poshmark':
        // If our default value for poshmark has the long/short ids,
        // ensure our normalized value has matching size_obj keys
        if (
          defaultValue &&
          defaultValue.long !== undefined &&
          defaultValue.short !== undefined
        ) {
          return JSON.stringify({
            size_obj: {
              display: option.display,
              display_with_size_set: option.display_with_size_set,
              id: option.id,
              short: option.short,
              long: option.long,
            },
            size_set_tags: [ valueSetID ],
          })
        } else {
          // Limit to basic keys (poshmark API)
          return JSON.stringify({
            size_obj: {
              display: option.display,
              display_with_size_set: option.display_with_size_set,
              id: option.id,
            },
            size_set_tags: [ valueSetID ],
          })
        }
      default:
        return JSON.stringify({
          label: option.name,
          id: option.id,
        })
    }
  }
}
