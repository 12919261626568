import React from 'react'
import store from 'store'

const Keys = {
  userInfo: 'user_info',
  userDetailsMercari: 'user_details_mercari',
  userDetailsPoshmark: 'user_details_poshmark',
  userDetailsFacebook: 'user_details_facebook',
  userDetailsTradesy: 'user_details_tradesy',
  importedItemIDs: 'imported_item_ids',
  lastQuickImportRunTimestamp: 'last_quick_import_run',
  lastSortType: 'last_sort_type',
  lastFilterSearch: 'last_filter_search',
}

const Events = {
  updateUserDetailsMercari: 'update:mercari:user_details',
  updateUserDetailsPoshmark: 'update:poshmark:user_details',
  updateUserDetailsFacebook: 'update:facebook:user_details',
  updateUserDetailsTradesy: 'update:tradesy:user_details',
}

// In memory credentials store
let credentials = {}
let imageFileObjects = {}
let verticalScrollPosition = 0
let gridRef = React.createRef()
let itemIndex = { scrollIndex: 0, rowIndex: 0 }

const StoreHelper = {

  get(key) {
    return store.get(key)
  },
  set(key, val) {
    return store.set(key, val)
  },

  getLastSortType() {
    return store.get(Keys.lastSortType)
  },

  setLastSortType(value) {
    store.set(Keys.lastSortType, value)
  },

  getLastFilterSearch() {
    return store.get(Keys.lastFilterSearch)
  },

  setLastFilterSearch(value) {
    store.set(Keys.lastFilterSearch, value)
  }, 

  getLastQuickImportRunTimestamp() {
    return store.get(Keys.lastQuickImportRunTimestamp)
  },

  setLastQuickImportRunTimestamp(value) {
    store.set(Keys.lastQuickImportRunTimestamp, value)
  },

  getUserInfo() {
    return store.get(Keys.userInfo) || null
  },

  getDefault(key, fallback = null) {
    const userInfo = StoreHelper.getUserInfo()
    if (
      !userInfo ||
      !userInfo.defaults ||
      userInfo.defaults[key] === undefined
    ) {
      return fallback
    }
    return userInfo.defaults[key]
  },

  setDefault(key, value) {
    let userInfo = StoreHelper.getUserInfo() || {}
    if (!userInfo.defaults) {
      userInfo.defaults = {}
    }
    userInfo.defaults[key] = value
    StoreHelper.setUserInfo(userInfo)
  },

  setUserInfo(data) {
    store.set(Keys.userInfo, data)
  },


  _importedItemIDKey(merchantName) {
    return `${merchantName}${Keys.importedItemIDs}`
  },

  getImportedItemIDs(merchantName) {
    return store.get(StoreHelper._importedItemIDKey(merchantName)) || []
  },

  addToImportedItemIDs(merchantName, ids = []) {
    if (ids.length === 0) {
      return
    }
    let existingIDsMap = StoreHelper.getImportedItemIDs(merchantName)
      .reduce((memo, id) => { memo[id] = true; return memo; }, {})

    ids.forEach(id => { existingIDsMap[id] = true })

    const resultIDs = Object.keys(existingIDsMap)

    store.set(StoreHelper._importedItemIDKey(merchantName), resultIDs)
  },

  // This is hacky
  addToImportedItemIDsAllMerchants(items = []) {
    if (items.length > 0) {
      return
    }

    // TODO dont hardcode the merchant names but instead have a helper to build this for us
    // we are including only merchants that we support importing for
    let itemsForMerchant = { etsy: [], mercari: [], poshmark: [] }
    items.forEach(item => {
      if (!item.merchants) {
        return
      }
      if (item.merchants.etsy && item.merchants.etsy.listingID) {
        itemsForMerchant.etsy.push(item.merchants.etsy.listingID)
      }
      if (item.merchants.mercari && item.merchants.mercari.listingID) {
        itemsForMerchant.mercari.push(item.merchants.mercari.listingID)
      }
      if (item.merchants.poshmark && item.merchants.poshmark.listingID) {
        itemsForMerchant.poshmark.push(item.merchants.poshmark.listingID)
      }
    })
    StoreHelper.addToImportedItemIDs('etsy', itemsForMerchant.etsy)
    StoreHelper.addToImportedItemIDs('mercari', itemsForMerchant.mercari)
    StoreHelper.addToImportedItemIDs('poshmark', itemsForMerchant.poshmark)    
  },

  getUserDetails(merchantName) {
    switch (merchantName.toLowerCase()) {
      case 'poshmark':
        return StoreHelper.getUserDetailsPoshmark()
      case 'mercari':
        return StoreHelper.getUserDetailsMercari()
      case 'facebook':
        return StoreHelper.getUserDetailsFacebook()
      case 'tradesy':
        return StoreHelper.getUserDetailsTradesy()
      case 'etsy':
      case 'ebay':
        return {}
      default:
        console.error('Unhandled merchantName', merchantName)
        return
    }
  },


  setUserDetails(merchantName, data) {
    switch (merchantName.toLowerCase()) {
      case 'poshmark':
        return StoreHelper.setUserDetailsPoshmark(data)
      case 'mercari':
        return StoreHelper.setUserDetailsMercari(data)
      case 'facebook':
        return StoreHelper.setUserDetailsFacebook(data)
      case 'tradesy':
        return StoreHelper.setUserDetailsTradesy(data)
      case 'etsy':
      case 'ebay':
      case 'facebook':
        return
      default:
        console.error('Unhandled merchantName')
        return
    }    
  },

  getUserDetailsPoshmark: () => {
    return store.get(Keys.userDetailsPoshmark)
  },

  setUserDetailsPoshmark: (data) => {
    store.set(Keys.userDetailsPoshmark, data)
    StoreHelper._emitEvent(Events.updateUserDetailsPoshmark, data)
  },

  getUserDetailsMercari: () => {
    return store.get(Keys.userDetailsMercari)
  },
  
  setUserDetailsMercari: (data) => {
    store.set(Keys.userDetailsMercari, data)
    StoreHelper._emitEvent(Events.updateUserDetailsMercari, data)
  },

  getUserDetailsFacebook: () => {
    return store.get(Keys.userDetailsFacebook)
  },
  
  setUserDetailsFacebook: (data) => {
    store.set(Keys.userDetailsFacebook, data)
    StoreHelper._emitEvent(Events.updateUserDetailsFacebook, data)
  },

  getUserDetailsTradesy: () => {
    return store.get(Keys.userDetailsTradesy)
  },
  
  setUserDetailsTradesy: (data) => {
    store.set(Keys.userDetailsTradesy, data)
    StoreHelper._emitEvent(Events.updateUserDetailsTradesy, data)
  },

  // In memory
  getCredentials() {
    return credentials
  },

  // In memory
  setCredentials(email, password) {
    credentials = {
      email,
      password,
    }
  },

  // Used to remember the homepage vertical scroll after navigating to the detail view
  // In memory
  getVerticalScrollPosition() {
    return verticalScrollPosition
  },
  // In memory
  setVerticalScrollPosition(position) {
    verticalScrollPosition = position
  },

  // Used to pass image base64 data to chrome extension
  // In memory
  getImageFileObjects() {
    return imageFileObjects
  },
  // In memory
  setImageFileObjects(objects) {
    imageFileObjects = objects
  },

  getGridRef() {
    return gridRef
  },
  // In memory
  setGridRef(ref) {
    gridRef = ref
  },

  getItemIndex() {
    return itemIndex
  },

  setItemIndex(index) {
    itemIndex = Object.assign({}, index)
  },

  _emitEvent(eventName, data) {
    var event = new CustomEvent(eventName, {
      detail: {
        data,
      },
    })
    document.dispatchEvent(event)    
  },
}

export default StoreHelper