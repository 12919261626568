import React, { Fragment } from 'react'
import colors from '../../styles/colors'
import {
  Button,
  Grid,
  Popover,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import ButtonLoader from '../base/ButtonLoader'

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  formSection: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2, 3),
    boxShadow: `0px 1px 6px ${colors.listingjoyShadow}`,
    background: 'rgb(255, 255, 255)',
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.listingjoy,
    borderImage: 'initial',
  },
  formSectionSuccess: {
    //borderColor: colors.success,
    //boxShadow: `0px 1px 6px ${colors.successShadow}`,
  },
}))

const ItemFormListedBanner = ({
  item,
  merchantName,

  onList = () => {},
  onDelist = () => {},
  onUpdate = null,

  isListing = false,
  isDelisting = false,
  isUpdating = false,

  isListingDisabled = false,

  saveButton = null,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  if (!merchantName || !item) {
    return null
  }

  // For popover
  const handlePopoverOpen = event => {
    console.log('handle popover open', event.currentTarget)
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    console.log('handle popover close')
    setAnchorEl(null)
  }

  let isListed = false
  let merchantData = {}

  if (item.merchants) {
    merchantData = item.merchants[merchantName] || {}
    isListed = merchantData.listed || false
  }

  const isEtsyDraftListing = merchantName === 'etsy' && merchantData.draft
  const buttonStyle = {
    margin: 10,
  }

  const showSaveButton = !isListed && saveButton
  const showDelistButton = (
    isListed &&
    merchantName !== 'facebook' &&
    (merchantName !== 'ebay' ||
      (
        merchantName === 'ebay' && // redundant but more readable
        item.merchants.ebay &&
        item.merchants.ebay.offerID
      )
    )
  )

  const editListingButton = (url) => {
    return (
      <Button onClick={() => {
        window.open(url, '_blank')
      }}
        color="secondary"
        variant="outlined"
        style={buttonStyle}
      >
        Edit Listing
      </Button>
    )
  }
  return (
    <div className={`${classes.formSection} ${isListed ? classes.formSectionSuccess : ''}`}>
      {
        isListed &&
        <Grid container>
          <Typography variant="h2" style={{
            textAlign: 'center',
            width: '100%',
            margin: '10px 10px 20px',
          }}>
            { '🥳 Your Item is Listed!' }
          </Typography>
        </Grid>
      }
      <Grid
        container
        justify={showSaveButton? 'space-between' : 'center'}
        alignItems="center"
      >
        { showSaveButton &&
          <Grid item>
            {saveButton}
          </Grid>
        }
        <Grid item>
          { isListed &&
            <Button onClick={() => {
              let url = null
              if (merchantData.listingURL) {
                url = merchantData.listingURL
              } else if (merchantName === 'poshmark' && merchantData.listedID) {
                url = `https://poshmark.com/listing/${merchantData.listedID}`
              } else if (merchantName === 'mercari' && merchantData.listedID) {
                url = `https://www.mercari.com/us/item/${merchantData.listedID}`
              } else if (merchantName === 'ebay' && merchantData.listedID) {
                url = `https://www.ebay.com/itm/${merchantData.listedID}`
              } else if (merchantName === 'etsy' && merchantData.listedID) {
                url = `https://www.etsy.com/listing/${merchantData.listedID}`
              } else if (merchantName === 'facebook' && merchantData.listedID) {
                url = `https://www.facebook.com/marketplace/item/${merchantData.listedID}`
              }
              window.open(url, '_blank')
            }}
              color="secondary"
              variant="outlined"
              style={buttonStyle}
            >
              View Listing
            </Button>
          }
          { isEtsyDraftListing && isListed && editListingButton(merchantData.draftListingURL) }
          { merchantName === 'mercari' && isListed && merchantData.listedID && editListingButton(`https://www.mercari.com/sell/edit/${merchantData.listedID}/`) }
          { merchantName === 'poshmark' && isListed && merchantData.listedID && editListingButton(`https://poshmark.com/edit-listing/${merchantData.listedID}`) }
        </Grid>
        <Grid item>
          { onUpdate && isListed &&
            <Button
              onClick={onUpdate}
              color="secondary"
              variant="outlined"
              style={buttonStyle}
              disabled={isUpdating}
            >
              {
                isUpdating ?
                <Fragment>Updating.. <ButtonLoader /></Fragment> :
                `Update Listing`
              }
            </Button>
          }
        </Grid>
        <Grid item>
          { showDelistButton &&
            <Button
              onClick={onDelist}
              color="secondary"
              variant="outlined"
              style={buttonStyle}
              disabled={isDelisting}
            >
              {
                isDelisting ?
                <Fragment>Delisting.. <ButtonLoader /></Fragment> :
                `Delist from ${merchantName}`
              }
            </Button>
          }
          {
            isListed && merchantName === 'facebook' &&
            <Button onClick={() => {
              window.open('https://www.facebook.com/marketplace/selling/', '_blank')
            }}
              color="secondary"
              variant="outlined"
              style={buttonStyle}
            >
              Manage Listings
            </Button>
          }
          { !isListed &&
            <div>
              <span
                aria-describedby={anchorEl ? 'list-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={isListingDisabled ? handlePopoverOpen : (() => {})}
                onMouseLeave={handlePopoverClose}
              >
              <Button
                onClick={(e) => {
                  if (isListingDisabled || isListing) {
                    return
                  }
                  onList(e)
                }}
                color="primary"
                variant="outlined"
                style={buttonStyle}
                disabled={Boolean(isListingDisabled || isListing)}
              >
                {
                  isListing ?
                  <Fragment>Listing.. <ButtonLoader /></Fragment> :
                  `List on ${merchantName}`
                }
              </Button>
              </span>
              <Popover
                id={anchorEl ? 'list-popover' : undefined}
                open={Boolean(anchorEl)}
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Alert severity='error' style={{ margin: -8 }}>Fix form validation issues before listing</Alert>
              </Popover>
            </div>
          }
          {
            (isListing || isDelisting) &&
            <div style={{ textAlign: 'center'}}>
              <Typography variant='body2'>
                Hang tight, it can take a few seconds
              </Typography>
            </div>
          }
        </Grid>
      </Grid>
    </div>
  )
}

export default ItemFormListedBanner
