import React from 'react'

import { 
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import styles from './ItemFormStyles'

class ItemFormInputSwitch extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      checked: props.defaultChecked || false,
    }

    this.onSwitch = this.onSwitch.bind(this)
  }

  onSwitch = function(e) {
    this.setState({ checked: e.target.checked })
    this.props.onChange(e)
  }

  render() {   
    const { name, label } = this.props; 
    return (
      <FormControlLabel 
        control={
          <Switch 
            checked={this.state.checked}
            onChange={this.onSwitch}
            name={name}
          />
        } 
        label={label}
      />
    )
  }

}

export default withStyles(styles)(ItemFormInputSwitch)