import firebase from 'firebase/app'
import ReactGA from 'react-ga'
import queryString from 'query-string'
import config from '../config'

const listEbay = (data) => {

  ReactGA.event({
    category: 'List',
    action: 'List Ebay',
  })

  return new Promise(async (resolve, reject) => {
    const currentUser = firebase.auth().currentUser
    if (!currentUser) {
      return reject(new Error('Not logged in'))
    }

    const token = await currentUser.getIdToken(true)
    try {
      const queryParams = queryString.stringify({ token })

      console.log('About to list on ebay with data: ', data)
      
      const result = await fetch(`${config.cloudFunctionBaseURL}/ebayList?${queryParams}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
      })
      const json = await result.json()
      console.log('Got json response: ', json)
      resolve(json)
    }
    catch (e) {
      return reject(e)
    }
  })
}

export default listEbay
