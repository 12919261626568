import firebase from 'firebase/app'
import ReactGA from 'react-ga'
import queryString from 'query-string'
import config from '../config'

const listEtsy = (data) => {

  ReactGA.event({
    category: 'List',
    action: 'List Etsy',
  })

  return new Promise(async (resolve, reject) => {
    const currentUser = firebase.auth().currentUser
    if (!currentUser) {
      return reject(new Error('Not logged in'))
    }

    try {
      const userDoc = await firebase.firestore()
        .collection('users')
        .doc(currentUser.uid)
        .get()

      if (!userDoc.exists || !userDoc.data().etsyToken) {
        return reject(new Error('User not found'))
      }

      const etsyToken = userDoc.data().etsyToken
      const queryParams = queryString.stringify({ etsyToken })
      const result = await fetch(`${config.cloudFunctionBaseURL}/etsyList?${queryParams}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
      })
      const json = await result.json()
      console.log('Got json response: ', json)
      resolve(json)
    }
    catch (e) {
      return reject(e)
    }
  })
}

export default listEtsy
