import React, { Fragment } from 'react';

// https://react-select.com/creatable
import AsyncCreatableSelect from 'react-select/async-creatable';
import {
  FormHelperText,
} from '@material-ui/core';

import fetchBrands from '../../../actions/fetchBrands'
import InputLabel from '../../base/InputLabel'

const customStyles = (hasError) => ({
  control: (styles) => ({
    ...styles,
    ...(hasError && { borderColor: 'red' }),

    borderRadius: 1,
    border: 'none',
    background: hasError ? '#FFEAE7' : 'rgb(245, 245, 245)',
    borderBottomColor: hasError ? '#e53935' : 'rgba(0, 0, 0, 0.42)',
    borderBottomWidth: hasError ? 2 : 1,
    borderBottomStyle: 'solid',
    cursor: 'pointer',
    transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transitionProperty: 'border-bottom-color',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDelay: '0ms',
    marginBottom: 20,
  }),
  option: (styles) => ({
    ...styles,
  }),
  input: (styles) => ({
    ...styles,
  }),
  placeholder: (styles) => ({
    ...styles,
  }),
  singleValue: (styles) => ({
    ...styles,
  }),
})

class ItemFormInputBrand extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      initialLabel: props.defaultBrand || null,
      label: props.defaultBrand || null,
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange(selectedOption) {
    console.log('Selected brand: ', selectedOption)

    this.setState({
      label: selectedOption ? selectedOption.label : null
    })
    if (this.props.onChange) {
      this.props.onChange(selectedOption)
    }
  }

  render() {
    const { merchantName = '', defaultBrand, loading } = this.props
    const hasError = !loading && (this.state.label === null || this.state.label.length === 0)

    // If defaultBrand is empty string, defaultValue is null
    const defaultValue = defaultBrand && defaultBrand.length > 0 ? { label: defaultBrand } : null
    return (
      <Fragment>
        <InputLabel>Brand</InputLabel>
        <AsyncCreatableSelect
          isClearable
          cacheOptions
          defaultOptions
          placeholder=''
          onChange={this.onChange}
          loadOptions={fetchBrands}
          defaultValue={defaultValue}
          inputId={`${merchantName}brand`}
          styles={customStyles(hasError)}
          required
          formatCreateLabel={(val) => `Use custom brand "${val}"`}
          inputProps={{
            /* not working */
            //className: 'shine',
          }}
        />
        { false /* sean hiding this for now */ && this.state.initialLabel !== this.state.label && <FormHelperText>Not Saved</FormHelperText> }
      </Fragment>
    )
  }
}

export default ItemFormInputBrand

// import brands from '../../data/brands-short'

// import {
//   TextField,
// } from '@material-ui/core';

// import AutocompleteWithSavedNote from './AutocompleteWithSavedNote'
// const ItemFormInputBrand = ({ item }) => (
//     <AutocompleteWithSavedNote
//       id="brand"
//       autoComplete={false}
//       freeSolo={true/* allow arbitrary value*/ }
//       options={brands}
//       getOptionLabel={option => option.label}
//       renderInput={params => (
//         <TextField {...params} label="Brand" variant="outlined" fullWidth />
//       )}
//       defaultValue={item && item.brand ? { label: item.brand } : null}
//     />
// )
