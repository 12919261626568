import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

import Store from '../store'

const saveUserDefault = async (name, value = null) => {

  if (!name) {
    console.error('No name given in saveUserDefault')
    return false
  }

  ReactGA.event({
    category: 'Save default',
    action: 'Save user default',
  })

  Store.setDefault(name, value)

  return new Promise((resolve, reject) => {
    Firebase.firestore()
      .collection('users')
      .doc(Firebase.auth().getUid())
      .set({ 
        defaults: {
          [name]: value,
        }
      },
      { merge: true })
      .then(() => {
        resolve(true)
      })
      .catch(e => {
        reject(e)
      })
  })
}

export default saveUserDefault
