import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'

import { 
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

import logOut from '../../actions/logOut'

const Profile = ({auth}) => (
  <Route render={({history}) => (
    <Fragment>
      <Grid container>
        <div style={{ marginBottom: 30 }}>
          <Typography variant="h1">
            Profile
          </Typography>
        </div>
      </Grid>
      <Grid container>
        <Grid item>
          { auth.photoURL && 
            <img src={auth.photoURL} alt={auth.displayName} width="100" height="100" />
          }
          <p><strong>{auth.displayName}</strong></p>
          <p>Email: {auth.email}</p>

          <div style={{ marginTop: 150 }}>
            <Button 
              variant="contained" 
              color="secondary" 
              onClick={() => logOut().then( () => history.push(`/`)) }
            >Log out</Button>
          </div>
          
        </Grid>
      </Grid>
    </Fragment>
  )} />
)

export default Profile
