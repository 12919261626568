import React, { Fragment } from 'react'
import {
  HeaderLink,
} from '../../styles/links'

import {
  Grid,
  Typography,
} from '@material-ui/core';

import NavPills from '../components/NavPills'
import ItemListFilterBar from '../posts/ItemListFilterBar'
import Button from '../components/Button'
import AddIcon from '@material-ui/icons/Add'

import DashboardIcon from '@material-ui/icons/Dashboard'
import StorefrontIcon from '@material-ui/icons/Storefront'
import PersonIcon from '@material-ui/icons/Person'
import ListingJoyIconWithText from '../icons/ListingJoyIconWithText'

import {
  Link
} from 'react-router-dom'

import { EE, Events } from '../../message'

const Nav = ({ windowWidth, itemCountString, style = null }) => {

  const pathname = window.location.pathname
  const isGrid = pathname === '/'
  const isOnboarding = pathname.indexOf('/onboarding') === 0
  const isAuth = (
    pathname.indexOf('/login') === 0 ||
    pathname.indexOf('/register') === 0
  )
  const getLogoLink = () => {
    if (isAuth) {
      return '/login'
    }
    if (isOnboarding) {
      return '#'
    }
    return '/'
  }

  const maxWidth = 1100
  const width = Math.floor(Math.min(windowWidth, maxWidth))
  const navWidth = 180 // SEAN todo make this not a constant.. it can change
  const navMobileMargin = Math.floor(navWidth * 4 / 3)
  const windowIsAtLeastMediumWidth = windowWidth > 960

  return (
    <Fragment>
      <Grid
        container
        justify='space-between'
        align={windowIsAtLeastMediumWidth ? 'flex-start' : 'center' }
        style={style ? {
          ...style,
          height: style.height,
          left: style.left,
        } : {}}
      >
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          { !isOnboarding &&
            <HeaderLink to={getLogoLink()}>
              <ListingJoyIconWithText />
            </HeaderLink>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          {isGrid && <ItemListFilterBar />}
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          { (!isOnboarding && !isAuth) &&
            <div style={windowIsAtLeastMediumWidth ? {
              float: 'right',
              marginRight: 15,
            } : {
              marginBottom: 24,
              marginLeft: `calc((100vw - ${navMobileMargin}px) / 2)`,
            }}>
             <NavPills
                color="primary"
                tabs={[
                  {
                    tabIcon: DashboardIcon,
                    path: '/',
                  },
                  {
                    tabIcon: StorefrontIcon,
                    path: '/settings',
                  },
                  {
                    tabIcon: PersonIcon,
                    path: '/account',
                  }
                ]}
              />
            </div>
          }
        </Grid>
        {
          isGrid &&
          <Grid container style={{
              marginBottom: 12,
              marginTop: windowIsAtLeastMediumWidth ? -32 : -92,
            }}>
            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
              <Typography variant="h1">
                Inventory
              </Typography>
              { itemCountString &&
                <div style={{
                  textTransform: 'uppercase',
                  fontSize: 20,
                  marginTop: 16,
                }}>
                  <Typography variant="body2">
                    { itemCountString }
                  </Typography>
                </div>
              }
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Link to='/new'>
                <Button
                  round
                  color="primary"
                  style={{ float: 'right', marginRight: 15 }}
                >
                  <AddIcon />
                    New item
                </Button>
              </Link>
            </Grid>
          </Grid>
        }
      </Grid>
    </Fragment>
  )
}

export default Nav
