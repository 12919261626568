import React from 'react'
import MerchantConnections from '../account/MerchantConnections'
import ChromeExtensionAlert from '../base/ChromeExtensionAlert'

import {
  Page,
} from '../../styles/layout'

import {
  Typography,
} from '@material-ui/core';

const Settings = ({ history }) => (
  <Page>
    <div style={{ marginBottom: 30 }}>
      <Typography variant="h1">
        Settings
      </Typography>
    </div>

    <div style={{ marginBottom: 30 }}>
      <Typography variant="h3">
        Merchant Connections
      </Typography>
    </div>
    <ChromeExtensionAlert style={{ marginBottom: 30 }}/>
    <MerchantConnections
      showPoshmarkSync={true}
      history={history}
    />
  </Page>
)

export default Settings
