
import React from 'react'
import iconStyles from './iconStyles'

const PoshmarkIcon = ({ style }) => (
  <div style={{
    ...iconStyles,
    ...style,
  }}>
    <svg viewBox="0 0 150 150"><g fill="#822533"><path d="M86.638 34.868c-11.92.02-21.574 9.81-21.58 21.884v34.386h7.983V56.752c.01-7.494 5.935-13.604 13.332-13.746 7.398-.14 13.547 5.737 13.835 13.225.288 7.49-5.39 13.835-12.777 14.276v8.103c11.67-.568 20.782-10.43 20.568-22.26-.214-11.833-9.677-21.35-21.36-21.482z"></path><path d="M62.815 111.86c11.868-.025 21.48-9.805 21.494-21.87V55.6h-7.955v34.39c-.017 7.495-5.93 13.6-13.3 13.732-7.37.132-13.492-5.755-13.77-13.245-.278-7.49 5.39-13.83 12.75-14.26V68.11c-11.7.476-20.88 10.37-20.664 22.27.215 11.902 9.746 21.447 21.454 21.486l-.01-.008z"></path></g></svg>
  </div>
)

export default PoshmarkIcon
