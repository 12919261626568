import React from 'react'

import {
  FormHelperText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import styles from './ItemFormStyles'
import TextFieldWithSavedNote from './TextFieldWithSavedNote'
import InputLabel from '../../base/InputLabel'


class ItemFormInputTitle extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      title: props.defaultTitle || '',
    }
  }

  onTitleChange = function(e) {
    this.setState({ title: e.target.value })
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  render() {
    const { defaultTitle, maxCharacters, loading } = this.props
    const hasError = !loading && (this.state.title.length === 0 || (maxCharacters !== undefined ? this.state.title.length > maxCharacters : false))
    return (
      <div>
          <InputLabel>Title</InputLabel>
          <TextFieldWithSavedNote
            type="text"
            name="title"
            fullWidth
            defaultValue={defaultTitle}
            onChange={this.onTitleChange.bind(this)}
            variant="filled"
            error={hasError}
            aria-describedby="form-item-title-helper"
            loading={loading}
          />
          <FormHelperText
            id="form-item-title-helper"
            style={{
              marginBottom: 20,
              color: hasError ? 'red' : 'inherit',
            }}
          >
          { maxCharacters ?
            `${this.state.title.length} of ${maxCharacters} characters` :
            `${this.state.title.length} character${this.state.title.length === 1 ? '' : 's'}`
          }
          </FormHelperText>

      </div>
    )
  }

}

export default withStyles(styles)(ItemFormInputTitle)
