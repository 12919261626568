import React from 'react'

import Iframe from 'react-iframe'
import $ from 'jquery'

import {
  ListingJoyExternalEvent,
  MercariEvent,
} from '../../constants'

import config from '../../config'
import Store from '../../store'
import { Message, EE, Events } from '../../message'
import updateItem from '../../actions/updateItem'

class ItemDelisterMercari extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      listingID: null,
    }
    this.itemID = null

    this.delistItem = this.delistItem.bind(this)
    this.onCompleteDelist = this.onCompleteDelist.bind(this)
    this.onReceiveShouldDelistData = this.onReceiveShouldDelistData.bind(this)
  }

  componentDidMount() {
    document.addEventListener(MercariEvent.RECEIVE.DELIST_LISTING, this.onCompleteDelist)

    EE.addListener(Events.ItemDelisterMercari.DELIST, this.onReceiveShouldDelistData)
  }

  componentWillUnmount() {
    document.removeEventListener(MercariEvent.RECEIVE.DELIST_LISTING, this.onCompleteDelist)

    EE.removeListener(Events.ItemDelisterMercari.DELIST, this.onReceiveShouldDelistData)
  }

  delistItem(item) {

    if (this.state.listingID) {
      return EE.emitEvent(Events.ItemDelisterMercari.DID_DELIST, [{
        errors: [{
          message: 'Currently delisting another item from Mercari. Wait for that to finish first.'
        }]
      }, null, item.id])
    }


    console.log('Delist item with data: ', item)


    const isExternalSession = (Store.getUserDetailsMercari() || {}).isExternalSession || false
    const listingID = item.merchants.mercari.listedID

    this.itemID = item.id

    if (isExternalSession) {
      // Send a message to the extension so we delist in another tab
      const delistURL = `https://www.mercari.com/sell/edit/${listingID}/?${MercariEvent.SEND.DELIST_LISTING}=${listingID}`
      Message.notifyExtension(ListingJoyExternalEvent.SEND.MERCARI.DELIST_LISTING_EXTERNAL, {
        url: delistURL,
      })
    } else {
      // By loading this iframe with the given parameters, the content script
      // will automatically delist the listing
      this.setState({
        listingID,
      })
    }
  }

  onReceiveShouldDelistData(data) {
    this.delistItem(data)
  }

  onCompleteDelist(e) {
    const data = e.detail.data || { errors: [{ message: 'Unknown error delisting from Mercari' }]}
    if (!this.itemID) {
      return console.error('No current itemID available in state')
    }
    const itemID = (' ' + this.itemID).slice(1) // Copy https://stackoverflow.com/a/31733628

    console.log('Received data from delist action: ', data)

    const success = data && (data.errors || []).length === 0 && data.success
    if (success) {
      updateItem(itemID, {
        merchants: {
          mercari: {
            listed: false,
          },
        }
      }).then(updatedValues => {
        EE.emitEvent(Events.ItemDelisterMercari.DID_DELIST, [data, updatedValues, itemID])
      }).catch(error => {
        EE.emitEvent(Events.ItemDelisterMercari.DID_DELIST, [{ errors: [{ message: error.message || 'Unknown error delisting from Mercari' }]}, null, itemID])
      })
    } else {
      EE.emitEvent(Events.ItemDelisterMercari.DID_DELIST, [data, null, itemID])
    }

    if (this.props.onCompleteDelist) {
      this.props.onCompleteDelist(data)
    }

    this.setState({
      listingID: null
    })
    this.itemID = null
  }

 /* Need to pass data as follows:
   1) this code to background
   2) background to content script iframeLoaded in mercari
   3) content script back to background
   4) background back listingjoy content script
   5) LJ content script dispatches an event that we are listening for in this component */
  render() {
    if (!config.merchantsAvailable.mercari) {
      return null
    }
    const { listingID } = this.state
    if (!listingID) {
      return null
    }
    return (
      <Iframe
        id='iframe-delist-mercari'
        url={`https://www.mercari.com/sell/edit/${listingID}/?${MercariEvent.SEND.DELIST_LISTING}=${listingID}`}
        width={800}
        height={800}
        display='initial'
        position='absolute'
      />
    )
  }
}

export default ItemDelisterMercari
