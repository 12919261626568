import firebase from 'firebase/app'
import ReactGA from 'react-ga'
import config from '../config'

const authorizeEbay = async (code) => {

  ReactGA.event({
    category: 'Connections',
    action: 'Authorize Ebay',
  })

  const token = await firebase.auth().currentUser.getIdToken(true)

  return new Promise(async (resolve, reject) => {
    if (!token) {
      const error = new Error('No id token for user')
      console.error(error)
      return reject(error)
    }
    try {
      const result = await fetch(`${config.cloudFunctionBaseURL}/ebayCode?code=${encodeURIComponent(code)}&token=${encodeURIComponent(token)}`, { 
         headers: new Headers({
           'Authorization': `Bearer ${token}`,
         }),
       })

      console.log('Got result from ebay authorize: ', result)
      if (result.ok) {
        return resolve()
      } else {
        console.log('Got response code : ', result.status)
        return reject(new Error(`Got status code: ${result.status}`))
      }
    }
    catch (e) {
      return reject(e)
    }
  })
}

export default authorizeEbay
