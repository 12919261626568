import React from 'react'

import ConnectionVerifierFacebook from './ConnectionVerifierFacebook'
import ConnectionVerifierMercari from './ConnectionVerifierMercari'
import ConnectionVerifierPoshmark from './ConnectionVerifierPoshmark'
import ConnectionVerifierTradesy from './ConnectionVerifierTradesy'

import config from '../../config'

class ConnectionVerifier extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
    this.onReceiveLoginData = this.onReceiveLoginData.bind(this)
    this.hasReceivedLoginData = false

    this.currentTimeoutToken = setTimeout(() => {
      this.onReceiveLoginData({ username: null })
    }, config.connectionVerifyTimeoutInSeconds * 1000)
  }

  onReceiveLoginData(data) {
    if (this.hasReceivedLoginData) {
      return
    }

    this.hasReceivedLoginData = true
    if (this.props.onReceiveLoginData) {
      this.props.onReceiveLoginData(data)
    }  
  }

  render() {
    const { merchantName } = this.props
    switch (merchantName) {
      case 'facebook':
        return <ConnectionVerifierFacebook onReceiveLoginData={this.onReceiveLoginData} />
      case 'mercari':
        return <ConnectionVerifierMercari onReceiveLoginData={this.onReceiveLoginData} debug={this.props.debug}/>
      case 'poshmark':
        return <ConnectionVerifierPoshmark onReceiveLoginData={this.onReceiveLoginData} debug={this.props.debug}/>
      case 'tradesy':
        return <ConnectionVerifierTradesy onReceiveLoginData={this.onReceiveLoginData} />
      case 'etsy':
      case 'ebay':
        return null
      default:
        console.error(`Unhandled merchantName ${merchantName} in ConnectionVerifier`)
        return null
    }
  }
}

export default ConnectionVerifier