
// Source: https://stackoverflow.com/a/16861050
export default ({url, title, w, h}) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  const finalWidth = w / systemZoom;
  const finalHeight = h / systemZoom;

  const metadata = `
    scrollbars=yes,
    width=${finalWidth},
    height=${finalHeight},
    top=${top},
    left=${left}
  `

  console.log('Pop up window with metadata: ', url, metadata)

  const newWindow = window.open(url, title, metadata)

  if (window.focus) {
    if (newWindow) {
      newWindow.focus()
    } else {
      console.error('No popup appeared for url: ', url)
    }
  }

  return newWindow;
}
