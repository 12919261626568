// the main file in our front-end app
// create-react-app expects a file in src/index.js and loads everything from here

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'

import App from './views/App'

console.log('create-react-app env:', process.env.NODE_ENV)
console.log('listingjoy project:', process.env.REACT_APP_ENV)

// connect our app to firebase 
const dbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,

  // Sean TODO add to env if needed (is it used?)
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // measurementId: "G-FVN2LF321H"
}
firebase.initializeApp(dbConfig)

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase.firestore().enablePersistence({
  synchronizeTabs: true,
}).catch(function(err) {
  if (err.code == 'failed-precondition') {
    console.error('Firestore error: Multiple tabs open, persistence can only be enabled in one tab at a a time.')
  } else if (err.code == 'unimplemented') {
    console.error('Firestore error: The current browser does not support all of the features required to enable persistence')
  } else if (err) {
    console.error(err)
  }
})

// Google Analytics
// https://github.com/react-ga/react-ga#api
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)

// Sentry
// https://docs.sentry.io/clients/javascript/integrations/react/
// if (process.env.REACT_APP_SENTRY_RAVEN_TRACKING_URL !== 'local') {
//   window.Raven.config(process.env.REACT_APP_SENTRY_RAVEN_TRACKING_URL).install()
// }

// render the App component to our document root with React
ReactDOM.render(<App />, document.getElementById('root'))
