import React from 'react'

import Error from '../misc/Error'
import GoogleButton from 'react-google-button'
import { Alert } from '@material-ui/lab'

import logInWithEmail from '../../actions/logInWithEmail'
import logInWithGoogle from '../../actions/logInWithGoogle'
import signUpWithEmail from '../../actions/signUpWithEmail'
import store from '../../store'

import {
  Button,
  Grid,
  TextField,
} from '@material-ui/core'

import ButtonLoader from '../base/ButtonLoader'

class Login extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      email: '',
      password: '',

      loadingLogin: false,
      loadingSignup: false,
    }
  }

  onEmailChange(e) {
    this.setState({ email: e.target.value })
  }

  onPasswordChange(e) {
    this.setState({ password: e.target.value })
  }

  async onClickLogin(e) {
    e.preventDefault()

    this.setState({
      loadingLogin: true
    })
    

    try {
      const { email, password } = this.state
      await logInWithEmail(email, password)()
      store.setCredentials(email, password)
      this.setState({
        errorMessage: null,
        loadingLogin: false,
      })
    }
    catch (e) {
      console.error('Login failed', e)
      this.setState({
        errorMessage: e.message,
        loadingLogin: false,
      })
    }
  }

  async onClickSignup(e) {
    console.log('click signup..', this.state)
    e.preventDefault()
    
    this.setState({
      loadingSignup: true
    })

    try {
      await signUpWithEmail(this.state.email, this.state.password)()
      this.setState({
        errorMessage: null,
        loadingSignup: false,
      })

      if (this.props.history) {
        this.props.history.push('/onboarding/chrome')
      } else {
        console.log('No history available')
      }
    }
    catch (e) {
      console.error('Signup failed', e)
      return this.setState({
        errorMessage: e.message,
        loadingSignup: false,
      })
    }
  }

/*

            <p>Log in or Sign Up to see your account</p>

            <GoogleButton
              onClick={logInWithGoogle}
            />

            <br />
            <p>or, log in with an email and password</p>

*/
  render() {
    const { errorMessage, loadingSignup, loadingLogin, email, password } = this.state
    return (

        <Grid container justify='center' alignItems='center'>
          <Grid item xs={12} sm={8} md={6} lg={6} xl={4}>
            <p>Log In or Sign Up to see your account:</p>
            {
              errorMessage &&
              <Alert severity="error" style={{ marginBottom: 20 }}>
                {errorMessage}
              </Alert>
            }
            <form>
              <TextField
                type="text" 
                name="email"
                onChange={this.onEmailChange.bind(this)}
                placeholder="Email address"
                fullWidth
                variant="filled"
                defaultValue={''}
                required 
                InputProps={{
                  style: {
                    marginBottom: 15,
                  },
                }}
                inputProps={{
                  style: {
                    paddingTop: 10,
                    borderRadius: 3,
                    background: 'rgb(245, 245, 245)',
                  },
                }}
              />
              <TextField
                type="password" 
                name="password"
                onChange={this.onPasswordChange.bind(this)}
                placeholder="Password"
                fullWidth
                variant="filled"
                defaultValue={''}
                required 
                InputProps={{
                  style: {
                    marginBottom: 15,
                  },
                }}
                inputProps={{
                  style: {
                    paddingTop: 10,
                    borderRadius: 3,
                    background: 'rgb(245, 245, 245)',
                  },
                }}
              />

              <Grid container style={{ marginTop: 30 }}>
                <Grid item md={6}>
                  <Button 
                    onClick={this.onClickLogin.bind(this)} 
                    variant="contained" 
                    color="primary" 
                    disabled={email.length === 0 || password.length < 6 || loadingLogin || loadingSignup}
                  >Log in {loadingLogin && <ButtonLoader/>}</Button>
                </Grid>

                <Grid item md={6}>
                  <Button 
                    onClick={this.onClickSignup.bind(this)} 
                    variant="contained" 
                    color="secondary" 
                    disabled={email.length === 0 || password.length < 6 || loadingLogin || loadingSignup}
                  >Sign up {loadingSignup && <ButtonLoader/>}</Button>
                </Grid>
              </Grid>
            </form>

          </Grid>
        </Grid>
    )
  }
}

export default Login
