

class ImportedMapPoshmark {

  constructor() {
    this.map = {}
  }

  hasImported(id) {
    return !!this.map[id]
  }

  add(id) {
    this.map[id] = true
  }
}
const Singleton = (() => {
  let instance;

  function createInstance() {
    let map = {}
    return map;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance()
      }
      return instance
    }
  }
})()

export let importedMapPoshmark = new ImportedMapPoshmark()