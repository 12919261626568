import firebase from 'firebase/app'
import ReactGA from 'react-ga'
import queryString from 'query-string'
import decode from 'unescape'
import config from '../config'

const fetchListedItemIDs = async (merchantName) => {

  ReactGA.event({
    category: 'Fetch',
    action: 'Fetch listed item IDs',
  })

  const currentUser = firebase.auth().currentUser
  if (!currentUser) {
    console.log('Not logged in')
    return
  }

  const token = await currentUser.getIdToken(true)

  try {
    const queryParams = queryString.stringify({
      merchantName,
      token,
    })
    const result = await fetch(`${config.cloudFunctionBaseURL}/fetchListedItemIDs?${queryParams}`)
    let json = await result.json()
    return json.ids || []
  } catch (e) {
    console.error(e);
  }
}

export default fetchListedItemIDs
