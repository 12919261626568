import React from 'react'

import { 
  Table,
  TableBody,
} from '@material-ui/core';

import ItemTableRow from './ItemTableRow'
import ItemCard from './ItemCard'
import Nav from '../layout/Nav'
import { withStyles } from '@material-ui/core/styles'

import { VariableSizeGrid } from 'react-window'
import windowSize from 'react-window-size'

import Store from '../../store'
import $ from 'jquery'
import { EE, Events } from '../../message'

const styles = theme => ({
  row: {
    cursor: 'pointer',
  },
  image: {
    maxWidth: 100,
    maxHeight: 100,
    padding: 0,
    margin: 0,
  },
  rowSubtitleItem: {
    marginRight: 20,
  },
})

const numColumns = (width) => {
  if (width < 720) {
    return 2
  }
  if (width < 960) {
    return 3
  }
  return 4
}
const itemPadding = 12
const maxWidth = 1094
const navHeightWide = 208
const navHeightNarrow = 300
const gridTopPadding = 75
const rowHeight = 386
const extraHeight = 40 // not sure why we need this to get the grid flush with the bottom of the screen.. investigate
const initialNavHeight = window.innerWidth > 960 ? navHeightWide : navHeightNarrow
let lastShowMiniNavValue = null

const Cell = ({ columnIndex, rowIndex, style, data }) => {

  let gutter = 0
  if (window.innerWidth > maxWidth) {
    gutter = Math.floor((window.innerWidth - maxWidth) / 2)
  }

  if (rowIndex === 0 && columnIndex === 0) {
    const navWidth = Math.min(data.windowWidth, maxWidth)
    return (
      <Nav
        style={{
          ...style,
          left: style.left + gutter + itemPadding,
          width: navWidth - (2 * itemPadding),
          height: data.navHeight,
          paddingTop: 32,
        }}
        itemCountString={data.itemCountString}
        windowWidth={data.windowWidth}
      />
    )
  } else if (rowIndex === 0) {
    return null
  }
  // For some reason, currently we do not get updated card positions in the style object when we resize the
  // window and the navHeight changes.
  // So our hacky workaround is we compute the difference between our current navHeight and
  // the original navHeight and we offset the card absolute positions by the difference
  const navHeightOffset = data.navHeight - initialNavHeight

  const itemRowIndex = rowIndex - 1
  const items = data.items || []
  const item = items[(itemRowIndex * 4) + columnIndex]
  if (!item) {
    return null
  }
  //console.log('Style for item: ', columnIndex, rowIndex, style, props)
  return (
    <ItemCard 
      style={{
        ...style,
        // Center the items for large screens
        left: style.left + gutter,
        top: style.top + navHeightOffset,
      }}
      key={item.id} 
      item={item}
      index={{
        columnIndex,
        rowIndex,
      }}
    />
  )
}

class ItemTable extends React.Component {

  constructor(props) {
    super(props)

    this.onViewStyleChanged = this.onViewStyleChanged.bind(this)

    this.state = {
      viewStyle: 'grid',
    }

    this.gridRef = Store.getGridRef()
  }

  componentDidMount() {
    document.addEventListener('view:style:change', this.onViewStyleChanged)

    window.document.body.style.height = '100%'
    window.document.body.style['overflow-y'] = 'hidden'

    const { rowIndex } = Store.getItemIndex()
    if (rowIndex > 0) {
      let intervalToken = setInterval(() => {
        if (!this.gridRef || !this.gridRef.current) {
          console.log('Attempting to scroll to column index:', rowIndex)
          return
        }
        clearInterval(intervalToken)
        if (rowIndex > 1) {
          this.gridRef.current.scrollToItem({ 
            align: 'center',
            rowIndex 
          })
        }
      }, 20)
    }
  }

  componentWillUnmount() {
    window.document.body.style.removeProperty('height')
    window.document.body.style.removeProperty('overflow-y')

    document.removeEventListener('view:style:change', this.onViewStyleChanged)
  }

  onViewStyleChanged(e) {
    const value = e.detail.data.value

    console.log('View style changed: ', value)
    this.setState({
      viewStyle: value,
    })
  }

  render() {
    const { classes, items, windowWidth, windowHeight, itemCountString } = this.props
    
    if (this.state.viewStyle === 'table') {
      return (
        <Table size={'medium'}>
          <TableBody>
           {
             items.map(item => <ItemTableRow key={item.id} item={item} />)
           }
          </TableBody>
        </Table>
      )
    }

    const columnCount = numColumns(windowWidth)
    const rowCount = Math.ceil(items.length / columnCount)
    const columnWidth = Math.min(Math.ceil(maxWidth / columnCount), Math.floor(windowWidth / columnCount))
    const windowIsAtLeastMediumWidth = windowWidth > 960
    const navHeight = windowIsAtLeastMediumWidth ? navHeightWide : navHeightNarrow

    return (
      <VariableSizeGrid
        ref={this.gridRef}
        columnCount={columnCount}
        columnWidth={() => columnWidth}
        estimatedColumnWidth={columnWidth}
        height={windowHeight /*+ extraHeight /* TODO instead of this, add a fixed padding/margin under the bottom row. then the scrollbar will be flush with the bottom */}
        rowCount={rowCount + 1}
        rowHeight={(index) => {
          if (index === 0) {
            // Nav is top row
            return navHeight
          }
          return rowHeight
        }}
        estimatedRowHeight={rowHeight}
        itemCount={items.length}
        itemData={{
          navHeight,
          itemCountString,
          windowWidth,
          items,
        }}
        width={windowWidth}
        style={{
          overflowX: 'hidden',
        }}
        windowWidth={windowWidth}
        overscanRowCount={3}
        onScroll={({
          scrollTop,
        }) => {
          const showMiniNav = scrollTop > navHeight
          if (lastShowMiniNavValue !== showMiniNav) {
            lastShowMiniNavValue = showMiniNav
            EE.emitEvent(Events.ItemList.SHOULD_UPDATE_MINI_NAV_VISIBILITY, [scrollTop > navHeight])
          }
        }}
      >
        {Cell}
      </VariableSizeGrid>
    ) 
  }
}

export default windowSize(ItemTable)
//export default withStyles(styles)(ItemTable)
