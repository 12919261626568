import React, {Fragment} from 'react'

import {
  FormControl,
  MenuItem,
} from '@material-ui/core';
import InputLabel from '../../../base/InputLabel'
import {withStyles} from '@material-ui/core/styles'
import styles from '../ItemFormStyles'
import SelectWithSavedNote from '../SelectWithSavedNote'

import poshmarkColorData from '../../../data/colors'
const poshmarkColors = poshmarkColorData.colors

// Etsy-specific colors
const colors = [
  {value: 1213, name: "Beige", css: "rgb(245,245,220)"},
  {value: 1, name: "Black", css: "black"},
  {value: 2, name: "Blue", css: "rgb(19, 127, 193)"},
  {value: 1216, name: "Bronze", css: "rgb(205,127,50)"},
  {value: 3, name: "Brown", css: "rgb(102, 53, 9)"},
  {value: 1219, name: "Clear", css: "rgb(224,254,240)"},
  {value: 1218, name: "Copper", css: "rgb(184,115,51)"},
  {value: 1214, name: "Gold", css: "rgb(255, 215, 46)"},
  {value: 5, name: "Gray", css: "rgb(146, 146, 146)"},
  {value: 4, name: "Green", css: "rgb(60, 156, 68)"},
  {value: 6, name: "Orange", css: "rgb(252, 166, 40)"},
  {value: 7, name: "Pink", css: "rgb(251, 22, 128)"},
  {value: 8, name: "Purple", css: "rgb(127, 15, 129)"},
  {value: 1220, name: "Rainbow", css: "linear-gradient(rgb(255, 0, 0),  rgb(255, 165, 0),  rgb(255, 255, 0), rgb(0, 128, 0),  rgb(238, 130, 238),  rgb(75, 0, 130),  rgb(0, 0, 255))"},
  {value: 9, name: "Red", css: "rgb(234, 46, 46)"},
  {value: 1217, name: "Rose gold", css: "rgb(183,110,121)"},
  {value: 1215, name: "Silver", css: "rgb(209, 180, 142)"},
  {value: 10, name: "White", css: "rgb(255, 255, 255)"},
  {value: 11, name: "Yellow", css: "rgb(255, 238, 55)"}
]

class ItemFormInputColor extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      color: props.defaultEtsyColor || (props.defaultColor ? mapPoshmarkColorToEtsyColor(props.defaultColor) : undefined),
    }
  }

  onColorChange = function (e) {
    this.setState({color: e.target.value})
    if (this.props.onChange) {
      this.props.onChange()
    }
  }

  render() {
    const {classes, secondary, labelText} = this.props;
    const type = secondary ? 'Secondary' : 'Primary';
    const label = labelText || `${type} Color`
    return (
      <Fragment>
        <InputLabel optional={secondary}>{label}</InputLabel>
        <FormControl
          className={classes.colorControl}
          fullWidth
        >
          <SelectWithSavedNote
            labelId="form-item-color-label"
            name={`etsyColor${type}`}
            value={this.state.color || ''}
            onChange={this.onColorChange.bind(this)}
            required={!secondary}
            fullWidth
          >
            {colors.map(color => <MenuItem key={color.value} value={color.value}>
              <div style={{
                  display: 'block',
                  width: '100%'}}>
                {color.name}
                <span style={{
                  height: 20,
                  width: 20,
                  background: `${color.css}`,
                  borderColor: 'rgb(180, 180, 180)',
                  borderWidth: 1,
                  borderRadius: '50%',
                  float: 'right',
                }}></span>
              </div>

            </MenuItem>)}
          </SelectWithSavedNote>
        </FormControl>
      </Fragment>
    )
  }
}

const mapPoshmarkColorToEtsyColor = (poshmarkColor) => {
  if (!poshmarkColor) {
    return undefined
  }
  switch (poshmarkColor.toLowerCase()) {
    case "red":
      return 9
    case "pink":
      return 7
    case "orange":
      return 6
    case "yellow":
      return 11
    case "green":
      return 4
    case "blue":
      return 2
    case "purple":
      return 8
    case "gold":
      return 1214
    case "silver":
      return 1215
    case "black":
      return 1
    case "gray":
      return 5
    case "white":
      return 10
    case "cream":
      return 1213 // beige
    case "brown":
      return 3
    case "tan":
      return 1213 // beige
    default:
      return undefined
  }
}

export default withStyles(styles)(ItemFormInputColor)
