import React, { Fragment } from 'react'

import $ from 'jquery'

import {
  Button,
  Grid,
  Paper,
  Popover,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'

import ConnectionBadgeLabel from '../base/ConnectionBadgeLabel'
import ClipLoader from 'react-spinners/ClipLoader'
import SyncIcon from '@material-ui/icons/Sync'
import RefreshIcon from '@material-ui/icons/Refresh'
import ExtensionIcon from '@material-ui/icons/Extension'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import ChromeExtensionVerifier from '../base/ChromeExtensionVerifier'
import ConnectionVerifier from './ConnectionVerifier'
import MerchantIcon from '../icons/MerchantIcon'

import chromeExtensionInstallPopup from '../base/js/chromeExtensionInstallPopup'
import popup from '../base/js/popup'
import SuccessIndicator from '../base/SuccessIndicator'
import Config from '../../config'
import Store from '../../store'
import Merchant from '../../merchant'
import { Message } from '../../message'
import { MercariEvent, PoshmarkEvent, ListingJoyExternalEvent } from '../../constants'
import store from 'store'

const styles = theme => ({
  root: {
    margin: theme.spacing(3, 0),
  },
  connectButton: {
    margin: theme.spacing(1),
  },
});

const eventForMerchantName = (merchantName) => {
  switch (merchantName) {
    case 'poshmark':
      return PoshmarkEvent.RECEIVE.LOGIN
    case 'mercari':
      return MercariEvent.RECEIVE.LOGIN
    default:
      return null
  }
}

const DEBUG = false


class MerchantConnectionItemChrome extends React.Component {

  constructor(props) {
    super(props)

    const userDetails = Store.getUserDetails(props.merchantName) || {}
    const username = userDetails.username
    // External == auth session is on another tab/window (not in iframe)
    const isExternalSession = userDetails.isExternalSession || false

    const invalidPoshmarkUid = (
      props.merchantName === 'poshmark' &&
      (!userDetails.uid || userDetails.uid.length <= 5)
    )

    console.log(`Got initial username ${username} for ${props.merchantName}`)
    this.state = {
      // If we already have a username persisted, assume we are connected
      // exception: invalid poshmark uid
      loading: !username || invalidPoshmarkUid,

      username: username,
      isExternalSession: isExternalSession,

      detectedLogin: false,

      popoverAnchorEl: null,
    }

    this.detectedLoginOnLoad = store.get(`detectedLogin${this.props.merchantName}`) || false
    this.merchantPopups = {} // merchantName -> popup window

    this.onReceiveDidLoginOnExternalSiteEvent = this.onReceiveDidLoginOnExternalSiteEvent.bind(this)
    this.onClickMerchantLoginButton = this.onClickMerchantLoginButton.bind(this)

    this.merchantSupportsTabBasedListing = this.props.merchantName === 'mercari'
  }


  componentDidMount() {
    const event = eventForMerchantName(this.props.merchantName)
    if (event) {

      console.log('LISTENING TO EVENT: ', event)
      document.addEventListener(event, this.onReceiveDidLoginOnExternalSiteEvent)
    }
  }

  componentWillUnmount() {
    const event = eventForMerchantName(this.props.merchantName)
    if (event) {
      document.removeEventListener(event, this.onReceiveDidLoginOnExternalSiteEvent)
    }
  }

  onClickMerchantLoginButton() {
    const { merchantName } = this.props
    const merchantNameFormatted = Merchant.merchantNameFormatted(merchantName)

    this.merchantPopups[merchantName] = popup({
      url: linkForMerchant(merchantName),
      title: `Login to ${merchantNameFormatted}`,
      w: 900,
      h: 500,
    }, '')
  }

  onReceiveDidLoginOnExternalSiteEvent(e) {
    const data = e.detail.data || {}
    console.log('RECEIVED LOGIN EVENT', data)


    // SEAN this may not be helpful to include because if we need this (i.e. if the ConnectionVerifierPoshmark)
    // doesn't get the username, then it means we are not in a good state to import items
    // Also the provided uid is wrong?
    // if (this.props.merchantName === 'poshmark' && data.username) {
    //   Store.setUserDetails(this.props.merchantName, data)
    // }
    //

    if (this.merchantSupportsTabBasedListing) {

      const userDetails = Store.getUserDetails(this.props.merchantName) || {}
      const { username, isExternalSession = false } = userDetails

      // If our new data is an improvement over our existing data, write to localhost
      if (data.username && (isExternalSession || !username)) {
        // Save external session data
        Store.setUserDetails(this.props.merchantName, {
          ...data,
          isExternalSession: true,
        })
      }

      if (data.username) {
        this.setState({
          detectedLogin: true
        })
      }
    }

    if (!this.state.username) {

      this.setState({
        detectedLogin: true
      })

      if (data && data.merchantName) {
        store.set(`detectedLogin${data.merchantName}`, true)
      }

      if (data && data.merchantName && this.merchantPopups[data.merchantName]) {
        console.log('Closing merchant popup', data.merchantName)
        this.merchantPopups[data.merchantName].close()
      }

      // Wait a second for the login to process on the external site's server side
      setTimeout(() => {
        Message.notifyExtension(ListingJoyExternalEvent.SEND.RELOAD, {})

        //if (!this.detectedLoginOnLoad) {
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        //}
      }, 3000)
    }
  }

  onReceiveLoginData(data) {
    console.log(`Receive login data for merchant ${this.props.merchantName}: `, data)


    if (this.merchantSupportsTabBasedListing) {
      // If our new data is an improvement over the existing data, overwrite it
      const { username } = data
      if (username) {
        Store.setUserDetails(this.props.merchantName, data)
        this.setState({
          loading: false,
          username: username,
          isExternalSession: false,
        })
      } else {
        this.setState({
          loading: false,
        })
      }
    } else {
      Store.setUserDetails(this.props.merchantName, data)
      this.setState({
        loading: false,
        username: data.username,

        // SEAN debug not sure if this is helpful actually
        // We may need to ensure we can get the Poshmark username
        // so we know we are in a good state to get importing/listing to work
        //uid: data.uid,
      })
    }
  }

  render() {

    const { merchantName, icon, classes, showPoshmarkSync, history, hideButtonsWhenConnected } = this.props
    const merchantNameFormatted = Merchant.merchantNameFormatted(merchantName)

    const { loading, username, isExternalSession, uid, popoverAnchorEl } = this.state
    const isConnected = !loading && (username || uid)
    const isHavingConnectionTrouble = !loading && !isConnected && this.detectedLoginOnLoad

    return (
      <Paper key={merchantName} className={classes.root}>
        <Grid
          container
          spacing={3}
          justify='space-between'
          alignItems='center'
        >
          <Grid item xs={12} sm={3}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <MerchantIcon
                  merchantName={merchantName}
                  style={{
                    height: 48,
                    width: 48,
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5" component="h2">
                  {Merchant.merchantNameFormatted(merchantName, true)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <ChromeExtensionVerifier>
          {
            ({ isConfidentlyNotInstalled }) => {

              const loginButton = (
                <Button
                  onClick={this.onClickMerchantLoginButton}
                  variant="contained"
                  color="primary"
                  className={classes.connectButton}
                  size="small"
                  startIcon={<ExitToAppIcon />}
                >
                  Login
                </Button>
              )
              const refreshButton = (
                <Button
                  onClick={() => {
                    Store.setUserDetails(merchantName, {})
                    window.location.reload()
                  }}
                  variant="outlined"
                  color="primary"
                  className={classes.connectButton}
                  size="small"
                  startIcon={<RefreshIcon />}
                >
                  Reconnect
                </Button>

              )
              if (isConfidentlyNotInstalled) {
                return (
                  <Fragment>
                    <Grid item xs={12} sm={3}>
                      <Alert severity='warning' style={{ marginTop: 10, marginBottom: 20 }}>
                        <span>Install the <a onClick={chromeExtensionInstallPopup} href='#' style={{ color: '#3366CC'}}>Chrome Extension</a> to connect to {merchantNameFormatted}</span>
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Button
                        onClick={chromeExtensionInstallPopup}
                        variant="contained"
                        color="primary"
                        className={classes.connectButton}
                        size="small"
                        startIcon={<ExtensionIcon />}
                      >
                        Install
                      </Button>
                    </Grid>
                  </Fragment>
                )
              }
              if (loading) {
                return (
                  <Fragment>
                    <Grid item xs={12} sm={3}>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item>
                          <div style={{
                            height: 40,
                          }}>
                            <ClipLoader />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} />
                  </Fragment>
                )
              }
              if (isConnected) {
                return (
                  <Fragment>
                    <Grid item xs={12} sm={3}>
                      <Alert severity='success' style={{ marginTop: 10, marginBottom: 20 }}>
                        { username ?
                          <span>Connected as: {username}{Config.showDebugUI && isExternalSession ? ' [external]' : ''}</span> :
                          <span>Connected</span>
                        }
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        { hideButtonsWhenConnected ?
                          <SuccessIndicator /> :
                          <Fragment>
                            {
                              showPoshmarkSync &&
                                <Grid item>
                                  <Button
                                    onClick={() => {
                                      // TODO can switch to this after we have modified the
                                      // QuickImporter so it can be re-initialized/reset
                                      //history.push('/?poshmark_sync')
                                      window.location.href = '/?poshmark_sync'
                                    }}
                                    variant="contained"
                                    color="primary"
                                    className={classes.connectButton}
                                    disabled={loading}
                                    size="small"
                                    startIcon={<SyncIcon />}
                                    aria-describedby={popoverAnchorEl ? 'list-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={(e) => { this.setState({ popoverAnchorEl: e.currentTarget }) }}
                                    onMouseLeave={() => { this.setState({ popoverAnchorEl: null }) }}
                                  >
                                    Sync Closet
                                  </Button>
                                  <Popover
                                    id={popoverAnchorEl ? 'list-popover' : undefined}
                                    open={Boolean(popoverAnchorEl)}
                                    className={classes.popover}
                                    classes={{
                                      paper: classes.paper,
                                    }}
                                    anchorEl={popoverAnchorEl}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                    onClose={() => { this.setState({ popoverAnchorEl: null }) }}
                                    disableRestoreFocus
                                  >
                                    <Alert severity='info' style={{ margin: -8 }}>Clicking this will automatically sync all of your Poshmark listings into ListingJoy. It can take a few minutes if you have lots of listings.</Alert>
                                  </Popover>
                                </Grid>
                            }
                            <Grid item>
                              {refreshButton}
                            </Grid>
                          </Fragment>
                        }
                      </Grid>
                    </Grid>
                  </Fragment>
                )
              }
              return (
                  <Fragment>
                    <Grid item xs={12} sm={3}>
                      <Alert severity='warning' style={{ marginTop: 10, marginBottom: 20 }}>
                        {
                          this.state.detectedLogin ?
                            <span>Detected login for {merchantNameFormatted}. About to refresh.. <ClipLoader size={10}/></span> :
                            (isHavingConnectionTrouble ?
                              <span>One last step needed to connect {merchantNameFormatted}. Please follow
                                <a
                                  href='//listingjoy.com/connection-trouble'
                                  target='_blank'
                                  onClick={() => {
                                    store.set(`detectedLogin${merchantName}`, false)
                                  }}
                                  style={{
                                    marginLeft: 3,
                                    color: '#3366CC',
                                  }}
                                >these instructions</a> and try logging in again.</span> :
                                <span>Not logged in to {merchantNameFormatted}. Log in to <a href={linkForMerchant(merchantName)} target='_blank' style={{ color: '#3366CC'}}>{merchantNameFormatted}</a> and refresh this page.</span>
                            )
                        }
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Grid
                        container
                        direction="column"
                        justify="space-evenly"
                        alignItems="center"
                      >
                        <Grid item>
                          {loginButton}
                        </Grid>
                        { (merchantName === 'mercari' || merchantName === 'facebook') &&
                          <Grid item style={{ marginTop: 10 }}>
                            <Typography variant='body2'>
                              <a
                                href='//listingjoy.com/connection-trouble'
                                target='_blank'
                                style={{ color: '#aaa' }}
                                onClick={() => {
                                  store.set(`detectedLogin${merchantName}`, false)
                                }}
                              >
                                Connection not working?
                              </a>
                            </Typography>
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                  </Fragment>
              )
            }
          }
          </ChromeExtensionVerifier>
          { (DEBUG || loading) &&
            <ConnectionVerifier
              merchantName={merchantName}
              onReceiveLoginData={this.onReceiveLoginData.bind(this)}
              debug={DEBUG}
            />
          }
          {
            // Try to refresh the session using internal/iframe auth
            // if we have an external (non-ideal) session
            this.merchantSupportsTabBasedListing && isExternalSession &&
            <ConnectionVerifier
              merchantName={merchantName}
              onReceiveLoginData={this.onReceiveLoginData.bind(this)}
              debug={DEBUG}
            />
          }
        </Grid>
      </Paper>
    )
  }
}

const linkForMerchant = (merchantName) => {
  switch (merchantName) {
    case 'mercari':
      return 'https://www.mercari.com/login'
    case 'poshmark':
      return 'https://www.poshmark.com/login'
    case 'facebook':
      return 'https://www.facebook.com/login'
    case 'tradesy':
      return 'https://www.tradesy.com/login'
    default:
      console.error(`Unhandled merchant ${merchantName} in linkForMerchant`)
      return '#'
  }
}

export default withStyles(styles)(MerchantConnectionItemChrome)
