import React from 'react'

import {
  InputLabel,
} from '@material-ui/core';

const extraText = (optional, customLabelText, customLabelColor) => {

  const labelText = customLabelText || '(Optional)'

  if (optional || customLabelText) {
    return (
      <span style={{
        color: customLabelColor || 'gray',
        marginLeft: 4,
        fontWeight: 500,
        fontSize: 12,
      }}>{labelText}</span>
    )
  }

  return (
    <span style={{
      color: 'red',
      marginLeft: 4,
    }}>
      *
    </span>
  )
}

const CustomInputLabel = ({
  children,
  style = {},
  optional = false,
  customLabelText = null,
  customLabelColor,
}) => (
  <InputLabel style={{
    paddingRight: 3,
    marginBottom: 10,
    fontSize: 15,
    fontWeight: 600,
    ...style,
  }}>
    {children}
    {extraText(optional, customLabelText, customLabelColor)}
  </InputLabel>
)

export default CustomInputLabel
