
import React from 'react'

import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import ItemFormSectionHeader from '../ItemFormSectionHeader'

const ItemFormSectionHeaderPrice = ({ number, text }) => (
  <ItemFormSectionHeader
    icon={<AttachMoneyIcon style={{ marginBottom: -6 }}/>}
    number={String(number)}
    text={text || 'Price'}
  />
)

export default ItemFormSectionHeaderPrice
