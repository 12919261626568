import React, { Fragment } from 'react'

import {
  Button,
  Grid,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'

import InputLabel from '../../base/InputLabel'
import SelectWithSavedNote from './SelectWithSavedNote'
import TextFieldWithSavedNote from './TextFieldWithSavedNote'

import Item from '../../../item'
import AspectHelpers from './ItemFormInputAspectHelpers'

import ItemFormInputAspectFreeText from './ItemFormInputAspectFreeText'
import ItemFormInputAspectMultiSet from './ItemFormInputAspectMultiSet'
import ItemFormInputAspectSelect from './ItemFormInputAspectSelect'
import ItemFormInputAspectSelectFreeText from './ItemFormInputAspectSelectFreeText'


class ItemFormInputAspect extends React.Component {

  constructor(props) {
    super(props)
  }

  /** Types of aspects:
   * 1) 1 value set, SelectionOnly (most common)
   * 2) >1 value sets, SelectionOnly (eg poshmark dresses: Maternity, Petite, Standard. etsy blouses)
   * 3) >1 values sets (value sets have no options), FreeText
   *    (e.g. etsy perfume, categoryID 2160. scale represents volume and there is an associated number value)
   *    (a value set with any options is a "unit")
   * 4) 1 value set, FreeText (eg. ebay select from options, with ability to create own option)
   * 5) 0 value sets, FreeText (e.g. ebay MPN. manufacturer part number. just a text field)
   *
   * Also:
   * - parent -> child (mercari dress style types. Child only appears if parent is selected)
   */
  render() {
    const {
      aspect,
      merchantName,
      classes,
      onChange = () => {},
      defaultValue = '',
      isChild = false,
    } = this.props

    const valueSets = Object.values(aspect.valueSets || {})
    const isRequired = aspect.constraint.required
    const isFreeText = aspect.constraint.mode === 'FREE_TEXT'

    // #1
    if (valueSets.length === 1 && !isFreeText) {
      return (
        <ItemFormInputAspectSelect
          aspect={aspect}
          classes={classes}
          values={Object.values(valueSets[0].values || {})}
          valueSetID={valueSets[0].id}
          defaultValue={defaultValue}
          merchantName={merchantName}
          onChange={onChange}
        />
      )
    }

    // #2 + #3
    // TODO etsy blouses should be selectiononly. need to parse schema manually
    if (valueSets.length > 1) {
      return (
        <ItemFormInputAspectMultiSet
          aspect={aspect}
          classes={classes}
          defaultValueSetID={valueSets[0].id /* TODO sean if we have saved a value set, use that */}
          defaultValue={defaultValue}
          merchantName={merchantName}
          onChange={onChange}
          isFreeText={isFreeText}
        />
      )
    }

    // #4
    if (valueSets.length === 1 && isFreeText) {
      const values = Object.values(valueSets[0].values || {})

      if (values.length > 0) {
        return (
          <ItemFormInputAspectSelectFreeText
            aspect={aspect}
            values={values}
            classes={classes}
            defaultValue={defaultValue}
            merchantName={merchantName}
            onChange={onChange}
          />
        )
      }

      // #5. Free text. We have a value set with zero possible values (e.g. etsy perfume)
      return (
        <ItemFormInputAspectFreeText
          aspect={aspect}
          merchantName={merchantName}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      )
    }

    // #5
    if (valueSets.length === 0 && isFreeText) {
      // ebay MPN
      return (
        <ItemFormInputAspectFreeText
          aspect={aspect}
          merchantName={merchantName}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      )
    }

    console.log('Unhandled aspect case: ', aspect)
    debugger
    return null
  }
}




// class ItemFormInputAspectBackup extends React.Component {

//   constructor(props) {
//     super(props)

//     this.renderInputForAspect = this.renderInputForAspect.bind(this)
//   }

//   renderInputForAspect(aspect, isChild = false) {
//       if (!aspect) {
//         return null
//       }

//       let isSelectionOnly
//       switch (aspect.constraint.selectionMode) {
//         case 'FREE_TEXT':
//           isSelectionOnly = false
//           break
//         case 'SELECTION_ONLY':
//           isSelectionOnly = true
//           break
//         default:
//           return null
//       }

//       const valueSetsList = Object.values(aspect.valueSets)

//       if (!isSelectionOnly && valueSetsList.length > 1) {
//         // TODO refactor to avoid this case
//         console.error('Need to handle free text with multiple value sets')
//         return null
//       } else if (!isSelectionOnly && valueSetsList === 1) {
//         return (
//           <ItemFormInputAspectSelectFreeText
//             aspect={aspect}
//             defaultValue={null}
//             merchantName={this.props.merchantName}
//             onChange={this.props.onChange}
//           />
//         )
//       } else if (!isSelectionOnly /* and no value sets */) {
//         // Free text
//         return (
//           <TextFieldWithSavedNote
//             defaultValue=''
//             fullWidth
//           />
//         )
//       } else if (isSelectionOnly) {

//       }


//       if (
//         aspect.constraint.selectionMode === 'FREE_TEXT' &&
//       )
//       if (
//         aspect.constraint.selectionMode === 'SELECTION_ONLY' &&
//         (!aspect.valueSets || !Object.values(aspect.valueSets).length)
//       ) {
//         // TODO handle FREE_TEXT
//         return null
//       }

//       const { defaultValues } = this.props
//       const valueSets = Object.values(aspect.valueSets)
//       // Value is full JSON of option (for poshmark)
//       const defaultValue = defaultValues[input.id] ? JSON.stringify(defaultValues[input.id]) : ''
//       const isRequired = input.constraint.required

//   }

//   render() {
//     const { aspect, classes, merchantName, reset } = this.props;
//     if (!aspect || reset) {
//       return null
//     }

//     return (
//       <Grid item key={aspect.id} xs={12} sm={6} md={6} lg={6}>
//         <InputLabel optional={!aspect.constraint.required}>{aspect.name}</InputLabel>
//           {/* HACK: put data about the categorySpecifics schema in the DOM for us to parse later for form validation */}
//           <input
//             style={{ display: 'none' }}
//             id={`${merchantName}CategorySpecificsSchema[${aspect.id}]`}
//             data-json={JSON.stringify({ name: aspect.name, id: aspect.id } )}
//           />
//           {this.renderInputForAspect(aspect)}
//       </Grid>
//     )
//   }

//     const buildInput = (input, isChild = false) => {

//       const valueSets = Object.values(input.valueSets)
//       // Value is full JSON of option (for poshmark)
//       const defaultValue = defaultValues[input.id] ? JSON.stringify(defaultValues[input.id]) : ''
//       const isRequired = input.constraint.required

//       if (!valueSets.length) {
//         console.log('Sean got no valueSets')
//         return null
//       } else if (valueSets.length === 1) {

//         // One scale type. Have just one selector
//         const options = Object.values(valueSets[0].options || {})

//         // I'm still figuring out how many options there are here in terms of valueSets/tags
//         // The normalized object has a "size_set_tags" field that includes the scale name (see poshmarkChromeHelpers.js)
//         const valueSetID = Object.keys(input.valueSets)[0]

//         // SEAN TODO need to finish this
//         //
//         //
//         // We need to filter out the values to those that
//         // are valid given the current schema.
//         // For example, we could have a "size" specific that applies to a category path, but
//         // we have changed the category path and that size is no longer valid. We must identify those
//         // and change the default
//         // console.log('filter out invalid?', input.id,defaultValue, options)
//         // debugger

//         return (
//           <Fragment>
//             <FormControl
//               className={classes.conditionControl}
//               fullWidth
//             >
//               <SelectWithSavedNote
//                 name={`${merchantName}CategorySpecifics[${input.id}]`}
//                 defaultValue={defaultValue}
//                 required={isRequired}
//                 onChange={(e) => {
//                   console.log('Set option with one scale!', e.target.value)


//                   try {
//                     const optionData = JSON.parse(e.target.value)

//                     // Assume that there is only one child option for Mercari (not sure if this is correct)
//                     // TODO do I need to hide this if we deselected the child?
//                     if (optionData.id && this.childForOptionIdMap[optionData.id]) {
//                       this.setState({
//                         child: this.childForOptionIdMap[optionData.id],
//                       })
//                     } else if (!isChild && this.state.child && this.state.child[input.id]) {
//                       this.setState({
//                         child: null,
//                       })
//                     }
//                   } catch (e) {
//                     console.error('Error changing cat specific:', e)
//                   }
//                   if (this.props.onChange) {
//                       this.props.onChange()
//                   }
//                 }}
//               >
//                 {
//                   options.map(option => {
//                     const value = this.normalizeValueForMerchant(option, valueSetID, defaultValue)
//                     return <MenuItem key={`${option.id}${valueSetID}`} value={value}>{option.display}</MenuItem>
//                   })
//                 }
//               </SelectWithSavedNote>
//             </FormControl>
//             {
//               // TODO instead of hardcoding sizeId here we want to check if the child corresponds to the selected values
//               // our assumption here breaks if there are any other specifics besides the parent and the size options
//               // so we should change this
//               !isChild && this.state.child && input.id !== 'sizeId' &&
//               Object.values(this.state.child).map(input => {
//                 const isRequired = input.constraint.required
//                 return (
//                   <Fragment key={input.id}>
//                     <InputLabel optional={!isRequired}>{input.display}</InputLabel>
//                     {/*.This is a hacky div that lets us put data about the categorySpecifics schema in the DOM for us to parse later for form validation */}
//                     {/*.This is the worst shit I've ever seen.. eventually use Redux or some shared State object instead but for now I'm sorry */}
//                     <input style={{ display: 'none' }} id={`${merchantName}CategorySpecificsSchema[${input.id}]`} data-json={JSON.stringify(input)} />
//                     <input style={{ display: 'none' }} id={`${merchantName}CategorySpecificsSchemaChild`} data-json={String(input.id)} />
//                     {buildInput(input, true)}
//                   </Fragment>
//                 )
//               })
//             }
//           </Fragment>
//         )
//       } else {

//         // For multiple valueSets, our default scale value is the selected size_set_tag (poshmark only)
//         // TODO handle non poshmark
//         // TODO handle multiple inputs. Currently it handles only one additional field
//         // valueSetID should be changed to a map of { categorySpecificID: valueSetID }
//         let defaultScale = this.state.valueSetID || ''
//         if (
//           merchantName === 'poshmark' &&
//           defaultValues[input.id] &&
//           defaultValues[input.id].size_set_tags &&
//           defaultValues[input.id].size_set_tags.length
//         ) {
//           defaultScale = defaultValues[input.id].size_set_tags[0]
//         }

//         // This handles valueSets with multiple options (targeted given etsy system)
//         // Unlike poshmark, we don't save the scale ID on the backend yet explicitly, so we need to find the corresponding
//         // option within the scale schema
//         // Example: categorySpecificsEtsy/443 inseam in/cm + all options
//         if (defaultValues[input.id]) {

//           const scale = valueSets.find(scale => {
//             const optionValue = defaultValues[input.id]
//             return optionValue && optionValue.id && scale.options && Object.values(scale.options).find(option => option.id === optionValue.id) !== undefined
//           })
//           if (scale && scale.id) {
//             defaultScale = scale.id
//           }
//         }

//         // This handles when there is a select scale without any options
//         // For Etsy, sometimes valueSets exist without options, so we just set the scale's value to the value
//         // Example: categorySpecificsEtsy/299 Volume valueSets
//         if (
//           defaultValues[input.id] &&
//           valueSets.find(scale => scale.id === defaultValues[input.id]) !== undefined
//         ) {
//           defaultScale = defaultValues[input.id]
//         }

//         const options = defaultScale && defaultScale !== '' ? Object.values((valueSets.find(scale => scale.id === defaultScale) || {}).options || {}) : []
//         const isRequired = input.constraint.required
//         const hasTextField = input.constraint.selectionMode === 'FREE_TEXT'

//         console.log('Sean scale + options', defaultScale, options)

//         return (
//           <Fragment>
//             <FormControl
//               className={classes.conditionControl}
//               fullWidth
//             >
//               <SelectWithSavedNote
//                 name={`${merchantName}CategorySpecificsScale[${input.id}]`}
//                 defaultValue={defaultScale}
//                 required={isRequired}
//                 fullWidth
//                 onChange={(e) => {
//                   console.log('Set option with multi valueSets!', e.target.value)
//                   this.setState({ valueSetID: e.target.value })
//                 }}
//               >
//                 {
//                   valueSets.map(scale => {
//                     return (
//                       <MenuItem
//                         key={scale.id}
//                         value={scale.id}
//                       >{scale.display}</MenuItem>
//                     )
//                   })
//                 }
//               </SelectWithSavedNote>
//             </FormControl>
//             { options.length > 0 &&
//               <FormControl
//                 className={classes.conditionControl}
//                 fullWidth
//               >
//                 <SelectWithSavedNote
//                   name={`${merchantName}CategorySpecifics[${input.id}]`}
//                   required={isRequired}
//                   defaultValue={defaultValue}
//                   fullWidth
//                   onChange={(e) => {
//                     console.log('Set option!', e.target.value)
//                   }}
//                 >
//                   {
//                     options.map(option => {
//                       return <MenuItem key={option.id} value={this.normalizeValueForMerchant(option, defaultScale, defaultValue)}>{option.display}</MenuItem>
//                     })
//                   }
//                 </SelectWithSavedNote>
//               </FormControl>
//             }
//             {
//               hasTextField &&
//               <TextFieldWithSavedNote
//                 defaultValue='0'
//                 fullWidth
//               />
//             }
//           </Fragment>
//         )
//         return null
//       }
//     }

//   }

// }

export default ItemFormInputAspect
