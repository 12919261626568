import React from 'react'
import { 
  InputAdornment,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    root: {
        padding: `3px 6px`,
    },
})

const CustomInputAdornment = (props) => (
  <InputAdornment
    {...props}
    classes={props.classes}
  >
    {props.children}
  </InputAdornment>
)

export default withStyles(styles)(CustomInputAdornment)